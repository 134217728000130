import ReactDOM from "react-dom";
import { IoCloseSharp } from "react-icons/io5";
import { useAuth } from "../../auth/AuthContext";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function NotificationModal({
  passed,
  setNotificationModal,
  notification,
}) {
  console.log("Notification: ", notification);
  const { darkMode } = useAuth();
  const [, profile, , roles] = useOutletContext();
  const navigate = useNavigate();

  return ReactDOM.createPortal(
    <div
      className={`bg-black bg-opacity-20 z-40 w-screen h-screen fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center ${
        darkMode ? "dark" : ""
      } `}
    >
      <div
        className="bg-white mx-2 dark:bg-dark-bg dark:text-secondary-text p-10 rounded-md shadow-md flex flex-col items-center"
        ref={passed}
      >
        {/* {children} */}
        <div className="flex justify-between items-center w-full mb-5">
          <div>
            <span className="font-bold text-lg">Notification Details</span>
          </div>
          <div
            className="dark:hover:bg-dark-bg-600 hover:bg-accent p-2 rounded-full cursor-pointer"
            onClick={() => setNotificationModal(false)}
          >
            <IoCloseSharp />
          </div>
        </div>

        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">Date</p>
          <p className="font-bold col-span-3 pl-8">
            : {moment(notification.created_at).format("DD-MM-YYYY, hh:mm a")}
          </p>
        </div>

        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">Type</p>
          <p className="font-bold col-span-3 pl-8">
            : {notification?.meta?.type}
          </p>
        </div>
        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">status</p>
          <p className="font-bold col-span-3 pl-8 capitalize">
            : {notification?.seen ? "seen" : "unseen"}
          </p>
        </div>

        <div className="grid grid-cols-5 gap-2 justify-start w-full">
          <p className="col-span-5 font-bold">Message</p>
        </div>
        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full max-w-2xl sm:max-w-xl">
          <p className="col-span-5">{notification?.message}</p>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
}
