import { Formik, Form, ErrorMessage } from "formik";
import { supabase } from "../../helpers/supabase";
import { toast, ToastContainer } from "react-toastify";
import { serviceProviderValidationSchema } from "../../helpers/validator";
import { sendSMS } from "../../helpers/sendsms";
import { useOutletContext } from "react-router-dom";

function AddProvider() {
  const initialValues = {
    name: "",
    service: "",
    description: "",
    created_at: "",
    updated_at: "",
  };

  const [, profile] = useOutletContext();
  const { id: admin_id } = profile;

  return (
    <div>
      <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
        Add Service Provider
      </h1>
      <div className="flex bg-white overflow-hidden relative dark:bg-dark-bg-700 dark:text-secondary-text p-6 min-h-full w-full justify-start ml-2">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setSubmitting(true);
            const toastNotification = toast.loading("Adding service provider");
            const { data, error } = await supabase
              .from("service_providers")
              .insert({
                ...values,
                created_at: new Date()
                  .toISOString()
                  .toLocaleString("en-GB", { timeZone: "UTC" }),
                updated_at: new Date()
                  .toISOString()
                  .toLocaleString("en-GB", { timeZone: "UTC" }),
                provider_meta: {
                  added_by: admin_id,
                },
              })
              .single();

            if (error) {
              console.log("Error: ", error);
              toast.update(toastNotification, {
                render: "Error adding service provider",
                isLoading: false,
                autoClose: 5000,
                type: "error",
              });

              resetForm({ values: initialValues });
            } else if (data) {
              console.log("Data: ", data);
              toast.update(toastNotification, {
                render: "Successfully added service provider",
                isLoading: false,
                autoClose: 5000,
                type: "success",
              });
              resetForm({ values: initialValues });

              const { data: administration } = await supabase
                .from("_member_profiles")
                .select(
                  `
                  id, fullname
                  `
                )
                .in("position_in_sacco", [
                  "secretary",
                  "assistant secretary",
                  "chairperson",
                  "asst_chairperson",
                  "c_credits",
                  "vc_credits",
                ])
                .eq("is_under_termination", false);

              if (administration && administration?.length > 0) {
                administration.forEach(async (member) => {
                  await sendSMS(
                    member.id,
                    `Hello ${member.fullname}, an external service provider known as ${data.name} has been added to the system.\n Regards,\n Bweyogerere Tuberebumu Sacco.`
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      console.log(data);
                    })
                    .catch((error) => console.log(error, "Error"));
                });
              }
            }
            console.log("Values: ", values);
            setSubmitting(false);
            document.getElementById("name").value = "";
            document.getElementById("service").value = "";
            document.getElementById("description").value = "";
          }}
          validationSchema={serviceProviderValidationSchema}
        >
          {({
            handleBlur,
            handleChange,
            values,
            errors,
            touched,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <div className=" md:h-[calc(100vh-20vh)] w-full">
                <ToastContainer position="top-center" />
                <div className="bg-white relative dark:bg-dark-bg-700 dark:text-secondary-text p-6">
                  <div className="flex justify-start items-center">
                    <h1 className="font-bold">Service Provider Details</h1>
                  </div>
                  <Form
                    className="flex flex-wrap w-full gap-5"
                    id="provider-form"
                  >
                    <div className="flex flex-col w-56 mb-3 ">
                      <label htmlFor="name" className="text-sm">
                        Name
                        <span className="text-sm text-red-500 ml-2 font-bold">
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter Name"
                        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.name && touched?.name
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={async (event) => {
                          setFieldValue(event.target.name, event.target.value);
                        }}
                        onBlur={handleBlur}
                        // defaultValue={initialValues?.name}
                      />

                      <ErrorMessage name="name">
                        {(msg) => (
                          <div className="error text-red-600 text-xs">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="flex flex-col w-56 mb-3 ">
                      <label htmlFor="service" className="text-sm">
                        Service
                        <span className="text-sm text-red-500 ml-2 font-bold">
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        name="service"
                        id="service"
                        placeholder="Enter Name"
                        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.service && touched?.service
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={async (event) => {
                          setFieldValue(event.target.name, event.target.value);
                        }}
                        onBlur={handleBlur}
                        // defaultValue={initialValues?.name}
                      />

                      <ErrorMessage name="service">
                        {(msg) => (
                          <div className="error text-red-600 text-xs">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="mb-3 w-full">
                      <h1 className="font-semibold">Description</h1>
                      <textarea
                        name="description"
                        id="description"
                        cols="30"
                        rows="10"
                        className="outline outline-1 rounded-md w-full p-2 dark:bg-dark-bg-600"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      ></textarea>
                      {touched?.description && errors?.description && (
                        <div className="error text-red-600 text-xs">
                          {errors?.description}
                        </div>
                      )}
                    </div>

                    <div className="flex mt-2 w-full">
                      <input
                        type="submit"
                        value="Submit"
                        className="outline bg-primary text-white px-4 py-2 cursor-pointer mb-5"
                        disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddProvider;
