import ReactDOM from "react-dom";
import { IoCloseSharp } from "react-icons/io5";
import { useAuth } from "../../auth/AuthContext";
import { IconContext } from "react-icons/lib";
import { useState } from "react";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { useOutletContext } from "react-router-dom";

export default function BaanaModal({ passed, setMwanaModal, mwana }) {
  const [show, setShow] = useState(false);
  const { darkMode } = useAuth();
  const [user] = useOutletContext();

  console.log("This is the baana modal: ")

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".portal")) {
        setMwanaModal(false);
      }
    };
  }

  return ReactDOM.createPortal(
    <div
      className={`bg-black bg-opacity-40 z-20 w-screen h-screen fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center ${
        darkMode ? "dark" : ""
      } `}
    >
      <div
        className="bg-white portal dark:bg-dark-bg dark:text-secondary-text p-10 rounded-md shadow-md flex flex-col items-center"
        ref={passed}
      >
        {/* {children} */}
        <div className="flex justify-between items-center w-full mb-2">
          <div className="flex gap-2 items-center">
            <h1 className="text-2xl font-bold m-2 ml-0 dark:text-white">
              {mwana?.name || mwana?.mwana_meta.name}
            </h1>
          </div>
          <div className="dark:hover:bg-dark-bg-600 hover:bg-accent p-2 rounded-full">
            <IconContext.Provider value={{ className: "cursor-pointer " }}>
              <IoCloseSharp onClick={() => setMwanaModal(false)} />
            </IconContext.Provider>
          </div>
        </div>

        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-2">Birth Date</p>
          <p className="font-bold col-span-3">
            : {mwana?.dob || mwana?.mwana_met?.dob}
          </p>
        </div>

        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-2">Gender</p>
          <p className="font-bold col-span-3 capitalize">
            : {mwana?.gender || mwana?.mwana_meta?.gender}
          </p>
        </div>
        {user && user?.id === mwana?.member_id && (
          <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
            <p className="col-span-2">Account balance</p>
            <p className="font-bold col-span-3">
              : UGX{" "}
              {mwana?.balance
                ? currencyFormatter(mwana?.balance)
                : mwana?.mwana_meta?.balance
                ? currencyFormatter(mwana?.mwana_meta?.balance)
                : 0}
            </p>
          </div>
        )}

        <div className="grid grid-cols-5 gap-2 mb-2 w-full">
          <p className="col-span-2">Mwana Status</p>
          <p className="col-span-3 font-bold">
            :{" "}
            <span
              className={` py-1 px-2 rounded-xl text-white font-normal ${
                mwana?.review_status === "pending"
                  ? "bg-yellow-400"
                  : mwana?.mwana_meta || mwana?.review_status === "approved"
                  ? "bg-green-400"
                  : "bg-rose-600"
              }`}
            >
              {mwana.review_status === "pending"
                ? "pending approval"
                : mwana?.mwana_meta
                ? "approved"
                : mwana?.review_status}
            </span>
          </p>
        </div>

        {user.id === mwana.member_id && (
          <>
            <div className="grid grid-cols-5 gap-2 mb-2 w-full">
              <p className="col-span-2">Education Level</p>
              <p className="font-bold col-span-3 capitalize">
                : {mwana?.education || mwana?.mwana_meta?.education}
              </p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 w-full">
              <p className="col-span-2">Health Issues</p>
              <p className="font-bold col-span-3">
                :{" "}
                {(mwana?.health_issue || mwana?.mwana_meta?.health_issue) ??
                  "None"}
              </p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 w-full">
              <p className="col-span-2">NIN</p>
              <p className="font-bold col-span-3">
                :{" "}
                {(mwana?.social_security_number ||
                  mwana?.mwana_meta?.social_security_number) ??
                  "Unspecified"}
              </p>
            </div>
          </>
        )}
      </div>
    </div>,
    document.getElementById("portal")
  );
}
