import { Pagination, Spinner } from "../../components";
import { useState, useEffect, Fragment } from "react";
import { supabase } from "../../helpers/supabase";
import { Helmet } from "react-helmet";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import Frown from "../../components/Frown";
import { useNavigate } from "react-router-dom";

export default function Announcements() {
  const [announcements, setAnnouncements] = useState([]);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(null);
  const navigate = useNavigate();

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [depositsPerPage, setDepositsPerPage] = useState(10);
  const indexOfLastPage = currentPage * depositsPerPage;
  const indexOfFirstPage = indexOfLastPage - depositsPerPage;
  const [show, setShow] = useState(false);

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const fetchAnnouncements = async () => {
    const { data, error } = await supabase
      .from("announcements")
      .select()
      .order("created_at", { ascending: false });

    if (error) throw error;
    setAnnouncements(data ?? []);
    setLoading(false)
  };

  useEffect(() => {
    fetchAnnouncements().catch((error) => console.log(error));

    return () => {};
  }, []);

  let filteredAnnouncements =
    announcements?.length > 0
      ? date
        ? announcements.filter(
            (announcement) => announcement?.created_at.substring(0, 10) === date
          )
        : announcements
      : null;

  filteredAnnouncements =
    announcements?.length > 0
      ? category
        ? announcements.filter(
            (announcement) => announcement?.category === category
          )
        : filteredAnnouncements
      : null;

      const exportAnnouncements = () => {
        const formattedAnnouncements = announcements.map((announcement) => {
          return {
            "Date": moment(announcement.created_at).format("DD-MM-YYYY") ?? "N/A",
            "Category": announcement?.category,
            "Title": announcement?._title ?? "N/A",
            "Subject": announcement?.subject ?? "N/A",
          };
        });
    
        generateReportFromJson(formattedAnnouncements, "Announcements_" + new Date().toISOString().slice(0, 10));
      };

      console.log(announcements)


  return (
    <div className="mx-5 my-2 h-[calc(100vh-140px)]">
      <Helmet>
        <title>Announcements - Bweyogere tuberebumu</title>
      </Helmet>
      <div className="flex flex-col justify-between pb-3 md:h-[110px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Announcements
        </h1>

        <div className="flex flex-wrap my-2 md:my-3 justify-between gap-2 md:gap-3 items-center">
          <div className="flex justify-between min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm w-full md:w-56">
            <select
              name="category"
              id=""
              className="py-3 px-2 rounded bg-white dark:bg-dark-bg-600 dark:text-secondary-text w-full"
              onChange={(event) => setCategory(event.target.value)}
              value={category}
            >
              <option value="">Category</option>
              <option value="administrators">All Admins</option>
              <option value="members">All members</option>
              <option value="others">Others</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (category) setCategory("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <div className="flex justify-between min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm w-full md:w-56">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id=""
              className="py-3 px-3 rounded dark:bg-dark-bg-600 dark:text-secondary-text w-full md:w-56"
              value={date}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-end mb-3 mt-3 gap-2 flex-wrap">
        <button
          className="bg-primary align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          onClick={() => {
            navigate(`/organisation/announcements/announce`);
            console.log("let's raise it")
          }}
        >
          Announce Now
        </button>
        <button
          className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          onClick={() => {
            // console.log("Here to export");
            exportAnnouncements()
          }}
        >
          Export
          <MdDownload className="ml-1" />
        </button>

      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-120px)] dark:bg-dark-bg-700">
        {announcements !== null &&
        filteredAnnouncements !== null &&
        filteredAnnouncements.length > 0 ? (
          <>
            <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="pr-6 py-4">Date</th>
                    <th className="px-6 py-4">Category</th>
                    <th className="px-6 py-4">Title</th>
                    {/* <th className="px-6 py-4">Subject</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredAnnouncements.map((announcement, index) => (
                    <Fragment key={index}>
                      <tr
                        className={`${
                          index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                        } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                        key={index}
                        onClick={() => {
                          console.log("Announcement ID: ",announcement.id)
                          console.log(index)
                          navigate(`/organisation/announcements/${announcement.id}`);
                        }}
                      >
                        <td>
                          <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                        </td>
                        <td className="pr-6 py-3">
                          {moment(announcement.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td className="px-6 py-3 capitalize">
                          {announcement?.category}
                        </td>
                        <td className="px-6 py-3">{announcement?._title}</td>
                        {/* <td className="px-6 py-3">{announcement?.subject}</td> */}
                      </tr>
                      {/* Modal */}
                      {/* {announcementModal && index === activeIndex && (
                        <AnnouncementModal
                          announcement={announcement}
                          setAnnouncementModal={setAnnouncementModal}
                        />
                      )} */}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(announcements.length / depositsPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={announcements}
                depositsPerPage={depositsPerPage}
                setDepositsPerPage={setDepositsPerPage}
              />
            </div>
          </>
        )  : loading ? (
          <Spinner />
        ) : (
          <Frown />
        )}
      </div>
    </div>
  );
}
