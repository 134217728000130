import { Submit, Spinner } from "../../components";
import { Formik, Form } from "formik";
import { supabase } from "../../helpers/supabase";
import { useAuth } from "../../auth/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  add_separator,
  remove_separator,
} from "../../helpers/thousand_separator";
import { transactionValidationSchema } from "../../helpers/validator";
import { Helmet } from "react-helmet";

function AddTransaction() {
  const [loading, setLoading] = useState(true);
  const [serviceProviders, setServiceProviders] = useState([]);

  useEffect(() => {
    getServiceProviders();
  }, []);

  const {
    user: { id: applicants_id },
  } = useAuth();
  const [, { fullname: applicants_name }] = useOutletContext();

  const initialValues = {
    applicants_name,
    applicants_id,
    _type: "deposit",
    reason: "",
    account_type: "",
    amount: "",
    comments: "",
    transaction_type: "",
    service_provider: "",
  };

  const getServiceProviders = async () => {
    const { data } = await supabase.rpc("get_service_providers");
    console.log("Service Providers: ", data);
    setServiceProviders(data ?? []);
    setLoading(false)
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <Helmet>
        <title>Add Transaction - Bweyogere tuberebumu</title>
      </Helmet>
      <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
        Add Transaction
      </h1>
      <div className="flex bg-white overflow-hidden relative dark:bg-dark-bg-700 dark:text-secondary-text p-6 min-h-full w-full justify-start">
        {loading && (
          <div className="absolute z-10 bg-white dark:bg-dark-bg-700 dark:bg-opacity-90 bg-opacity-90 w-full h-full rounded-lg">
            <Spinner />
          </div>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { resetForm }) => {
            const { _type } = values;

            try {
              const { data, error } = await supabase
                .from("applications")
                .insert({
                  _type,
                  application_meta: {
                    ...values,
                    review_status: "pending",
                  },
                  updated_at: new Date()
                    .toISOString()
                    .toLocaleString("en-GB", { timeZone: "UTC" }),
                  created_at: new Date()
                    .toISOString()
                    .toLocaleString("en-GB", { timeZone: "UTC" }),
                  reviewed: false,
                });

              if (error) {
                throw error;
              } else if (data) {
                toast.success(`Your request has been submitted for review`, {
                  position: "top-center",
                });
                resetForm({ values: initialValues });
                document.getElementById("amount").value = "";
              }
            } catch (error) {
              toast(`${error.message}`, { position: "top-center" });
            }
          }}
          validationSchema={transactionValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            return (
              <Form className="w-full">
                <div className="flex flex-grow flex-col min-h-full w-full">
                  <ToastContainer />
                  <div className="mb-3 flex flex-col md:flex-row gap-5 flex-wrap ">
                    <div className="flex flex-col w-56 pb-3">
                      <label className="text-sm">Type</label>
                      <div className="flex gap-2">
                        <div className="flex gap-1">
                          <input
                            type="radio"
                            id="deposit"
                            name="_type"
                            value="deposit"
                            className="w-4 h-4"
                            onChange={() => {
                              setFieldValue("_type", "deposit");
                            }}
                            defaultChecked={initialValues._type === "deposit"}
                          />
                          <label htmlFor="own" className="text-sm">
                            Deposit
                          </label>
                        </div>
                        <div className="flex gap-1">
                          <input
                            type="radio"
                            id="withdraw"
                            name="_type"
                            value="deposit"
                            className="w-4 h-4"
                            onChange={() => {
                              setFieldValue("_type", "withdraw");
                            }}
                            defaultChecked={initialValues._type === "withdraw"}
                          />
                          <label htmlFor="other" className="text-sm">
                            Withdraw
                          </label>
                        </div>
                      </div>
                    </div>
                    {values._type === "deposit" ? (
                      <div className="flex flex-col w-56">
                        <label className="text-sm">Transaction Type</label>
                        <select
                          name="transaction_type"
                          id="transaction_type"
                          className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                            errors?.transaction_type &&
                            touched?.transaction_type
                              ? "ring-red-500"
                              : "ring-black"
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.transaction_type}
                        >
                          <option value="">--Select type--</option>
                          <option value="executive contribution">
                            Executive contribution
                          </option>
                          <option value="bank interest">Bank Interest</option>
                        </select>
                        {touched?.transaction_type &&
                          errors?.transaction_type && (
                            <div className="error text-red-600 text-xs">
                              {errors?.transaction_type}
                            </div>
                          )}
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-col w-56">
                          <label className="text-sm">Transaction Type</label>
                          <select
                            name="transaction_type"
                            id="transaction_type"
                            className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                              touched?.transaction_type &&
                              errors?.transaction_type
                                ? "ring-red-500"
                                : "ring-black"
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.transaction_type}
                          >
                            <option value="">--Select type--</option>
                            <option value="administrator expenses">
                              Administrative Expenses
                            </option>
                            <option value="bank charge">Bank Charges</option>
                            <option value="withholding tax">
                              Withholding Tax
                            </option>
                            <option value="agm">AGM</option>
                            <option value="procurements">
                              Procurements/Projects
                            </option>
                            <option value="external service">
                              External Service
                            </option>
                          </select>
                          {touched?.transaction_type &&
                            errors?.transaction_type && (
                              <div className="error text-red-600 text-xs">
                                {errors?.transaction_type}
                              </div>
                            )}
                        </div>
                        {values?.transaction_type === "external service" && (
                          <div className="flex flex-col w-56 ">
                            <label className=" text-sm">Sevice Provider</label>
                            {/* <input
                              type="text"
                              name="service_provider"
                              id="service_provider"
                              placeholder="Enter Service Provider"
                              className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                errors?.service_provider &&
                                touched?.service_provider
                                  ? "ring-red-500"
                                  : "ring-black"
                              }`}
                              onChange={handleChange("service_provider")}
                              onBlur={handleBlur}
                            /> */}

                            <select
                              name="service_provider"
                              id="service_provider"
                              className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                                touched?.service_provider &&
                                errors?.service_provider
                                  ? "ring-red-500"
                                  : "ring-black"
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.transaction_type}
                            >
                              <option value="">--Select Provider--</option>
                              {serviceProviders &&
                                serviceProviders?.length > 0 &&
                                serviceProviders.map((provider, index) => (
                                  <option key={index} value={provider.name}>
                                    {provider.name}
                                  </option>
                                ))}
                            </select>
                            {touched?.service_provider &&
                              errors?.service_provider && (
                                <div className="error text-red-600 text-xs">
                                  {errors?.service_provider}
                                </div>
                              )}
                          </div>
                        )}
                        <div className="flex flex-col w-56 ">
                          <label className=" text-sm">Reason</label>
                          <input
                            type="text"
                            name="reason"
                            id="reason"
                            placeholder="Enter Reason"
                            className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                              errors?.reason && touched?.reason
                                ? "ring-red-500"
                                : "ring-black"
                            }`}
                            onChange={handleChange("reason")}
                            onBlur={handleBlur}
                          />
                          {touched?.reason && errors?.reason && (
                            <div className="error text-red-600 text-xs">
                              {errors?.reason}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col w-56">
                          <label className="text-sm">Cashout Method</label>
                          <select
                            name="cashout_method"
                            id="cashout_method"
                            className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                              errors?.cashout_method && touched?.cashout_method
                                ? "ring-red-500"
                                : "ring-black"
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cashout_method}
                          >
                            <option value="">--Select Method--</option>
                            <option value="Cash">Cash</option>
                            <option value="Mobile Money">Mobile Money</option>
                            <option value="Airtel Money">Airtel Money</option>
                            <option value="Cheque">Mwana</option>
                            <option value="EFT">EFT</option>
                            <option value="RTGS">RTS</option>
                          </select>
                          {touched?.cashout_method &&
                            errors?.cashout_method && (
                              <div className="error text-red-600 text-xs">
                                {errors?.cashout_method}
                              </div>
                            )}
                        </div>
                      </>
                    )}
                    <div className="flex flex-col w-56">
                      <label className="text-sm">Account Type</label>
                      <select
                        name="account_type"
                        id="account_type"
                        className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                          errors?.account_type && touched?.account_type
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.account_type}
                      >
                        <option value="">--Select Account--</option>
                        <option value="reserve">Reserve</option>
                        <option value="sacco">Sacco</option>
                      </select>
                      {touched?.account_type && errors?.account_type && (
                        <div className="error text-red-600 text-xs">
                          {errors?.account_type}
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col w-56 ">
                      <label className=" text-sm">Enter Amount</label>
                      <input
                        type="text"
                        name="amount"
                        id="amount"
                        placeholder="Enter amount"
                        className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.amount && touched?.amount
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={(event) => {
                          let formatted_string = add_separator(
                            remove_separator(event.target.value)
                          );
                          event.target.value = formatted_string;
                          setFieldValue(
                            event.target.name,
                            parseFloat(remove_separator(formatted_string))
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      {touched?.amount && errors?.amount && (
                        <div className="error text-red-600 text-xs">
                          {errors?.amount}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="mb-3 flex flex-col md:flex-row gap-5"> */}
                  {/* </div> */}
                  <div className="mb-3">
                    <h1 className="font-semibold">Comments</h1>
                    <textarea
                      name="comments"
                      id="comments"
                      cols="30"
                      rows="10"
                      className="outline outline-1 rounded-md w-full p-2 dark:bg-dark-bg-600"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comments}
                    ></textarea>
                    {touched?.comments && errors?.comments && (
                      <div className="error text-red-600 text-xs">
                        {errors?.comments}
                      </div>
                    )}
                  </div>

                  <div className="flex justify-start w-full">
                    {/* <button
                      onClick={(event) => {
                        event.preventDefault()
                        console.log("values", values)
                        console.log("errors", errors)
                      }}
                      className="mx-3"
                    >
                      Debug
                    </button> */}
                    <div className="w-56">
                      <Submit value="Submit" disabled={!(isValid && dirty)} />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddTransaction;
