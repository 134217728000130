import { InputField } from "../../components/Form/CustomInputField";
import { ErrorMessage, Form, Formik } from "formik";
import { member1ValidationSchema } from "../../helpers/validator";
import {
  add_separator,
  remove_separator,
} from "../../helpers/thousand_separator";

export default function ApplicationPg1({
  initialValues,
  setInitialValues,
  setPageNumber,
  pageNumber,
}) {
  const {
    fullname,
    dob,
    gender,
    present_address,
    email_address,
    phone_number,
    id_passport_number,
    marital_status,
    fathers_name,
    fathers_address,
    income_sources: {
      employed: {
        employers_name,
        employers_address,
        position,
        work_station,
        gross_monthly_income: gross_employed,
        appointment_date,
        payroll_number,
        source_of_income,
      },
      business: {
        business_name,
        business_address,
        business_location,
        gross_monthly_income: gross_bussiness,
        other_income_sources,
      },
    },
  } = initialValues;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        setInitialValues({ ...initialValues, ...values });
        setPageNumber(pageNumber + 1);
      }}
      validationSchema={member1ValidationSchema}
    >
      {({
        handleChange,
        handleBlur,
        errors,
        touched,
        values,
        setFieldValue
      }) => (
        <Form>
          <div className="mb-3">
            <h1 className="font-semibold">Applicant's Details</h1>
            <span className="text-sm text-red-500 mb-3">
              All Fields with a * are mandatory
            </span>
            <div className="flex flex-wrap gap-5">
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="fullname"
                label="Name"
                placeholder="Enter Full name"
                defaultValue={fullname}
                mandatory={true}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
              />
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="dob"
                label="Date of Birth"
                placeholder="06/04/2022"
                defaultValue={dob}
                type="date"
                mandatory={true}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
              />

              <div className="flex flex-col w-56 ">
                <label className=" text-sm">
                  Gender
                  <span className={`text-sm text-red-500 ml-2 font-bold`}>
                    *
                  </span>
                </label>
                <select
                  name="gender"
                  id="gender"
                  defaultValue={gender}
                  onChange={(event) => {
                    values.gender = event.target.value;
                  }}
                  onBlur={handleBlur}
                  className={`ring-1 rounded px-2 py-2 bg-white dark:bg-dark-bg-600 focus:outline-none focus:ring-2 focus:ring-primary ${
                    errors?.gender && touched?.gender
                      ? "ring-red-500"
                      : "ring-black"
                  }`}
                >
                  <option value="">--Select Gender--</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <ErrorMessage name="gender">
                  {(msg) => <span className="text-xs text-red-500">{msg}</span>}
                </ErrorMessage>
              </div>

              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="present_address"
                label="Present Address"
                placeholder="Address"
                defaultValue={present_address}
                mandatory={true}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
              />
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="email_address"
                label="Email Address"
                placeholder="example@gmail.com"
                type="email"
                defaultValue={email_address}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
                mandatory={true}
              />
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="phone_number"
                label="Phone Number"
                placeholder="Enter Phone No."
                type="phone"
                defaultValue={phone_number}
                mandatory={true}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
                instructions={["Use local format"]}
              />
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="id_passport_number"
                label="ID/Passport No."
                placeholder="placeholder"
                defaultValue={id_passport_number}
                mandatory={true}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
              />
              <div className="flex flex-col w-56 ">
                <label className=" text-sm">
                  Marital Status
                  <span className={`text-sm text-red-500 ml-2 font-bold`}>
                    *
                  </span>
                </label>
                <select
                  name="marital_status"
                  id=""
                  defaultValue={marital_status}
                  onChange={(event) =>
                    (values.marital_status = event.target.value)
                  }
                  className={`ring-1 rounded px-2 py-2 bg-white dark:bg-dark-bg-600 focus:outline-none focus:ring-2 focus:ring-primary ${
                    errors?.marital_status && touched?.marital_status
                      ? "ring-red-500"
                      : "ring-black"
                  }`}
                >
                  <option value="">--Marital Status--</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="widowed">Widowed</option>
                  <option value="divorced">Divorced</option>
                </select>
                <ErrorMessage name="marital_status">
                  {(msg) => (
                    <div className="error text-xs text-red-500">{msg}</div>
                  )}
                </ErrorMessage>
              </div>
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="fathers_name"
                label="Father's Name"
                placeholder="Enter Father's Name"
                defaultValue={fathers_name}
                mandatory={true}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
              />
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="fathers_address"
                label="Father's Address"
                placeholder="Enter your Father's Address"
                defaultValue={fathers_address}
                mandatory={true}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
              />
            </div>
          </div>
          <div className="mb-3">
            <h1 className="font-semibold">Next of Kin</h1>
            <div className="flex flex-wrap gap-5">
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="kin_name"
                label="Name"
                placeholder="Enter name"
                defaultValue={initialValues.kin_name}
                mandatory={true}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
              />
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="kin_contact"
                label="Contact"
                placeholder="Enter contact"
                defaultValue={initialValues.kin_contact}
                mandatory={true}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
                instructions={['Use local format']}
              />
            </div>
          </div>
          <div className="mb-3">
            <h1 className="font-semibold">Source Of Income</h1>
            <div className="flex flex-wrap gap-5">
              <div className="flex flex-col w-56 ">
                <div className="flex justify-between" role="group">
                  <div className="flex gap-1">
                    <input
                      type="radio"
                      name="income_sources[status]"
                      value="Employed"
                      id="employed"
                      onChange={handleChange}
                      defaultChecked={initialValues.income_sources.status}
                    />
                    <label htmlFor="employed" className="text-sm">
                      Employed
                    </label>
                  </div>
                  <div className="flex gap-1">
                    <input
                      type="radio"
                      name="income_sources[status]"
                      value="Business"
                      id="business"
                      onChange={handleChange}
                      defaultChecked={initialValues.income_sources.status}
                    />
                    <label htmlFor="business" className="text-sm">
                      Business
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {touched.income_sources?.status && errors.income_sources?.status && (
            <div className="error text-xs text-red-500">
              {errors.income_sources?.status}
            </div>
          )}
          {values.income_sources.status === "Employed" ? (
            <>
              <p className="text-inputblue">
                *To be filled by employed applicants
              </p>
              <div className="mb-3">
                <div className="flex flex-wrap gap-5">
                  <div className="flex flex-col w-56 mb-3">
                    <label htmlFor="amount" className=" text-sm">
                      Employer
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="income_sources[employed][employers_name]"
                      id="income_sources[employed][employers_name]"
                      placeholder="Enter employer"
                      className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                        errors?.income_sources?.employed?.employers_name &&
                        touched?.income_sources?.employed?.employers_name
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={employers_name}
                    />
                    <ErrorMessage name="income_sources.employed.employers_name">
                      {(msg) => (
                        <span className="text-xs text-red-500">{msg}</span>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="flex flex-col w-56 mb-3">
                    <label htmlFor="amount" className=" text-sm">
                      Employer's Address
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="income_sources[employed][employers_address]"
                      id="income_sources[employed][employers_address]"
                      placeholder="Enter employer"
                      className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                        errors?.income_sources?.employed?.employers_address &&
                        touched?.income_sources?.employed?.employers_address
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={employers_address}
                    />
                    <ErrorMessage name="income_sources.employed.employers_address">
                      {(msg) => (
                        <span className="text-xs text-red-500">{msg}</span>
                      )}
                    </ErrorMessage>
                  </div>
                  <InputField
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    reference="income_sources[employed][position]"
                    label="Position"
                    placeholder="Enter position"
                    defaultValue={position}
                    setFieldValue={setFieldValue}
                    setInitialValues={setInitialValues}
                    initialValues={initialValues}
                  />
                  <InputField
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    reference="income_sources[employed][work_station]"
                    label="Work station"
                    placeholder="Enter work station"
                    defaultValue={work_station}
                    setFieldValue={setFieldValue}
                    setInitialValues={setInitialValues}
                    initialValues={initialValues}
                  />
                  <div className="flex flex-col w-56 mb-3">
                    <label htmlFor="amount" className=" text-sm">
                      Enter gross income
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="income_sources[employed][gross_monthly_income]"
                      id="income_sources[employed][gross_monthly_income]"
                      placeholder="Gross Monthly Income"
                      className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                        errors?.income_sources?.employed
                          ?.gross_monthly_income &&
                        touched?.income_sources?.employed?.gross_monthly_income
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      onChange={(event) => {
                        let formatted_string = add_separator(
                          remove_separator(event.target.value)
                        );
                        event.target.value = formatted_string;
                        values.income_sources.employed.gross_monthly_income =
                          parseFloat(remove_separator(event.target.value));
                        setInitialValues((initialValues) => ({
                          ...initialValues,
                          ...values,
                        }));
                      }}
                      onBlur={handleBlur}
                      defaultValue={remove_separator(gross_employed)}
                    />
                    <ErrorMessage name="income_sources.employed.gross_monthly_income">
                      {(msg) => (
                        <span className="text-xs text-red-500">{msg}</span>
                      )}
                    </ErrorMessage>
                  </div>
                  <InputField
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    reference="income_sources[employed][appointment_date]"
                    label="Appointment Date"
                    placeholder="07/04/2022"
                    defaultValue={appointment_date}
                    type="date"
                    setFieldValue={setFieldValue}
                    setInitialValues={setInitialValues}
                    initialValues={initialValues}
                  />
                  <InputField
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    reference="income_sources[employed][payroll_number]"
                    label="Payroll Number"
                    placeholder="Enter payroll No."
                    defaultValue={payroll_number}
                    setFieldValue={setFieldValue}
                    setInitialValues={setInitialValues}
                    initialValues={initialValues}
                  />
                  <InputField
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    reference="income_sources[employed][source_of_income]"
                    label="Source of Income"
                    placeholder="Salary"
                    defaultValue={source_of_income}
                    setFieldValue={setFieldValue}
                    setInitialValues={setInitialValues}
                    initialValues={initialValues}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="text-inputblue">
                *To be filled by business applicants
              </p>
              <div className="flex flex-wrap gap-5 mb-10">
                <div className="flex flex-col w-56 mb-3">
                  <label htmlFor="amount" className=" text-sm">
                    Business Name
                    <span className="text-sm text-red-500 ml-2 font-bold">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    name="income_sources[business][business_name]"
                    id="income_sources[business][business_name]"
                    placeholder="Enter business name"
                    className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                      errors?.income_sources?.business?.business_name &&
                      touched?.income_sources?.business?.business_name
                        ? "ring-red-500"
                        : "ring-black"
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue={business_name}
                  />
                  <ErrorMessage name="income_sources.business.business_name">
                    {(msg) => (
                      <span className="text-xs text-red-500">{msg}</span>
                    )}
                  </ErrorMessage>
                </div>
                <div className="flex flex-col w-56 mb-3">
                  <label htmlFor="amount" className=" text-sm">
                    Business Address
                    <span className="text-sm text-red-500 ml-2 font-bold">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    name="income_sources[business][business_address]"
                    id="income_sources[business][business_address]"
                    placeholder="Enter Address"
                    className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                      errors?.income_sources?.business?.business_address &&
                      touched?.income_sources?.business?.business_address
                        ? "ring-red-500"
                        : "ring-black"
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue={business_address}
                  />
                  <ErrorMessage name="income_sources.business.business_address">
                    {(msg) => (
                      <span className="text-xs text-red-500">{msg}</span>
                    )}
                  </ErrorMessage>
                </div>
                <InputField
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  reference="income_sources[business][business_location]"
                  label="Business Location"
                  placeholder="Enter location"
                  defaultValue={business_location}
                  setFieldValue={setFieldValue}
                  setInitialValues={setInitialValues}
                  initialValues={initialValues}
                />
                <div className="flex flex-col w-56 mb-3">
                  <label htmlFor="amount" className=" text-sm">
                    Business Income
                    <span className="text-sm text-red-500 ml-2 font-bold">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    name="income_sources[business][gross_monthly_income]"
                    id="income_sources[business][gross_monthly_income]"
                    placeholder="Enter Address"
                    className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                      errors?.income_sources?.business?.gross_monthly_income &&
                      touched?.income_sources?.business?.gross_monthly_income
                        ? "ring-red-500"
                        : "ring-black"
                    }`}
                    onChange={(event) => {
                      let formatted_string = add_separator(
                        remove_separator(event.target.value)
                      );
                      event.target.value = formatted_string;
                      values.income_sources.business.gross_monthly_income =
                        parseFloat(remove_separator(event.target.value));
                      setInitialValues((initialValues) => ({
                        ...initialValues,
                        ...values,
                      }));
                    }}
                    onBlur={handleBlur}
                    defaultValue={gross_bussiness}
                  />
                  <div className="flex relative mt-1">
                    <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                      {" "}
                    </div>
                    <div className="text-xs text-gray-400 pl-3">
                      Use figures without separators
                    </div>
                  </div>
                  <ErrorMessage name="income_sources.business.gross_monthly_income">
                    {(msg) => (
                      <span className="text-xs text-red-500">{msg}</span>
                    )}
                  </ErrorMessage>
                </div>

                <InputField
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  reference="income_sources[business][other_income_sources]"
                  label="Other income sources"
                  placeholder="Enter source"
                  defaultValue={other_income_sources}
                  setFieldValue={setFieldValue}
                  setInitialValues={setInitialValues}
                  initialValues={initialValues}
                />
              </div>
            </>
          )}

          <div className="flex justify-end w-full">
            <button
              type="submit"
              className="outline outline-gray-500 outline-2 text-gray-500 px-4 py-1 rounded-lg cursor-pointer"
              onClick={() => {
                console.log("Errors: ", errors);
                console.log("Values: ", values);
              }}
            >
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
