import { Formik, Form, FieldArray, ErrorMessage, Field } from "formik";
import { InputField } from "../../components/Form/CustomInputField";
import { loan5ValidationSchema } from "../../helpers/validator";

export default function ApplicationPg4({
  initialValues,
  setInitialValues,
  setPageNumber,
  profiles,
}) {

  console.log("Profiles: ", profiles)
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        
        setInitialValues(values);
        setPageNumber(5);
      }}
      validationSchema={loan5ValidationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => {
        return (
          <Form>
            <div className="mb-3">
              <span className="text-sm text-red-500 mb-3">
                All Fields with a * are mandatory
              </span>
              <h1 className="font-semibold">
                Security Details
                <span className="text-sm text-red-500 ml-2 font-bold">*</span>
              </h1>
              <p>I offer the following Security</p>

              {/* <div id="checkbox-group">Checked</div> */}
              <div role="group" aria-labelledby="checkbox-group">
                <label className="block">
                  <Field type="checkbox" name="securities" value="salary" />
                  Salary
                </label>
                <label className="block">
                  <Field type="checkbox" name="securities" value="shares" />
                  Shares
                </label>
                <label className="block">
                  <Field type="checkbox" name="securities" value="savings" />
                  Savings
                </label>
                <label className="block">
                  <Field type="checkbox" name="securities" value="guarantors" checked/>
                  Guarantors
                </label>
                <div className="flex relative mt-1">
                  <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                    {" "}
                  </div>
                  <div className="text-xs text-gray-400 pl-3">
                    Select two or more options
                  </div>
                </div>
                <ErrorMessage name="securities">
                  {(msg) => (
                    <div className="error text-red-600 text-xs">{msg}</div>
                  )}
                </ErrorMessage>
              </div>
            </div>
            <div className="mb-3">
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="other_security"
                label="Others Specify"
                placeholder="Enter other"
                setFieldValue={setFieldValue}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
              />
            </div>
            <div className="mb-3">
              <h1 className="font-semibold">
                Guarantor's Details
                <span className="text-sm text-red-500 ml-2 font-bold">*</span>
              </h1>
              <div className="flex relative my-2">
                <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                  {" "}
                </div>
                <div className="text-xs text-gray-400 pl-3">
                  Guarantors are limited to a minimum of 2 and a maximum of 3 sacco members.
                </div>
              </div>
              <FieldArray
                name="guarantors"
                render={(fieldArrayProp) => (
                  <>
                    {values.guarantors.map((guarantor, index) => (
                      <div className="flex flex-wrap gap-5 mb-2 outline p-2 outline-1 rounded-md outline-gray-300" key={index}>
                        {/* <InputField errors={errors} touched={touched} handleChange={handleChange}  handleBlur={handleBlur} reference={`guarantors[${index}].name`} label="Name" placeholder="Enter name" /> */}
                        <div className="flex flex-col w-56">
                          <label className="text-sm">
                            Select Member
                            <span className="text-md text-red-500 font-bold ml-2">
                              *
                            </span>
                          </label>
                          <select
                            name={`guarantors[${index}].name`}
                            className={`ring-1 ring-black rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${errors?.guarantors && errors?.guarantors?.length > 0 && errors?.guarantors[index]?.name && touched?.guarantors && touched?.guarantors?.length > 0 && touched?.guarantors[index]?.name ? 'ring-red-500' : 'ring-black'}`}
                            onChange={(event) => {
                              values.guarantors[index]["name"] = profiles[event.target.value].fullname;
                              values.guarantors[index]["guarantor_id"] = profiles[event.target.value].guarantor_id;
                              const temp = initialValues
                              temp.guarantors[index]["name"] = profiles[event.target.value].fullname;
                              temp.guarantors[index]["guarantor_id"] = profiles[event.target.value].guarantor_id
                              setInitialValues(initialValues => ({...initialValues, ...temp}))
                            }}
                            onBlur={handleBlur}
                            defaultValue={initialValues.guarantors[index]?.name ?? ""}
                          >
                            <option value="">--Select Guarantor--</option>
                            {profiles &&
                              profiles.map(({ fullname }, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={index}
                                    className="capitalize"
                                  >
                                    {fullname}
                                  </option>
                                );
                              })}
                          </select>
                          <ErrorMessage name={`guarantors.[${index}].name`}>
                            {(msg) => (
                              <div className="error text-xs text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className="flex flex-col w-56 mb-3">
                          <label
                            htmlFor={`gurantorsContact${index}`}
                            className=" text-sm"
                          >
                            Phone Number
                            <span className="text-sm text-red-500 ml-2 font-bold">
                              *
                            </span>
                            
                          </label>
                          <input
                            type="text"
                            name={`guarantors[${index}].contact`}
                            id={`guarantorsContact${index}`}
                            placeholder="Enter number"
                            className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${errors?.guarantors && errors.guarantors.length > 0 && errors.guarantors[index]?.contact && touched?.guarantors && touched?.guarantors.length > 0 && touched.guarantors[index]?.contact ? "ring-red-500" : "ring-black"}`}
                            onChange={(event) => {
                              setFieldValue(
                                event.target.name,
                                event.target.value
                              );
                              const temp = initialValues
                              temp.guarantors[index].contact = event.target.value
                              setInitialValues(initialValues => ({...initialValues, ...temp}))
                            }}
                            onBlur={handleBlur}
                            defaultValue={
                              initialValues.guarantors[index]?.contact ?? ""   
                            }
                          />
                          <div className="flex relative mt-1">
                            <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                              {" "}
                            </div>
                            <div className="text-xs text-gray-400 pl-3">
                              Use local format
                            </div>
                          </div>
                          
                          <ErrorMessage
                            name={`guarantors[${index}].contact`}
                          >
                            {(msg) => (
                              <div className="error text-red-600 text-xs">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                    ))}
                    {
                      errors.guarantors &&
                        typeof errors.guarantors === "string" && (
                          <div className="error text-xs text-red-500">
                            {errors.guarantors}
                          </div>
                        )
                    }
                    <button
                      type="button"
                      className="bg-primary text-white px-3 py-2 rounded m-2"
                      onClick={() =>
                        values.guarantors.length < 3
                          ? fieldArrayProp.push({
                              name: "",
                              contact: "",
                              guaranteed: false,
                              guarantee_status: "pending",
                              guarantor_id: ""
                            })
                          : null
                      }
                    >
                      +
                    </button>
                    <button
                      type="button"
                      className="bg-accent-red text-white px-3 py-2 rounded m-2"
                      onClick={() =>
                        values.guarantors.length > 2
                          ? fieldArrayProp.pop()
                          : null
                      }
                    >
                      -
                    </button>
                  </>
                )}
              ></FieldArray>
            </div>
            <div className="flex justify-between w-full mb-10">
              <button
                type="button"
                className="outline outline-gray-500 outline-2 text-gray-500 px-4 py-1 rounded-lg cursor-pointer"
                onClick={() => setPageNumber(3)}
              >
                Back
              </button>
              <input
                type="submit"
                value="Next"
                className="outline outline-gray-500 outline-2 text-gray-500 px-4 py-1 rounded-lg cursor-pointer"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
