import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { Spinner } from "../../components";
import { useMediaQuery } from "../../hooks";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import {
  add_separator,
  remove_separator,
} from "../../helpers/thousand_separator";
import { sharesTransfer2ValidationSchema } from "../../helpers/validator";
import TerminationStepper from "../../components/TerminationStepper";
import { BsExclamationTriangle } from "react-icons/bs";
import { sendSMS } from "../../helpers/sendsms";

function Renumeration() {
  const { id } = useParams();
  const matches = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    fetch_members()
      .then((members) => {
        console.log("Members: ", members);
        setProfiles(() => members);
      })
      .catch((error) => console.log(error));

    getApplicantsInformation()
      .then((data) => {
        setAccounts(() => data);
        setAmountRenumerable(
          (data?.savings?.balance ?? 0) +
            (data?.mwana?.balance ?? 0) +
            (data?.fixed?.balance ?? 0)
        );
        setRemainingShares(data?.shares?.balance ?? 0);
        setInitialValues({
          members: [
            {
              id: "",
              amount: "",
              account: "shares",
              name: "",
              availableShares: data?.shares?.balance,
            },
          ],
        });
        setTotalShares(data?.shares?.balance ?? 0);
        setLoading(false);
      })
      .catch((error) => console.log("Error: ", error));
  }, []);

  const fetch_members = async () => {
    const { data, error } = await supabase.rpc("fetch_members_only");
    if (error) throw error;
    console.log("Members: ", data);
    return data;
  };

  const getApplicantsInformation = async () => {
    const { data: application_data } = await supabase
      .from("applications")
      .select()
      .eq("application_id", id)
      .single();
    setApplicationInfo(() => application_data);

    setApplicantsId(application_data.application_meta.applicants_id);

    const { data: accounts_data } = await supabase.rpc(
      "get_user_accounts_information",
      { user_id: application_data.application_meta.applicants_id }
    );

    return accounts_data;
  };

  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState({});
  const [applicationInfo, setApplicationInfo] = useState({});
  const [amountRenumerable, setAmountRenumerable] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [remainingShares, setRemainingShares] = useState(0);
  const [sharesTransferred, setSharesTransferred] = useState(0);
  const [totalShares, setTotalShares] = useState(0);
  const [applicantsId, setApplicantsId] = useState("");
  if (applicationInfo)
    console.log("Application Information: ", applicationInfo);

  return (
    <div>
      <ToastContainer position="top-center" />
      <h1 className="my-5 font-bold uppercase">Renumeration</h1>
      <div className="bg-white overflow-scroll relative  md:h-screen dark:bg-dark-bg-700 dark:text-secondary-text p-6">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <span className="my-2 font-bold">
              {applicationInfo?.application_meta?.applicants_name}'s
            </span>
            <span> account information </span>
            {matches ? (
              <div className="flex justify-between mb-5 gap-5 dark:text-white">
                <div className="bg-white w-3/12 flex flex-col py-7 rounded-md justify-center items-center dark:bg-dark-bg-700">
                  <h1 className="font-bold text-2xl">
                    {accounts && accounts?.shares?.balance
                      ? `UGX ${currencyFormatter(accounts?.shares?.balance)}`
                      : `0`}
                  </h1>
                  <h1 className="font-semibold">Shares</h1>
                </div>
                <div className="bg-white w-3/12 flex flex-col py-7 rounded-md justify-center items-center dark:bg-dark-bg-700">
                  <h1 className="font-bold text-2xl">
                    {accounts && accounts?.savings?.balance
                      ? `UGX ${currencyFormatter(accounts?.savings?.balance)}`
                      : `0`}
                  </h1>
                  <h1 className="font-semibold">Savings</h1>
                </div>
                <div className="bg-white w-3/12 flex flex-col py-7 rounded-md justify-center items-center dark:bg-dark-bg-700">
                  <h1 className="font-bold text-2xl">
                    {accounts && accounts?.mwana == null
                      ? "No account"
                      : accounts?.mwana?.balance
                      ? `UGX ${currencyFormatter(accounts?.mwana?.balance)}`
                      : `0`}
                  </h1>
                  <h1 className="font-semibold">Mwana</h1>
                </div>
                <div className="bg-white w-3/12 flex flex-col py-7 rounded-md justify-center items-center dark:bg-dark-bg-700">
                  <h1 className="font-bold text-2xl">
                    {accounts && accounts.fixed == null
                      ? "No account"
                      : accounts?.fixed?.balance
                      ? `UGX ${currencyFormatter(accounts?.fixed?.balance)}`
                      : `0`}
                  </h1>
                  <h1 className="font-semibold">Fixed</h1>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-5  dark:text-white">
                <div className="flex gap-5">
                  <div className="bg-white dark:bg-dark-bg-700  w-6/12 flex flex-col py-7 rounded-md justify-center items-center">
                    {accounts && accounts?.shares?.balance ? (
                      <span className="flex flex-col">
                        <span className="text-xs">UGX</span>
                        <span className="font-bold text-lg">
                          {currencyFormatter(accounts?.shares?.balance)}
                        </span>
                      </span>
                    ) : (
                      <h1 className="text-2xl">0</h1>
                    )}
                    <h1 className="font-semibold">Shares</h1>
                  </div>
                  <div className="bg-white dark:bg-dark-bg-700 w-6/12 flex flex-col py-7 rounded-md justify-center items-center">
                    {accounts && accounts?.savings?.balance ? (
                      <span className="flex flex-col">
                        <span className="text-xs">UGX</span>
                        <span className="font-bold text-lg">
                          {currencyFormatter(accounts?.savings?.balance)}
                        </span>
                      </span>
                    ) : (
                      <h1 className="text-2xl">0</h1>
                    )}
                    <h1 className="font-semibold">Savings</h1>
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="bg-white dark:bg-dark-bg-700 w-6/12 flex flex-col py-7 rounded-md justify-center items-center">
                    {accounts && accounts?.mwana?.balance ? (
                      <span className="flex flex-col">
                        <span className="text-xs">UGX</span>
                        <span className="font-bold text-lg">
                          {currencyFormatter(accounts?.mwana?.balance)}
                        </span>
                      </span>
                    ) : (
                      <h1 className="text-2xl">0</h1>
                    )}
                    <h1 className="font-semibold">Mwana</h1>
                  </div>
                  <div className="bg-white dark:bg-dark-bg-700 w-6/12 flex flex-col py-7 rounded-md justify-center items-center">
                    {accounts && accounts?.fixed == null ? (
                      "No account"
                    ) : accounts?.fixed?.balance ? (
                      <span className="flex flex-col">
                        <span className="text-xs">UGX</span>
                        <span className="font-bold text-lg">
                          {currencyFormatter(accounts?.fixed?.balance)}
                        </span>
                      </span>
                    ) : (
                      <h1 className="text-2xl">0</h1>
                    )}
                    <h1 className="font-semibold">Fixed</h1>
                  </div>
                </div>
              </div>
            )}
            <div>
              <TerminationStepper
                pageNumber={
                  applicationInfo?.application_meta?.termination_status ===
                  "share_transfer"
                    ? 2
                    : applicationInfo?.application_meta?.termination_status ===
                      "termination"
                    ? 3
                    : 1
                }
                terminated={
                  applicationInfo?.application_meta?.review_status ===
                  "approved"
                }
              />
            </div>
            <div>
              {applicationInfo?.application_meta?.termination_status ===
              "renumeration" ? (
                <div>
                  <h1 className="my-2 semi-bold text-xl">Renumerate Balance</h1>
                  <div className="flex justify-between">
                    <div className="max-h-fit flex gap-2">
                      <div className="my-2 font-light flex items-center">
                        Account balance to renumerate:{" "}
                      </div>
                      <div className="text-xl font-semibold flex items-center">
                        {" "}
                        UGX {currencyFormatter(amountRenumerable ?? 0)}
                      </div>
                    </div>
                    {amountRenumerable !== "0" && (
                      <button
                        type="button"
                        className="outline bg-primary text-white px-4 py-2 cursor-pointer"
                        onClick={async (event) => {
                          event.preventDefault();
                          console.log("Renumerating");

                          const toastNotification = toast.loading(
                            "Processing share renumeration"
                          );

                          try {
                            const { data, error } = await supabase.rpc(
                              "renumerate_accounts",
                              {
                                details: {
                                  applicants_id: applicantsId,
                                  applicants_accounts: accounts,
                                  amount: amountRenumerable,
                                  application_id: id,
                                },
                              }
                            );

                            if (error) throw error;
                            console.log("Renumeration Data: ", data);

                            if (data) {
                              sendSMS(
                                applicationInfo.application_meta.applicants_id,
                                `Hello ${applicationInfo.application_meta.applicants_name}, your accounts have been renumerated. Regards, Bweyogerere Tuberebumu Sacco.`
                              )
                                .then((response) => response.json())
                                .then((data) => {
                                  console.log(data);
                                })
                                .catch((error) => console.log(error, "Error"));

                              setApplicationInfo((applicationInfo) => ({
                                ...applicationInfo,
                                ...data.updatedApplicationInfo,
                              }));
                              setAccounts((accounts) => ({
                                ...accounts,
                                ...data.updatedAccountInformation,
                              }));
                              setAmountRenumerable(0);
                              toast.update(toastNotification, {
                                render: `Successfully settled accounts.`,
                                type: "success",
                                isLoading: false,
                                autoClose: 5000,
                              });
                            }
                          } catch (error) {
                            toast.update(toastNotification, {
                              render: `${error.message}`,
                              type: "error",
                              isLoading: false,
                              autoClose: 5000,
                            });
                          }

                          // Invoke the function that sends an SMS notification to the sacco member that requested Termination.
                        }}
                      >
                        Renumerate
                      </button>
                    )}
                  </div>
                </div>
              ) : applicationInfo?.application_meta?.termination_status ===
                "share_transfer" ? (
                <>
                  <h1 className="my-3 semi-bold text-xl">Transfer Shares</h1>
                  <Formik
                    onSubmit={async (
                      { members },
                      { resetForm, setSubmitting }
                    ) => {
                      console.log("Values: ", members);
                      const toastNotification = toast.loading(
                        "Processing share transfers"
                      );
                      try {
                        const { data, error } = await supabase.rpc(
                          "transfer_shares",
                          {
                            details: {
                              members,
                              member_id: applicantsId,
                              sharesTransferred,
                              application_id: id,
                            },
                          }
                        );

                        if (error) throw error;
                        if (data) {
                          toast.update(toastNotification, {
                            render: `Shares successfully transferred`,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000,
                          });

                          console.log("Data: ", data);
                          sendSMS(
                            applicationInfo.application_meta.applicants_id,
                            `Hello ${applicationInfo.application_meta.applicants_name}, your shares have been renumerated please await your termination any time from now. Regards, Bweyogerere Tuberebumu Sacco.`
                          )
                            .then((response) => response.json())
                            .then((data) => {
                              console.log(data);
                            })
                            .catch((error) => console.log(error, "Error"));

                          setApplicationInfo((applicationInfo) => ({
                            ...applicationInfo,
                            ...data.updatedApplicationInfo,
                          }));
                          setAccounts((accounts) => ({
                            ...accounts,
                            ...data.updatedAccountInformation,
                          }));

                          console.log(
                            "UpdatedShares: ",
                            data.updatedAccountInformation.shares.balance
                          );
                          setTotalShares(
                            () =>
                              data?.updatedAccountInformation?.shares.balance ??
                              0
                          );
                          setRemainingShares(
                            () =>
                              data.updatedAccountInformation.shares.balance ?? 0
                          );
                          setSharesTransferred(() => 0);
                          setSubmitting(false);
                        }
                      } catch (error) {
                        console.log("Error: ", error);
                        toast.update(toastNotification, {
                          render: `${error.message}`,
                          type: "error",
                          isLoading: false,
                          autoClose: 5000,
                        });
                      }

                      resetForm({
                        values: {
                          members: [
                            {
                              id: "",
                              amount: "",
                              account: "shares",
                              name: "",
                              availableShares: 0,
                            },
                          ],
                        },
                      });
                    }}
                    initialValues={initialValues}
                    validationSchema={sharesTransfer2ValidationSchema}
                  >
                    {({ handleBlur, errors, touched, values }) => {
                      return (
                        <>
                          <div className="flex justify-between h-96 overflow-y-scroll">
                            <div className="h-full">
                              <div className="p-4 rounded-lg border-2 border-black">
                                <h2 className="font-semibold text-lg">
                                  Breakdown
                                </h2>
                                <div className="max-h-fit grid grid-cols-2 gap-2">
                                  <div className="my-2 font-light flex items-center">
                                    Total Shares:{" "}
                                  </div>
                                  <div className="text-xl font-semibold flex items-center">
                                    {" "}
                                    UGX {currencyFormatter(totalShares ?? 0)}
                                  </div>
                                </div>
                                <div className="max-h-fit grid grid-cols-2 gap-2">
                                  <div className="my-2 font-light flex items-center">
                                    Shares Transferred:{" "}
                                  </div>
                                  <div className="text-xl font-semibold flex items-center">
                                    {" "}
                                    UGX{" "}
                                    {sharesTransferred
                                      ? currencyFormatter(
                                          sharesTransferred ?? 0
                                        )
                                      : 0}
                                  </div>
                                </div>
                                <div className="max-h-fit grid grid-cols-2 gap-2">
                                  <div className="my-2 font-light flex items-center">
                                    Remaining shares:{" "}
                                  </div>
                                  <div className="text-xl font-semibold flex items-center">
                                    {" "}
                                    UGX{" "}
                                    {remainingShares
                                      ? currencyFormatter(remainingShares ?? 0)
                                      : currencyFormatter(totalShares)}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Form className="overflow-y-scroll h-full">
                              <FieldArray
                                name="members"
                                render={(fieldArrayProp) => {
                                  return (
                                    <div>
                                      {values.members.map((member, index) => (
                                        <Fragment key={index}>
                                          <div
                                            className="m-2 flex flex-col"
                                            key={index}
                                          >
                                            <div className="flex justify-start items-center">
                                              <h1 className="font-bold">
                                                Member 0{index + 1}
                                              </h1>
                                            </div>
                                            <div className="flex gap-5">
                                              <div className="flex flex-col w-56">
                                                <label className="text-sm">
                                                  Select Member
                                                  <span className="text-md text-red-500 font-bold ml-2">
                                                    *
                                                  </span>
                                                </label>
                                                <select
                                                  name={`members[${index}].id`}
                                                  className={`ring-1 ring-black rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                                                    errors?.members &&
                                                    errors?.members?.length >
                                                      0 &&
                                                    errors?.members[index]
                                                      ?.id &&
                                                    touched?.members &&
                                                    touched?.members?.length >
                                                      0 &&
                                                    touched?.members[index]?.id
                                                      ? "ring-red-500"
                                                      : "ring-black"
                                                  }`}
                                                  onChange={async (event) => {
                                                    values.members[index][
                                                      "id"
                                                    ] =
                                                      profiles[
                                                        event.target.value
                                                      ].id;
                                                    values.members[index][
                                                      "name"
                                                    ] =
                                                      profiles[
                                                        event.target.value
                                                      ].fullname;
                                                  }}
                                                  onBlur={handleBlur}
                                                  defaultValue={
                                                    initialValues?.members[
                                                      index
                                                    ]?.name ?? ""
                                                  }
                                                >
                                                  <option value="">
                                                    --Select Member--
                                                  </option>
                                                  {profiles &&
                                                    profiles.map(
                                                      ({ fullname }, index) => {
                                                        return (
                                                          <option
                                                            key={index}
                                                            value={index}
                                                            className="capitalize"
                                                          >
                                                            {fullname}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                </select>
                                                <ErrorMessage
                                                  name={`members.[${index}].id`}
                                                >
                                                  {(msg) => (
                                                    <div className="error text-xs text-red-500">
                                                      {msg}
                                                    </div>
                                                  )}
                                                </ErrorMessage>
                                              </div>
                                              <div className="flex flex-wrap gap-5">
                                                <div className="flex flex-col w-56 mb-3">
                                                  <label
                                                    htmlFor={`member${index}`}
                                                    className=" text-sm"
                                                  >
                                                    Amount
                                                    <span className="text-sm text-red-500 ml-2 font-bold">
                                                      *
                                                    </span>
                                                  </label>
                                                  <input
                                                    type="text"
                                                    name={`members[${index}].amount`}
                                                    id={`members-${index}-amount`}
                                                    placeholder="Enter amount"
                                                    className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                                      errors?.members &&
                                                      errors.members.length >
                                                        0 &&
                                                      errors.members[index]
                                                        ?.amount &&
                                                      touched?.members &&
                                                      touched?.members.length >
                                                        0 &&
                                                      touched.members[index]
                                                        ?.amount
                                                        ? "ring-red-500"
                                                        : "ring-black"
                                                    }`}
                                                    onChange={async (event) => {
                                                      let formatted_string =
                                                        add_separator(
                                                          remove_separator(
                                                            event.target.value
                                                          )
                                                        );

                                                      event.target.value =
                                                        formatted_string;
                                                      values.members[
                                                        index
                                                      ].amount = parseFloat(
                                                        remove_separator(
                                                          event.target.value ??
                                                            " "
                                                        )
                                                      );
                                                      let allocatedShares =
                                                        await values.members.reduce(
                                                          (acc, curr) => {
                                                            return (
                                                              acc +
                                                              (curr?.amount ??
                                                                0)
                                                            );
                                                          },

                                                          0
                                                        );
                                                      values.members[
                                                        index
                                                      ].availableShares =
                                                        totalShares -
                                                        allocatedShares;
                                                      setRemainingShares(
                                                        totalShares -
                                                          allocatedShares
                                                      );
                                                      setSharesTransferred(
                                                        allocatedShares
                                                      );
                                                    }}
                                                    onBlur={handleBlur}
                                                    defaultValue={add_separator(
                                                      (values?.members &&
                                                        values.members[index]
                                                          .amount) ??
                                                        0
                                                    )}
                                                  />
                                                  <div className="flex relative mt-1">
                                                    <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                                                      {" "}
                                                    </div>
                                                    <div className="text-xs text-gray-400 pl-3">
                                                      Use figures without
                                                      separators
                                                    </div>
                                                  </div>
                                                  <ErrorMessage
                                                    name={`members[${index}].amount`}
                                                  >
                                                    {(msg) => (
                                                      <div className="error text-red-600 text-xs">
                                                        {msg}
                                                      </div>
                                                    )}
                                                  </ErrorMessage>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </Fragment>
                                      ))}

                                      {errors?.members_uniqueness && (
                                        <div className="error text-red-600 text-xs mt-1">
                                          {errors.members_uniqueness}
                                        </div>
                                      )}

                                      <button
                                        type="button"
                                        className="bg-primary text-white px-3 py-2 rounded m-2"
                                        onClick={() => {
                                          if (values.members.length > 0)
                                            fieldArrayProp.push({
                                              id: "",
                                              amount: "",
                                              account: "shares",
                                              name: "",
                                              availableShares: 0,
                                            });

                                          console.log("Values: ", values);
                                          console.log("Errors: ", errors);
                                        }}
                                        disabled={remainingShares <= 0}
                                      >
                                        +
                                      </button>
                                      <button
                                        type="button"
                                        className="bg-accent-red text-white px-3 py-2 rounded m-2"
                                        onClick={async () => {
                                          if (values.members.length > 1) {
                                            await fieldArrayProp.pop();
                                            let allocatedShares =
                                              await values.members.reduce(
                                                (acc, curr) =>
                                                  acc + (curr?.amount ?? 0),
                                                0
                                              );

                                            setRemainingShares(
                                              totalShares - allocatedShares
                                            );
                                            setSharesTransferred(
                                              allocatedShares
                                            );
                                          }
                                        }}
                                      >
                                        -
                                      </button>
                                    </div>
                                  );
                                }}
                              ></FieldArray>
                              <div className="flex justify-end">
                                <input
                                  type="submit"
                                  value="Submit"
                                  className="outline bg-primary text-white px-4 py-2 cursor-pointer mb-5"
                                />
                              </div>
                            </Form>
                          </div>
                        </>
                      );
                    }}
                  </Formik>
                </>
              ) : (
                applicationInfo?.application_meta?.termination_status ===
                  "termination" && (
                  <div>
                    <h2 className="my-5 font-semibold uppercase">Terminate</h2>

                    <section className="mb-5">
                      <p>
                        {applicationInfo?.application_meta?.review_status ===
                        "pending" ? (
                          <>
                            <BsExclamationTriangle
                              size={40}
                              className="mb-2 inline"
                            />
                            Please take caution before terminating the member
                            from the sacco as this action is permanent and
                            irreversible.
                          </>
                        ) : (
                          <>
                            <span className="font-semibold">
                              {applicationInfo?.application_meta
                                ?.applicants_name ?? "Member"}
                            </span>{" "}
                            was terminated from the system. Please note that the
                            user will no longer have access to the system and
                            any associated data has been removed from the
                            database. If you have any questions or concerns
                            regarding this action, please contact the Super
                            Admin.
                          </>
                        )}
                      </p>
                    </section>
                    <div className="flex justify-end">
                      {applicationInfo?.application_meta?.review_status ===
                        "pending" && (
                        <button
                          type="button"
                          className="bg-accent-red px-3 py-1 outline outline-1 outline-accent-red text-white"
                          onClick={async (event) => {
                            event.preventDefault();
                            const toastNotification = toast.loading(
                              "Processing member termination"
                            );
                            try {
                              const { data, error } = await supabase.rpc(
                                "terminate_user",
                                {
                                  details: JSON.stringify({
                                    user_id: applicantsId,
                                    application_id: id,
                                  }),
                                }
                              );
                              if (error) throw error;
                              if (data) {
                                console.log("Termination Data: ", data);
                                sendSMS(
                                  applicationInfo.application_meta
                                    .applicants_id,
                                  `Hello ${applicationInfo.application_meta.applicants_name}, your membership has been terminated. \n Regards, Bweyogerere Tuberebumu Sacco.`
                                )
                                  .then((response) => response.json())
                                  .then((data) => {
                                    console.log(data);
                                  })
                                  .catch((error) =>
                                    console.log(error, "Error")
                                  );
                                setApplicationInfo((applicationInfo) => ({
                                  ...applicationInfo,
                                  ...data.updatedApplicationInfo,
                                }));

                                toast.update(toastNotification, {
                                  render: `Member successfully terminated`,
                                  type: "success",
                                  isLoading: false,
                                  autoClose: 5000,
                                });
                              }
                            } catch (error) {
                              console.log("Error: ", error);
                              toast.update(toastNotification, {
                                render: `${error.message}`,
                                type: "error",
                                isLoading: false,
                                autoClose: 5000,
                              });
                            }
                          }}
                        >
                          Terminate
                        </button>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Renumeration;
