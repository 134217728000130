import { useEffect, useState } from "react";
import { supabase } from "../../helpers/supabase";
import { useOutletContext } from "react-router-dom";
import { NothingShown, Spinner } from "../../components";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Frown from "../../components/Frown";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ErrorBoundary from "../../components/ErrorBoundary";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { Pagination } from "../../components";
import { TbFilterOff } from "react-icons/tb";
import { useMediaQuery } from "../../hooks";

function VerificationRequests() {
  const [, profile, , roles] = useOutletContext();
  const [requests, setRequests] = useState([]);
  const [allRequests, setAllRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nothingShown, setNothingShown] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const navigate = useNavigate();

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage, setRequestsPerPage] = useState(10);
  const indexOfLastPage = currentPage * requestsPerPage;
  const indexOfFirstPage = indexOfLastPage - requestsPerPage;

  const fetchAssetRequests = async () => {
    const { data, error } = await supabase.from("assets").select();

    if (error) {
      setLoading(false);
      throw error;
    }

    if (data && data?.length > 0) {
      const myRequests = data
        .filter((asset) =>
          asset.witnesses.find((witness) => witness.id === profile.id)
        )
        .map((asset) => {
          let status = asset.witnesses.find(
            (witness) => witness.id === profile.id
          )["confirmation_status"];
          return { ...asset, status };
        });

      if (myRequests && myRequests?.length > 0) {
        setRequests(myRequests);
        setAllRequests(myRequests);
        setLoading(false);
      } else {
        setNothingShown(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssetRequests().catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    let data = allRequests;
    data =
      data && data?.length > 0
        ? data.filter(
            (asset) => !date || asset.date_acquired.substring(0, 10) === date
          )
        : null;

    data =
      !data ||
      data.filter((asset) =>
        !status || status === "" ? asset : asset?.status === status
      );

    console.log("Filtered Data: ", data);

    setRequests(data ?? []);
  }, [date, searchText, status]);

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  let filteredRequests =
    requests && requests?.length > 0
      ? requests.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  return (
    <div className="mx-5 my-2 md:h-[calc(100vh-70px)]">
      <ToastContainer />
      <div className="flex flex-col justify-between pb-3 md:h-[150px]">
        <h1 className="mb-3 mt-2 font-bold uppercase dark:text-white">
          Assets
        </h1>
        <div className="flex flex-wrap justify-between gap-2 mb-3 md:mb-0">
          <input
            type="text"
            className="w-full md:w-8/12 rounded-md px-2 py-2 sm:py-1 dark:bg-dark-bg-600"
            placeholder="Search"
            onChange={(event) => setSearchText(event.target.value)}
          />
        </div>
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-4 bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded-l"
              onChange={(event) => setStatus(event.target.value)}
              value={status}
            >
              <option value="">Select Status</option>
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <div className="flex gap-2 flex-wrap">
            <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
              <input
                type="date"
                name=""
                onChange={(event) => setDate(event.target.value)}
                id=""
                className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
                value={date}
              />
              <button
                className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
                onClick={() => {
                  if (date) setDate("");
                }}
              >
                <AiOutlineCloseCircle size={20} />
              </button>
            </div>
            <button
              onClick={(status) => {
                if (status) setStatus("");
                if (date) setDate("");
                if (searchText) setSearchText("");
              }}
              className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
            >
              {largeDesktop && !smallDesktop && "Reset Filters"}
              <TbFilterOff className="ml-1" size={20} />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-170px)] dark:bg-dark-bg-700">
        <ErrorBoundary>
          {loading ? (
            <Spinner />
          ) : filteredRequests && filteredRequests?.length > 0 ? (
            <>
              <div className="w-full pb-3 overflow-x-auto h-full  relative overflow-y-auto ">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                    <tr>
                      <th></th>
                      <th className="px-6 py-4">Name</th>
                      <th className="px-6 py-4">Type</th>
                      <th className="px-6 py-4">Date acquired</th>
                      <th className="px-6 py-4 whitespace-nowrap">Valuation</th>
                      <th className="px-6 py-4">Status</th>
                      {roles && roles.includes("super_admin") && (
                        <th>Actions</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredRequests.map((asset, index) => (
                      <tr
                        className={`${
                          index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                        } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                        key={index}
                        onClick={() => {
                          navigate(`/assets/verification-requests/${asset.id}`);
                        }}
                      >
                        <td>
                          <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                        </td>
                        <td className="px-6 py-3 capitalize">{asset?.name}</td>
                        <td className="px-6 py-3 capitalize">{asset?.type}</td>
                        <td className="px-6 py-3">
                          {moment(asset?.date_acquired).format("DD-MM-YYYY")}
                        </td>
                        <td className="px-6 py-3">
                          UGX {currencyFormatter(asset?.valuation)}
                        </td>

                        <td className={`px-6 py-3 font-semibold`}>
                          <span
                            className={` py-1 px-2 rounded-xl text-white ${
                              asset?.status === "approved"
                                ? "bg-emerald-400"
                                : asset?.status === "pending"
                                ? "bg-yellow-400"
                                : "bg-rose-400"
                            } inline-block`}
                          >
                            {asset.status === "pending"
                              ? "pending approval"
                              : asset.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
                <Pagination
                  pages={Math.ceil(filteredRequests.length / requestsPerPage)}
                  setCurrentPage={setCurrentPage}
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPage={indexOfLastPage}
                  data={filteredRequests}
                  depositsPerPage={filteredRequests}
                  setDepositsPerPage={setRequestsPerPage}
                />
              </div>
            </>
          ) : nothingShown ? (
            <NothingShown />
          ) : (
            allRequests.length > 0 &&
            (filteredRequests?.length === 0 || !filteredRequests) && <Frown />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default VerificationRequests;
