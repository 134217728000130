import { supabase } from "../../helpers/supabase";
import { useEffect, useState, Fragment } from "react";
import { Spinner, NothingShown } from "../../components";
import { Pagination } from "../../components";
import { TbFilterOff } from "react-icons/tb";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import LoanPayModal from "../../components/Modals/LoanGuaranteeModal";
import DateRangeFilter from "../../components/DateRangeFilter";
import Frown from "../../components/Frown";
import { Helmet } from "react-helmet";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function LoanPaymentApplications() {
  useEffect(() => {
    supabase
      .rpc(`fetch_guarantee_requests`)
      .then(({ data }) => {
        if (!data || data.length === 0) setNothingShown(true);
        const sorted_data =
          data && data.length > 0
            ? data.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )
            : null;
        setAllLoans(() => sorted_data);
        setLoans(() => sorted_data);
        setLoading(() => false);
      })
      .catch((error) => console.log(error));
  }, []);

  const [loans, setLoans] = useState([]);
  const [loanModal, setLoanModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState(null);
  const [allLoans, setAllLoans] = useState([]);
  const [nothingShown, setNothingShown] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawPerPage, setWithdrawPerPage] = useState(10);
  const indexOfLastPage = currentPage * withdrawPerPage;
  const indexOfFirstPage = indexOfLastPage - withdrawPerPage;

  useEffect(() => {
    let data = allLoans;

    data =
      data && data?.length > 0
        ? data.filter(
            (deposit) => !date || deposit.created_at.substring(0, 10) === date
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(startDate).isSameOrBefore(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(endDate).isSameOrAfter(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(loan?.created_at?.substring(0, 10)).isBetween(
                moment(startDate),
                moment(endDate),
                undefined,
                "[]"
              );
            })
          : null;
    }

    data =
      !data ||
      data.filter((deposit) =>
        !status || status === ""
          ? deposit
          : status === "approved"
          ? deposit?.transaction_meta
          : deposit?.application_meta?.review_status === status
      );

    data =
      data && data?.length > 0
        ? data.filter(
            (loan) =>
              !searchText ||
              loan?.application_meta?.applicants_name
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
          ).length > 0
          ? data.filter(
              (loan) =>
                !searchText ||
                loan?.application_meta?.applicants_name
                  .toLowerCase()
                  .indexOf(searchText.toLowerCase()) > -1
            )
          : null
        : null;

    console.log("Filtered Data: ", data);
    setLoans(() => data);
  }, [startDate, endDate, status, searchText, date]);

  let shownLoans =
    loans && loans?.length > 0
      ? loans.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  //context
  const [show, setShow] = useState(false);
  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const [activeIndex, setActiveIndex] = useState(false);

  return (
    <div className="flex-grow mx-5 my-2 h-[calc(100vh-70px)]">
      <Helmet>
        <title>Loan Guarantee Requests - Bweyogerere Tuberebumu</title>
      </Helmet>
      <div className="flex flex-col justify-between pb-3 mb-10 md:h-[130px]">
        <h1 className="mt-2 mb-2 font-bold uppercase dark:text-white">
          Loan Guarantee Requests
        </h1>
        <div className="flex flex-wrap gap-2 justify-between">
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="text"
              name="name"
              onChange={(event) => setSearchText(event.target.value)}
              id="name"
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={searchText}
              placeholder="Search by name..."
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (searchText) setSearchText("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-2 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
            >
              <option value="">Status</option>
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
         

          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id=""
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={date}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <DateRangeFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          <button
            onClick={() => {
              if (searchText) setSearchText("");
              if (status) setStatus("");
              if (date) setDate("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              if (startDate) setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              if (endDate) setEndDate("");
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {"Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
        </div>
      </div>
      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-200px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : loans !== null && shownLoans !== null && shownLoans.length > 0 ? (
          <>
            <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Date</th>
                    <th className="px-6 py-4">Transaction ID</th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">Amount Paid</th>
                    <th className="px-6 py-4">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {shownLoans.map((deposit, index) => (
                    <Fragment key={index}>
                      <tr
                        className={`cursor-pointer ${
                          index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                        } hover:bg-gray-100 dark:hover:bg-dark-bg-600`}
                        key={index}
                        onClick={() => setLoanModal(true)}
                      >
                        <td
                          onClick={() => {
                            setActiveIndex(index);
                          }}
                        >
                          <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                        </td>
                        <td
                          className="px-6 py-3"
                          onClick={() => {
                            setActiveIndex(index);
                          }}
                        >
                          {moment(deposit.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td
                          className="px-6 py-3"
                          onClick={() => {
                            setActiveIndex(index);
                          }}
                        >
                          {deposit.app_id ||
                            deposit?.trans_id ||
                            deposit?.transaction_id}
                        </td>
                        <td
                          className="px-6 py-3"
                          onClick={() => {
                            setActiveIndex(index);
                          }}
                        >
                          {deposit?.application_meta?.applicants_name
                            ? deposit.application_meta.applicants_name
                            : deposit?.transaction_meta?.member_name}
                        </td>
                        <td
                          className="px-6 py-3"
                          onClick={() => {
                            setActiveIndex(index);
                          }}
                        >
                          {currencyFormatter(
                            deposit?.application_meta?.amount || deposit?.amount
                          )}
                        </td>

                        <td
                          className="px-6 py-3"
                          onClick={() => {
                            setActiveIndex(index);
                          }}
                        >
                          <span
                            className={` py-1 px-2 rounded-xl text-white ${
                              deposit?.application_meta?.review_status ===
                              "pending"
                                ? "bg-yellow-400"
                                : deposit?.application_meta?.review_status ===
                                  "rejected"
                                ? "bg-red-400"
                                : "bg-green-400"
                            }`}
                          >
                            {deposit?.application_meta?.review_status ||
                              "approved"}
                          </span>
                        </td>
                      </tr>
                      {loanModal && activeIndex === index && (
                        <LoanPayModal
                          setLoanModal={setLoanModal}
                          loan={deposit}
                        />
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(loans.length / withdrawPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={loans}
                loansPerPage={withdrawPerPage}
                setLoansPerPage={setWithdrawPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allLoans &&
          allLoans.length > 0 &&
          (!loans || loans?.length === 0) && <Frown />
        )}
      </div>
    </div>
  );
}
