import * as Yup from "yup";
import { uniq } from "lodash";
import moment from "moment/moment";

const numberRegExp = /^(0|[1-9]\d*)$/;

export const validationSchema = Yup.object({
  phoneNo: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Phone number must have 10 digits only")
    .max(10, "Phone number must have 10 digits only")
    .required("Phone number is required"),
  password: Yup.string()
    .trim()
    .min(8, "Password must be atleast 8 characters")
    .required("Password is required"),
});

export const registerValidationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .min(8, "Password must be atleast 8 characters")
    .required("Password is required"),
  // Matching passwords schema
  confirmPassword: Yup.string()
    .trim()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Password must be the same!")
    .required("Required!"),
});

export const validationSubmitSchema = Yup.object({
  phoneNo: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Phone number must have 10 digits only")
    .max(10, "Phone number must have 10 digits only")
    .required("Phone number is required"),
});

const otpRegExp = /^[0-9]{6}$/gm;
export const otpValidationSchema = Yup.object({
  otp: Yup.string().required().matches(otpRegExp, "Invalid OTP"),
});

export const verifyCodeSchema = Yup.object({
  code: Yup.string()
    .min(6, "Code must be 6-digits")
    .max(6, "Code must be 6-digit")
    .required("Verification Code is required"),
});

export const changeUserPasswordValidationSchema = Yup.object({
  new_password: Yup.string()
    .trim()
    .min(8, "Must be atleast 8 characters!")
    .notOneOf(
      [Yup.ref("current_password")],
      "Should not be the same as old password!"
    )
    .required("New password required!"),
  confirm_password: Yup.string()
    .trim()
    .oneOf([Yup.ref("new_password")], "Should be the same as the New password!")
    .notOneOf(
      [Yup.ref("current_password")],
      "Should not be the same as old password!"
    )
    .required("Password confirmation required!"),
  current_password: Yup.string()
    .trim()
    .min(8, "Must be atleast 8 characters!")
    .required("Old password required!"),
});

export const selfTermination = Yup.object({
  current_password: Yup.string()
    .min(8, "Must be atleast 8 characters!")
    .required("current password required!"),
});

// creating a custom yup validation method
Yup.addMethod(Yup.string, "isNumber", function () {
  return this.matches(/^[0-9]+$/, {
    message: "Must be a number",
    excludedEmptyStrings: true,
  }).required("Required!");
});

Yup.addMethod(
  Yup.array,
  "unique",
  function (message, mapper = (value) => value) {
    return this.test(
      "unique",
      message,
      (list = []) => list.length === new Set(list.map(mapper)).size
    );
  }
);

export const evidencedRequestValidationSchema = Yup.object({
  amount: Yup.string()
    .matches(/^[0-9]+$/, "Must be in figures")
    .required("Make sure that you've entered the amount"),
  account_type: Yup.string().required(
    "Make sure that you' select your account"
  ),
  evidence: Yup.string().required("Receipt is required"),
  designated_for: Yup.string().required("Member should be selected"),
  member_id: Yup.string().when("designated_for", {
    is: "other",
    then: Yup.string().required("Select the member"),
  }),
  comments: Yup.string().when("designated_for", {
    is: "organisation",
    then: Yup.string().required("Reason required!"),
  }),
  baana: Yup.mixed().test(
    "baana",
    "Member not added baana yet",
    function (value) {
      if (this.parent.account_type === "mwana" && value.length < 1) {
        // this.createError({
        //   path: "member_id",
        //   message: "Member not added baana yet",
        // })

        return this.createError({
          path: "account_type",
          message: "Member has not yet added baana",
        });
      }
      return true;
    }
  ),
  // b
});

export const loanPaymentRequestValidationSchema = Yup.object({
  amount: Yup.string()
    .matches(/^[0-9]+$/, "Amount should be in figures")
    .test("violates_minimum_constraint", "should not subsceed 1", (value) =>
      value && !parseFloat(value) ? false : true
    )
    .required("Enter amount to pay"),
  comments: Yup.string(),
});

export const nonEvidencedRequestValidationSchema = Yup.object({
  amount: Yup.string()
    .matches(/^[0-9]+$/, "Amount should be in figures")
    .required("Enter amount to withdraw"),
  account_type: Yup.string().required("Account should be selected"),
  phone_number: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Phone number must have 10 digits only")
    .max(10, "Phone number must have 10 digits only")
    .required("Phone number is required"),
  comments: Yup.string(),
  cashout_method: Yup.string().required("Select cashout method"),
  designated_for: Yup.string().required("Required!"),
  member_id: Yup.string().when("designated_for", {
    is: "other",
    then: Yup.string().required("The member is required"),
  }),
  baana: Yup.mixed().test(
    "baana",
    "Member not added baana yet",
    function (value) {
      return this.parent.account_type === "mwana" && value.length < 1
        ? this.createError({
            path: "member_id",
            message: "Member not added baana yet",
          })
        : true;
    }
  ),
});

export const loanPaymentValidationSchema = Yup.object({
  amount: Yup.string().isNumber(),
  comments: Yup.string(),
});

export const loan1ValidationSchema = Yup.object({
  landline_number: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Phone number must have 10 digits only")
    .max(10, "Phone number must have 10 digits only")
    .required("Phone Number is required"),
  kin_name: Yup.string().required("Name required"),
  kin_contact: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Phone number must have 10 digits only")
    .max(10, "Phone number must have 10 digits only")
    .required("Contact required"),
  spouse_name: Yup.string(),
  spouse_contact: Yup.string().when("spouse_name", (val, schema) => {
    if (val?.length > 0) {
      return Yup.string()
        .matches(/^[0-9]+$/, "Should contain digits only")
        .min(10, "Phone number must have 10 digits only")
        .max(10, "Phone number must have 10 digits only")
        .required("Phone number is required");
    } else {
      return Yup.string().notRequired();
    }
  }),
  no_of_dependents: Yup.string()
    .min(1, "Should not be less than 1")
    .matches(/^[0-9]+$/, "Should be a number")
    .required("No. of dependents is required"),
  district: Yup.string().required("District required"),
  county: Yup.string().required("County required"),
  years_spent: Yup.string()
    .min(1, "Should not be less than 1")
    .matches(/^[0-9]+$/, "Should be a number")
    .required("Years spent required"),
  ownership: Yup.string().required("Ownership required"),
  marital_status: Yup.string().required("Marital status required"),
});

export const loan2ValidationSchema = Yup.object({
  landline_number: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Phone number must have 10 digits only")
    .max(10, "Phone number must have 10 digits only")
    .required("Phone Number is required"),
  employer_no: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Phone number must have 10 digits only")
    .max(10, "Phone number must have 10 digits only")
    .required("Phone Number is required"),
  no_of_dependents: Yup.string()
    .min(1, "Should not be less than 1")
    .required("No. of dependents is required"),
  employer: Yup.string().required("employer's name is required"),
  asset1: Yup.string().required("asset is required"),
  asset2: Yup.string().required("asset 2 is required"),
  loan_purpose: Yup.string().required("Loan purpose is required"),
  amount: Yup.string()
    .matches(/^[0-9]+$/, "Use figures only")
    .required("Amount is required"),
  amount_in_words: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Must only contain letters")
    .required("Amount in words required"),
  months: Yup.string().required("Period in months is required"),
  loan_type: Yup.string().required("Loan type required"),
  // existing_loan: Yup.string().when("loan_type", {
  //   is: 'extended',
  //   then: Yup.string().required("Arreared loan is required.")
  // })
});

export const loan3ValidationSchema = Yup.object({
  landline_number: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Phone number must have 10 digits only")
    .max(10, "Phone number must have 10 digits only")
    .required("Phone Number is required"),
  employment_type: Yup.string(),
  no_of_dependents: Yup.string().required("No. of dependents is required"),
  asset1: Yup.string().required("asset is required"),
  business_type: Yup.string().required("required"),
  years_of_operation: Yup.string().required("required"),
  business_income: Yup.string().required("required"),
  loan_purpose: Yup.string().required("required"),
  amount: Yup.string().required("Amount is required!"),
  amount_in_words: Yup.string().required("required!"),
  months: Yup.string().required("required!"),
  existing_loan: Yup.string().when("loan_type", {
    is: "extended",
    then: Yup.string().required("Arreared loan is required."),
  }),
});

export const loan4ValidationSchema = Yup.object({
  bank_loans: Yup.array().of(
    Yup.object().shape(
      {
        name: Yup.string().when(
          ["amount_advanced", "date_granted", "repayment_period", "balance"],
          {
            is: (amount_advanced, date_granted, repayment_period, balance) =>
              amount_advanced !== undefined ||
              date_granted !== undefined ||
              repayment_period !== undefined ||
              balance !== undefined,
            then: Yup.string()
              .required("Name required")
              .matches(/^[a-zA-Z ]+$/, "Must only contain letters"),
          }
        ),
        amount_advanced: Yup.string().when(
          ["name", "date_granted", "repayment_period", "balance"],
          {
            is: (name, date_granted, repayment_period, balance) =>
              name !== undefined ||
              date_granted !== undefined ||
              repayment_period !== undefined ||
              balance !== undefined,
            then: Yup.string()
              .matches(numberRegExp, "Must be a number")
              .required("Amount required"),
          }
        ),
        date_granted: Yup.string().when(
          ["name", "amount_advanced", "repayment_period", "balance"],
          {
            is: (name, amount_advanced, repayment_period, balance) =>
              name !== undefined ||
              amount_advanced !== undefined ||
              repayment_period !== undefined ||
              balance !== undefined,
            then: Yup.string().required("Date required"),
          }
        ),
        repayment_period: Yup.string().when(
          ["name", "amount_advanced", "date_granted", "balance"],
          {
            is: (name, amount_advanced, date_granted, balance) =>
              name !== undefined ||
              amount_advanced !== undefined ||
              date_granted !== undefined ||
              balance !== undefined,
            then: Yup.string()
              .matches(numberRegExp, "Must be a number")
              .required("Period required"),
          }
        ),
        balance: Yup.string().when(
          ["name", "amount_advanced", "date_granted", "repayment_period"],
          {
            is: (name, amount_advanced, date_granted, repayment_period) =>
              name !== undefined ||
              amount_advanced !== undefined ||
              date_granted !== undefined ||
              repayment_period !== undefined,
            then: Yup.string()
              .matches(numberRegExp, "Must be a number")
              .required("Balance required"),
          }
        ),
      },
      [
        ["name", "amount_advanced"],
        ["name", "date_granted"],
        ["name", "repayment_period"],
        ["name", "balance"],
        ["amount_advanced", "date_granted"],
        ["amount_advanced", "repayment_period"],
        ["amount_advanced", "balance"],
        ["date_granted", "repayment_period"],
        ["date_granted", "balance"],
        ["repayment_period", "balance"],
      ]
    )
  ),
});

export const loan5ValidationSchema = Yup.object({
  securities: Yup.array().min(2, "Select a minimum of two options").required(),
  guarantors: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Name required"),
        contact: Yup.string()
          .matches(/^[0-9]+$/, "Should contain digits only")
          .min(10, "Phone number must have 10 digits only")
          .max(10, "Phone number must have 10 digits only"),
      })
    )
    .unique("Choose different members", (val) => val.name)
    .unique(
      "Provide a different phone number for each guarantor",
      (val) => val.contact
    ),
});

export const member1ValidationSchema = Yup.object({
  fullname: Yup.string().required("fullname is required"),
  gender: Yup.string().required("Gender is required"),
  present_address: Yup.string().required("Present Address is required"),
  marital_status: Yup.string().required("Marital status is required"),
  email_address: Yup.string()
    .email("Invalid email address")
    .required("The email is required"),
  phone_number: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Phone number must have 10 digits only")
    .max(10, "Phone number must have 10 digits only")
    .required("Phone number is required"),
  id_passport_number: Yup.string().required("ID/Passport number is required"),
  fathers_name: Yup.string().required("father's name is required"),
  fathers_address: Yup.string().required("father's address is required"),
  dob: Yup.string()
    .required("Date if birth is required")
    .test("Members Age", "Members should be above 18 years", function (value) {
      return moment().diff(value, "years") < 18
        ? this.createError({
            path: "dob",
            message: "Member should be above 18 years",
          })
        : true;
    }),
  income_sources: Yup.object({
    status: Yup.string().required("Your income source is required"),
    employed: Yup.object().when("status", {
      is: "Employed",
      then: Yup.object({
        employers_name: Yup.string().required("employers name is required"),
        employers_address: Yup.string().required(
          "employers address is required"
        ),
        gross_monthly_income: Yup.string()
          .matches(/^[0-9]+$/, "Use figures only")
          .required("Gross monthly income is required"),
      }),
    }),
    business: Yup.object().when("status", {
      is: "Business",
      then: Yup.object({
        business_name: Yup.string().required("business name is required"),
        business_address: Yup.string().required("business address is required"),
        gross_monthly_income: Yup.string()
          .matches(/^[0-9]+$/, "Use figures only")
          .required("Gross monthly income is required"),
      }),
    }),
  }),
  kin_name: Yup.string().required("Name is required"),
  kin_contact: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Contact must have 10 digits only")
    .max(10, "Contact must have 10 digits only")
    .required("Contact is required"),
});

export const member2ValidationSchema = Yup.object({
  proposed_monthly_contributions: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .required("Monthly contributions are required!"),
  amount_in_words: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Must only contain letters")
    .required("Amount in words is required!"),
  nominees: Yup.array().of(
    Yup.object().shape({
      percentage: Yup.number()
        .required("Percentage required")
        .max(100, "Should not exceed 100")
        .min(0.1, "Should not greater than 0"),
      fullname: Yup.string().required("Name is required"),
      dob: Yup.string().required("Date of birth is required"),
      contact: Yup.string()
        .matches(/^[0-9]+$/, "Should contain digits only")
        .min(10, "Phone number must have 10 digits only")
        .max(10, "Phone number must have 10 digits only")
        .required("Phone Number is required"),
    })
  ),
  evidence: Yup.string().required("Payment evidence is required"),
  membership_fee: Yup.string()
    .required("Amount is required")
    .matches(/^[0-9*]+$/, "Must only contain figures"),
});

export const assetsValidationSchema = Yup.object({
  name: Yup.string().required("Name is required!"),
  type: Yup.string().required("Specify type of asset!"),
  valuation: Yup.string().required("Specify asset valuation"),
  date_acquired: Yup.string().required("Date is required!"),
  description: Yup.string().required("Describe the asset"),
  evidence: Yup.string().required("Supporting files are required!"),
  witnesses: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Name is required!"),
        contact: Yup.string()
          .required("Phone number is required!")
          .matches(/^[0-9]+$/, "Should contain digits only")
          .min(10, "Phone number must have 10 digits only")
          .max(10, "Phone number must have 10 digits only")
          .required("Phone number is required"),
      })
    )
    .unique("Names must be unique", (val) => val.name)
    .unique("Contacts must be unique", (val) => val.contact),
});

export const announcementValidationSchema = Yup.object({
  notification_type: Yup.string().required("Notification type is required"),
  category: Yup.string().required("Category is required"),
  title: Yup.string().when("notification_type", {
    is: "sms",
    then: Yup.string().required("Title is required"),
  }),
  notification_type: Yup.string(),
  message: Yup.string()
    // .required("Message is required")
    .test(
      "character limit",
      "The message should not exceed 160 characters",
      function (value) {
        console.log("Notification_type: ", this.parent.notification_type);
        if (this.parent.notification_type === "SMS" && value.length > 160) {
          return false;
        }
        return true;
      }
    ),
});

export const transactionValidationSchema = Yup.object({
  amount: Yup.string()
    .required("Amount is required")
    .matches(/^[0-9*]+$/, "Must only contain figures"),
  account_type: Yup.string().required("Account type required"),
  reason: Yup.string().when("_type", {
    is: "withdraw",
    then: Yup.string().required("Specify the reason"),
  }),
  transaction_type: Yup.string().required("Transaction type required"),
  cashout_method: Yup.string().when("_type", {
    is: "withdraw",
    then: Yup.string().required("Cashout method is required"),
  }),
  service_provider: Yup.string().when("transaction_type", {
    is: "external service",
    then: Yup.string().required("Service provider is required"),
  }),
});

export const loan6ValidationSchema = Yup.object({
  securities_offered: Yup.array().when("securities", (securities) => {
    if (securities.includes("shares") && securities.includes("savings")) {
      return Yup.array().of(
        Yup.object().shape({
          shares: Yup.number()
            .required("Percentage shares required")
            .max(100, "Should not exceed 100")
            .min(0.1, "Should not greater than 0"),
          savings: Yup.number()
            .required("Percentage savings required")
            .max(100, "Should not exceed 100")
            .min(0.1, "Should not greater than 0"),
        })
      );
    } else if (securities.includes("savings")) {
      return Yup.array().of(
        Yup.object().shape({
          savings: Yup.number()
            .required("Percentage savings required")
            .max(100, "Should not exceed 100")
            .min(0.1, "Should not greater than 0"),
        })
      );
    } else if (securities.includes("shares")) {
      return Yup.array().of(
        Yup.object().shape({
          shares: Yup.number()
            .required("Percentage shares required")
            .max(100, "Should not exceed 100")
            .min(0.1, "Should not greater than 0"),
        })
      );
    }
  }),
});

export const editProfileValidationSchema = Yup.object().shape({
  fullname: Yup.string().required("Fullname is required"),
  dob: Yup.string().required("Date of birth is required"),
  phone_number: Yup.string()
    .matches(/^[0-9]+$/, "Should contain digits only")
    .min(10, "Phone number must have 10 digits only")
    .max(10, "Phone number must have 10 digits only")
    .required("Phone Number is required"),
  id_passport_number: Yup.string().required("ID/ Passport number is required"),
  present_address: Yup.string().required("Present address is required"),
  marital_status: Yup.string().required("Marital status is required"),
  password: Yup.string()
    .trim()
    .min(8, "Password must be atleast 8 characters")
    .required("Password is required"),
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
});

Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

export const sharesTransfer2ValidationSchema = Yup.object().shape({
  members: Yup.array().of(
    Yup.object()
      .shape({
        availableShares: Yup.number()
          .required("Available shares is required")
          .min(0, "Available shares should not exceed 0"),
        id: Yup.string().required("The member is required"),
        amount: Yup.number()
          .typeError("Member amount must be a number")
          .required("Member amount is required")
          .test(
            "available-shares",
            "Amount transfered exceeds available shares",
            function (value) {
              return this.parent.availableShares >= 0;
            }
          ),
      })
      .test(
        "unique-name",
        "Member already has already been selected",
        function () {
          const ids = this.parent.map((member) => member.id);
          const duplicates = uniq(ids).length !== ids.length;
          return duplicates
            ? this.createError({
                path: "members_uniqueness",
                message: "Member has already been selected",
              })
            : true;
        }
      )
  ),
});

export const addBaanaValidationSchema = Yup.object().shape({
  children: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(`The child's name is required`),
      dob: Yup.string().required(`The child's date of birth is required`),
      gender: Yup.string().required(`The child's gender is required`),
      education: Yup.string().required(
        `The child's education level is required`
      ),
    })
  ),
});

export const mwanaEditValidationSchema = Yup.object().shape({
  name: Yup.string().required(`The child's name is required`),
  dob: Yup.string().required(`The child's date of birth is required`),
  gender: Yup.string().required(`The child's gender is required`),
  education: Yup.string().required(`The child's education level is required`),
});

export const transferValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError("Member amount must be a number")
    .required("Member amount is required"),
  comments: Yup.string(),
  // evidence: Yup.mixed().required("Receipt is required"),
  designated_for: Yup.string().required("Tranfer designation required"),
  receivers_member_id: Yup.string().required(
    "Member transfer destination required"
  ),
  receivers_member_name: Yup.string().required(
    "Member transfer destination required "
  ),
  senders_member_id: Yup.string().required("Member transfer source required"),
  senders_member_name: Yup.string().required("Member transfer source required"),
  senders_baana: Yup.mixed().test(
    "senders baana",
    "Member not added baana yet",
    function (value) {
      return this.parent.senders_account_type === "mwana" && value.length < 1
        ? this.createError({
            path: "senders_member_id",
            message: "Member not added baana yet",
          })
        : true;
    }
  ),
  receivers_baana: Yup.mixed().test(
    "senders baana",
    "Member already has already been selected",
    function (value) {
      return this.parent.senders_account_type === "mwana" && value.length < 1
        ? this.createError({
            path: "receivers_member_id",
            message: "Member not added baana yet",
          })
        : true;
    }
  ),
  senders_account_type: Yup.string().required(
    "Member transfer source account is required"
  ),
  receivers_account_type: Yup.string().required(
    "Member transfer destination account is required"
  ),
  senders_mwana: Yup.string().test(
    "senders mwana",
    "Senders mwana not specified",
    function (value) {
      return this.parent.senders_account_type === "mwana" && !value
        ? this.createError({ path: "senders_mwana", message: "Select Mwana" })
        : true;
    }
  ),
  receivers_mwana: Yup.string().test(
    "receivers mwana",
    "Senders mwana not specified",
    function (value) {
      return this.parent.senders_account_type === "mwana" && !value
        ? this.createError({ path: "receivers_mwana", message: "Select Mwana" })
        : true;
    }
  ),
  // uniqueness: Yup.string().test('uniqueness','Use a unique combination of the user member and account', function () {
  //   const { senders_account_type, receivers_account_type, senders_name, receivers_name, senders_mwana, receivers_mwana, senders_member_id, receivers_member_id } = this.parent
  //   return senders_account_type === receivers_account_type && senders_name === receivers_name && senders_mwana === receivers_mwana && senders_member_id === receivers_member_id ? this.createError({path: "uniqueness", message: "Use a unique combination of the account, source and destination fields"}) : true
  // })
});

export const serviceProviderValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  service: Yup.string().required("Service is required"),
  // ,
  // description: Yup.string().required("Description is required"),
});
