import { Transactions } from "../../components";
import { supabase } from "../../helpers/supabase";
import { useEffect, useState } from "react";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { useNavigate } from "react-router-dom";

function Organisation() {
  const [deposits, setDeposits] = useState(0);
  const [withdraws, setWithdraws] = useState(0);
  const [loans, setLoans] = useState(0);
  const [members, setMembers] = useState(0);
  const [reserve, setReserve] = useState(0);
  const [accountTotals, setAccountTotals] = useState({});
  const navigate = useNavigate();

  const getData = async () => {
    const { data: depositTransactions, error: depositTransactionsError } =
      await supabase.from("transactions").select().match({ _type: "deposit" });
    if (depositTransactionsError) throw depositTransactionsError;

    const { data: withdrawTransactions, error: withdrawTransactionsError } =
      await supabase.from("transactions").select().match({ _type: "withdraw" });
    if (withdrawTransactionsError) throw withdrawTransactionsError;

    const { data: loansTransactions, error: loansTransactionsError } =
      await supabase.from("loans").select();
    if (loansTransactionsError) throw loansTransactionsError;

    const { data: members, error: membersError } = await supabase
      .from("new_members")
      .select();
    if (membersError) throw membersError;

    const { data: accountTotalsData, error: accountTotalsError } =
      await supabase.rpc("fetch_account_totals");
    if (accountTotalsError) throw accountTotalsError;

    setLoans(loansTransactions.length);
    setDeposits(depositTransactions.length);
    setWithdraws(withdrawTransactions.length);
    setMembers(members.length);
    setAccountTotals(accountTotalsData);
  };

  useEffect(() => {
    getData().catch((error) => console.log(error));
  }, []);

  return (
    <div className={`mx-5 mb-2 my-2 md:h-[calc(100vh-70px)]`}>
      <div className="">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Organisation
        </h1>
      </div>
      <div className="flex flex-col pb-10 m-1 my-2 overflow-x-hidden">
        <Transactions
          members={members}
          deposits={deposits}
          loans={loans}
          withdraws={withdraws}
        />
      </div>
      <div className="flex h-[70%]">
        <div className="flex mx-2 my-5 flex-1 flex-col p-5 bg-white rounded">
          <div className="flex justify-between">
            <h1 className="mb-2 font-semibold">Public Announcements 📣</h1>
            <button
              type="button"
              onClick={(event) => {
                event.preventDefault();
                navigate("/organisation/announcements/announce");
              }}
            >
              Announce
            </button>
          </div>
        </div>
        <div className="flex mx-2 my-5 flex-1 flex-col p-5 bg-white rounded">
          <h1>Accounts Summary</h1>
          <table className="w-full">
            <thead>
              <th className="text-left py-2">Account type</th>
              <th className="text-left py-2">Totals</th>
            </thead>
            <tbody>
              <tr>
                <td className="col-span-2 font-bold py-2"> Member Accounts </td>
              </tr>
              <tr>
                <td>Savings</td>
                <td>UGX {currencyFormatter(accountTotals?.savings ?? 0)}</td>
              </tr>
              <tr>
                <td>Shares</td>
                <td>UGX {currencyFormatter(accountTotals?.shares ?? 0)}</td>
              </tr>
              <tr>
                <td>Fixed</td>
                <td>
                  UGX {currencyFormatter(accountTotals?.fixed_deposits ?? 0)}
                </td>
              </tr>
              <tr>
                <td>Mwana</td>
                <td>
                  UGX {currencyFormatter(accountTotals?.mwana_deposits ?? 0)}
                </td>
              </tr>
              <tr>
                <td className="col-span-2 font-bold py-2">
                  {" "}
                  Organisation Accounts{" "}
                </td>
              </tr>
              <tr>
                <td>Commercial</td>
                <td>UGX {currencyFormatter(accountTotals?.commercial ?? 0)}</td>
              </tr>
              <tr>
                <td>Reserve</td>
                <td>UGX {currencyFormatter(accountTotals?.reserve ?? 0)}</td>
              </tr>
              <tr>
                <td>Sacco</td>
                <td>UGX {currencyFormatter(accountTotals.sacco ?? 0)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Organisation;
