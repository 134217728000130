import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import { loan4ValidationSchema } from "../../helpers/validator";
import {
  remove_separator,
  add_separator,
} from "../../helpers/thousand_separator";
import React from "react";

export default function ApplicationPg3({
  profile,
  initialValues,
  setInitialValues,
  setPageNumber,
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loan4ValidationSchema}
      onSubmit={async (values) => {
        console.log(values)
        setInitialValues(values);
        setPageNumber(4);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => {
        return (
          <Form>
            <div className="mb-3">
              <h1 className="font-semibold">
                Loan in other banks or financial institutions
              </h1>
              <span className="text-sm text-red-500 mb-3">
                All Fields with a * are mandatory
              </span>
              <FieldArray
                name="bank_loans"
                render={(fieldArrayProps) => {
                  return (
                    <div>
                      {values.bank_loans.map(({ amount_advanced }, index) => (
                        <React.Fragment key={index}>
                          <div className="m-2 flex gap-2" key={index}>
                            <div className="flex justify-center items-center">
                              <h1 className="font-bold">0{index + 1}</h1>
                            </div>
                            <div className="flex flex-wrap gap-5">
                              <div className="flex flex-col w-56 mb-3">
                                <label
                                  htmlFor={`bankLoanName${index}`}
                                  className=" text-sm"
                                >
                                  Name
                                  {(values?.bank_loans[index]?.name ||
                                    values?.bank_loans[index]
                                      ?.amount_advanced ||
                                    values?.bank_loans[index]?.date_granted ||
                                    values?.bank_loans[index]
                                      ?.balance ||
                                    values?.bank_loans[index]?.balance) && (
                                    <span className="text-sm text-red-500 ml-2 font-bold">
                                      *
                                    </span>
                                  )}
                                </label>
                                <input
                                  type="text"
                                  name={`bank_loans[${index}].name`}
                                  id={`bankLoanName${index}`}
                                  placeholder="Enter name"
                                  className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${errors?.bank_loans && errors.bank_loans.length > 0 && errors.bank_loans[index]?.repayment_period && touched?.bank_loans && touched?.bank_loans.length > 0 && touched.bank_loans[index]?.repayment_period ? "ring-red-500" : "ring-black"}`}
                                  onChange={(event) => {
                                    setFieldValue(
                                      event.target.name,
                                      event.target.value
                                    );
                                    const temp = initialValues
                                    temp.bank_loans[index].name = event.target.value
                                    setInitialValues(initialValues => ({...initialValues, ...temp}))
                                  }}
                                  onBlur={handleBlur}
                                  defaultValue={
                                    initialValues.bank_loans[index].name   
                                  }
                                />
                                <ErrorMessage
                                  name={`bank_loans[${index}].name`}
                                >
                                  {(msg) => (
                                    <div className="error text-red-600 text-xs">
                                      {msg}
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>

                              <div className="flex flex-col w-56 mb-3">
                                <label
                                  htmlFor={`bankLoanAmountAdvanced${index}`}
                                  className=" text-sm"
                                >
                                  Amount Advanced
                                  {(values?.bank_loans[index]?.name ||
                                    values?.bank_loans[index]
                                      ?.amount_advanced ||
                                    values?.bank_loans[index]?.date_granted ||
                                    values?.bank_loans[index]
                                      ?.repayment_period ||
                                    values?.bank_loans[index]?.balance) && (
                                    <span className="text-sm text-red-500 ml-2 font-bold">
                                      *
                                    </span>
                                  )}
                                </label>
                                <input
                                  type="text"
                                  name={`bank_loans[${index}].amount_advanced`}
                                  id={`bankLoanAmountAdvanced${index}`}
                                  placeholder="Enter amount"
                                  className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${errors?.bank_loans && errors.bank_loans.length > 0 && errors.bank_loans[index]?.amount_advanced && touched?.bank_loans && touched?.bank_loans.length > 0 && touched.bank_loans[index]?.amount_advanced ? "ring-red-500" : "ring-black"}`}
                                  onChange={(event) => {
                                    let formatted_string = add_separator(
                                      remove_separator(event.target.value)
                                    );
                                    event.target.value = formatted_string;
                                    setFieldValue(
                                      event.target.name,
                                      parseFloat(
                                        remove_separator(event.target.value)
                                      )
                                    );
                                    const temp = initialValues
                                    temp.bank_loans[index].amount_advanced = event.target.value
                                    setInitialValues(initialValues => ({...initialValues, ...temp}))
                                  }}
                                  onBlur={handleBlur}
                                  defaultValue={add_separator(
                                    initialValues.bank_loans[index]
                                      .amount_advanced
                                  )}
                                />
                                <div className="flex relative mt-1">
                                  <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                                    {" "}
                                  </div>
                                  <div className="text-xs text-gray-400 pl-3">
                                    Use figures without separators
                                  </div>
                                </div>
                                <ErrorMessage
                                  name={`bank_loans[${index}].amount_advanced`}
                                >
                                  {(msg) => (
                                    <div className="error text-red-600 text-xs">
                                      {msg}
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>
                              <div className="flex flex-col w-56 ">
                                <label className=" text-sm">
                                  Date Granted
                                  {(values?.bank_loans[index]?.name ||
                                    values?.bank_loans[index]
                                      ?.amount_advanced ||
                                    values?.bank_loans[index]?.date_granted ||
                                    values?.bank_loans[index]
                                      ?.repayment_period ||
                                    values?.bank_loans[index]?.balance) && (
                                    <span className="text-sm text-red-500 ml-2 font-bold">
                                      *
                                    </span>
                                  )}
                                </label>
                                <input
                                  type="date"
                                  defaultValue={
                                    initialValues.bank_loans[index].date_granted
                                  }
                                  className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${errors?.bank_loans && errors.bank_loans.length > 0 && errors.bank_loans[index]?.date_granted && touched?.bank_loans && touched?.bank_loans.length > 0 && touched.bank_loans[index]?.date_granted ? 'ring-red-500' : 'ring-black'}`}
                                  name={`bank_loans[${index}].date_granted`}
                                  onChange={( event ) => {
                                    setFieldValue(event.target.name, event.target.value)
                                    const temp = initialValues
                                    temp.bank_loans[index].date_granted = event.target.value
                                    setInitialValues(initialValues => ({...initialValues, ...temp}))
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  name={`bank_loans[${index}].date_granted`}
                                >
                                  {(msg) => (
                                    <div className="error text-red-600 text-xs">
                                      {msg}
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>


                              <div className="flex flex-col w-56 mb-3">
                                <label
                                  htmlFor={`bankLoanRepaymentPeriod${index}`}
                                  className=" text-sm"
                                >
                                  Repayment Period
                                  {(values?.bank_loans[index]?.name ||
                                    values?.bank_loans[index]
                                      ?.amount_advanced ||
                                    values?.bank_loans[index]?.date_granted ||
                                    values?.bank_loans[index]
                                      ?.balance ||
                                    values?.bank_loans[index]?.balance) && (
                                    <span className="text-sm text-red-500 ml-2 font-bold">
                                      *
                                    </span>
                                  )}
                                </label>
                                <input
                                  type="text"
                                  name={`bank_loans[${index}].repayment_period`}
                                  id={`bankLoanRepaymentPeriod${index}`}
                                  placeholder="Enter period"
                                  className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${errors?.bank_loans && errors.bank_loans.length > 0 && errors.bank_loans[index]?.repayment_period && touched?.bank_loans && touched?.bank_loans.length > 0 && touched.bank_loans[index]?.repayment_period ? "ring-red-500" : "ring-black"}`}
                                  onChange={(event) => {
                                    setFieldValue(
                                      event.target.name,
                                      event.target.value
                                    );
                                    const temp = initialValues
                                    temp.bank_loans[index].repayment_period = event.target.value
                                    setInitialValues(initialValues => ({...initialValues, ...temp}))
                                  }}
                                  onBlur={handleBlur}
                                  defaultValue={
                                    initialValues.bank_loans[index].repayment_period    
                                  }
                                />
                                <div className="flex relative mt-1">
                                  <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                                    {" "}
                                  </div>
                                  <div className="text-xs text-gray-400 pl-3">
                                    Should be a number
                                  </div>
                                </div>
                                <div className="flex relative mt-1">
                                  <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                                    {" "}
                                  </div>
                                  <div className="text-xs text-gray-400 pl-3">
                                    Use figures
                                  </div>
                                </div>
                                <ErrorMessage
                                  name={`bank_loans[${index}].repayment_period`}
                                >
                                  {(msg) => (
                                    <div className="error text-red-600 text-xs">
                                      {msg}
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>
                              <div className="flex flex-col w-56 mb-3">
                                <label
                                  htmlFor={`bankLoanAmountAdvanced${index}`}
                                  className=" text-sm"
                                >
                                  Balance
                                  {(values?.bank_loans[index]?.name ||
                                    values?.bank_loans[index]
                                      ?.amount_advanced ||
                                    values?.bank_loans[index]?.date_granted ||
                                    values?.bank_loans[index]
                                      ?.repayment_period ||
                                    values?.bank_loans[index]?.balance) && (
                                    <span className="text-sm text-red-500 ml-2 font-bold">
                                      *
                                    </span>
                                  )}
                                </label>
                                <input
                                  type="text"
                                  name={`bank_loans[${index}].balance`}
                                  id={`balance${index}`}
                                  placeholder="Enter amount"
                                  className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${errors?.bank_loans && errors.bank_loans.length > 0 && errors.bank_loans[index]?.balance && touched?.bank_loans && touched?.bank_loans.length > 0 && touched.bank_loans[index]?.balance ? 'ring-red-500' : 'ring-black'}`}
                                  onChange={(event) => {
                                    let formatted_string = add_separator(
                                      remove_separator(event.target.value)
                                    );
                                    event.target.value = formatted_string;
                                    setFieldValue(
                                      event.target.name,
                                      parseFloat(
                                        remove_separator(event.target.value)
                                      )
                                    );

                                    const temp = initialValues
                                    temp.bank_loans[index].balance = event.target.value
                                    setInitialValues(initialValues => ({...initialValues, ...temp}))
                                  }}
                                  onBlur={handleBlur}
                                  defaultValue={add_separator(
                                    initialValues.bank_loans[index]
                                      .balance
                                  )}
                                />
                                <div className="flex relative mt-1">
                                  <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                                    {" "}
                                  </div>
                                  <div className="text-xs text-gray-400 pl-3">
                                    Use figures without separators
                                  </div>
                                </div>
                                <ErrorMessage
                                  name={`bank_loans[${index}].balance`}
                                >
                                  {(msg) => (
                                    <div className="error text-red-600 text-xs">
                                      {msg}
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </React.Fragment>
                      ))}
                    </div>
                  );
                }}
              ></FieldArray>
            </div>

            <div className="mb-3">
              <h1 className="font-semibold">Additional Files</h1>
              <div className="m-2">
                <div className="flex flex-wrap gap-5">
                  <div className="flex flex-col w-56">
                    <input
                      type="file"
                      name="additional_files"
                      className="ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600"
                      onChange={(event) => {
                        setFieldValue(event.target.name, event.target.files[0]);
                      }}
                      accept="image/png, image/jpeg, application/pdf, .doc, .docx"

                    />
                    <div className="flex relative mt-1">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs capitalize text-gray-400 pl-3">
                        Click choose file
                      </div>
                    </div>
                    <div className="flex relative">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs capitalize text-gray-400 pl-3">
                        Select the file 
                      </div>
                    </div>
                    <div className="flex relative">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs capitalize text-gray-400 pl-3">
                        Click upload to upload any other additional files related to the banking institutions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full mb-10">
              <button
                type="button"
                className="outline outline-gray-500 outline-2 text-gray-500 px-4 py-1 rounded-lg cursor-pointer"
                onClick={() => setPageNumber(2)}
              >
                Back
              </button>
              <input
                type="submit"
                value="Next"
                className="outline outline-gray-500 outline-2 text-gray-500 px-4 py-1 rounded-lg cursor-pointer"
              />
              {/* <button
            onClick={(event) => {
                event.preventDefault()
                console.log("Values: ", values)
                console.log("Errors: ", errors)
            }}
            >
                try me
            </button> */}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
