import { useState, useEffect } from "react";
import { Pagination } from "../../components";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { Spinner } from "../../components";
import { ToastContainer } from "react-toastify";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import moment from "moment/moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Frown from "../../components/Frown";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import round from "../../helpers/round";

export default function Reports() {
  useEffect(() => {
    // Fetch reports on component render
    fetchReports().catch((error) => console.log(error));

    // Realtime
    const mySubscription = supabase
      .from("*")
      .on("*", async () => {
        await fetchReports().catch((error) => console.log(error));
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, []);

  const fetchReports = async () => {
    setLoading(true);
    const { data: transactions, error: transactions_error } =
      await supabase.rpc("sacco_income_performance");

    if (transactions_error) throw transactions_error;

    const { data: loans, error: loans_error } = await supabase.rpc(
      "sacco_loan_performance"
    );

    if (loans_error) throw loans_error;
    const reports = await generateReports(transactions, loans);
    setReports(reports);
    setLoading(false);
  };

  const generateReports = async (transactions, loans) => {
    let year_data = [];

    let income_summaries = [];
    let expenditure_summaries = [];
    let cummulative_loan_performance = [];

    for (let year in loans) {
      // Loans performance
      let number_of_loans = 0;
      let amount_on_loan_returned = 0;
      let amount_still_on_loan = 0;
      let amount_available_for_loan = 0;
      let interest_on_loans = 0;

      if (loans[year]?.length > 0) {
        loans[year].forEach((loan) => {
          if (loan) {
            number_of_loans += 1;
            amount_on_loan_returned += loan?.interest_paid ?? 0;
            amount_on_loan_returned += loan?.principal_paid;
            amount_still_on_loan += loan?.reducing_balance ?? 0;
            interest_on_loans += loan?.interest_paid ?? 0;
          }
        });
      }

      const loan_summary = {
        Year: year,
        "Number of Loans": number_of_loans,
        "Amount on Loan returned": amount_on_loan_returned,
        "Amount still on Loan": amount_still_on_loan,
        "Amount avalilabe for Loan": amount_available_for_loan,
        "Interest on Loans": interest_on_loans,
      };

      cummulative_loan_performance.push(loan_summary);
    }

    for (let transaction in transactions) {
      let currentTransactions = transactions[transaction];
      let shares = [];
      let savings = [];
      let fixed_deposits = [];
      let mwana = [];
      let payments = [];
      let interest_by_bank = [];
      let monthly_charges = [];
      let executive_contributions = [];
      let loan_application_fees = [];
      let development_fees = [];
      let interest_paid = [];
      let principal_paid = [];
      let custom_payments = [];
      let withholding_tax = [];

      // Expenses
      let administrator_expenses = [];
      let bank_charges = [];
      let dividends_payments = [];
      let agm_expenses = [];
      let procurements = [];

      // Income
      let total_shares = 0;
      let total_savings = 0;
      let total_fixed_deposits = 0;
      let total_mwana = 0;
      let total_payments = 0;
      let total_interest = 0;
      let total_monthly_charge = 0;
      let total_bank_interest = 0;
      let total_development_fees = 0;
      let total_executive_contributions = 0;
      let total_loan_application_fees = 0;
      let total_principal = 0;
      let total_witholding_tax = 0;

      // Expenses
      let total_administrator_expenses = 0;
      let total_bank_charge = 0;
      let total_dividends_payment = 0;
      let total_procurements = 0;
      let total_agm = 0;

      await currentTransactions.forEach((transaction) => {
        if (transaction?.transaction_meta?.account_type === "shares") {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              member_name,
            },
            _type,
          } = transaction;

          shares.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            Type: _type ?? "",
          });
          total_shares += transaction.amount;
        }
        if (transaction?.transaction_meta?.account_type === "savings") {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              cashout_method,
              member_name,
            },
            _type,
          } = transaction;

          savings.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            "Cashout Method": cashout_method ?? "",
            Type: _type ?? "",
          });
          total_savings += transaction.amount;
        }

        if (transaction?.transaction_meta?.account_type === "fixed") {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              cashout_method,
              member_name,
            },
            _type,
          } = transaction;

          fixed_deposits.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            "Cashout Method": cashout_method ?? "",
            Type: _type ?? "",
          });
          total_fixed_deposits += transaction.amount;
        }
        if (transaction?.transaction_meta?.account_type === "mwana") {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              cashout_method,
              member_name,
            },
            _type,
          } = transaction;

          mwana.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            "Cashout Method": cashout_method ?? "",
            Type: _type ?? "",
          });
          total_mwana += transaction.amount;
        }
        if (transaction?._type === "payment") {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              // cashout_method,
              member_name,
            },
            _type,
          } = transaction;

          payments.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            // "Cashout Method": cashout_method ?? "",
            Type: _type ?? "",
          });
          total_payments += transaction.amount;
          custom_payments.push(transaction);
        }
        if (transaction?._type === "monthly charge") {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              cashout_method,
              member_name,
            },
            _type,
          } = transaction;

          monthly_charges.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            "Cashout Method": cashout_method ?? "",
            Type: _type ?? "",
          });
          total_monthly_charge += transaction.amount;
        }
        if (
          transaction?.transaction_meta?.transaction_type === "bank interest"
        ) {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              cashout_method,
              transaction_type,
              member_name,
            },
          } = transaction;

          interest_by_bank.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            "Cashout Method": cashout_method ?? "",
            Type: transaction_type,
          });
          total_bank_interest += transaction.amount;
        }
        if (
          transaction?.transaction_meta?.transaction_type ===
          "executive contribution"
        ) {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              cashout_method,
              transaction_type,
              member_name,
            },
          } = transaction;

          executive_contributions.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            "Cashout Method": cashout_method ?? "",
            Type: transaction_type,
          });
          total_executive_contributions += transaction.amount;
        }
        if (
          transaction?.transaction_meta?.transaction_type ===
          "loan application fees"
        ) {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              cashout_method,
              transaction_type,
              member_name,
            },
          } = transaction;

          loan_application_fees.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            "Cashout Method": cashout_method ?? "",
            Type: transaction_type,
          });
          total_loan_application_fees += transaction.amount;
        }
        if (
          transaction?.transaction_meta?.transaction_type === "withholding tax"
        ) {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              transaction_type,
              member_name,
            },
            _type,
          } = transaction;

          withholding_tax.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            Type: transaction_type,
          });
          total_witholding_tax += 0;
        }
        if (transaction?.transaction_meta?.transaction_type === "development") {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              cashout_method,
              member_name,
            },
            _type,
          } = transaction;

          development_fees.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            "Cashout Method": cashout_method ?? "",
            Type: _type,
          });
          total_development_fees += transaction.amount;
        }
        if (transaction?.transaction_meta?.principal_installment_paid > 0) {
          const {
            created_at,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              principal_installment_paid,
              member_name,
            },
          } = transaction;

          principal_paid.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            "Principal Installment Paid": principal_installment_paid,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            Type: "interest_on_loans",
          });
          total_principal += principal_installment_paid;
        }
        if (transaction?.transaction_meta?.interest_paid > 0) {
          const {
            created_at,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              interest_paid: interest,
              member_name,
            },
          } = transaction;

          interest_paid.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            "Interest Paid": interest,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            Type: "interest_on_loans",
          });
          total_interest += interest;
        }
        if (
          transaction?._type === "administrator expenses" ||
          transaction?.transaction_meta?.transaction_type ===
            "administrator expenses"
        ) {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              member_name,
            },
            _type,
          } = transaction;

          administrator_expenses.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            Type: _type ?? "",
          });
          total_administrator_expenses += transaction.amount;
        }
        if (transaction?.transaction_meta?.transaction_type === "bank charge") {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              transaction_type,
              member_name,
            },
            _type,
          } = transaction;

          bank_charges.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            Type: transaction_type,
          });
          total_bank_charge += transaction.amount;
        }
        if (transaction?.transaction_meta?.transaction_type === "agm") {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              transaction_type,
              member_name,
            },
            _type,
          } = transaction;

          agm_expenses.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            Type: transaction_type,
          });
          total_agm += transaction.amount;
        }
        if (
          transaction?.transaction_meta?.transaction_type === "procurements"
        ) {
          const {
            created_at,
            amount,
            transaction_meta: {
              account_type,
              approved_at,
              review_status,
              approved_by,
              transaction_type,
              member_name,
            },
            _type,
          } = transaction;

          procurements.push({
            Date: moment(created_at).format("DD/MM/YYYY"),
            Name: member_name ?? "",
            Amount: amount,
            Account: account_type,
            "Approved at": moment(approved_at).format("DD/MM/YYYY"),
            Status: review_status ?? "",
            "Approved By": approved_by,
            Type: transaction_type,
          });
          total_procurements += transaction.amount;
        }

        return reports;
      });

      const income_summary = {
        year: transaction,
        "Total Shares": currencyFormatter(round(total_shares, 2)),
        "Total Savings": currencyFormatter(round(total_savings, 2)),
        "Total Fixed Savings": currencyFormatter(
          round(total_fixed_deposits, 2)
        ),
        "Total Mwana Savings": currencyFormatter(round(total_mwana, 2)),
        "Total Loan Payments": currencyFormatter(round(total_payments, 2)),
        "Total Interest By Bank": currencyFormatter(
          round(total_bank_interest, 2)
        ),
        "Total Monthly Charges": currencyFormatter(
          round(total_monthly_charge, 2)
        ),
        "Total Executive Contributions": currencyFormatter(
          round(total_executive_contributions, 2)
        ),
        "Total Loan Application Fees": currencyFormatter(
          round(total_loan_application_fees, 2)
        ),
        "Total Development Fees": currencyFormatter(
          round(total_development_fees, 2)
        ),
        "Total Interest Paid": currencyFormatter(round(total_interest, 2)),
        "Total Principal Paid": currencyFormatter(round(total_principal, 2)),
        "Total Witholding Tax": currencyFormatter(
          round(total_witholding_tax, 2)
        ),
      };

      console.log("Income Summary: ", income_summaries);

      const expenditure_summary = {
        year: transaction,
        "Bank Charge": total_bank_charge,
        "Dividends Payment": total_dividends_payment,
        "Administrator Expenses": total_administrator_expenses,
        AGM: total_agm,
        "Procurements /Projects": total_procurements,
      };

      income_summaries.push(income_summary);
      expenditure_summaries.push(expenditure_summary);

      if (shares.length > 0)
        year_data.push({
          year: transaction,
          data: shares,
          category: "shares",
          name: `Bweyogerere Tuberebumu Shares ${transaction}`,
        });
      if (savings.length > 0)
        year_data.push({
          year: transaction,
          data: savings,
          category: "savings",
          name: `Bweyogerere Tuberebumu Savings ${transaction}`,
        });
      if (fixed_deposits.length > 0)
        year_data.push({
          year: transaction,
          data: fixed_deposits,
          category: "fixed deposits",
          name: `Bweyogerere Tuberebumu Fixed Deposits ${transaction}`,
        });
      if (mwana.length > 0)
        year_data.push({
          year: transaction,
          data: mwana,
          category: "mwana deposits",
          name: `Bweyogerere Tuberebumu Mwana Savings ${transaction}`,
        });
      if (payments.length > 0)
        year_data.push({
          year: transaction,
          data: payments,
          category: "loan payments",
          name: `Bweyogerere Tuberebumu Loan Payments ${transaction}`,
        });
      if (interest_by_bank.length > 0)
        year_data.push({
          year: transaction,
          data: interest_by_bank,
          category: "interest by bank",
          name: `Bweyogerere Tuberebumu Interest By Bank ${transaction}`,
        });
      if (monthly_charges.length > 0)
        year_data.push({
          year: transaction,
          data: monthly_charges,
          category: "monthly charge",
          name: `Bweyogerere Tuberebumu Monthly Charges ${transaction}`,
        });
      if (executive_contributions.length > 0)
        year_data.push({
          year: transaction,
          data: executive_contributions,
          category: "executive contribution",
          name: `Bweyogerere Tuberebumu Executive Contributions ${transaction}.`,
        });
      if (loan_application_fees.length > 0)
        year_data.push({
          year: transaction,
          data: loan_application_fees,
          category: "loan application fees",
          name: `Bweyogerere Tuberebumu Loan Application Fees ${transaction}`,
        });
      if (development_fees.length > 0)
        year_data.push({
          year: transaction,
          data: development_fees,
          category: "development fees",
          name: `Bweyogerere Tuberebumu Development Fees ${transaction}`,
        });
      if (interest_paid.length > 0)
        year_data.push({
          year: transaction,
          data: interest_paid,
          category: "interest on loan",
          name: `Bweyogerere Tuberebumu Interest on Loans ${transaction}`,
        });

      console.log("income_summary: ", income_summary);
      year_data.push({
        year: transaction,
        data: [income_summary],
        category: "total income summaries",
        name: `Bweyogerere Tuberebumu Transaction Income Summaries ${transaction}`,
      });
      if (administrator_expenses.length > 0)
        year_data.push({
          year: transaction,
          data: administrator_expenses,
          category: "administrator expenses",
          name: `Bweyogerere Tuberebumu Transaction Administrator Expenses ${transaction}`,
        });
    }

    year_data.push({
      year: "All",
      data: income_summaries,
      category: "Over All",
      name: `Bweyogerere Sacco Income performance`,
    });
    year_data.push({
      year: "All",
      data: expenditure_summaries,
      category: "Over All",
      name: `Bweyogerere Sacco Expenditure performance`,
    });
    year_data.push({
      year: "All",
      data: cummulative_loan_performance,
      category: "Over All",
      name: `Bweyogerere Loan performance`,
    });

    return year_data.reverse();
  };

  const [reports, setReports] = useState(null);
  const [date, setDate] = useState(null);
  const navigate = useNavigate();
  const [year, setYear] = useState(null);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const currentYear = new Date().getFullYear();
  const beginningYear = 2014;
  const years = [];
  for (let year = currentYear; year >= beginningYear; year--) {
    years.push(year);
  }

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawPerPage, setWithdrawPerPage] = useState(10);
  const indexOfLastPage = currentPage * withdrawPerPage;
  const indexOfFirstPage = indexOfLastPage - withdrawPerPage;

  const filteredReports =
    !reports ||
    reports
      .filter(
        (report) =>
          !searchText ||
          report?.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      .filter((report) => !category || report?.category === category)
      .filter((report) => !year || report?.year === year);

  const shownReports =
    reports && filteredReports.slice(indexOfFirstPage, indexOfLastPage);

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  console.log(reports);

  return (
    <div className="mx-5 my-2 md:h-[calc(100vh-70px)]">
      <ToastContainer />
      <div className="flex flex-col justify-between pb-3 md:h-[150px]">
        <h1 className="mb-3 mt-2 font-bold uppercase dark:text-white">
          Reports
        </h1>
        <div className=" flex justify-between">
          <input
            type="text"
            className="w-full rounded-md px-2 py-2 dark:bg-dark-bg-600"
            placeholder="Search"
            onChange={(event) => setSearchText(event.target.value)}
          />
        </div>
        <div className="flex flex-wrap mt-2 items-center gap-2 justify-between">
          <div className="flex justify-between min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm w-full md:w-56">
            <select
              name="category"
              id=""
              className="py-3 px-2 rounded bg-white dark:bg-dark-bg-600 dark:text-secondary-text w-full"
              onChange={(event) => setCategory(event.target.value)}
              value={category}
            >
              <option value="">Category</option>
              <option value="Over All">Over All</option>
              <option value="total income summaries">Income summaries</option>
              <option value="interest on loan">Interest on loan</option>
              <option value="executive contribution">
                Executive contribution
              </option>
              <option value="monthly charge">Monthly charge</option>
              <option value="loan payments">Loan payments</option>
              <option value="mwana deposits">Mwana deposits</option>
              <option value="shares">shares</option>
              <option value="savings">Savings</option>
              <option value="development fees">Development fees</option>
              <option value="administrator expenses">
                Administrator expenses
              </option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (category) setCategory("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <div className="flex justify-between min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm w-full md:w-56">
            <select
              name="year"
              className="py-3 px-2 rounded bg-white dark:bg-dark-bg-700 dark:text-secondary-text w-full"
              onChange={(event) => {
                setYear(event.target.value);
              }}
              value={year}
            >
              <option value="">Year</option>
              {years &&
                years.map((year, index) => {
                  return (
                    <option value={year} key={index}>
                      {year}
                    </option>
                  );
                })}
              <option value="All">All</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (year) setYear("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-170px)] dark:bg-dark-bg-700">
        {reports && reports.length > 0 && shownReports.length > 0 ? (
          <>
            <div className="w-full pb-3 overflow-x-auto h-full  relative overflow-y-auto ">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">Category</th>
                    <th className="px-6 py-4">Year</th>
                    <th className="px-6 py-4 whitespace-nowrap">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {shownReports.map((report, index) => (
                    <tr
                      className={`${
                        index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                      } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                      key={index}
                      onClick={() => {
                        console.log(report.name, report);
                        if (report?.data && report.data?.length > 0)
                          navigate(
                            `/organisation/reports/${report.name.replace(
                              /\s+/g,
                              ""
                            )}`,
                            {
                              state: {
                                data: {
                                  report_data: report.data,
                                  report_name: report.name,
                                },
                              },
                            }
                          );
                      }}
                      disabled={!report?.data}
                    >
                      <td>
                        <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                      </td>
                      <td className="px-6 py-3 capitalize">{report?.name}</td>
                      <td className="px-6 py-3 capitalize">
                        {report?.category}
                      </td>
                      <td className="px-6 py-3">{report?.year}</td>
                      <td className="px-6 py-2">
                        <button
                          className="bg-green-500 align-text-middle px-2 py-2 text-white font-bold rounded flex items-center"
                          onClick={() => {
                            generateReportFromJson(report.data, report.name);
                          }}
                        >
                          Export
                          <MdDownload className="ml-1" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(filteredReports.length / withdrawPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={filteredReports}
                depositsPerPage={withdrawPerPage}
                setDepositsPerPage={setWithdrawPerPage}
              />
            </div>
          </>
        ) : loading ? (
          <Spinner />
        ) : (
          <Frown />
        )}
      </div>
    </div>
  );
}
