import { useState, useEffect } from "react";
import { Pagination } from "../../components";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { Spinner, NothingShown } from "../../components";
import { useLocation, useOutletContext } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import moment from "moment/moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ErrorBoundary from "../../components/ErrorBoundary";
import Frown from "../../components/Frown";

export default function Assets() {
  useEffect(() => {
    document.title = "Assets - Bweyogere tuberebumu";
    // Fetch assets on component render
    fetchAssets().catch((error) => console.log(error));

    // Realtime
    const mySubscription = supabase
      .from("assets")
      .on("*", async () => {
        await fetchAssets().catch((error) => console.log(error));
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, []);

  const [, profile, , roles] = useOutletContext();

  const fetchAssets = async () => {
    const { data, error } = await supabase.from("assets").select();

    console.log( "Asset Requests: ", data)

    if (error) throw error;
    setAssets(data ?? null);
    setLoading(false);
  };

  const [assets, setAssets] = useState([]);
  const [date, setDate] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState(null);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawPerPage, setWithdrawPerPage] = useState(10);
  const indexOfLastPage = currentPage * withdrawPerPage;
  const indexOfFirstPage = indexOfLastPage - withdrawPerPage;

  const filteredAssets =
    !assets ||
    assets
      .filter(
        (asset) =>
          !asset?.name ||
          asset?.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      .filter((asset) => !status || asset.status === status)
      .filter(
        (asset) =>
          !date ||
          !asset?.date_acquired ||
          asset.date_acquired.substring(0, 10) === date
      );

  const shownAssets =
    assets && filteredAssets.slice(indexOfFirstPage, indexOfLastPage);

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const exportAssets = () => {
    const formattedAssets = assets.map((asset) => {
      return {
        "Date Acquired": moment(asset?.date_acquired).format("DD/MM/YYYY") ?? "N/A",
        "Name": asset.name,
        "Type": asset.type ?? "N/A",
        "Valuation": "UGX " + currencyFormatter(asset?.valuation) ?? "N/A",
        "Added By": asset?.meta?.added_by.name ?? "N/A",
        "Status": asset.status ?? "N/A",
      };
    });

    generateReportFromJson(formattedAssets, "Organisation-Assets_" + new Date().toISOString().slice(0, 10));
  };

  return (
    <div className="mx-5 my-2 md:h-[calc(100vh-70px)]">
      <ToastContainer />
      <div className="flex flex-col justify-between pb-3 md:h-[150px]">
        <h1 className="mb-3 mt-2 font-bold uppercase dark:text-white">
          Assets
        </h1>
        <div className="flex flex-wrap justify-between gap-2 mb-3 md:mb-0">
          <input
            type="text"
            className="w-full md:w-8/12 rounded-md px-2 py-2 sm:py-1 dark:bg-dark-bg-600"
            placeholder="Search"
            onChange={(event) => setSearchText(event.target.value)}
          />
          {profile?.roles && !profile?.roles.includes("super_admin") && (
            <button
              className=" px-4 bg-primary py-2 text-white rounded-md flex justify-center items-center"
              onClick={() => {
                navigate("/organisation/assets/add-asset", {
                  state: { from: location.pathname },
                });
              }}
            >
              {/* <MdAdd />  */}
              Add Asset
            </button>
          )}
        </div>
        <div className="flex flex-wrap justify-between items-center">

        <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-4 bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded-l"
              onChange={(event) => setStatus(event.target.value)}
              value={status}
            >
              <option value="">Select Status</option>
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id=""
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={date}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <div className="flex justify-end mb-3 mt-3 w-[8%]">
            <button
              className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
              onClick={() => {
                exportAssets();
              }}
            >
              Export
              <MdDownload className="ml-1" />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-170px)] dark:bg-dark-bg-700">
        <ErrorBoundary>
        {assets && assets.length > 0 && shownAssets.length > 0 ? (
          <>
            <div className="w-full pb-3 overflow-x-auto h-full  relative overflow-y-auto ">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">Type</th>
                    <th className="px-6 py-4">Date acquired</th>
                    <th className="px-6 py-4 whitespace-nowrap">Valuation</th>
                    <th className="px-6 py-4">Status</th>
                    {roles && roles.includes("super_admin") && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {shownAssets.map((asset, index) => (
                    <tr
                      className={`${
                        index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                      } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                      key={index}
                      onClick={() => {
                        navigate(`/organisation/assets/${asset.id}`);
                      }}
                    >
                      <td>
                        <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                      </td>
                      <td className="px-6 py-3 capitalize">{asset?.name}</td>
                      <td className="px-6 py-3 capitalize">{asset?.type}</td>
                      <td className="px-6 py-3">
                        {moment(asset?.date_acquired).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-6 py-3">
                        UGX {currencyFormatter(asset?.valuation)}
                      </td>

                      <td className={`px-6 py-3 font-semibold`}>
                        <span
                          className={` py-1 px-2 rounded-xl text-white ${
                            asset?.status === "approved"
                              ? "bg-emerald-400"
                              : asset?.status === "pending"
                              ? "bg-yellow-400"
                              : "bg-rose-600"
                          } capitalize`}
                        >
                          {asset.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(filteredAssets.length / withdrawPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={filteredAssets}
                depositsPerPage={withdrawPerPage}
                setDepositsPerPage={setWithdrawPerPage}
              />
            </div>
          </>
        ) : loading === false ? (
          <Frown />
        ) : (
          loading && <Spinner />
        )}
        </ErrorBoundary>
      </div>
    </div>
  );
}
