import { Pagination, Spinner, NothingShown } from "../../components";
import { useState, useEffect, Fragment } from "react";
import { supabase } from "../../helpers/supabase";
import { TbFilterOff } from "react-icons/tb";
import { Helmet } from "react-helmet";
import DepositModal from "../../components/Modals/DepositModal";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import DateRangeFilter from "../../components/DateRangeFilter";
import Frown from "../../components/Frown";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "../../hooks";
import { useOutletContext } from "react-router-dom";

export default function Deposit() {
  const [deposits, setDeposits] = useState([]);
  const [depositModal, setDepositModal] = useState(false);
  const [account, setAccount] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allDeposits, setAllDeposits] = useState([]);
  const [nothingShown, setNothingShown] = useState(false);
  const [, profile] = useOutletContext();

  useEffect(() => {
    document.title = "Deposit - Bweyogere tuberebumu";
    getDeposits();

    supabase.from("applications").on("*", async (payload) => {
      await getDeposits();
    });
    getDeposits();

    supabase
      .from("transactions")
      .on("*", async (payload) => {
        await getDeposits();
      })
      .subscribe();

    return () => {};
  }, []);

  const [date, setDate] = useState(null);

  const getDeposits = async () => {
    const {
      data: { transactions, applications },
      error,
    } = await supabase.rpc("fetch_deposits");
    if (error) {
      setLoading(false);
      throw error;
    } else {
      let data = [];
      if (applications) data.push(...applications);
      if (transactions) data.push(...transactions);

      const sorted_data =
        data && data?.length > 0
          ? data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          : null;

      if (data?.length === 0 || !data) setNothingShown(true);

      setLoading(false);
      setDeposits(sorted_data);
      setAllDeposits(sorted_data);
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [depositsPerPage, setDepositsPerPage] = useState(10);
  const indexOfLastPage = currentPage * depositsPerPage;
  const indexOfFirstPage = indexOfLastPage - depositsPerPage;

  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  let filteredDeposits =
    deposits && deposits?.length > 0
      ? deposits.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  useEffect(() => {
    let data = allDeposits;
    data =
      data && data?.length > 0
        ? data.filter(
            (deposit) => !date || deposit.created_at.substring(0, 10) === date
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(startDate).isSameOrBefore(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(endDate).isSameOrAfter(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(loan?.created_at?.substring(0, 10)).isBetween(
                moment(startDate),
                moment(endDate),
                undefined,
                "[]"
              );
            })
          : null;
    }

    data =
      !data ||
      data
        .filter((deposit) =>
          !status || status === ""
            ? deposit
            : status === "approved"
            ? deposit?.transaction_meta
            : deposit?.application_meta?.review_status === status
        )
        .filter(
          (deposit) =>
            !account ||
            (deposit?.transaction_meta
              ? deposit?.transaction_meta?.account_type === account
              : deposit?.application_meta?.account_type === account)
        );

    console.log(data);

    setDeposits(data ?? []);
  }, [date, startDate, endDate, account, status]);

  const generate_deposits_report = () => {
    const formattedDeposits = allDeposits.map((deposit) => {
      return {
        "Transaction ID": deposit?.application_meta
          ? deposit?.app_id
          : deposit?.trans_id,
        Date: moment(deposit?.created_at).format("DD-MM-YYYY"),
        Amount: deposit?.transaction_meta
          ? deposit?.amount
          : deposit?.application_meta?.amount,
        Account: deposit?.transaction_meta
          ? deposit?.transaction_meta?.account_type
          : deposit?.application_meta?.account_type,
        "Approved At": deposit?.transaction_meta
          ? moment(deposit.transaction_meta?.approved_at).format("DD-MM-YYYY")
          : "",
        Status: deposit?.transaction_meta ? "approved" : "pending",
        "Approved By": deposit?.transaction_meta
          ? deposit?.transaction_meta?.approved_by
          : "",
      };
    });

    generateReportFromJson(
      formattedDeposits,
      `${profile.fullname} Deposits ${new Date()
        .toISOString()
        .substring(0, 10)} `
    );
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-140px)]">
      <Helmet>
        <title>Deposit - Bweyogere tuberebumu</title>
      </Helmet>
      <div className="flex flex-col pb-3 md:h-[150px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          My Deposits
        </h1>

        <div className="flex flex-wrap gap-2 justify-between">
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id="status"
              className="py-2 px-2 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
            >
              <option value="">Status</option>
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="forwarded">Forwarded</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <select
              name="account"
              id="account"
              className="py-2 px-4 bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded-l"
              onChange={(event) => setAccount(event.target.value)}
              value={account}
            >
              <option value="">Account</option>
              <option value="savings">Savings</option>
              <option value="shares">Shares</option>
              <option value="fixed">Fixed</option>
              <option value="mwana">Mwana</option>
              <option value="development">Development</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (account) setAccount("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id="date"
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={date}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <DateRangeFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          {/* <div className="flex justify-between text-sm"> */}
          <button
            onClick={(status) => {
              if (status) setStatus("");
              if (date) setDate("");
              if (account) setAccount("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              if (startDate) setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              if (endDate) setEndDate("");
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {largeDesktop && !smallDesktop && "Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
          <button
            className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
            onClick={() => {
              generate_deposits_report();
            }}
            disabled={!allDeposits || allDeposits?.length === 0}
          >
            {largeDesktop && !smallDesktop && "Export"}
            <MdDownload className="ml-1" />
          </button>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-120px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : filteredDeposits && filteredDeposits.length > 0 ? (
          <>
            <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="pr-6 py-4">Date</th>
                    <th className="px-6 py-4">Transaction ID</th>
                    <th className="px-6 py-4">Account</th>
                    <th className="px-6 py-4">Amount(UGX)</th>
                    <th className="px-6 py-4">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDeposits.map((deposit, index) => (
                    <Fragment key={index}>
                      <tr
                        className={`${
                          index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                        } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                        key={index}
                        onClick={() => {
                          setDepositModal(true);
                          setActiveIndex(index);
                        }}
                      >
                        <td>
                          <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                        </td>
                        <td className="pr-6 py-3">
                          {moment(deposit.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td className="px-6 py-3">
                          {deposit?.trans_id || deposit?.app_id}
                        </td>
                        <td className="px-6 py-3 capitalize">
                          {deposit?.transaction_meta?.account_type ||
                            deposit?.application_meta?.account_type}
                        </td>
                        <td className="px-6 py-3">
                          {currencyFormatter(
                            deposit?.amount || deposit?.application_meta?.amount
                          )}
                        </td>
                        <td className="px-6 py-3">
                          <span
                            className={` py-1 px-2 rounded-xl text-white inline-block ${
                              deposit?.application_meta?.review_status ===
                              "pending"
                                ? "bg-yellow-400"
                                : deposit?.application_meta?.review_status ===
                                  "rejected"
                                ? "bg-red-400"
                                : deposit?.application_meta?.review_status ===
                                  "forwarded"
                                ? "bg-blue-400"
                                : "bg-green-400"
                            }`}
                          >
                            {deposit?.application_meta?.review_status ===
                            "pending"
                              ? "pending approval"
                              : deposit?.transaction_meta
                              ? "approved "
                              : deposit?.application_meta?.review_status}
                          </span>
                        </td>
                      </tr>
                      {depositModal && index === activeIndex && (
                        <DepositModal
                          deposit={deposit}
                          setDepositModal={setDepositModal}
                        />
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(deposits.length / depositsPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={deposits}
                depositsPerPage={depositsPerPage}
                setDepositsPerPage={setDepositsPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allDeposits.length > 0 &&
          (filteredDeposits?.length === 0 || !filteredDeposits) && <Frown />
        )}
      </div>
    </div>
  );
}
