import { Pagination, Spinner } from "../../components";
import { useEffect, useState, Fragment } from "react";
import { supabase } from "../../helpers/supabase";
import { TbFilterOff } from "react-icons/tb";
import { LoanModal } from "../../components";
import moment from "moment";
import { NothingShown } from "../../components";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import DateRangeFilter from "../../components/DateRangeFilter";
import Frown from "../../components/Frown";
import { Helmet } from "react-helmet";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "../../hooks";

export default function MemberLoans() {
  useEffect(() => {
    fetch_member_loans().catch((error) => console.log(error));

    // Realtime.
    const mySubscription = supabase
      .from("loans")
      .on("*", async (payload) => {
        await fetch_member_loans().catch((error) => console.log(error));
      })
      .subscribe();

    // CleanUp.
    return () => supabase.removeSubscription(mySubscription);
  }, []);

  const [searchText, setSearchText] = useState("");
  const [loans, setLoans] = useState([]);
  const [loanModal, setLoanModal] = useState(false);
  const [status, setStatus] = useState("");
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allLoans, setAllLoans] = useState([]);
  const [nothingShown, setNothingShown] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [loansPerPage, setLoansPerPage] = useState(10);
  const indexOfLastPage = currentPage * loansPerPage;
  const indexOfFirstPage = indexOfLastPage - loansPerPage;

  useEffect(() => {
    let data = allLoans;

    data =
      data && data?.length > 0
        ? data.filter((loan) => !status || loan?.loan?.loan_status === status)
        : null;

    data =
      data && data?.length > 0
        ? data.filter(
            (loan) => !date || loan?.loan?.created_at?.substring(0, 10) === date
          )
        : null;

    data =
      data && data?.length > 0
        ? data.filter(
            (loan) =>
              !searchText ||
              loan?.loan?.loan_meta?.applicants_name
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(startDate).isSameOrBefore(
                moment(loan?.loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(endDate).isSameOrAfter(
                moment(loan?.loan?.created_at?.substring(0, 10)).format("")
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(loan?.loan?.created_at?.substring(0, 10)).isBetween(
                moment(startDate),
                moment(endDate),
                undefined,
                "[]"
              );
            })
          : null;
    }

    console.log("Filtered Loans: ", data);
    setLoans(() => data);
  }, [status, searchText, startDate, endDate, date]);

  let shownloans =
    loans && loans?.length > 0
      ? loans.slice(indexOfFirstPage, indexOfLastPage)
      : null;

  const [activeIndex, setActiveIndex] = useState(null);
  const [show, setShow] = useState(false);
  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const fetch_member_loans = async () => {
    const { data, error } = await supabase.rpc("fetch_member_loans");
    if (error) {
      setLoading(false);
      throw error;
    }

    if (!data) setNothingShown(true);
    console.log("Loan: ", data);

    setAllLoans(data ?? null);
    setLoans(data ?? null);
    setLoading(false);
  };

  const generate_member_loan_report = () => {
    const formattedData = loans.map((loan) => {
      return {
        "Member Name": loan.loan.loan_meta.applicants_name,
        "Loan Status":
          loan?.loan?.loan_status === "defaulted"
            ? "arrears"
            : loan?.loan?.loan_status === "pending"
            ? "Approved"
            : loan.loan.loan_status,
        "Loan ID": loan?.loan?.loan_id,
        Principal: loan?.amount_issued,
        "Amount Paid": loan?.loan?.principal_paid + loan?.loan?.interest_paid,
        "Interest Rate": loan?.loan?.interest_rate,
        "Approved At": moment(loan?.loan.loan_meta?.approved_at).format(
          "DD-MM-YYYY"
        ),
        "Start Date": moment(loan?.loan?.start_date).format(
          "DD-MM-YYYY"
        ),
        "End Date": moment(loan?.loan?.end_date).format("DD-MM-YYYY hh:mm a"),
        "Approved by": loan?.loan?.loan_meta?.approved_by,
        "Interest Paid": loan?.loan?.interest_paid,
        "Interest to pay": loan?.loan?.interest_to_pay,
        Imported: loan?.loan?.loan_meta?.imported_by ? "True" : "False",
      };
    });

    generateReportFromJson(
      formattedData,
      "Issued loans " + new Date().toISOString().slice(0, 10)
    );

    const loans_with_payments = loans.filter((loan) => loan?.payments);

    let all_payments = [];

    loans_with_payments.forEach((loan) => {
      let processedPayments = loan.payments.map((payment) => {
        return {
          Date: moment(payment.created_at).format("DD-MM-YYYY"),
          "Amount Paid": currencyFormatter(
            Math.round(payment.amount * 100) / 100
          ),
          "Principal Installment Paid": currencyFormatter(
            Math.round(
              payment?.transaction_meta?.principal_installment_paid * 100
            ) / 100
          ),
          "Interest Paid": currencyFormatter(
            Math.round(payment?.transaction_meta?.interest_paid * 100) / 100
          ),
          "Amount To Pay":
            Math.round(payment?.transactions_meta?.amount_to_pay ?? 0 * 100) /
            100,
          "Reducing Balance":
            payment?.reducing_balance <= 0
              ? "0.00"
              : currencyFormatter(
                  Math.round(payment?.reducing_balance ?? 0 * 100) / 100
                ),
          "Loan ID": loan.loan.loan_id,
        };
      });

      all_payments.push(...processedPayments);
    });

    generateReportFromJson(
      all_payments,
      "Loan Payments " + new Date().toISOString().slice(0, 10)
    );
  };

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  return (
    <div className="flex-grow mx-3 md:mx-5 my-2 h-[calc(100vh-140px)]">
      <Helmet>
        <title>Issued Loans - Bweyogere tuberebumu</title>
      </Helmet>
      <div className="flex flex-col pb-3 md:h-[150px] mb-5">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Issued Loans
        </h1>
        <div className="flex flex-wrap gap-2 justify-between">
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="text"
              name="name"
              onChange={(event) => setSearchText(event.target.value)}
              id="name"
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={searchText}
              placeholder="Search by name..."
              disabled={loading || !allLoans }
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (searchText) setSearchText("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-2 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
              disabled={loading || !allLoans }
            >
              <option value="">Status</option>
              <option value="on going">On going</option>
              <option value="cleared">Cleared</option>
              <option value="defaulted">Due</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          {/* <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <select
              name="status"
              id=""
              className="py-2 px-4 bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded-l"
              onChange={(event) => setStatus(event.target.value)}
              value={status}
            >
              <option value="">Account</option>
              <option value="savings">Savings</option>
              <option value="shares">Shares</option>
              <option value="fixed deposit">Fixed</option>
              <option value="mwana">Mwana</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div> */}

          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id=""
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={date}
              disabled={loading || !allLoans }
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <DateRangeFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          {/* <div className="flex justify-between text-sm"> */}
          <button
            onClick={(status) => {
              if (status) setStatus("");
              if (date) setDate("");
              if (status) setStatus("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              if (startDate) setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              if (endDate) setEndDate("");
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {largeDesktop && !smallDesktop && "Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
          <button
            className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
            onClick={() => {
              generate_member_loan_report();
            }}
            disabled={!allLoans || allLoans?.length === 0}
            
          >
            {largeDesktop && !smallDesktop && "Export"}
            <MdDownload className="ml-1" />
          </button>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-140px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : loans !== null && shownloans !== null && shownloans.length > 0 ? (
          <>
            <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th className=""></th>
                    <th className="pr-4 py-4">Date</th>
                    <th className="px-6 py-4">ID</th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">Amount to Pay</th>
                    <th className="px-6 py-4">Amount Paid</th>
                    <th className="px-6 py-4">Principal</th>
                    <th className="px-6 py-4">Interest Rate(%)</th>
                    <th className="px-6 py-4 min-w-fit">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {shownloans.map(({ loan, payments }, index) => (
                    <Fragment key={index}>
                      <tr
                        className={`${
                          index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                        } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                        key={index}
                        onClick={() => {
                          setLoanModal(true);
                          setActiveIndex(index);
                        }}
                      >
                        <td>
                          <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                        </td>
                        <td className="pr-4 py-3">
                          {moment(loan.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td className="px-6 py-3">{loan.loan_id}</td>
                        <td className="px-6 py-3">
                          {loan.loan_meta.applicants_name}
                        </td>
                        <td className="px-6 py-3">
                          {currencyFormatter(
                            loan.amount_issued + 0.05 * loan.amount_issued
                          )}
                        </td>
                        <td className="px-6 py-3">
                          {currencyFormatter(
                            (loan?.interest_paid ?? 0) +
                              (loan?.principal_paid ?? 0)
                          )}
                        </td>
                        <td className="px-6 py-3">
                          {currencyFormatter(loan.amount_issued)}
                        </td>
                        <td className="px-6 py-3">{loan.interest_rate}</td>
                        <td className={`px-6 py-3`}>
                          <span
                            className={` py-1 px-2 rounded-xl text-white inline-block ${
                              loan.loan_status === "pending"
                                ? "bg-yellow-400"
                                : loan.loan_status === "cleared"
                                ? "bg-green-400"
                                : loan.loan_status === "on going"
                                ? "bg-blue-400"
                                : "bg-red-400"
                            }`}
                          >
                            {loan.loan_status}
                          </span>
                        </td>
                      </tr>
                      {loanModal && activeIndex === index && (
                        <LoanModal
                          setLoanModal={setLoanModal}
                          loan={{ loan, payments }}
                        />
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(loans.length / loansPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={loans}
                DepositsPerPage={loansPerPage}
                setDepositsPerPage={setLoansPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allLoans &&
          allLoans.length > 0 &&
          (!loans || loans?.length === 0) && <Frown />
        )}
      </div>
    </div>
  );
}
