import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/images/tube-no-bg.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  headerText: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexDirection: "row",
    paddingBottom: 8,
  },
  debug: {
    padding: 5,
    border: "solid",
    borderWidth: 1,
    borderColor: "red",
  },
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    // paddingHorizontal: 35,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  title: {
    fontSize: 10,
    fontWeight: 700,
    paddingBottom: 5,
  },
  box: {
    width: "100%",
    marginBottom: 30,
    borderRadius: 5,
  },
});

// creating the document
function ReportPDF({ reportName, reportData }) {
  const labels =
    reportData && reportData?.length > 0 ? Object.keys(reportData[0]) : null;
  console.log(labels);

  console.log("PDF Report Name: ", reportName);
  console.log("PDF Report Data: ", reportData);
  return (
    <Document title={reportName}>
      {/* Transformed */}
      <Page
        style={[styles.body, { position: "relative" }]}
        orientation="landscape"
      >
        <View
          style={[
            {
              height: 200,
              width: 200,
              justifyContent: "center",
              alignItems: "center",
              marginHorizontal: "auto",
            },
            // styles.debug,
          ]}
        >
          <Image
            src={logo}
            style={[{ width: 150, height: 150, marginBottom: -20 }]}
          />
          <Text style={[{ fontSize: 8, fontWeight: "bold" }]}>
            BWEYOGERERE TUBEREBUMU CO-OPERATIVE
          </Text>
          <Text style={[{ fontSize: 8, fontWeight: "bold" }]}>
            SAVING AND CREDIT SOCIETY LTD
          </Text>
        </View>

        <Text
          style={[
            {
              width: "100%",
              fontSize: 10,
              fontWeight: "bold ",
              paddingHorizontal: 35,
              textAlign: "center",
              marginBottom: 5,
            },
          ]}
        >
          {reportName}
        </Text>

        {/* Information */}

        <View
          style={[
            {
              paddingHorizontal: 35,
            },

            // styles.debug,
          ]}
        >
          {
            <View style={[{ width: "100%" }]}>
              <View
                style={[
                  {
                    borderBottom: "solid",
                    borderBottomWidth: 1,
                    borderBottomColor: "black",
                    flexDirection: "row",
                    borderTop: "solid",
                    borderTopWidth: 1,
                    borderTopColor: "black",
                    borderLeft: "solid",
                    borderLeftWidth: 1,
                    borderLeftColor: "black",
                    width: "100%",
                    backgroundColor: "#687387",
                    fontWeight: "bold",
                  },
                  // styles.debug
                ]}
                wrap={false}
                fixed
              >
                {labels &&
                  labels?.length > 0 &&
                  labels.map((label, index) => (
                    <Text
                      key={index}
                      style={[
                        {
                          flex: 1,
                          fontSize: 8,
                          borderRight: "solid",
                          borderRightWidth: 1,
                          borderRightColor: "black",
                          paddingHorizontal: 3,
                          paddingVertical: 3,
                        },
                      ]}
                    >
                      {label}
                    </Text>
                  ))}
              </View>

              {reportData &&
                reportData?.length > 0 &&
                reportData.map((report, index) => {
                  let reportAttributes = Object.values(report);
                  return (
                    <View
                      key={index}
                      style={[
                        {
                          width: "100%",
                          borderBottom: "solid",
                          borderBottomWidth: 1,
                          borderBottomColor: "black",
                          flexDirection: "row",
                          // borderRight: "solid",
                          // borderRightWidth: 1,
                          borderRightColor: "black",
                          borderLeft: "solid",
                          borderLeftWidth: 1,
                          borderLeftColor: "black",
                        },
                      ]}
                      wrap={false}
                    >
                      {reportAttributes.map((attribute, index) => {
                        return (
                          <Text
                            key={index}
                            style={[
                              {
                                flex: 1,
                                fontSize: 8,
                                borderRight: "solid",
                                borderRightWidth: 1,
                                borderRightColor: "black",
                                paddingLeft: 5,
                                paddingTop: 3,
                                paddingBottom: 4,
                              },
                            ]}
                          >
                            {attribute ?? " "}
                          </Text>
                        );
                      })}
                    </View>
                  );
                })}
            </View>
          }
        </View>

        {/* Watermark */}

        <View
          style={[
            {
              height: 200,
              width: 200,
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              opacity: 0.5,
              position: "absolute",
              top: "40%",
              left: "32%",
            },
            // styles.debug,
          ]}
          fixed
        >
          <Image
            src={logo}
            style={[
              { opacity: 0.3, width: 150, height: 150, marginBottom: -20 },
            ]}
          />
          <Text style={[{ fontSize: 8, opacity: 0.3 }]}>
            BWEYOGERERE TUBEREBUMU CO-OPERATIVE
          </Text>
          <Text style={[{ fontSize: 8, opacity: 0.3 }]}>
            SAVING AND CREDIT SOCIETY LTD
          </Text>
        </View>

        {/* page number */}
        <Text
          style={[
            {
              textAlign: "center",
              width: "100%",
              fontSize: 8,
              position: "absolute",
              bottom: 15,
            },
          ]}
          fixed
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
        />
        <Text
          style={[
            {
              textAlign: "left",
              width: "100%",
              fontSize: 8,
              position: "absolute",
              bottom: 15,
              paddingLeft: 15,
            },
          ]}
          fixed
        >
          DATE: {new Date().toISOString().substring(0, 10)}
        </Text>
        <Text
          style={[
            {
              textAlign: "right",
              width: "100%",
              fontSize: 8,
              position: "absolute",
              bottom: 15,
              paddingRight: 15,
            },
          ]}
          fixed
        >
          {reportName}
        </Text>
        {/* </View> */}
      </Page>
    </Document>
  );
}

export default ReportPDF;
