import { Formik, Form, ErrorMessage, useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { InputField } from "../../components/Form/CustomInputField";
import {
  loan2ValidationSchema,
  loan3ValidationSchema,
} from "../../helpers/validator";
import {
  remove_separator,
  add_separator,
} from "../../helpers/thousand_separator";
import { supabase } from "../../helpers/supabase";

function ExistingLoanCheck() {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    // Get member's arreared loans.
    getExistingLoan()
      .then((data) => {
        console.log("Existing Loan Data: ", data);
        if (data?.existing_loan_id) {
          setFieldValue("existing_loan", data?.existing_loan_id ?? "");
          setFieldValue("existing_loan_ref", data?.existing_loan_ref);
        }
      })
      .catch((error) => console.log(error));
  });

  const getExistingLoan = async () => {
    const { data, error } = await supabase.rpc("get_existing_loan");
    if (error) throw error;
    return data;
  };
}

function ApplicationPg2({ initialValues, setInitialValues, setPageNumber }) {
  const [employed, setEmployed] = useState(
    initialValues?.employment === "employed" ? true : false
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        setInitialValues(values);
        setPageNumber(3);
      }}
      validationSchema={
        employed === true ? loan2ValidationSchema : loan3ValidationSchema
      }
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => {
        return (
          <Form>
            <div className="mb-3">
              <h1 className="font-semibold">Employment Details</h1>
              <span className="text-sm text-red-500 mb-3">
                All Fields with a * are mandatory
              </span>

              <div className="flex flex-col w-56 m-2">
                <label className="text-sm font-bold">
                  Employment type{" "}
                  <span className="text-sm text-red-500 ml-2 font-bold">*</span>
                </label>
                <div className="flex justify-between">
                  <div className="flex gap-1">
                    <input
                      type="radio"
                      id="employed"
                      name="employment"
                      value="employed"
                      defaultChecked={
                        initialValues.employment === "employed" ? true : false
                      }
                      onChange={() => {
                        setEmployed(true);
                        values.employment = "employed";
                        setInitialValues((initialValues) => ({
                          ...initialValues,
                          employment: "employed",
                        }));
                      }}
                    />
                    <label className="text-sm" htmlFor="employed">
                      Employed
                    </label>
                  </div>
                  <div className="flex gap-1">
                    <input
                      type="radio"
                      id="business"
                      name="employment"
                      value="business"
                      defaultChecked={
                        initialValues.employment === "business" ? true : false
                      }
                      onChange={() => {
                        setEmployed(false);
                        values.employment = "business";
                        setInitialValues((initialValues) => ({
                          ...initialValues,
                          employment: "business",
                        }));
                      }}
                    />
                    <label className="text-sm" htmlFor="business">
                      Self Employed
                    </label>
                  </div>
                </div>
              </div>
              {employed && (
                <>
                  {/* <p className="text-primary my-2">
                    *To be filled by employed applicants
                  </p> */}

                  <div className="flex flex-wrap gap-5">
                    <InputField
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      reference="employer"
                      label="Employer"
                      placeholder="Enter employer"
                      defaultValue={initialValues.employer}
                      mandatory={true}
                      setFieldValue={setFieldValue}
                      setInitialValues={setInitialValues}
                      initialValues={initialValues}
                    />

                    <InputField
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      reference="employer_postal_address"
                      label="Employer Postal Address"
                      placeholder="Enter postal address"
                      defaultValue={initialValues.employer_postal_address}
                      setFieldValue={setFieldValue}
                      values={values}
                      setInitialValues={setInitialValues}
                      initialValues={initialValues}
                    />

                    <InputField
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      reference="employer_no"
                      label="Telephone Number"
                      placeholder="Enter number"
                      defaultValue={initialValues.employer_no}
                      mandatory={true}
                      setFieldValue={setFieldValue}
                      instructions={["Use local format"]}
                      setInitialValues={setInitialValues}
                      initialValues={initialValues}
                    />

                    <InputField
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      reference="employer_designation"
                      label="Employer designation"
                      placeholder="Enter designation"
                      defaultValue={initialValues.employer_designation}
                      setFieldValue={setFieldValue}
                      instructions={["Type your current role/ post at work"]}
                      setInitialValues={setInitialValues}
                      initialValues={initialValues}
                    />

                    <div className="flex flex-col w-56 ">
                      <label className=" text-sm">Retirement Date</label>
                      <input
                        type="date"
                        name="retirement_date"
                        onChange={handleChange("retirement_date")}
                        className="ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600"
                        defaultValue={initialValues.retirement_date}
                      />
                    </div>
                    <div className="flex flex-col w-56 ">
                      <InputField
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        reference="type_of_employment"
                        label="Nature of employment"
                        placeholder="Enter employment type"
                        defaultValue={initialValues.type_of_employment}
                        setFieldValue={setFieldValue}
                        instructions={[
                          "It includes but is not limited to Full-time, Part-time, Casual, Fixed-term, Contract, Apprentices, Training, Commision, Piece rate, Migrant or Oversees etc.",
                        ]}
                        setInitialValues={setInitialValues}
                        initialValues={initialValues}
                      />
                    </div>
                  </div>
                </>
              )}
              {!employed && (
                <>
                  {/* <p className="text-primary my-2">
                    *To be filled by self-employed applicants
                  </p> */}
                  <div className="flex flex-wrap gap-5">
                    <InputField
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      reference="business_type"
                      defaultValue={initialValues.business_type}
                      label="Business Type"
                      placeholder="Enter business type"
                      mandatory={true}
                      setFieldValue={setFieldValue}
                      setInitialValues={setInitialValues}
                      initialValues={initialValues}
                    />

                    <InputField
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      reference="years_of_operation"
                      defaultValue={initialValues.years_of_operation}
                      label="Years of Operation"
                      placeholder="Enter years"
                      mandatory={true}
                      setFieldValue={setFieldValue}
                      setInitialValues={setInitialValues}
                      initialValues={initialValues}
                    />

                    <div className="flex flex-col w-56 mb-3">
                      <label htmlFor="business_income" className=" text-sm">
                        Business income(UGX){" "}
                        <span className="text-sm text-red-500 ml-2 font-bold">
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        name="business_income"
                        id="business_income"
                        placeholder="Enter income"
                        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.business_income && touched?.business_income
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={(event) => {
                          let formatted_string = add_separator(
                            remove_separator(event.target.value)
                          );
                          event.target.value = formatted_string;
                          setFieldValue(
                            event.target.name,
                            parseFloat(remove_separator(event.target.value))
                          );
                          setInitialValues((initialValues) => ({
                            ...initialValues,
                            business_income: parseFloat(
                              remove_separator(event.target.value)
                            ),
                          }));
                        }}
                        onBlur={handleBlur}
                        defaultValue={add_separator(
                          initialValues.business_income
                        )}
                      />
                      {touched?.business_income && errors?.business_income && (
                        <div className="error text-red-600 text-xs">
                          {errors?.business_income}
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col w-56 ">
                      <label className=" text-sm">
                        Six months bank settlement
                      </label>
                      <input
                        type="file"
                        name="bank_settlement"
                        onChange={(event) => {
                          setFieldValue(
                            event.target.name,
                            event.target.files[0]
                          );
                          setInitialValues((initialValues) => ({
                            ...initialValues,
                            bank_statement: event.target.files[0],
                          }));
                        }}
                        onBlur={handleBlur}
                        id=""
                        placeholder="Enter postal address"
                        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.bank_statement && touched?.bank_statement
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                      />
                    </div>

                    <div className="flex flex-col w-56 ">
                      <label className=" text-sm">
                        One year cash flow forecast
                      </label>
                      <input
                        type="file"
                        name="a_years_cashflow"
                        className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.a_years_cashflow && touched?.a_years_cashflow
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={(event) => {
                          setFieldValue(
                            event.target.name,
                            event.target.files[0]
                          );

                          setInitialValues((initialValues) => ({
                            ...initialValues,
                            a_years_cashflow: event.target.files[0],
                          }));
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="mb-3">
              <h1 className="font-semibold">Assets owned</h1>
              <div className="m-2">
                <div className="flex flex-wrap gap-5">
                  <InputField
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    reference="asset1"
                    label="First asset"
                    placeholder="Enter asset 1"
                    defaultValue={initialValues.asset1}
                    mandatory={true}
                    setFieldValue={setFieldValue}
                    setInitialValues={setInitialValues}
                    initialValues={initialValues}
                  />

                  <InputField
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    reference="asset2"
                    label="Second asset"
                    placeholder="Enter asset 2"
                    defaultValue={initialValues.asset2}
                    mandatory={true}
                    setFieldValue={setFieldValue}
                    setInitialValues={setInitialValues}
                    initialValues={initialValues}
                  />

                  <InputField
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    reference="asset3"
                    label="Third asset"
                    placeholder="Enter asset 3"
                    defaultValue={initialValues.asset3}
                    setFieldValue={setFieldValue}
                    setInitialValues={setInitialValues}
                    initialValues={initialValues}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <h1 className="font-semibold">Loan Particulars</h1>

              <div className="m-2">
                <div className="flex flex-wrap gap-5 m-2">
                  {values?.existing_loan && (
                    <div className="flex flex-col">
                      <label className="text-sm font-bold">
                        Type{" "}
                        <span className="text-sm text-red-500 ml-2 font-bold">
                          *
                        </span>
                      </label>
                      <div className="flex flex-col justify-between px-2 w-36">
                        <div className="flex gap-1">
                          <input
                            type="radio"
                            id="normal"
                            name="loan_type"
                            value="normal"
                            onChange={(event) => {
                              setFieldValue(
                                event.target.name,
                                event.target.value
                              );
                              setInitialValues((initialValues) => ({
                                ...initialValues,
                                loan_type: event.target.value,
                              }));
                            }}
                            onBlur={handleBlur}
                            defaultChecked={
                              initialValues?.loan_type === "normal"
                            }
                          />
                          <label className="text-sm" htmlFor="normal">
                            Normal
                          </label>
                        </div>
                        <div className="flex gap-1">
                          <input
                            type="radio"
                            id="extended"
                            name="loan_type"
                            value="extended"
                            onChange={(event) => {
                              setFieldValue(
                                event.target.name,
                                event.target.value
                              );
                              setInitialValues((initialValues) => ({
                                ...initialValues,
                                loan_type: event.target.value,
                              }));
                            }}
                            onBlur={handleBlur}
                            defaultChecked={
                              initialValues?.loan_type === "extended"
                            }
                          />
                          <label className="text-sm" htmlFor="extended">
                            Extended
                          </label>
                        </div>
                      </div>
                      <ErrorMessage name="loan_typê">
                        {(msg) => (
                          <span className="text-xs text-red-500">{msg}</span>
                        )}
                      </ErrorMessage>
                    </div>
                  )}
                  {/* {values.loan_type === "extended" && (
                    <div className="flex flex-col w-56 ">
                      <label className=" text-sm">Existing Loan</label>
                      <select
                        name="existing_loan"
                        id="existing_loan"
                        onChange={(event) => {
                          console.log(event.target.value);
                          values.existing_loan = event.target.value;
                          setInitialValues((initialValues) => ({
                            ...initialValues,
                            existing_loan: event.target.value,
                          }));
                        }}
                        defaultValue={initialValues.existing_loan}
                        onBlur={handleBlur}
                        className={`ring-1 rounded px-2 py-2 bg-white dark:bg-dark-bg-600 focus:outline-none focus:ring-2 focus:ring-primary ${
                          errors?.existing_loan && touched?.existing_loan
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                      >
                        <option value="">-- Select Loan--</option>
                        {arrearedLoans?.length > 0 &&
                          arrearedLoans.map((existingLoan, index) => (
                            <option key={index} value={existingLoan.loan.id}>
                              {existingLoan.loan.loan_id}
                            </option>
                          ))}
                      </select>
                      <ErrorMessage name="existing_loan">
                        {(msg) => (
                          <div className="error text-xs text-red-500">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  )} */}
                  <div className="flex flex-col w-72 ">
                    <label className=" text-sm">
                      Purpose of loan
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <textarea
                      name="loan_purpose"
                      id="loan_purpose"
                      cols="30"
                      rows="4"
                      onChange={(event) => {
                        setFieldValue(
                          event.target.name,
                          event.target.value,
                          true
                        );
                        setInitialValues((initialValues) => ({
                          ...initialValues,
                          loan_purpose: event.target.value,
                        }));
                      }}
                      onBlur={handleBlur}
                      placeholder="Enter the reason why you need the loan."
                      className={`ring-1 rounded px-2 py-2 bg-white dark:bg-dark-bg-600 focus:outline-none focus:ring-2 focus:ring-primary ${
                        errors?.loan_purpose && touched?.loan_purpose
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      defaultValue={initialValues.loan_purpose}
                    ></textarea>
                    <ErrorMessage name="loan_purpose">
                      {(msg) => (
                        <div className="error text-xs text-red-500">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="flex flex-col w-56 ">
                    <label className=" text-sm">Supporting files</label>
                    <input
                      type="file"
                      name="supporting_files"
                      className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                        errors?.supporting_files && touched?.supporting_files
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      onChange={(event) => {
                        setFieldValue(event.target.name, event.target.files[0]);
                        setInitialValues((initialValues) => ({
                          ...initialValues,
                          supporting_files: event.target.files[0],
                        }));
                      }}
                      accept="image/png, image/jpeg, application/pdf, .doc, .docx"
                    />
                    {/* <span className="text-sm font-bold text-gray-400 uppercase">
                      Instructions
                    </span> */}

                    <div className="flex relative mt-1">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs capitalize text-gray-400 pl-3">
                        Compile all files into one file
                      </div>
                    </div>
                    <div className="flex relative">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs capitalize text-gray-400 pl-3">
                        Click choose file
                      </div>
                    </div>
                    <div className="flex relative">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs capitalize text-gray-400 pl-3">
                        Select the file
                      </div>
                    </div>
                    <div className="flex relative">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs capitalize text-gray-400 pl-3">
                        Click upload
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col w-56 mb-3">
                    <label htmlFor="amount" className=" text-sm">
                      Amount in figures
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      placeholder="Enter loan amount"
                      className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                        errors?.amount && touched?.amount
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      onChange={(event) => {
                        let formatted_string = add_separator(
                          remove_separator(event.target.value)
                        );
                        event.target.value = formatted_string;
                        setFieldValue(
                          event.target.name,
                          parseFloat(remove_separator(event.target.value))
                        );

                        setInitialValues((initialValues) => ({
                          ...initialValues,
                          amount: parseFloat(
                            remove_separator(event.target.value)
                          ),
                        }));
                      }}
                      onBlur={handleBlur}
                      defaultValue={add_separator(initialValues.amount)}
                    />

                    <div className="flex relative mt-1">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs text-gray-400 pl-3">
                        Use figures without separators.
                      </div>
                    </div>

                    {touched?.amount && errors?.amount && (
                      <div className="error text-red-600 text-xs">
                        {errors?.amount}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col w-[45%] flex-wrap">
                    <label className=" text-sm">
                      Amount in words
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <textarea
                      name="amount_in_words"
                      id="amount_in_words"
                      cols="40"
                      rows="1"
                      onChange={(event) => {
                        setFieldValue(
                          event.target.name,
                          event.target.value,
                          true
                        );
                        setInitialValues((initialValues) => ({
                          ...initialValues,
                          amount_in_words: event.target.value,
                        }));
                      }}
                      onBlur={handleBlur}
                      placeholder="Enter amount"
                      className={`ring-1 ring-black rounded px-2 py-2 bg-white dark:bg-dark-bg-600 focus:outline-none focus:ring-2 focus:ring-primary ${
                        errors?.amount_in_words && touched?.amount_in_words
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      defaultValue={initialValues.amount_in_words}
                    ></textarea>
                    <div className="flex relative mt-1">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs text-gray-400 pl-3">
                        Use Letters only
                      </div>
                    </div>
                    <ErrorMessage name="amount_in_words">
                      {(msg) => (
                        <div className="error text-xs text-red-500">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>

                  <InputField
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    reference="months"
                    label="Repayment period in months"
                    placeholder="Enter months"
                    defaultValue={initialValues.months}
                    mandatory={true}
                    instructions={[
                      "Specify the number of months you will take to repay the loan.",
                    ]}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                  />

                  <div className="flex flex-col w-56 ">
                    <label className=" text-sm">Repayment Method</label>
                    <select
                      name="repayment_method"
                      id=""
                      defaultValue={initialValues.repayment_method}
                      onChange={(event) =>
                        setFieldValue(event.target.name, event.target.value)
                      }
                      className={`ring-1 rounded px-2 py-2 bg-white dark:bg-dark-bg-600 focus:outline-none focus:ring-2 focus:ring-primary ${
                        errors?.marital_status && touched?.marital_status
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                    >
                      <option value="">--Repayment Method--</option>
                      <option value="cash">Cash</option>
                      <option value="mobile money">Mobile Money</option>
                      <option value="airtel money">Airtel Money</option>
                      <option value="RTGS">RTGS</option>
                      <option value="EFT">EFT</option>
                    </select>
                    <ErrorMessage name="repayment_method">
                      {(msg) => (
                        <div className="error text-xs text-red-500">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>
                  <ExistingLoanCheck />
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full mb-5">
              <button
                type="button"
                className="outline outline-gray-500 outline-2 text-gray-500 px-4 py-1 rounded-lg cursor-pointer"
                onClick={() => {
                  setPageNumber(1);
                }}
              >
                Back
              </button>
              <input
                type="submit"
                value="Next"
                className="outline outline-gray-500 outline-2 text-gray-500 px-4 py-1 rounded-lg cursor-pointer"
                onClick={() => {
                  console.log("Values: ", values);
                  console.log("Errors: ", errors)
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ApplicationPg2;
