import {
  Login,
  Dashboard,
  ForgotPassword,
  SignUp,
  Verification,
  SetPassword,
  Deposit,
  Profile,
  DepositHistory,
  MakeDeposit,
  Development,
} from "../pages";
import {
  Loans,
  LoanHistory,
  LoanPayment,
  LoanRequest,
  LoanVerify,
  LoanAdmin,
  VerifyPayment,
  MemberLoans,
  LoanPaymentApplications,
  GuaranteeRequests,
  GuaranteeLoan,
  Renumeration,
} from "../pages";
import {
  Withdraw,
  WithdrawHistory,
  WithdrawRequest,
  WithdrawMembers,
  DepositVerify,
  WithdrawVerify,
  Members,
  Applications,
  MemberApplication,
  TerminationRequests,
} from "../pages";
import {
  Accounts,
  Savings,
  Mwana,
  Fixed,
  Shares,
  DepositAdmin,
  Reserve,
  Transfers,
  TransferAdmin,
  RequestSingleTransfer,
  TransferVerify,
} from "../pages";
import { Admins, AdminInfo, SuperAdDashboard } from "../pages";
import {
  Report,
  Announcement,
  Organisation,
  Announcements,
  Reports,
  Assets,
  Announce,
  AddAsset,
  Asset,
  Transactions,
  Transaction,
  AddTransaction,
} from "../pages";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { useState } from "react";
import Unauthorized from "../pages/Unauthorized";
import { ApplicantApproval } from "../pages";
import ErrorBoundary from "../components/ErrorBoundary";
import {
  AddBaana,
  Baana,
  BaanaApplications,
  BaanaApproval,
  MembersBaana,
} from "../pages";
import { AssetRequestVerification, VerificationRequests } from "../pages";
import { ServiceProviders, AddProvider, Notifications } from "../pages";

export default function App() {
  const [loading, setLoading] = useState(true);
  const preloader = document.getElementById("preloader");

  const html = document.querySelector("html");
  if (localStorage.getItem("darkMode") === "true") {
    html.classList.add("darkClass");
  } else {
    html.classList.remove("darkClass");
  }

  if (preloader) {
    setTimeout(() => {
      preloader.style.display = "none";
      setLoading(false);
    }, 500);
  }

  return (
    !loading && (
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/verify" element={<Verification />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* Accessed privately but doesnot have restricted access depending on the access role */}

          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>

          <Route
            element={
              <PrivateRoute allowedRoles={["under_termination", "member"]} />
            }
          >
            <Route path="accounts" element={<Accounts />} />
            <Route path="accounts/savings" element={<Savings />} />
            <Route path="accounts/mwana" element={<Mwana />} />
            <Route path="accounts/fixed" element={<Fixed />} />
            <Route path="accounts/shares" element={<Shares />} />
          </Route>

          <Route element={<PrivateRoute allowedRoles={["super_admin"]} />}>
            <Route path="admins" element={<Admins />} />
            <Route path="admins/:id" element={<AdminInfo />} />
            {/* <Route path="dashboard" element={<SuperAdDashboard />} /> */}
          </Route>

          {/* Accessed by admins only */}
          <Route element={<PrivateRoute allowedRoles={["admin"]} />}>
            <Route
              path="/transfers/members/:id"
              element={
                <ErrorBoundary>
                  <TransferVerify />
                </ErrorBoundary>
              }
            />
            <Route
              path="baana/applications/:application_id"
              element={
                <ErrorBoundary>
                  <BaanaApproval />
                </ErrorBoundary>
              }
            />
            <Route
              path="transfers/members"
              element={
                <ErrorBoundary>
                  <TransferAdmin />
                </ErrorBoundary>
              }
            />
          </Route>
          {/* Accessed by only administrators. */}
          <Route
            element={<PrivateRoute allowedRoles={["admin", "super_admin"]} />}
          >
            <Route
              path="members/termination"
              element={
                <ErrorBoundary>
                  <TerminationRequests />
                </ErrorBoundary>
              }
            />

            <Route
              path="baana/applications"
              element={
                <ErrorBoundary>
                  <BaanaApplications />
                </ErrorBoundary>
              }
            />

            <Route
              path="baana/members-baana"
              element={
                <ErrorBoundary>
                  <MembersBaana />
                </ErrorBoundary>
              }
            />

            <Route
              path="members/termination/:id"
              element={
                <ErrorBoundary>
                  <Renumeration />
                </ErrorBoundary>
              }
            />
            <Route
              path="loans/members-requests/:id"
              element={
                <ErrorBoundary>
                  <LoanVerify />
                </ErrorBoundary>
              }
            />
            <Route
              path="accounts/reserve"
              element={
                <ErrorBoundary>
                  <Reserve />
                </ErrorBoundary>
              }
            />
            <Route
              path="loans/members"
              element={
                <ErrorBoundary>
                  <MemberLoans />
                </ErrorBoundary>
              }
            />
            <Route
              path="loans/members-requests"
              element={
                <ErrorBoundary>
                  <LoanAdmin />
                </ErrorBoundary>
              }
            />
            <Route
              path="loans/applications"
              element={
                <ErrorBoundary>
                  <LoanPaymentApplications />
                </ErrorBoundary>
              }
            />
            <Route
              path="withdraw/members/:id"
              element={
                <ErrorBoundary>
                  <WithdrawVerify />
                </ErrorBoundary>
              }
            />
            <Route
              path="withdraw/members"
              element={
                <ErrorBoundary>
                  <WithdrawMembers />
                </ErrorBoundary>
              }
            />
            <Route
              path="deposit/members/:id"
              element={
                <ErrorBoundary>
                  <DepositVerify />
                </ErrorBoundary>
              }
            />
            <Route
              path="deposit/members"
              element={
                <ErrorBoundary>
                  <DepositAdmin />
                </ErrorBoundary>
              }
            />

            <Route
              path="members"
              element={
                <ErrorBoundary>
                  <Members />
                </ErrorBoundary>
              }
            />
            <Route
              path="members/applications"
              element={
                <ErrorBoundary>
                  <Applications />
                </ErrorBoundary>
              }
            />
            <Route
              path="members/applications/:id"
              element={
                <ErrorBoundary>
                  <ApplicantApproval />
                </ErrorBoundary>
              }
            />
            <Route
              path="application"
              element={
                <ErrorBoundary>
                  <MemberApplication />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation"
              element={
                <ErrorBoundary>
                  <Organisation />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation/announcements"
              element={
                <ErrorBoundary>
                  <Announcements />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation/announcements/:id"
              element={
                <ErrorBoundary>
                  <Announcement />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation/announcements/announce"
              element={
                <ErrorBoundary>
                  <Announce />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation/reports"
              element={
                <ErrorBoundary>
                  <Reports />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation/reports/:name"
              element={
                <ErrorBoundary>
                  <Report />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation/transactions"
              element={
                // <ErrorBoundary>
                <Transactions />
                // </ErrorBoundary>
              }
            />
            <Route
              path="organisation/transactions/:id"
              element={
                <ErrorBoundary>
                  <Transaction />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation/add-transaction"
              element={
                <ErrorBoundary>
                  <AddTransaction />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation/assets"
              element={
                <ErrorBoundary>
                  <Assets />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation/assets/:id"
              element={
                <ErrorBoundary>
                  <Asset />
                </ErrorBoundary>
              }
            />
            <Route
              path="organisation/assets/add-asset"
              element={
                <ErrorBoundary>
                  <AddAsset />
                </ErrorBoundary>
              }
            />
          </Route>
          <Route element={<PrivateRoute allowedRoles={["member", "admin"]} />}>
            {/* <Route path="/transfers/approvals"
              element={
                <ErrorBoundary>
                  <TransferApproval />
                </ErrorBoundary>
              }
            /> */}
            <Route
              path="/transfers"
              element={
                <ErrorBoundary>
                  <Transfers />
                </ErrorBoundary>
              }
            />

            <Route
              path="/organisation/service-providers"
              element={
                <ErrorBoundary>
                  <ServiceProviders />
                </ErrorBoundary>
              }
            />

            <Route
              path="/organisation/service-providers/add-providers"
              element={
                <ErrorBoundary>
                  <AddProvider />
                </ErrorBoundary>
              }
            />

            <Route
              path="/assets/verification-requests"
              element={
                <ErrorBoundary>
                  <VerificationRequests />
                </ErrorBoundary>
              }
            />

            <Route
              path="/assets/verification-requests/:id"
              element={
                <ErrorBoundary>
                  <AssetRequestVerification />
                </ErrorBoundary>
              }
            />
            {/* <Route path="/transfers/approvals:id"
              element={
                <ErrorBoundary>
                  <TransferApproval />
                </ErrorBoundary>
              }
            /> */}

            <Route
              path="/baana/add-baana"
              element={
                <ErrorBoundary>
                  <AddBaana />
                </ErrorBoundary>
              }
            />
            <Route
              path="/baana"
              element={
                <ErrorBoundary>
                  <Baana />
                </ErrorBoundary>
              }
            />
            <Route
              path="/transfers/request"
              element={
                <ErrorBoundary>
                  <RequestSingleTransfer />
                </ErrorBoundary>
              }
            />

            <Route
              path="/loans/guarantee-requests"
              element={
                <ErrorBoundary>
                  <GuaranteeRequests />
                </ErrorBoundary>
              }
            />
            <Route
              path="loans/guarantor-requests/:id"
              element={
                <ErrorBoundary>
                  <GuaranteeLoan />
                </ErrorBoundary>
              }
            />
          </Route>

          {/* Accessed by both members and admins */}
          <Route
            element={
              <PrivateRoute allowedRoles={["member", "admin", "super_admin"]} />
            }
          >
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/loans" element={<Loans />} />
            <Route path="loans/history" element={<LoanHistory />} />
            <Route path="loans/Payment/:id" element={<LoanPayment />} />
            <Route
              path="loans/verify-payment/:id"
              element={<VerifyPayment />}
            />

            <Route path="loans/request" element={<LoanRequest />} />
            <Route path="deposit" element={<Deposit />} />
            <Route path="deposit/history" element={<DepositHistory />} />
            <Route path="deposit/deposit" element={<MakeDeposit />} />

            <Route path="accounts" element={<Accounts />} />
            <Route path="accounts/savings" element={<Savings />} />
            <Route path="accounts/mwana" element={<Mwana />} />
            <Route path="accounts/fixed" element={<Fixed />} />
            <Route path="accounts/shares" element={<Shares />} />
            <Route path="accounts/development" element={<Development />} />

            <Route path="/withdraw" element={<Withdraw />} />
            <Route path="withdraw/history" element={<WithdrawHistory />} />
            <Route path="withdraw/request" element={<WithdrawRequest />} />

            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Route>
        </Routes>
      </Router>
    )
  );
}
