import { Pagination, Spinner, NothingShown } from "../components";
import { useState, useEffect, Fragment } from "react";
import { supabase } from "../helpers/supabase";
import { TbFilterOff } from "react-icons/tb";
import { Helmet } from "react-helmet";
import NotificationModal from "../components/Modals/NotificationModal";
import moment from "moment";
import DateRangeFilter from "../components/DateRangeFilter";
import Frown from "../components/Frown";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "../hooks";
import { useOutletContext } from "react-router-dom";

export default function Notifications() {
  const [notificationModal, setNotificationModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allDeposits, setAllDeposits] = useState([]);
  const [nothingShown, setNothingShown] = useState(false);
  const [, profile] = useOutletContext();

  // My states
  const [notifications, setNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);

  useEffect(() => {
    supabase.from("notifications").on("*", getNotifications()).subscribe();
    getNotifications();

    return () => {};
  }, []);

  const [date, setDate] = useState(null);

  const getNotifications = async () => {
    const { data } = await supabase
      .from("notifications")
      .select()
      .order("created_at", { ascending: false })
      .match({ receiver: profile?.id });

    if (!data || !data?.length > 0) setNothingShown(true);
    setNotifications(data ?? []);
    setAllNotifications(data ?? []);
    setLoading(false);
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage, setNotificationsPerPage] = useState(10);
  const indexOfLastPage = currentPage * notificationsPerPage;
  const indexOfFirstPage = indexOfLastPage - notificationsPerPage;

  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  let filteredNotifications =
    notifications && notifications?.length > 0
      ? notifications.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  useEffect(() => {
    let data = allNotifications;
    data =
      data && data?.length > 0
        ? data.filter(
            (notification) =>
              !date || notification.created_at.substring(0, 10) === date
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((notification) => {
              return moment(startDate).isSameOrBefore(
                moment(notification?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((notification) => {
              return moment(endDate).isSameOrAfter(
                moment(notification?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((notification) => {
              return moment(
                notification?.created_at?.substring(0, 10)
              ).isBetween(moment(startDate), moment(endDate), undefined, "[]");
            })
          : null;
    }

    console.log("All Notifications: ", data);

    setNotifications(data ?? []);
  }, [date, startDate, endDate]);

  return (
    <div className="mx-5 my-2 h-[calc(100vh-140px)]">
      <Helmet>
        <title>Deposit - Bweyogere tuberebumu</title>
      </Helmet>
      <div className="flex flex-col pb-3 md:h-[150px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          My Notifications
        </h1>

        <div className="flex flex-wrap gap-2 justify-between">
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id="date"
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={date}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <DateRangeFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          {/* <div className="flex justify-between text-sm"> */}
          <button
            onClick={() => {
              if (date) setDate("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              if (startDate) setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              if (endDate) setEndDate("");
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {largeDesktop && !smallDesktop && "Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-120px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : filteredNotifications && filteredNotifications.length > 0 ? (
          <>
            <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="pr-6 py-4">Date</th>
                    <th className="px-6 py-4">Type</th>
                    <th className="px-6 py-4">status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredNotifications.map((notification, index) => (
                    <Fragment key={index}>
                      <tr
                        className={`${
                          index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                        } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                        key={index}
                        onClick={() => {
                          setNotificationModal(true);
                          setActiveIndex(index);
                        }}
                      >
                        <td>
                          <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                        </td>
                        <td className="pr-6 py-3">
                          {moment(notification.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td className="px-6 py-3">
                          {notification?.meta?.type}
                        </td>
                        <td className="px-6 py-3">
                          {notification?.seen ? "seen" : "not seen"}
                        </td>
                      </tr>
                      {notificationModal && index === activeIndex && (
                        <NotificationModal
                          notification={notification}
                          setNotificationModal={setNotificationModal}
                        />
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(notifications.length / notificationsPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={notifications}
                depositsPerPage={notificationsPerPage}
                setDepositsPerPage={setNotificationsPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allNotifications.length > 0 &&
          (filteredNotifications?.length === 0 || !filteredNotifications) && (
            <Frown />
          )
        )}
      </div>
    </div>
  );
}
