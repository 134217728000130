import { ImKey } from "react-icons/im";
import { useState } from "react";

function PasswordTextField({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="m-1 w-full">
      <div className="w-full relative flex items-center">
        <input
          type={showPassword ? "text" : "password"}
          placeholder={"Password"}
          name="password"
          onChange={handleChange("password")}
          onBlur={handleBlur("password")}
          className={`mt-1 py-3 focus:outline-none focus:ring-2 focus:ring-inputblue bg-inputblue focus:bg-transparent w-full pl-8 ${
            errors?.password && touched?.password && "ring-1 ring-red-500"
          }`}
        />
        <i className="absolute left-3">
          <ImKey />
        </i>
        <div
          className={`absolute right-3 cursor-pointer text-xs text-primary font-semibold ${
            values?.password?.length < 1 && "hidden"
          }`}
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {showPassword ? "HIDE" : "SHOW"}
        </div>
      </div>
      <div
        className={`${
          errors?.password && touched?.password ? "block" : "hidev"
        }`}
      >
        <label
          className={`${
            errors?.password && touched?.password
              ? "text-red-500 text-sm"
              : "text-transparent text-sm"
          }`}
        >{`${
          errors?.password && touched?.password ? errors.password : "hide"
        }`}</label>
      </div>
    </div>
  );
}

export default PasswordTextField;
