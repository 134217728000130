import { Pagination, Spinner, NothingShown } from "../../components";
import { useState, useEffect, Fragment } from "react";
import { supabase } from "../../helpers/supabase";
import { TbFilterOff } from "react-icons/tb";
import { Helmet } from "react-helmet";
import { TransferModal } from "../../components";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import DateRangeFilter from "../../components/DateRangeFilter";
import Frown from "../../components/Frown";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "../../hooks";
import { useOutletContext } from "react-router-dom";

export default function Transfers() {
  const [transfers, setTransfers] = useState([]);
  const [transferModall, setTransferModal] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [alltransfers, setAllTransfers] = useState([]);
  const [nothingShown, setNothingShown] = useState(false);
  const [, profile] = useOutletContext()

  useEffect(() => {
    fetchTransfers();

    supabase
      .from("applications")
      .on("*", async (payload) => {
        console.log("Current Transfer Payload: ", payload)
        await fetchTransfers();
      });
    fetchTransfers();

    supabase
      .from("transactions")
      .on("*", async (payload) => {
        console.log("Current Transfer payload: ", payload)
        await fetchTransfers();
      })
      .subscribe();

    return () => {};
  }, []);

  const [date, setDate] = useState(null);

  const fetchTransfers = async () => {
    const {
      data: { transactions, applications },
      error,
    } = await supabase.rpc("fetch_transfers");
    if (error) {
      setLoading(false);
      throw error;
    } else {
      let data = [];
      if (applications) data.push(...applications);
      if (transactions) data.push(...transactions);

      const sorted_data =
        data && data?.length > 0
          ? data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          : null;

      if (data?.length === 0 || !data) setNothingShown(true);

      setLoading(false);
      setTransfers(sorted_data);
      setAllTransfers(sorted_data);
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [transfersPerPage, setTransfersPerPage] = useState(10);
  const indexOfLastPage = currentPage * transfersPerPage;
  const indexOfFirstPage = indexOfLastPage - transfersPerPage;

  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  let filteredTransfers =
    transfers && transfers?.length > 0
      ? transfers.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  useEffect(() => {
    let data = alltransfers;
    data =
      data && data?.length > 0
        ? data.filter(
            (transfer) => !date || transfer.created_at.substring(0, 10) === date
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((transfer) => {
              return moment(startDate).isSameOrBefore(
                moment(transfer?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((transfer) => {
              return moment(endDate).isSameOrAfter(
                moment(transfer?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((transfer) => {
              return moment(transfer?.created_at?.substring(0, 10)).isBetween(
                moment(startDate),
                moment(endDate),
                undefined,
                "[]"
              );
            })
          : null;
    }

    data =
      !data ||
      data.filter((transfer) =>
        !status || status === ""
          ? transfer
          : status === "approved"
          ? transfer?.transaction_meta
          : transfer?.application_meta?.review_status === status
      );

    setTransfers(data ?? []);
  }, [date, startDate, endDate, status]);

  const generate_transfers_report = () => {
    const formattedtransfers = alltransfers.map((transfer) => {
      return {
        "Transaction ID": transfer?.application_meta
          ? transfer?.app_id
          : transfer?.trans_id,
        Date: transfer?.created_at,
        Source: transfer?.transaction_meta
          ? transfer?.transaction_meta?.senders_member_name || transfer?.transaction_meta?.transferred_from
          : transfer?.application_meta?.senders_member_name ?? "",
        Destination: transfer?.transaction_meta
          ? transfer?.transaction_meta?.receivers_member_name || transfer?.transaction_meta?.member_name
          : transfer?.application_meta?.receivers_member_name,
        "Source Account": transfer?.transaction_meta
          ? transfer?.transaction_meta.senders_account_type ?? "shares"
          : transfer?.application_meta?.senders_account_type ?? "",
        "Destination Account": transfer?.transaction_meta
          ? transfer?.transaction_meta?.receivers_account_type ?? "savings"
          : transfer?.application_meta?.receivers_account_type,
        Amount: transfer?.transaction_meta
          ? transfer?.amount
          : transfer?.application_meta?.amount,
        "Approved At": transfer?.transaction_meta
          ? moment(transfer.transaction_meta?.approved_at).format("DD-MM-YYYY")
          : "",
        Status: transfer?.transaction_meta ? "approved" : "pending",
        "Approved By": transfer?.transaction_meta
          ? transfer?.transaction_meta?.approved_by
          : "",
      };
    });

    generateReportFromJson(formattedtransfers, `${profile?.fullname} Transfers Bweyogerere Tuberebumu ${ new Date().toISOString().substring(0, 10)}`);
  };

  if (alltransfers) console.log("Transfer: ", alltransfers[0]);

  return (
    <div className="mx-5 my-2 h-[calc(100vh-140px)]">
      <Helmet>
        <title>Transfers - Bweyogere tuberebumu</title>
      </Helmet>
      <div className="flex flex-col pb-3 md:h-[150px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          My Transfers
        </h1>
        <div className="flex flex-wrap gap-2 justify-between">
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-2 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
            >
              <option value="">Status</option>
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
              <option value="forwarded">Forwarded</option>
              <option value="cancelled">Cancelled</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id=""
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={date}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <DateRangeFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          {/* <div className="flex justify-between text-sm"> */}
          <button
            onClick={(status) => {
              if (status) setStatus("");
              if (date) setDate("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              if (startDate) setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              if (endDate) setEndDate("");
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {largeDesktop && !smallDesktop && "Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
          <button
            className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
            onClick={() => {
              generate_transfers_report();
            }}
            disabled={!alltransfers || alltransfers?.length === 0}
          >
            {largeDesktop && !smallDesktop && "Export"}
            <MdDownload className="ml-1" />
          </button>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-120px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : filteredTransfers && filteredTransfers.length > 0 ? (
          <>
            <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="pr-6 py-4">Date</th>
                    <th className="px-6 py-4">Transaction ID</th>
                    <th className="px-6 py-4">Source</th>
                    <th className="px-6 py-4">Destination</th>
                    <th className="px-6 py-4">Amount</th>
                    <th className="px-6 py-4">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTransfers.map((transfer, index) => {
                    console.log("Transfer: ", transfer.application_meta);
                    return (
                      <Fragment key={index}>
                        <tr
                          className={`${
                            index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                          } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                          key={index}
                          onClick={() => {
                            setTransferModal(true);
                            setActiveIndex(index);
                          }}
                        >
                          <td>
                            <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                          </td>
                          <td className="pr-6 py-3">
                            {moment(transfer.created_at).format("DD-MM-YYYY")}
                          </td>
                          <td className="px-6 py-3">
                            {transfer?.trans_id || transfer?.app_id}
                          </td>
                          <td className="px-6 py-3">
                            {transfer?.transaction_meta?.from?.member_name ||
                              transfer?.application_meta?.senders_member_name ||
                              transfer?.transaction_meta?.transferred_from}
                          </td>
                          <td className="px-6 py-3">
                            {transfer?.transaction_meta?.to?.member_name ||
                              transfer?.application_meta
                                ?.receivers_member_name ||
                              transfer?.transaction_meta?.member_name}
                          </td>
                          <td className="px-6 py-3">
                            {currencyFormatter(
                              transfer?.amount ||
                                transfer?.application_meta?.amount
                            )}
                          </td>
                          <td className="px-6 py-3">
                            <span
                              className={` py-1 px-2 rounded-xl text-white inline-block ${
                                transfer?.application_meta?.review_status ===
                                "pending"
                                  ? "bg-yellow-400"
                                  : transfer?.application_meta
                                      ?.review_status === "rejected" || transfer?.application_meta?.review_status === "cancelled"
                                  ? "bg-red-400"
                                  : "bg-green-400"
                              }`}
                            >
                              {transfer?.application_meta?.review_status ===
                              "pending"
                                ? "pending approval"
                                : transfer?.transaction_meta
                                ? "approved "
                                : transfer?.application_meta?.review_status}
                            </span>
                          </td>
                        </tr>
                        {transferModall && index === activeIndex && (
                          <TransferModal
                            transfer={transfer}
                            setTransferModal={setTransferModal}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(transfers.length / transfersPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={transfers}
                depositsPerPage={transfersPerPage}
                setDepositsPerPage={setTransfersPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          alltransfers.length > 0 &&
          (filteredTransfers?.length === 0 || !filteredTransfers) && <Frown />
        )}
      </div>
    </div>
  );
}
