import { useState, useEffect, Fragment } from "react";
import { BaanaModal } from "../../components";
import { Pagination } from "../../components";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { Spinner, NothingShown } from "../../components";
import { useOutletContext } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import { Helmet } from "react-helmet";
import Frown from "../../components/Frown";
import moment from "moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { currencyFormatter } from "../../helpers/currencyFormatter";

export default function Members() {
  useEffect(() => {
    // Fetch members on component render

    fetchBaana().catch((error) => console.log(error));

    // Realtime
    const mySubscription = supabase
      .from("baana")
      .on("*", async () => {
        await fetchBaana().catch((error) => console.log(error));
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, []);

  const [, profile] = useOutletContext();

  const fetchBaana = async () => {
    const {
      data,
      error,
    } = await supabase.rpc("fetch_other_members_baana");
    if (error) throw error;


    console.log("Data: ", data);

    if (!data || data?.length === 0) setNothingShown(true);
    setMembers(data);
    setAllMembers(data);
    setLoading(false);
  };


  const [nothingShown, setNothingShown] = useState(false);
  const [members, setMembers] = useState([]);
  const [date, setDate] = useState(null);
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [show, setShow] = useState(false);

  const [mwanaModal, setMwanaModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [allMembers, setAllMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawPerPage, setWithdrawPerPage] = useState(10);
  const indexOfLastPage = currentPage * withdrawPerPage;
  const indexOfFirstPage = indexOfLastPage - withdrawPerPage;
  const [deleteModal, setDeleteModal] = useState(true);

  console.log("All Members: ", members);

  useEffect(() => {
    let data = allMembers;

    data =
      data && data?.length > 0
        ? data.filter((member) =>
            member?.mwana_meta
              ? member?.mwana_meta?.name
                  ?.toLowerCase()
                  .indexOf(searchText.toLowerCase()) > -1
              : member?.name
              ? member.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
              : false
          )
        : null;
    data =
      data && data?.length > 0
        ? data.filter((member) =>
            status === "approved"
              ? member?.mwana_meta
              : !status
              ? member
              : member?.review_status === status
          )
        : null;
    data =
      data && data?.length > 0
        ? data.filter(
            (member) =>
              !date ||
              (member?.created_at &&
                member.created_at.substring(0, 10) === date)
          )
        : null;

    setMembers(data);
  }, [searchText, date, status]);

  const shownMembers =
    members && members?.length > 0
      ? members.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const export_baana = () => {
    const formattedBaana = members.map((mwana) => {
      return {
        Name: (mwana.name || mwana.mwana_meta.name) ?? "Not specified",
        "Date of Birth": (mwana.dob || mwana.mwana_meta.dob) ?? "Not specified",
        Gender: (mwana.gender || mwana.mwana_meta.gender) ?? "Not specified",
        "Account Balance( UGX )":
          mwana?.balance && mwana?.balance > 0
            ? currencyFormatter(mwana.balance)
            : 0,
        "Mwana status": mwana.mwana_meta ? "approved" : mwana.review_status,
        "Educaton Level": mwana?.mwana_meta
          ? mwana.mwana_meta.education
          : mwana.education,
        "Health Issues": mwana.mwana_meta
          ? mwana.mwana_meta.health_issues
          : mwana.health_issues,
      };
    });

    generateReportFromJson(
      formattedBaana,
      "Baana " + new Date().toISOString().substring(0, 10)
    );
  };

  return (
    <div className="mx-5 my-2 md:h-[calc(100vh-70px)]">
      <ToastContainer />
      <Helmet>
        <title>Members - Bweyogere tuberebumu</title>
      </Helmet>
      <div className="flex flex-col justify-between pb-3 md:h-[150px]">
        <h1 className="mb-3 mt-2 font-bold uppercase dark:text-white">BAANA</h1>
        <div className=" flex justify-between flex-wrap">
          <input
            type="text"
            className="w-8/12 rounded-md px-2 py-2 sm:py-1 dark:bg-dark-bg-600"
            placeholder="Search"
            onChange={(event) => setSearchText(event.target.value)}
          />
          {profile?.roles && !profile?.roles?.includes("super_admin") && (
            <button
              className=" px-4 bg-primary py-2 text-white rounded-md flex justify-center items-center"
              onClick={() => {
                navigate("/baana/add-baana");
              }}
            >
              Add Baana
            </button>
          )}
        </div>
        <div className="flex justify-between flex-wrap">
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-4 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
            >
              <option value="">Status</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option valaue="rejected">Rejected</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex gap-2 flex-wrap">
            <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
              <input
                type="date"
                name=""
                onChange={(event) => setDate(event.target.value)}
                id="inputDate"
                className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
                value={date}
              />
              <button
                className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
                onClick={() => {
                  if (date) setDate("");
                  document.getElementById("inputDate").value = "";
                }}
              >
                <AiOutlineCloseCircle size={20} />
              </button>
            </div>
            <button
              className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
              onClick={() => {
                export_baana();
              }}
              disabled={!allMembers || allMembers?.length === 0}
            >
              Export
              <MdDownload className="ml-1" />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-170px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : members !== null && shownMembers.length > 0 ? (
          <>
            <div className="w-full pb-3 overflow-x-auto h-full  relative overflow-y-auto ">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Date</th>
                    <th className="px-6 py-4">Mwanas Name</th>
                    <th className="px-6 py-4">Members Name</th>
                    <th className="px-6 py-4">Status</th>
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {shownMembers.map((mwana, index) => {
                    return (
                      <Fragment key={index}>
                        <tr
                          className={`${
                            index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                          } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                          key={index}
                        >
                          <td
                            onClick={() => {
                              setActiveIndex(index);
                              setMwanaModal(true);
                            }}
                          >
                            <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                          </td>
                          <td
                            className="px-6 py-3"
                            onClick={() => {
                              setActiveIndex(index);
                              setMwanaModal(true);
                            }}
                          >
                            {mwana?.created_at
                              ? moment(mwana?.created_at).format("DD-MM-YYYY")
                              : ""}
                          </td>
                          <td
                            className="px-6 py-3"
                            onClick={() => {
                              setActiveIndex(index);
                              setMwanaModal(true);
                            }}
                          >
                            {mwana?.name || mwana?.mwana_meta.name}
                          </td>
                          <td
                            className="px-6 py-3"
                            onClick={() => {
                              setActiveIndex(index);
                              setMwanaModal(true);
                            }}
                          >
                            {mwana?.owners_name || mwana?.mwana_meta.owners_name}
                          </td>
                          <td
                            className={`px-4 py-3`}
                            onClick={() => {
                              setActiveIndex(index);
                              setMwanaModal(true);
                            }}
                          >
                            <span
                              className={` py-1 px-2 rounded-xl text-white ${
                                mwana?.review_status === "pending"
                                  ? "bg-yellow-400"
                                  : mwana?.review_status === "approved"
                                  ? "bg-green-400"
                                  : "bg-rose-600"
                              }`}
                            >
                              {mwana?.review_status === "pending"
                                ? "pending approval"
                                : mwana?.review_status}
                            </span>
                          </td>
                        </tr>
                        {mwanaModal && activeIndex === index && (
                          <BaanaModal
                            mwana={activeIndex === index && mwana}
                            setMwanaModal={setMwanaModal}
                            mwanaModal={mwanaModal}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(members.length / withdrawPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={members}
                depositsPerPage={withdrawPerPage}
                setDepositsPerPage={setWithdrawPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allMembers.length > 0 &&
          (shownMembers?.length === 0 || !shownMembers) && <Frown />
        )}
      </div>
    </div>
  );
}
