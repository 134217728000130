export function getFormattedDate(date) { 
    // Month mappings.
    const MONTH_NAMES = [
        'Jan', 
        'Feb',
        'Mar', 
        'Apr', 
        'May', 
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov', 
        'Dec'
    ]
    
    // Day mappings.
    const DAY_NAMES = [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
        'Sun'
    ]


    // Current date details.
    const currentDate = new Date()
    const currentDay = DAY_NAMES[currentDate.getDay()]
    const currentMonth = MONTH_NAMES[currentDate.getMonth()]
    const currentYear = currentDate.getFullYear()

    // Extract specific values from the date.
    if(date !== null) {
        const calendarDate = date.getDate();
        const day = DAY_NAMES[date.getDay()]
        const month = MONTH_NAMES[date.getMonth()]
        const year = date.getFullYear()
        const hours = date.getHours()
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        const ampm = hours >= 12 ? 'pm' : 'am'
    
        // Format the date for display along with the message.

        console.log('Current Day: ', DAY_NAMES[currentDate.getDay()])
        if(currentDay === day && currentMonth === month && currentYear === year ) return `Today ${hours}:${minutes} ${ampm}`
        else if(currentMonth === month && currentYear === year) return `${ day } ${hours}:${minutes} ${ampm}`
        else return `${month} ${calendarDate}, ${year}, ${hours}:${minutes} ${ampm}`
    }

    return null

}

export function getFormattedDateAgo(date) { 

    // Current date details.
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()
    const currentCalendarDate = currentDate.getDate()
    const currentHour = currentDate.getHours()
    const currentMinutes = currentDate.getMinutes()
    const currentSeconds = currentDate.getSeconds()

    // Extract specific values from the date.
    if(date !== null) {
        const calendarDate = date.getDate();
        const month = date.getMonth()
        const year = date.getFullYear()
        const hours = date.getHours()
        const minutes = date.getMinutes() 
        const seconds = date.getSeconds()
    
        // Format the date for display along with the message.

        // console.log('Current Day: ', DAY_NAMES[currentDate.getDay()])
        if(currentYear === year) {
            if( currentMonth === month ) {
                if ( currentCalendarDate === calendarDate ) {
                    if ( currentHour === hours ) {
                        if ( currentMinutes === minutes ) {
                            if ( currentSeconds - seconds < 11 ) {
                                return 'now'
                            } else {
                                return `${currentSeconds - seconds} seconds ago.`
                            }
                        }
                        return  `${currentMinutes - minutes > 1 ? `${currentMinutes - minutes} minutes` : "A minute"} ago`
                    }
                    return `${currentHour - hours > 1 ? `${currentHour - hours} hours` : "An hour"} ago`
                }
                return `${currentCalendarDate - calendarDate > 1 ? `${currentCalendarDate - calendarDate} days` : "A day"} ago` 
            } 
            return `${currentMonth - month > 1 ? `${currentMonth - month} months` : "A month"} ago`
        }
        else return `${currentYear - year > 1 ? `${currentYear - year} years` : "A year"} ago`
    }

    return null
}