import { Submit, Spinner } from "../../components";
import { Formik, Form, ErrorMessage } from "formik";
import { uploadFile } from "../../helpers/uploadFile";
import { supabase } from "../../helpers/supabase";
import { useAuth } from "../../auth/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { evidencedRequestValidationSchema as depositRequestValidationSchema } from "../../helpers/validator";
import { useOutletContext } from "react-router-dom";
import { Fragment, useState } from "react";
import {
  add_separator,
  remove_separator,
} from "../../helpers/thousand_separator";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sendSMS } from "../../helpers/sendsms";

function MakeDeposit() {
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState({});
  const [baana, setBaana] = useState([]);
  const [allBaana, setAllBaana] = useState([]);
  const navigate = useNavigate();

  const {
    user: { id: applicants_id },
  } = useAuth();
  const [
    user,
    { fullname: applicants_name, position_in_sacco },
    setProfile,
    roles,
  ] = useOutletContext();

  useEffect(() => {
    fetchMemberAccounts();
    setLoading(false);

    // // Realtime setup
    // const mySubscription = supabase
    //   .from("_member_profiles")
    //   .on("*", async (payload) => {
    //     console.log("Change received!", payload);
    //     fetchMemberAccounts().then(() => setLoading(false));
    //   })
    //   .subscribe();

    // // Cleanup
    // return () => supabase.removeSubscription(mySubscription);
  }, []);

  const fetchMemberAccounts = async () => {
    const { data } = await supabase.rpc("fetch_other_members_information");
    const { data: baanaData } = await supabase.from("baana").select();
    console.log("Members accounts: ", data);
    setProfiles(() => data);
    setAllBaana(() => baanaData ?? []);
    setBaana(
      baanaData.filter((mwana) => mwana.member_id === applicants_id) ?? []
    );
    setLoading(false);
    // console.log("All Baana: ", baanaData)
  };

  const initialValues = {
    account_type: "",
    amount: "",
    comments: "",
    evidence: "",
    designated_for: "own",
    member_id: applicants_id,
    member_name: "",
    baana: baana,
    mwana: "",
    mwana_name: "",
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">Deposit</h1>
      <div className="flex bg-white overflow-hidden relative dark:bg-dark-bg-700 dark:text-secondary-text p-6 min-h-full w-full justify-start">
        {loading && (
          <div className="absolute z-10 bg-white dark:bg-dark-bg-700 dark:bg-opacity-90 bg-opacity-90 w-full h-full rounded-lg">
            <Spinner />
          </div>
        )}
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);
            const toastID = toast.loading("Processing deposit application");
            const { evidence, baana, ...rest } = values;
            try {
              let file_url = null;

              if (evidence) {
                const { Key: url } = await uploadFile(evidence, "deposits");
                file_url = url;
              }

              const application_meta = {
                applicants_name,
                applicants_id,
                ...rest,
                files: [
                  {
                    file_url: file_url ? file_url : "",
                  },
                ],
                created_at: new Date()
                  .toISOString()
                  .toLocaleString("en-GB", { timeZone: "UTC" }),
                updated_at: new Date()
                  .toISOString()
                  .toLocaleString("en-GB", { timeZone: "UTC" }),
                member: {
                  id: values.member_id,
                  member_name: values?.member_name || applicants_name,
                },
                mwana: {
                  id: values?.mwana ?? "",
                  mwana_name: values?.mwana_name ?? "",
                },
                review_status: "pending",
              };

              console.log("Application meta: ", application_meta);
              const { data, error } = await supabase
                .from("applications")
                .insert({
                  _type: "deposit",
                  created_at: new Date()
                    .toISOString()
                    .toLocaleString("en-GB", { timeZone: "UTC" }),
                  updated_at: new Date()
                    .toISOString()
                    .toLocaleString("en-GB", { timeZone: "UTC" }),
                  reviewed: false,
                  application_meta,
                })
                .single();

              if (error) throw error;
              resetForm({ values: initialValues });
              setSubmitting(false);
              setLoading(false);

              const { data: secretariate } = await supabase
                .from("_member_profiles")
                .select(
                  `
                  id, fullname
                  `
                )
                .in("position_in_sacco", ["secretary", "assistant secretary"])
                .eq("is_under_termination", false);

              if (secretariate && secretariate?.length > 0) {
                secretariate.forEach(async (member) => {
                  await sendSMS(
                    member.id,
                    `Hello ${member.fullname},\n ${
                      data?.application_meta?.designated_for === "own"
                        ? data.application_meta.applicants_name
                        : data.application_meta.member.member_name
                    } has made a deposit.\nForward to the chairperson. Visit https://tube.ablestate.co/deposit/members\nTube Sacco.`
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      console.log(data);
                    })
                    .catch((error) => console.log(error, "Error"));
                });
              }

              await sendSMS(
                data.application_meta.member.id,
                `Hello ${data.application_meta.member.member_name},\nYour deposit has been submitted for review.\nRegards,\nTube Sacco`
              )
                .then((response) => response.json())
                .then((data) => console.log(data))
                .catch((error) => console.log(error));

              if (rest?.designated_for === "other") {
                sendSMS(
                  values.member_id,
                  `Hello ${values.member_name}, a deposit has been made by the ${position_in_sacco} on your ${values.account_type} account. For more details visit For more details visit https://tube.ablestate.co/deposits Regards, Bweyogerere Tuberebumu Sacco.`
                )
                  .then((response) => response.json())
                  .then((data) => {
                    console.log(data);
                  })
                  .catch((error) => console.log(error, "Error"));
              }

              toast.update(toastID, {
                render: `Request submitted for review.`,
                autoClose: 5000,
                isLoading: false,
                type: "success",
              });
            } catch (error) {
              setLoading(false);
              console.log(error);
              toast.error(`${error?.message}`, { position: "top-center" });
            }
          }}
          validationSchema={depositRequestValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <Form className="w-full">
                <div className="flex flex-grow flex-col min-h-full w-full">
                  <ToastContainer position="top-center" />
                  <div className="mb-5 flex gap-5 flex-wrap ">
                    {roles && roles.includes("treasurer") && (
                      <div className="flex flex-col w-56 pb-3">
                        <label className="text-sm">
                          Designated for{" "}
                          <span className="text-sm text-red-500 ml-2 font-bold">
                            *
                          </span>
                        </label>
                        <div className="flex justify-between">
                          <div className="flex gap-1">
                            <input
                              type="radio"
                              id="own"
                              name="designated_for"
                              value="own"
                              className="w-4 h-4"
                              onChange={() => {
                                setFieldValue("designated_for", "own");
                                const filteredBaana = allBaana.filter(
                                  (mwana) => mwana.member_id === applicants_id
                                );
                                console.log("FilteredBaana:", filteredBaana);
                                setFieldValue("baana", filteredBaana);
                                setBaana(() => filteredBaana);
                              }}
                            />
                            <label htmlFor="own" className="text-sm">
                              My Own
                            </label>
                          </div>
                          <div className="flex gap-1">
                            <input
                              type="radio"
                              id="other"
                              name="designated_for"
                              value="other"
                              className="w-4 h-4"
                              onChange={() => {
                                setFieldValue("designated_for", "other");
                              }}
                            />
                            <label htmlFor="other" className="text-sm">
                              Other Member
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col w-56">
                      <label className="text-sm">
                        Select your account{" "}
                        <span className="text-sm text-red-500 ml-2 font-bold">
                          *
                        </span>
                      </label>
                      <select
                        name="account_type"
                        id="account_type"
                        className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                          errors?.account_type && touched?.account_type
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.account_type}
                      >
                        <option value="">--Select Account--</option>
                        {roles &&
                        roles?.length > 0 &&
                        roles.includes("treasurer") &&
                        values.designated_for === "organisation" ? (
                          <>
                            <option value="reserve">Reserve</option>
                          </>
                        ) : (
                          <>
                            <option value="savings">Savings</option>
                            <option value="shares">Shares</option>
                            <option value="fixed">Fixed</option>
                            <option value="mwana">Mwana</option>
                            {/* <option value="development">Development</option> */}
                          </>
                        )}
                      </select>
                      <ErrorMessage name="account_type">
                        {(msg) => (
                          <div className="error text-red-500 text-xs">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    {values?.designated_for === "other" && (
                      <div className="flex flex-col w-56">
                        <label className="text-sm">
                          Select Member{" "}
                          <span className="text-sm text-red-500 ml-2 font-bold">
                            *
                          </span>
                        </label>
                        <select
                          name="member_id"
                          id="member_id"
                          className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                            errors?.member_id && touched?.member_id
                              ? "ring-red-500"
                              : "ring-black"
                          }`}
                          onChange={async (event) => {
                            const [memberID, memberName] =
                              event.target.value.split("|");

                            setFieldValue(event.target.name, memberID);
                            values.member_name = memberName;
                            const filteredBaana = allBaana.filter(
                              (mwana) => mwana.member_id === memberID
                            );
                            setFieldValue("baana", filteredBaana);
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="">--Select Member--</option>
                          {values.account_type === "savings"
                            ? profiles &&
                              profiles?.savings_accounts &&
                              profiles.savings_accounts.map(
                                ({ fullname, id }, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={`${id}|${fullname}`}
                                      className="capitalize"
                                    >
                                      {fullname}
                                    </option>
                                  );
                                }
                              )
                            : values?.account_type === "shares"
                            ? profiles &&
                              profiles?.shares_accounts &&
                              profiles.shares_accounts.map(
                                ({ fullname, id }, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={`${id}|${fullname}`}
                                      className="capitalize"
                                    >
                                      {fullname}
                                    </option>
                                  );
                                }
                              )
                            : values?.account_type === "mwana"
                            ? profiles &&
                              profiles?.mwana_accounts &&
                              profiles.mwana_accounts.map(
                                ({ fullname, id }, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={`${id}|${fullname}`}
                                      className="capitalize"
                                    >
                                      {fullname}
                                    </option>
                                  );
                                }
                              )
                            : values?.account_type === "development"
                            ? values?.account_type === "fixed" &&
                              profiles &&
                              profiles?.development_accounts &&
                              profiles.development_accounts.map(
                                ({ fullname, id }, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={`${id}|${fullname}`}
                                      className="capitalize"
                                    >
                                      {fullname}
                                    </option>
                                  );
                                }
                              )
                            : values?.account_type === "fixed" &&
                              profiles &&
                              profiles?.fixed_deposit_accounts &&
                              profiles.fixed_deposit_accounts.map(
                                ({ fullname, id }, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={`${id}|${fullname}`}
                                      className="capitalize"
                                    >
                                      {fullname}
                                    </option>
                                  );
                                }
                              )}
                        </select>
                        <ErrorMessage name="member_id">
                          {(msg) => (
                            <div className="error text-red-600 text-xs">
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                        {values?.account_type === "mwana" &&
                          values?.designated_for === "other" &&
                          values?.member_id &&
                          baana.length === 0 &&
                          touched.member_id && (
                            <div className="error text-red-600 text-xs">
                              The member has not yet added baana
                            </div>
                          )}
                      </div>
                    )}

                    {values.account_type === "mwana" &&
                      (values.baana && values.baana.length > 0 ? (
                        <div className="flex flex-col w-56">
                          <label className="text-sm">
                            Select Mwana{" "}
                            <span className="text-sm text-red-500 ml-2 font-bold">
                              *
                            </span>
                          </label>
                          <select
                            name="mwana"
                            id="mwana"
                            className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                              errors?.mwana && touched?.mwana
                                ? "ring-red-500"
                                : "ring-black"
                            }`}
                            onChange={(event) => {
                              const [mwanaID, mwanaName] =
                                event.target.value.split("|");
                              setFieldValue("mwana", mwanaID);
                              setFieldValue("mwana_name", mwanaName);
                            }}
                            onBlur={handleBlur}
                            value={values.mwana + "|" + values.mwana_name}
                          >
                            <option value="">--Select Mwana--</option>
                            {values.baana.map((mwana, index) => {
                              return (
                                <option
                                  value={`${mwana.id}|${mwana.mwana_meta.name}`}
                                  key={index}
                                >
                                  {mwana.mwana_meta.name}
                                </option>
                              );
                            })}
                          </select>
                          <ErrorMessage name="account_type">
                            {(msg) => (
                              <div className="error text-red-500 text-xs">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      ) : (
                        values.designated_for === "own" && (
                          <Fragment>
                            <div className="flex justify-center items-center text-xs text-red-500">
                              Not yet added baana
                            </div>
                            <div className="flex justify-center items-center">
                              <button
                                className="bg-primary inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2 cursor-pointer self-center mt-2"
                                type="button"
                                onClick={(event) => {
                                  event.preventDefault();
                                  navigate("/baana/add-baana", {
                                    replace: true,
                                  });
                                }}
                              >
                                {" "}
                                Add mwana{" "}
                              </button>
                            </div>
                          </Fragment>
                        )
                      ))}
                  </div>
                  <div className="mb-3 flex gap-5 flex-wrap">
                    <div className="flex flex-col w-56 ">
                      <label className=" text-sm">
                        Enter Amount{" "}
                        <span className="text-sm text-red-500 ml-2 font-bold">
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        name="amount"
                        id="amount"
                        placeholder="Enter amount"
                        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.amount && touched?.amount
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={(event) => {
                          let formatted_string = add_separator(
                            remove_separator(event.target.value)
                          );
                          event.target.value = formatted_string;
                          setFieldValue(
                            event.target.name,
                            parseFloat(remove_separator(formatted_string))
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      <div className="relative mt-1">
                        <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                          {" "}
                        </div>
                        <div className="text-xs text-gray-400 pl-3">
                          Use figures
                        </div>
                      </div>
                      <ErrorMessage name="amount">
                        {(msg) => (
                          <div className="error text-red-500 text-xs">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    {values?.designated_for != "organisation" && (
                      <div className="mb-3 flex flex-col md:flex-row gap-5">
                        <div className="flex flex-col w-56 ">
                          <label className=" text-sm">
                            Upload Receipt{" "}
                            <span className="text-sm text-red-500 ml-2 font-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="file"
                            name="evidence"
                            id="evidence"
                            placeholder="Enter postal address"
                            className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                              errors?.evidence && touched?.evidence
                                ? "ring-red-500"
                                : "ring-black"
                            }`}
                            onChange={(event) => {
                              values.evidence = event.currentTarget.files[0];
                            }}
                            onBlur={handleBlur}
                            accept="image/png, image/jpeg, application/pdf, .doc, .docx"
                          />
                          {touched?.evidence && errors?.evidence && (
                            <div className="error text-red-600 text-xs">
                              {errors?.evidence}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <h1 className="font-semibold">
                      {values.designated_for === "organisation"
                        ? "Reason"
                        : "Comments"}
                      {"    "}
                      <span className="text-sm font-normal">
                        ( Specify the transaction ID )
                      </span>
                    </h1>
                    <textarea
                      name="comments"
                      id="comments"
                      cols="30"
                      rows="10"
                      className="outline outline-1 rounded-md w-full p-2 dark:bg-dark-bg-600"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comments}
                      placeholder="Hint: Type transaction ID for payments made via mobile money or bank app and specify the service provider."
                    ></textarea>
                    {touched?.comments && errors?.comments && (
                      <div className="error text-red-600 text-xs">
                        {errors?.comments}
                      </div>
                    )}
                  </div>
                  <div className="flex justify-start w-full">
                    <div className="w-56">
                      <Submit
                        value="Submit"
                        disabled={!(isValid && dirty) || isSubmitting}
                      />
                    </div>
                    {/*Debug */}
                    {/* <button
                      className="px-2 bg-primary text-white capitalize ml-2"
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        console.log("Values: ", values);
                        console.log("Errors: ", errors);
                      }}
                    >
                      debug
                    </button> */}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default MakeDeposit;
