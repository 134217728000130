import { useState, useEffect, Fragment } from "react";
import { Pagination } from "../../components";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { Spinner, NothingShown } from "../../components";
import { useLocation, useOutletContext } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import moment from "moment/moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import Frown from "../../components/Frown";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ErrorBoundary from "../../components/ErrorBoundary";
import TransactionModal from "../../components/Modals/TransactionModal";
import { Helmet } from "react-helmet";
import DateRangeFilter from "../../components/DateRangeFilter";
import { useMediaQuery } from "../../hooks";
import { TbFilterOff } from "react-icons/tb";

export default function Transactions() {
  useEffect(() => {
    // Fetch transactions on component render
    fetchAssets().catch((error) => console.log(error));

    // Realtime
    const mySubscription = supabase
      .from("organisation_transactions")
      .on("*", async () => {
        await fetchAssets().catch((error) => console.log(error));
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, []);

  const [, profile, ] = useOutletContext();

  const fetchAssets = async () => {
    const {
      data: { transactions, applications },
      error,
    } = await supabase.rpc("fetch_organisation_transactions");
    if (error) {
      setLoading(false);
      throw error;
    } else {
      let data = [];
      if (applications) data.push(...applications);
      if (transactions) data.push(...transactions);

      if (!data || data?.length === 0) setNothingShown(true);

      const sorted_data = data.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      );

      setTransactions(sorted_data ?? null);
      setAllTransactions(sorted_data ?? null);
      setLoading(false);
      console.log("Organisation Transactions: ", data);
    }
  };

  const [transactions, setTransactions] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [date, setDate] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState(null);
  const [show, setShow] = useState(false);
  const [account, setAccount] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);
  const [nothingShown, setNothingShown] = useState(false);
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [transactionModal, setTransactionModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage, setTransactionsPerPage] = useState(10);
  const indexOfLastPage = currentPage * transactionsPerPage;
  const indexOfFirstPage = indexOfLastPage - transactionsPerPage;

  useEffect(() => {
    let data = allTransactions;
    data =
      data && data?.length > 0
        ? data.filter(
            (deposit) => !date || deposit.created_at.substring(0, 10) === date
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(startDate).isSameOrBefore(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(endDate).isSameOrAfter(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(loan?.created_at?.substring(0, 10)).isBetween(
                moment(startDate),
                moment(endDate),
                undefined,
                "[]"
              );
            })
          : null;
    }

    data =
      !data ||
      data
        .filter((deposit) =>
          !status || status === ""
            ? deposit
            : status === "approved"
            ? deposit?.transaction_meta
            : deposit?.application_meta?.review_status === status
        )
        .filter(
          (deposit) =>
            !account ||
            (deposit?.transaction_meta
              ? deposit?.transaction_meta?.account_type === account
              : deposit?.application_meta?.account_type === account)
        );

    data = data && data?.length > 0 && 
      data.filter((transaction) => !type || transaction._type === type);

    console.log(data);

    setTransactions(data ?? []);
  }, [
    date,
    type,
    startDate,
    endDate,
    account,
    status,
  ]);

  const filteredTransactions =
    transactions && transactions?.length > 0
      ? transactions.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  return (
    <div className="mx-5 my-2 md:h-[calc(100vh-120px)]">
      <Helmet>
        <title>Transactions - Bweyogere tuberebumu</title>
      </Helmet>
      <ToastContainer />
      <div className="flex flex-col pb-5 md:h-[210px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          transactions
        </h1>
        <div className="flex flex-wrap gap-2 justify-between overflow-hidden px-1">
          <div className="flex flex-wrap gap-2 justify-between">
            <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
              <select
                name="status"
                id=""
                className="py-2 px-4 bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded-l"
                onChange={(event) => setStatus(event.target.value)}
                value={status}
              >
                <option value="">Select Status</option>
                <option value="approved">Approved</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
                <option value="forwarded">Forwarded</option>
              </select>
              <button
                className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
                onClick={() => {
                  if (status) setStatus("");
                }}
              >
                <AiOutlineCloseCircle size={20} />
              </button>
            </div>
            <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
              <select
                name="account"
                id=""
                className="py-2 px-4 bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded-l"
                onChange={(event) => setAccount(event.target.value)}
                value={account}
              >
                <option value="">Select Account</option>
                <option value="sacco">Sacco</option>
                <option value="reserve">Reserve</option>
              </select>
              <button
                className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
                onClick={() => {
                  if (account) setAccount("");
                }}
              >
                <AiOutlineCloseCircle size={20} />
              </button>
            </div>
            <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
              <input
                type="date"
                name=""
                onChange={(event) => setDate(event.target.value)}
                id=""
                className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
                value={date}
              />
              <button
                className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
                onClick={() => {
                  if (date) setDate("");
                }}
              >
                <AiOutlineCloseCircle size={20} />
              </button>
            </div>
            <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
              <select
                name="type"
                id=""
                className="py-2 px-4 bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded-l"
                onChange={(event) => setType(event.target.value)}
                value={type}
              >
                <option value="">Select Type</option>
                <option value="withdraw">Withdraw</option>
                <option value="deposit">Deposit</option>
              </select>
              <button
                className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
                onClick={() => {
                  if (type) setType("");
                }}
              >
                <AiOutlineCloseCircle size={20} />
              </button>
            </div>
            <DateRangeFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          {/* <div className="flex justify-between text-sm"> */}
          <button
            onClick={(status) => {
              if (status) setStatus("");
              if (date) setDate("");
              if (account) setAccount("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              if (startDate) setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              if (endDate) setEndDate("");
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {largeDesktop && !smallDesktop && "Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
            
          </div>
          <ErrorBoundary>
            {profile?.roles && !profile?.roles.includes("super_admin") && (
              <button
                className=" px-4 bg-primary py-2 text-white rounded-md flex justify-center items-center"
                onClick={() => {
                  navigate("/organisation/add-transaction", {
                    state: { from: location.pathname },
                  });
                }}
              >
                Add Transaction
              </button>
            )}
          </ErrorBoundary>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-170px)] dark:bg-dark-bg-700">
        <ErrorBoundary>
          {loading ? (
            <Spinner />
          ) : filteredTransactions && filteredTransactions?.length > 0 ? (
            <>
              <div className="w-full pb-3 overflow-x-auto h-full  relative overflow-y-auto ">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                    <tr>
                      <th></th>
                      <th className="pr-6 py-4">Date</th>
                      <th className="px-6 py-4">Transaction ID</th>
                      <th className="px-6 py-4">Account</th>
                      <th className="px-6 py-4">Amount(UGX)</th>
                      <th className="px-6 py-4">Type</th>
                      <th className="px-6 py-4">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTransactions.map((transaction, index) => (
                      <Fragment key={index}>
                        <tr
                          className={`${
                            index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                          } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                          key={index}
                          onClick={() => {
                            setTransactionModal(true);
                            setActiveIndex(index);
                          }}
                        >
                          <td>
                            <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                          </td>
                          <td className="pr-6 py-3">
                            {moment(transaction.created_at).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td className="px-6 py-3">
                            {transaction?.trans_id || transaction?.app_id}
                          </td>
                          <td className="px-6 py-3 capitalize">
                            {transaction?.transaction_meta?.account_type ||
                              transaction?.application_meta?.account_type}
                          </td>
                          <td className="px-6 py-3">
                            {currencyFormatter(
                              transaction?.amount ||
                                transaction?.application_meta?.amount
                            )}
                          </td>
                          <td className="px-6 py-3 capitalize">
                            {transaction?._type}
                          </td>
                          <td className="px-6 py-3">
                            <span
                              className={` py-1 px-2 rounded-xl text-white inline-block ${
                                transaction?.application_meta?.review_status ===
                                "pending"
                                  ? "bg-yellow-400"
                                  : transaction?.application_meta
                                      ?.review_status === "rejected"
                                  ? "bg-red-400"
                                  : transaction?.transaction_meta
                                  ? "bg-green-400"
                                  : transaction?.application_meta
                                      ?.review_status === "forwarded"
                                  ? "bg-blue-400"
                                  : ""
                              }`}
                            >
                              {transaction?.application_meta?.review_status === "pending" ? "pending approval" : transaction?.application_meta?.review_status ||
                                "approved"}
                            </span>
                          </td>
                        </tr>
                        {transactionModal && index === activeIndex && (
                          <TransactionModal
                            transaction={transaction}
                            setTransactionModal={setTransactionModal}
                          />
                        )}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
                <Pagination
                  pages={Math.ceil(transactions.length / transactionsPerPage)}
                  setCurrentPage={setCurrentPage}
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPage={indexOfLastPage}
                  data={transactions}
                  depositsPerPage={transactionsPerPage}
                  setDepositsPerPage={setTransactionsPerPage}
                />
              </div>
            </>
          ) : nothingShown ? (
            <NothingShown />
          ) : (
            allTransactions.length > 0 &&
            (filteredTransactions?.length === 0 || !filteredTransactions) && (
              <Frown />
            )
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
}
