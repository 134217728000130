import { supabase } from "../../helpers/supabase";
import { useState, useEffect } from "react";
import Pagination from "../../components/Pagination";
import { Spinner } from "../../components";
import { NothingShown } from "../../components";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import WithdrawModal from "../../components/Modals/WithdrawModal2";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import { MdDownload } from "react-icons/md";
import { TbFilterOff } from "react-icons/tb";
import DateRangeFilter from "../../components/DateRangeFilter";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "../../hooks";
import Frown from "../../components/Frown";

export default function WithdrawMembers() {
  const [status, setStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState("");
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [withdraws, setWithdraws] = useState([]);
  const [allWithdraws, setAllWithdraws] = useState([]);
  const [nothingShown, setNothingShown] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [account, setAccount] = useState("");

  useEffect(() => {
    let data = allWithdraws;
    data =
      data && data?.length > 0
        ? data.filter(
            (deposit) => !date || deposit.created_at.substring(0, 10) === date
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(startDate).isSameOrBefore(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(endDate).isSameOrAfter(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(loan?.created_at?.substring(0, 10)).isBetween(
                moment(startDate),
                moment(endDate),
                undefined,
                "[]"
              );
            })
          : null;
    }

    data =
      !data ||
      data
        .filter((deposit) =>
          !status || status === ""
            ? deposit
            : status === "approved"
            ? deposit?.transaction_meta
            : deposit?.application_meta?.review_status === status
        )
        .filter(
          (deposit) =>
            !account ||
            (deposit?.transaction_meta
              ? deposit?.transaction_meta?.account_type === account
              : deposit?.application_meta?.account_type === account)
        );

    data =
      data && data?.length > 0
        ? data.filter(
            (deposit) =>
              !searchText ||
              deposit?.application_meta?.applicants_name
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1 ||
              deposit?.transaction_meta?.member_name
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
          )
        : null;

    setWithdraws(data ?? []);
  }, [date, startDate, endDate, account, status, searchText]);

  useEffect(() => {
    getApplications().catch((error) => console.log(error));

    const mySubscription = supabase
      .from("applications")
      .on("*", async (payload) => {
        await getApplications().catch((error) => console.log(error));
      })
      .subscribe();

    supabase
      .from("transactions")
      .on("*", async (payload) => {
        await getApplications().catch((error) => console.log(error));
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, []);

  const getApplications = async () => {
    const {
      data: { transactions, applications },
      error,
    } = await supabase.rpc("fetch_member_withdraws");
    if (error) {
      setLoading(false);
      throw error;
    } else {
      let data = [];
      if (applications) data.push(...applications);
      if (transactions) data.push(...transactions);

      console.log("Number of withdraw transactions: ", data.length);

      const sorted_data =
        data && data?.length > 0
          ? data.sort(
              (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
            )
          : null;

      setNothingShown(() => !sorted_data || sorted_data?.length === 0);
      setAllWithdraws(sorted_data);
      setWithdraws(sorted_data);
      setLoading(false);
    }
  };

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawPerPage, setWithdrawPerPage] = useState(10);
  const indexOfLastPage = currentPage * withdrawPerPage;
  const indexOfFirstPage = indexOfLastPage - withdrawPerPage;

  const approvedwithdraws = allWithdraws.filter(
    (deposit) => deposit?.transaction_meta
  );
  const pendingwithdraws = allWithdraws.filter(
    (deposit) => deposit?.application_meta?.review_status === "pending"
  );
  const rejectedwithdraws = allWithdraws.filter(
    (deposit) => deposit?.application_meta?.review_status === "rejected"
  );
  const forwardedwithdraws = allWithdraws.filter(
    (withdraw) => withdraw?.application_meta?.review_status === "forwarded"
  );

  const approved =
    approvedwithdraws && approvedwithdraws.length > 0
      ? (approvedwithdraws.length / allWithdraws.length) * 100
      : 0;
  const pending =
    pendingwithdraws && pendingwithdraws.length > 0
      ? (pendingwithdraws.length / allWithdraws.length) * 100
      : 0;
  const rejected =
    rejectedwithdraws && rejectedwithdraws.length > 0
      ? (rejectedwithdraws.length / allWithdraws.length) * 100
      : 0;

  const forwarded =
    forwardedwithdraws && forwardedwithdraws.length > 0
      ? (forwardedwithdraws.length / allWithdraws.length) * 100
      : 0;

  const [activeIndex, setActiveIndex] = useState(false);

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const generate_withdraw_report = () => {
    const formattedDeposits = allWithdraws.map((deposit) => {
      return {
        "Member Name": deposit?.transaction_meta
          ? deposit?.transaction_meta?.member_name
          : deposit?.application_meta?.applicants_name,
        "Transaction ID": deposit?.application_meta
          ? deposit.app_id
          : deposit?.trans_id,
        Date: moment(deposit?.created_at).format("DD-MM-YYYY"),
        Amount: deposit?.transaction_meta
          ? deposit?.amount
          : deposit?.application_meta?.amount,
        Account: deposit?.transaction_meta
          ? deposit?.transaction_meta?.account_type
          : deposit?.application_meta?.account_type,
        "Approved At": deposit?.transaction_meta
          ? moment(deposit?.transaction_meta?.approved_at).format("DD-MM-YYYY")
          : "",
        Status: deposit?.transaction_meta
          ? "approved"
          : deposit?.application_meta?.review_status,
        "Approved By": deposit?.transaction_meta
          ? deposit?.transaction_meta?.approved_by
          : "",
      };
    });

    generateReportFromJson(
      formattedDeposits,
      `Bweyogerere Tuberebumu Member Withdraws ${new Date()
        .toISOString()
        .substring(0, 10)}`
    );
  };

  let filteredWithdraws =
    withdraws && withdraws?.length > 0
      ? withdraws.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  return (
    <div className="flex-grow mx-5 my-2 h-[calc(100vh-140px)]">
      <div className="flex flex-col justify-between pb-3 md:h-[110px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Members Withdraw
        </h1>
        <div className=" dark:text-secondary-text rounded">
          <div className="w-full h-7 rounded flex overflow-hidden">
            {allWithdraws.length === 0 && (
              <div
                className="animate-pulse h-7 inline-block bg-gray-300"
                style={{ width: `100%` }}
              ></div>
            )}
            <div
              className="h-7 inline-block bg-green-400 transition duration-300"
              style={{ width: `${approved}%` }}
            ></div>
            <div
              className="h-7 inline-block bg-yellow-400"
              style={{ width: `${pending}%` }}
            ></div>
            <div
              className="h-7 inline-block bg-red-400"
              style={{ width: `${rejected}%` }}
            ></div>
            <div
              className="h-7 inline-block bg-blue-400"
              style={{ width: `${forwarded}%` }}
            ></div>
          </div>
          <div className={`flex justify-between px-2 items-center py-2`}>
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-green-400 inline-block rounded-full"></div>
              <span className={`${status === "approved" && "font-bold"}`}>
                Approved: {approvedwithdraws.length} ({Math.round(approved)}%)
              </span>
            </div>
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-yellow-400 inline-block rounded-full"></div>
              <span className={`${status === "pending" && "font-bold"}`}>
                Pending: {pendingwithdraws.length} ({Math.round(pending)}%)
              </span>
            </div>
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-red-400 inline-block rounded-full"></div>
              <span className={`${status === "rejected" && "font-bold"}`}>
                Reject: {rejectedwithdraws.length} ({Math.round(rejected)}%)
              </span>
            </div>
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-blue-400 inline-block rounded-full"></div>
              <span className={`${status === "forwarded" && "font-bold"}`}>
                Forwarded: {forwarded.length} ({Math.round(forwarded)}%)
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap my-3 justify-between gap-5">
        <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
          <input
            type="text"
            name="name"
            onChange={(event) => setSearchText(event.target.value)}
            id="name"
            className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
            value={searchText}
            placeholder="Search by name..."
          />
          <button
            className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
            onClick={() => {
              if (searchText) setSearchText("");
            }}
          >
            <AiOutlineCloseCircle size={20} />
          </button>
        </div>
        <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
          <select
            name="status"
            id=""
            className="py-2 px-2 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
            onChange={(event) => {
              setStatus(event.target.value);
            }}
            value={status}
          >
            <option value="">Status</option>
            <option value="approved">Approved</option>
            <option value="pending">Pending</option>
            <option value="rejected">Rejected</option>
            <option value="forwarded">Fowarded</option>
          </select>
          <button
            className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
            onClick={() => {
              if (status) setStatus("");
            }}
          >
            <AiOutlineCloseCircle size={20} />
          </button>
        </div>
        <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
          <select
            name="account"
            id=""
            className="py-2 px-4 bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded-l"
            onChange={(event) => setAccount(event.target.value)}
            value={account}
          >
            <option value="">Account</option>
            <option value="savings">Savings</option>
            <option value="fixed">Fixed</option>
            <option value="mwana">Mwana</option>
            <option value="development">Development</option>
          </select>
          <button
            className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
            onClick={() => {
              if (account) setAccount("");
            }}
          >
            <AiOutlineCloseCircle size={20} />
          </button>
        </div>

        <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
          <input
            type="date"
            name=""
            onChange={(event) => setDate(event.target.value)}
            id=""
            className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
            value={date}
          />
          <button
            className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
            onClick={() => {
              if (date) setDate("");
            }}
          >
            <AiOutlineCloseCircle size={20} />
          </button>
        </div>
        <DateRangeFilter setEndDate={setEndDate} setStartDate={setStartDate} />
        <button
          onClick={(status) => {
            if (status) setStatus("");
            if (date) setDate("");
            if (account) setAccount("");
            document.getElementById("startDate").value = "";
            document.getElementById("startDate").setAttribute("max", "");
            if (startDate) setStartDate("");
            document.getElementById("endDate").value = "";
            document.getElementById("endDate").setAttribute("min", "");
            if (endDate) setEndDate("");
            if (searchText) setSearchText("");
          }}
          className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
        >
          {largeDesktop && !smallDesktop && "Reset Filters"}
          <TbFilterOff className="ml-1" size={20} />
        </button>
        <button
          className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          onClick={() => {
            generate_withdraw_report();
          }}
          disabled={!allWithdraws || allWithdraws?.length === 0}
        >
          {largeDesktop && !smallDesktop && "Export"}
          <MdDownload className="ml-1" />
        </button>
      </div>
      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-170px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : withdraws !== null && filteredWithdraws.length > 0 ? (
          <>
            <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
              <table className="w-full h-6 text-sm text-left text-gray-500 dark:text-gray-400 mb-5">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Member</th>
                    <th className="px-6 py-4">Date</th>
                    <th className="px-6 py-4">Transactions ID</th>
                    <th className="px-6 py-4">Account</th>
                    <th className="px-6 py-4">Amount</th>
                    <th className="px-6 py-4">Cashout Method</th>
                    <th className="px-6 py-4">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredWithdraws.map((withdraw, index) => {
                    return (
                      <>
                        <tr
                          className={`${
                            index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                          } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                          key={index}
                          onClick={() => {
                            setActiveIndex(index);
                            setWithdrawModal(true);
                          }}
                        >
                          <td>
                            <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                          </td>
                          <td className="px-6 py-3">
                            {withdraw?.application_meta?.applicants_name ||
                              withdraw?.transaction_meta?.member_name}
                          </td>
                          <td className="px-6 py-3">
                            {moment(withdraw.created_at).format("DD-MM-YYYY")}
                          </td>
                          <td className="px-6 py-3">
                            {withdraw?.app_id || withdraw?.trans_id}
                          </td>
                          <td className="px-6 py-3">
                            {withdraw.application_meta?.account_type ||
                              withdraw?.transaction_meta?.account_type}
                          </td>
                          <td className="px-6 py-3">
                            {currencyFormatter(
                              withdraw?.application_meta?.amount ||
                                withdraw?.amount
                            )}
                          </td>
                          <td className="px-6 py-3">
                            {withdraw?.application_meta?.cashout_method ||
                              withdraw?.transaction_meta?.cashout_method ||
                              "Unknown"}
                          </td>
                          <td className={`px-6 py-3`}>
                            <span
                              className={` py-1 px-2 rounded-xl text-white inline-block ${
                                withdraw.transaction_meta
                                  ? "bg-green-400"
                                  : withdraw.application_meta?.review_status ===
                                    "rejected"
                                  ? "bg-red-400"
                                  : withdraw?.application_meta
                                      ?.review_status === "forwarded"
                                  ? "bg-blue-400"
                                  : "bg-yellow-400"
                              }`}
                            >
                              {withdraw.transaction_meta
                                ? "Approved"
                                : withdraw.application_meta?.review_status ===
                                  "rejected"
                                ? "Rejected"
                                : withdraw.application_meta?.review_status ===
                                  "forwarded"
                                ? "Forwarded"
                                : "Pending approval"}
                            </span>
                          </td>
                        </tr>
                        {withdrawModal && index === activeIndex && (
                          <WithdrawModal
                            withdraw={withdraw}
                            setWithdrawModal={setWithdrawModal}
                          />
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(withdraws.length / withdrawPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={withdraws}
                depositsPerPage={withdrawPerPage}
                setDepositsPerPage={setWithdrawPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allWithdraws?.length > 0 &&
          (filteredWithdraws?.length === 0 || !filteredWithdraws) && <Frown />
        )}
      </div>
    </div>
  );
}
