import { Formik, Form, useFormikContext, ErrorMessage } from "formik";
import { Submit } from "../../components";
import { useEffect, useState } from "react";
import { supabase } from "../../helpers/supabase";
import { useOutletContext } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { uniqBy } from "lodash";
import { announcementValidationSchema } from "../../helpers/validator";
import { ToastContainer, toast } from "react-toastify";
import { sendSMS } from "../../helpers/sendsms";

const ValidateSpecifiedMembers = ({ recipients }) => {
  const { values, errors, setFieldError } = useFormikContext();

  useEffect(() => {
    if (values.category === "others") {
      if (recipients.length < 1) {
        setFieldError("recipients", "Members required");
      }
    }
  }, [values, errors, recipients]);
};

function AddAsset() {
  const [profiles, setProfiles] = useState([]);
  const [, { fullname, id, position_in_sacco }] = useOutletContext();
  const [show, setShow] = useState(false);
  const [recipients, setSelectedMembers] = useState([]);

  const initialValues = {
    admin_id: id,
    admin_name: fullname,
    admin_position: position_in_sacco,
    category: "",
    recipients: recipients,
    title: "",
    message: "",
    notification_type: "system",
  };

  useEffect(() => {
    getProfiles()
      .then((data) => setProfiles(data))
      .catch((error) => console.log(error));
  }, []);

  const getProfiles = async () => {
    const { data, error } = await supabase.from("_member_profiles").select();

    if (error) throw error;
    return data;
  };

  const clearOptions = () => {
    setProfiles((profiles) => [
      ...uniqBy([...profiles, ...recipients], (obj) => obj.id),
    ]);
    setSelectedMembers([]);
  };

  const clearOption = (member) => {
    setSelectedMembers((recipients) => [
      ...uniqBy(
        recipients.filter((selectedMember) => member?.id !== selectedMember.id),
        (obj) => obj.id
      ),
    ]);
    setProfiles((profiles) => uniqBy([...profiles, member], (obj) => obj.id));
  };

  const selectOption = (option) => {
    setSelectedMembers((recipients) => [
      ...uniqBy([...recipients, option], (obj) => obj.id),
    ]);
    setProfiles((profiles) => [
      ...uniqBy(
        profiles.filter((profile) => option?.id !== profile.id),
        (obj) => obj.id
      ),
    ]);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const notificationType = values.notification_type;
        let formattedRecipients =
          values.category === "members"
            ? profiles
                .filter((profile) => profile?.roles.includes("member"))
                .map(({ id, fullname, position_in_sacco }) => ({
                  id,
                  fullname,
                  position_in_sacco,
                }))
            : values.category === "administrators"
            ? profiles
                .filter((profile) => profile?.roles.includes("admin"))
                .map(({ id, fullname, position_in_sacco }) => ({
                  id,
                  fullname,
                  position_in_sacco,
                }))
            : recipients.map(({ id, fullname, position_in_sacco }) => ({
                id,
                fullname,
                position_in_sacco,
              }));

        const { error } = await supabase.rpc("handle_announcement", {
          details: JSON.stringify({
            ...values,
            recipients: formattedRecipients,
            created_at: new Date()
              .toISOString()
              .toLocaleString("en-GB", { timeZone: "UTC" }),
            updated_at: new Date()
              .toISOString()
              .toLocaleString("en-GB", { timeZone: "UTC" }),
          }),
        });
        if (error) {
          toast.error("Failed to make the announcement", {
            position: "top-center",
          });
        } else {
          toast.success("Successfully created the announcement", {
            position: "top-center",
          });

          if (notificationType === "SMS") {
            for (let recipient of formattedRecipients) {
              await sendSMS(
                recipient.id,
                `${values.message}. Regards Tube Sacco.`
              )
                .then((response) => response.json())
                .then((data) => {
                  console.log(data);
                })
                .catch((error) => console.log(error, "Error"));
            }
          }
        }

        resetForm(initialValues);
        setSubmitting(false);
        console.log("Values: ", values);
      }}
      validationSchema={announcementValidationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => {
        return (
          <div className="mx-5 scroll h-[calc(100vh-140px)]">
            <ToastContainer />
            <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white md:h-[50px]">
              Make Announcement
            </h1>
            <div className="flex bg-white overflow-hidden relative dark:bg-dark-bg-700 dark:text-secondary-text min-h-full md:h-[calc(100%-80px)] w-full justify-start">
              <Form className="flex flex-col h-full overflow-y-scroll overflow-x-hidden p-6 w-full">
                <div className="mb-3">
                  {/* Going to put some radios to select whether to notify the whole group or some individuals  */}
                  <div className="flex flex-col pb-3">
                    <label className="text-sm font-semibold">
                      Announcement Type{" "}
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <div className="flex gap-2">
                      <div className="flex gap-1">
                        <input
                          type="radio"
                          id="system"
                          name="notification_type"
                          value="system"
                          className="w-4 h-4"
                          onChange={handleChange}
                          defaultChecked={
                            initialValues.notification_type === "system"
                          }
                        />
                        <label htmlFor="system" className="text-sm">
                          System
                        </label>
                      </div>
                      <div className="flex gap-1">
                        <input
                          type="radio"
                          id="sms"
                          name="notification_type"
                          value="SMS"
                          className="w-4 h-4"
                          onChange={handleChange}
                          defaultChecked={
                            initialValues.notification_type === "SMS"
                          }
                        />
                        <label htmlFor="notification_type" className="text-sm">
                          SMS
                        </label>
                      </div>
                    </div>
                    <ErrorMessage name="notification_type">
                      {(msg) => (
                        <div className="error text-red-600 text-xs">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="flex flex-col pb-3">
                    <label className="text-sm font-semibold">
                      Category{" "}
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <div className="flex gap-2">
                      <div className="flex gap-1">
                        <input
                          type="radio"
                          id="members"
                          name="category"
                          value="members"
                          className="w-4 h-4"
                          onChange={handleChange}
                        />
                        <label htmlFor="members" className="text-sm">
                          All Members
                        </label>
                      </div>
                      <div className="flex gap-1">
                        <input
                          type="radio"
                          id="administrators"
                          name="category"
                          value="administrators"
                          className="w-4 h-4"
                          onChange={handleChange}
                        />
                        <label htmlFor="administrators" className="text-sm">
                          All Admins
                        </label>
                      </div>
                      <div className="flex gap-1">
                        <input
                          type="radio"
                          id="others"
                          name="category"
                          value="others"
                          className="w-4 h-4"
                          onChange={handleChange}
                        />
                        <label htmlFor="others" className="text-sm">
                          Others
                        </label>
                      </div>
                    </div>
                    <ErrorMessage name="category">
                      {(msg) => (
                        <div className="error text-red-600 text-xs">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>

                  {values.category === "others" && (
                    <div className="flex flex-col w-full gap-2 mb-5">
                      <label className="text-sm mr-5" htmlFor="member">
                        Specify Others
                      </label>
                      <div className="relative w-full flex flex-col w-100 flex-wrap">
                        <div
                          className="ring-1 ring-black rounded px-2 py-1 bg-white dark:bg-dark-bg-600 h-28 flex mx-2 focus:outline-primary focus:outline relative w-full justify-start gap-2 flex-wrap resize-y"
                          tabIndex={0}
                          onClick={() => setShow((show) => !show)}
                          onBlur={() => setShow(false)}
                        >
                          {recipients?.length > 0 &&
                            recipients.map((member, index) => {
                              return (
                                <div
                                  className="outline outline-gray-500 gap-2 h-8 justify-between flex items-center px-1 rounded w-32 relative my-1"
                                  key={index}
                                >
                                  <div className="text-sm overflow-x-hidden whitespace-nowrap">
                                    {member.fullname}
                                  </div>
                                  <div
                                    className="flex justify-center items-center rounded-full w-5 h-5 bg-accent absolute right-2"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      clearOption(member);
                                    }}
                                  >
                                    <IoClose color="gray" />
                                  </div>
                                </div>
                              );
                            })}
                          <div
                            className="flex justify-center items-center rounded-full w-5 h-5 bg-accent absolute right-2"
                            onClick={(event) => {
                              event.stopPropagation();
                              clearOptions();
                            }}
                          >
                            <IoClose color="gray" />
                          </div>
                          {
                            <ul
                              className={`h-60 overflow-y-auto bg-white shadow-sm rounded mt-2 p-5 flex flex-col gap-2 w-full top-[calc(100%+0.05em)] right-0 border absolute ${
                                !show && "hidden"
                              }`}
                            >
                              {profiles?.length > 0 &&
                                profiles.map((member, index) => (
                                  <li
                                    className="cursor-pointer"
                                    key={index}
                                    value={index}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      selectOption(member);
                                      setShow(false);
                                    }}
                                  >
                                    {member.fullname}
                                  </li>
                                ))}
                            </ul>
                          }
                        </div>
                      </div>
                      {touched?.recipients && errors?.recipients && (
                        <div className="error text-red-600 text-xs">
                          {errors?.recipients}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex flex-col gap-5 w-full">
                    {values?.notification_type === "system" && (
                      <div className="flex flex-col w-full mb-3">
                        <label htmlFor="title" className=" text-sm">
                          Title{" "}
                          <span className="text-sm text-red-500 ml-2 font-bold">
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          placeholder="Enter Title"
                          className="ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ml-2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.title}
                        />
                        {touched?.title && errors?.title && (
                          <div className="error text-red-600 text-xs">
                            {errors?.title}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="my-3">
                  <h1 className="font-semibold">
                    Message{" "}
                    <span className="text-sm text-red-500 ml-2 font-bold">
                      *
                    </span>
                  </h1>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    className="outline outline-1 p-2 rounded-md w-full dark:bg-dark-bg-600"
                    value={values?.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  {touched?.message && errors?.message && (
                    <div className="error text-red-600 text-xs">
                      {errors?.message}
                    </div>
                  )}
                </div>
                <div className="w-56">
                  <Submit value="Submit" disabled={isSubmitting} />
                  {/* Debugging */}
                  {/* <button
                    onClick={(event) => {
                      event.preventDefault()

                      console.log(values)
                      console.log(errors)
                    }}
                  >
                    try me

                  </button> */}
                  <ValidateSpecifiedMembers recipients={recipients} />
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default AddAsset;
