import { MdOutlinePersonOutline, MdCalendarViewWeek, MdOutlineAdminPanelSettings, MdPeopleOutline } from 'react-icons/md'
import { IoMdCard } from 'react-icons/io'
import { RiExchangeDollarLine } from 'react-icons/ri'
import { SiHomeassistantcommunitystore } from 'react-icons/si'
import { AiOutlineHome } from 'react-icons/ai'
import { GiOrganigram } from 'react-icons/gi'
import { FcMoneyTransfer } from 'react-icons/fc'
import { FaChild } from 'react-icons/fa'
import { RiVipDiamondFill } from 'react-icons/ri'
export const menuData = {
    member : [
        {
            label: 'Dashboard',
            icon: <AiOutlineHome />,
            link: 'dashboard'
        },
        {
            label: 'Loans',
            icon: <SiHomeassistantcommunitystore />,
            link: 'loans',
            sublinks: [
                {
                    label: 'Request Loan',
                    link: 'loans/request'
                },
                {
                    label: 'Guarantor Requests',
                    link: 'loans/guarantee-requests'
                },
            ]
        },
        {
            label: 'Deposit',
            icon: <RiExchangeDollarLine />,
            link: 'deposit',
            sublinks: [
                {
                    label: 'Make Deposit',
                    link: '/deposit/deposit'
                }
            ]
        },
        {
            label: 'Withdraw',
            icon: <IoMdCard />,
            link: 'withdraw',
            sublinks: [
                {
                    label: 'Request Withdraw',
                    link: 'withdraw/request'
                }
            ]
        },
        {
            label: 'Transfers',
            icon: <FcMoneyTransfer /> ,
            link: 'transfers',
            sublinks: [
                {
                    label: 'Request',
                    link: 'transfers/request'
                }
            ]
        },
        {
            label: 'Accounts',
            icon: <MdCalendarViewWeek />,
            link: 'accounts',
            sublinks: [
                {
                    label: 'Shares',
                    link: 'accounts/shares'
                },
                {
                    label: 'Mwana',
                    link: 'accounts/mwana'
                },
                {
                    label: 'Fixed',
                    link: 'accounts/fixed'
                },
                {
                    label: 'Savings',
                    link: 'accounts/savings'
                }
                // ,
                // {
                //     label: 'Development',
                //     link: 'accounts/development'
                // }
            ]
        },
        {
            label: 'Baana',
            icon: <FaChild />,
            link: 'baana'
        },
        {
            label: 'Asset Verification',
            icon: <RiVipDiamondFill />,
            link: 'assets/verification-requests'
        },
        {
            label: 'Profile',
            icon: <MdOutlinePersonOutline />,
            link: 'profile'
        }
    ],
    admin : [
        {
            label: 'Dashboard',
            icon: <AiOutlineHome />,
            link: 'dashboard'
        },
        {
            label: 'Loans',
            icon: <SiHomeassistantcommunitystore />,
            link: 'loans',
            sublinks: [
                {
                    label: 'Request',
                    link: 'loans/request'
                },
                {
                    label: 'Issued Loans',
                    link: 'loans/members'
                },
                {
                    label: 'Guarantee Requests',
                    link: 'loans/guarantee-requests'
                },
                {
                    label: 'Payment Applications',
                    link: 'loans/applications'
                },
                {
                    label: 'Member Loan Requests',
                    link: 'loans/members-requests'
                }
            ]
        },
        {
            label: 'Deposit',
            icon: <RiExchangeDollarLine />,
            link: 'deposit',
            sublinks: [
                {
                    label: 'Make Deposit',
                    link: '/deposit/deposit'
                },
                {
                    label: 'Member Deposits',
                    link: '/deposit/members'
                }
            ]
        },
        {
            label: 'Withdraw',
            icon: <IoMdCard />,
            link: 'withdraw',
            sublinks: [
                {
                    label: 'Request',
                    link: 'withdraw/request'
                },
                {
                    label: 'Member Withdraws',
                    link: 'withdraw/members'
                }
            ]
        },
        {
            label: 'Transfers',
            icon: <FcMoneyTransfer /> ,
            link: 'transfers',
            sublinks: [
                {
                    label: 'Request',
                    link: 'transfers/request'
                },
                {
                    label: 'Member Transfers',
                    link: 'transfers/members'
                }
            ]
        },
        {
            label: 'Accounts',
            icon: <MdCalendarViewWeek />,
            link: 'accounts',
            sublinks: [
                {
                    label: 'Savings',
                    link: 'accounts/savings'
                },
                {
                    label: 'Shares',
                    link: 'accounts/shares'
                },
                {
                    label: 'Mwana',
                    link: 'accounts/mwana'
                },
                {
                    label: 'Fixed',
                    link: 'accounts/fixed'
                }
                // ,
                // {
                //     label: 'Development',
                //     link: 'accounts/development'
                // }
            ]
        },
        {
            label: 'Baana',
            icon: <FaChild />,
            link: 'baana',
            sublinks: [
                {
                    label: 'Baana Applications',
                    link: 'baana/applications'
                },
                {
                    label: 'Members Baana',
                    link: 'baana/members-baana'
                }
            ]
        },
        {
            label: 'Members',
            icon: <MdPeopleOutline />,
            link: 'members',
            sublinks: [
                {
                    label: 'Applications',
                    link: 'members/applications'
                },
                {
                    label: 'Termination',
                    link:  'members/termination'
                }
            ]
        },
        {
            label: 'Organisation',
            icon: <GiOrganigram />,
            link: 'organisation',
            sublinks: [
                {
                    label: 'Announcements',
                    link: 'organisation/announcements'
                },
                {
                    label: 'Reports',
                    link: 'organisation/reports'
                },
                {
                    label: 'Assets',
                    link: 'organisation/assets'
                },
                {
                    label: 'Transactions',
                    link: 'organisation/transactions'
                },
                {
                    label: 'Service Providers',
                    link: 'organisation/service-providers'
                }
            ]
        },
        {
            label: 'Profile',
            icon: <MdOutlinePersonOutline />,
            link: 'profile'
        }
    ],
    super_admin : [
        {
            label: 'Dashboard',
            icon: <AiOutlineHome />,
            link: 'dashboard'
        },
        {
            label: 'Admins',
            icon: <MdOutlineAdminPanelSettings />,
            link: 'admins'
        },
        {
            label: 'Members',
            icon: <MdPeopleOutline />,
            link: 'members'
        },
        {
            label: 'Loans',
            icon: <SiHomeassistantcommunitystore />,
            link: 'loans/members'
        },
        {
            label: 'Deposit',
            icon: <RiExchangeDollarLine />,
            link: 'deposit/members'
        },
        {
            label: 'Withdraw',
            icon: <IoMdCard />,
            link: 'withdraw/members'
        },
        {
            label: 'Profile',
            icon: <MdOutlinePersonOutline />,
            link: 'profile'
        }
    ],
    under_termination: [
        {
            label: 'Dashboard',
            icon: <AiOutlineHome />,
            link: 'dashboard'
        },
        {
            label: 'Accounts',
            icon: <MdCalendarViewWeek />,
            link: 'accounts',
            sublinks: [
                {
                    label: 'Shares',
                    link: 'accounts/shares'
                },
                {
                    label: 'Mwana',
                    link: 'accounts/mwana'
                },
                {
                    label: 'Fixed',
                    link: 'accounts/fixed'
                },
                {
                    label: 'Savings',
                    link: 'accounts/savings'
                },  
                {
                    label: 'Development',
                    link: 'accounts/development'
                }
            ]
        }
    ]
}