import { useState, useEffect } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { supabase } from "../../helpers/supabase";
import { Spinner, NothingShown, ConfirmModal } from "../../components";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdNoAccounts } from "react-icons/md";
import { MemberModal } from "../../components";
import { toast, ToastContainer } from "react-toastify";
import { HiLockOpen } from "react-icons/hi";
import { sendSMS } from "../../helpers/sendsms";

export default function Members() {
  const [admins, setAdmins] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getMembers();
    document.title = "Admins - Bweyogere tuberebumu";
  }, []);

  const [adminModal, setAdminModal] = useState(false);
  const [demoteModal, setDemoteModal] = useState(false);
  const [suspendModal, setSuspendModal] = useState(false);
  const [unsuspendModal, setUnsuspendModal] = useState(false);
  const [nothingShown, setNothingShown] = useState(false);
  const [loading, setLoading] = useState(true);

  const getMembers = async () => {
    const { error, data } = await supabase.rpc("fetch_admins");

    if (!data || data?.length === 0) setNothingShown(true);
    if (error) console.log(error);
    console.log(data);
    setAdmins(data ?? null);
    setLoading(false);
  };

  const [activeIndex, setActiveIndex] = useState("");

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const unsuspendMember = async (admin) => {
    setUnsuspendModal(false);
    const { data, error } = await supabase
      .from("users")
      .update({ suspended: false })
      .eq("id", admin.id)
      .single();

    if (error) {
      toast.error(`${error.message}`, { position: "top-center" });
    } else {
      sendSMS(
        admin.id,
        `Good news, ${admin.fullname}! Your sacco account has been unsuspended. Please visit https://tube.ablestate.co/ to resume your activities as a member. Thank you.`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.log(error, "Error"));

      setAdmins((admins) =>
        admins.map((admin) => {
          if (admin.id === data.id) {
            return { ...admin, suspended: data.suspended };
          }
          return admin;
        })
      );
      toast.success(`${admin.fullname} has been unsuspended`, {
        position: "top-center",
      });
    }
  };

  const demoteMember = async (admin) => {
    const toastID = toast.loading("Demoting member");
    const { error, data } = await supabase
      .from("new_members")
      .update({ roles: ["member"], position_in_sacco: "member" })
      .eq("id", admin.id)
      .single();

    if (error) {
      toast.update(toastID, {
        render: `Failed to demote ${admin.fullname}.`,
        isLoading: false,
        autoClose: 5000,
        type: "error",
      });
    } else {
      toast.update(toastID, {
        render: `Demoted ${admin.fullname}.`,
        isLoading: false,
        autoClose: 5000,
        type: "success",
      });

      const { error } = await supabase
        .from("notifications")
        .insert({
          created_at: new Date()
            .toISOString()
            .toLocaleString("en-GB", { timeZone: "UTC" }),
          updated_at: new Date()
            .toISOString()
            .toLocaleString("en-GB", { timeZone: "UTC" }),
          message: `Hey ${
            admin.fullname.split(" ")[0]
          }, you have been demoted to a normal sacco member. Thank you for your service on the sacco's executive. It was good working with you.`,
          receiver: admin.id,
          seen: false,
          meta: {
            type: "demotion",
          },
        })
        .single();

      if (error) throw error;

      if (data) {
        sendSMS(
          admin.id,
          `Hello ${admin.fullname}, your status has been changed to a regular sacco member. As a result, you will no longer be performing executive activities. Thank you for your service. If you have any questions, please let us know. Regards, Bweyogerere Tuberebumu Sacco.`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error, "Error"));

        // console.log(data);
        setAdmins((admins) => {
          return admins.filter((admin) => {
            return admin.id !== data.id;
          });
        });
      }
    }

    setDemoteModal(false);
  };

  return (
    <div className="mx-2 md:mx-5 md:mt-2 h-[calc(100vh-70px)] flex flex-col">
      <ToastContainer />
      <div className="flex flex-col justify-between  md:h-[50px]">
        <h1 className="mt-2 font-bold uppercase dark:text-white">
          Administrators
        </h1>
      </div>

      <div className="bg-white md:shadow-md overflow-hidden flex-grow  relative md:h-[calc(100%-50px)] sm:min-h-[calc(100%-50px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : admins && admins.length > 0 ? (
          <>
            <div className="w-full  overflow-x-auto h-full  relative overflow-y-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Admin</th>
                    <th className="px-6 py-4">ID</th>
                    <th className="px-6 py-4">Role</th>
                    <th className="px-6 py-4">Phone Number</th>
                    <th className="px-6 py-4">Status</th>
                    <th className="px-6 py-4">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {admins.map((admin, index) => (
                    <tr
                      className={`${
                        index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                      } hover:bg-gray-100 dark:hover:bg-dark-bg-600`}
                      key={index}
                    >
                      <td
                        onClick={() => {
                          setActiveIndex(index);
                          setAdminModal(true);
                        }}
                      >
                        <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                      </td>
                      {adminModal && activeIndex === index && (
                        <MemberModal
                          member={activeIndex === index && admin}
                          setMemberModal={setAdminModal}
                          memberModal={adminModal}
                        />
                      )}
                      {demoteModal && activeIndex === index && (
                        <ConfirmModal setPopUp={setDemoteModal}>
                          <h1 className="font-bold">Are you sure?</h1>
                          <p>
                            You are demoting {admin.fullname.toUpperCase()}.
                          </p>
                          <div className="flex justify-end gap-3 mt-3">
                            <button
                              className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                              onClick={() => setDemoteModal(false)}
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-accent-red px-3 py-1 outline outline-1  rounded-md text-white"
                              onClick={() => demoteMember(admin)}
                            >
                              Demote
                            </button>
                          </div>
                        </ConfirmModal>
                      )}
                      {unsuspendModal && activeIndex === index && (
                        <ConfirmModal setPopUp={setUnsuspendModal}>
                          <h1 className="font-bold">Are you sure?</h1>
                          <p>
                            You are unsuspending {admin.fullname.toUpperCase()}.
                          </p>
                          <div className="flex justify-end gap-3 mt-3">
                            <button
                              className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                              onClick={() => setUnsuspendModal(false)}
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-green-500 px-3 py-1 outline outline-1  rounded-md text-white"
                              onClick={() => unsuspendMember(admin)}
                            >
                              Unsuspend
                            </button>
                          </div>
                        </ConfirmModal>
                      )}
                      {suspendModal && activeIndex === index && (
                        <ConfirmModal setPopUp={setSuspendModal}>
                          <h1 className="font-bold">Are you sure?</h1>
                          <p>
                            {admin.fullname.toUpperCase()} won't be able to use
                            it until you unsuspend.
                          </p>
                          <div className="flex justify-end gap-3 mt-3">
                            <button
                              className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                              onClick={() => {
                                setSuspendModal(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-accent-red px-3 py-1 outline outline-1  rounded-md text-white"
                              onClick={async () => {
                                setSuspendModal(false);
                                const toastID =
                                  toast.loading("Suspending member");

                                const { data, error } = await supabase
                                  .from("users")
                                  .update({ suspended: true })
                                  .eq("id", admin.id);

                                if (error) {
                                  toast.update(toastID, {
                                    render: `${error.message}`,
                                    isLoading: false,
                                    type: "error",
                                    autoClose: 5000,
                                  });
                                } else {
                                  admins.map((admin) => {
                                    if (admin.id === data.id) {
                                      return {
                                        ...admin,
                                        suspended: data.suspended,
                                      };
                                    }
                                    return admin;
                                  });

                                  sendSMS(
                                    admin.id,
                                    `Hey ${admin.fullname}, we regret to inform you that your account has been temporarily suspended. As a result, you will any sacco activities through the system. If you have any questions, contact the Sacco's executive. Regards, Bweyogerere Tuberebumu Sacco.`
                                  )
                                    .then((response) => response.json())
                                    .then((data) => {
                                      console.log(data);
                                    })
                                    .catch((error) =>
                                      console.log(error, "Error")
                                    );

                                  toast.update(toastID, {
                                    render: `${admin.fullname} has been suspended`,
                                    type: "success",
                                    isLoading: false,
                                    autoClose: 5000,
                                  });
                                }
                              }}
                            >
                              Suspend
                            </button>
                          </div>
                        </ConfirmModal>
                      )}
                      <td
                        className="px-6 py-3"
                        onClick={() => {
                          setActiveIndex(index);
                          setAdminModal(true);
                        }}
                      >
                        {admin.fullname}
                      </td>
                      <td
                        className="px-6 py-3"
                        onClick={() => {
                          setActiveIndex(index);
                          setAdminModal(true);
                        }}
                      >
                        {admin?.member_id || admin?.id}
                      </td>
                      <td
                        className="px-6 py-3 capitalize"
                        onClick={() => {
                          setActiveIndex(index);
                          setAdminModal(true);
                        }}
                      >
                        {admin.position_in_sacco}
                      </td>
                      <td
                        className="px-6 py-3"
                        onClick={() => {
                          setActiveIndex(index);
                          setAdminModal(true);
                        }}
                      >
                        {admin.phone_number}
                      </td>

                      <td
                        className={`px-6 py-3`}
                        onClick={() => {
                          setActiveIndex(index);
                          setAdminModal(true);
                        }}
                      >
                        <span
                          className={` py-1 px-2 rounded-xl text-white font-bold text-sm ${
                            admin.member_status === "active"
                              ? "bg-emerald-600"
                              : "bg-red-400"
                          }`}
                        >
                          {admin.member_status}
                        </span>
                      </td>

                      <td className="p-2">
                        <div className="relative">
                          <button
                            className="block p-2 rounded-md dialog"
                            onClick={(event) => {
                              setActiveIndex(index);
                              setShow(!show);
                              event.stopPropagation();
                            }}
                          >
                            <FaEllipsisV />
                          </button>
                          <ul
                            className={`absolute right-0 w-48 py-2 mt-2 z-50 bg-white shadow-lg ease-in-out duration-300 dark:bg-dark-bg-700 ${
                              index === activeIndex && show ? "" : "hidden"
                            }`}
                          >
                            {admin?.suspended ? (
                              <li
                                className="flex gap-1 justify-start items-center px-4 py-2 cursor-pointer mb-2 hover:bg-accent dark:hover:bg-dark-bg-600"
                                onClick={() => setUnsuspendModal(true)}
                              >
                                <HiLockOpen /> Unsuspend
                              </li>
                            ) : (
                              <li
                                className="flex gap-1 justify-start items-center px-4 py-2 cursor-pointer mb-2 hover:bg-accent dark:hover:bg-dark-bg-600"
                                onClick={() => setSuspendModal(true)}
                              >
                                <MdNoAccounts /> Suspend
                              </li>
                            )}
                            <li
                              className="flex gap-1 justify-start items-center px-4 py-2 cursor-pointer mb-2 hover:bg-accent dark:hover:bg-dark-bg-600"
                              onClick={() => setDemoteModal(true)}
                            >
                              <RiDeleteBin6Line /> Demote
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
