import React from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { useState, useEffect } from "react";
import moment from "moment";
import { Spinner } from "../../components";

function Announcement() {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchAnnouncement = async () => {
    const { data, error } = await supabase
      .from("announcements")
      .select()
      .eq("id", id)
      .order("created_at", { ascending: false })
      .single();

    if (error) throw error;
    console.log("Data: ", data);
    setAnnouncement(data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    fetchAnnouncement().catch((error) =>
      console.log("Fetching error: ", error)
    );
  }, [id]);

  return (
    <div className="bg-white dark:bg-dark-bg dark:text-secondary-text p-10 shadow-md flex flex-col h-[calc(100vh-70px)]">
      {/* {children} */}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
            <p className="col-span-2">Date</p>
            <p className="font-bold col-span-3 capitalize">
              : {moment(announcement?.created_at).format("DD-MM-YYYY")}
            </p>
          </div>

          <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
            <p className="col-span-2">Category</p>
            <p className="font-bold col-span-3 capitalize">
              : {announcement?.category}
            </p>
          </div>
          <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
            <p className="col-span-2">Title</p>
            <p className="font-bold col-span-3 capitalize">
              : {announcement?._title}
            </p>
          </div>
          <div className="w-full flex h-40 mb-2 justify-start flex-col">
            <span className="font-bold mb-2">Message</span>
            <span className="w-full">{announcement?.message}</span>
          </div>
          <div className="font-bold mb-2 h-10">Recipients</div>
          <div className="flex w-full mb-2 justify-start flex-col overflow-y-scroll flex-grow">
            <table className="w-full">
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Role</td>
                </tr>
              </thead>
              <tbody>
                {announcement?.recipients &&
                  announcement.recipients?.length > 0 &&
                  announcement.recipients.map((recipient) => {
                    return (
                      <tr>
                        <td>{recipient.fullname}</td>
                        <td>{recipient.position_in_sacco}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default Announcement;
