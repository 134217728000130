import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { supabase } from "../../helpers/supabase";
import { useOutletContext } from "react-router-dom";
import moment from "moment/moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { Spinner } from "../../components";

const Asset = () => {
  let { id } = useParams();
  const [imageURL, setImageURL] = useState("");
  const [imageLoad, setImageLoad] = useState(false);
  const [user, { id: userID }] = useOutletContext();
  const [asset, setAsset] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAsset().catch((error) => console.log(error));

    // Realtime
    const mySubscription = supabase
      .from("assets")
      .on("*", async (payload) => {
        console.log(payload);
        await fetchAsset().catch((error) => console.log(error));
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, [id, !imageURL]);

  const fetchAsset = async () => {
    const { data, error } = await supabase
      .from("assets")
      .select()
      .match({ id: id })
      .single();

    if (error) throw error;
    setAsset(data);

    const { data: file, error: evidenceError } = await supabase.storage
      .from("assets")
      .download(data.evidence.substring(7));

    if (evidenceError) throw error;
    const url = URL.createObjectURL(file);
    setImageURL(url);
  };

  return (
    <>
      <h1 className={`text-left font-bold uppercase my-5 mx-5`}>ASSETS</h1>
      <div
        className={`bg-white dark:bg-dark-bg dark:text-secondary-text p-10 shadow-md flex flex-col items-center overflow-y-scroll mx-5 h-[calc(100vh-100px)]`}
      >
        {!loading ? (
          <Spinner />
        ) : (
          <>
            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full ">
              <p className="col-span-2">Name</p>
              <p className={`font-bold col-span-3 capitalize `}>
                : {asset?.name}
              </p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Type</p>
              <p className="font-bold col-span-3 capitalize">: {asset?.type}</p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Valuation</p>
              <p className="font-bold col-span-3 capitalize">
                : UGX {currencyFormatter(asset?.valuation)}
              </p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Date Acquired</p>
              <p className="font-bold col-span-3">
                : {moment(asset?.date_acquired).format("DD-MM-YYYY HH:mm:ss")}
              </p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 w-full">
              <p className="col-span-2">Status</p>
              <p className="font-bold col-span-3">
                {": "}
                <span
                  className={` py-1 px-2 rounded-xl text-white ${
                    asset?.status === "approved"
                      ? "bg-emerald-400"
                      : asset?.status === "pending"
                      ? "bg-yellow-400"
                      : "bg-rose-600"
                  }`}
                >
                  {asset?.status === "pending"
                    ? "pending aproval"
                    : asset.status}
                </span>
              </p>
            </div>

            {imageURL && (
              <div className="grid grid-cols-5 gap-2 justify-start w-full items-center">
                <p className="col-span-2">Supporting Documents </p>
                <div className="flex min-w-fit col-span-3 items-center">
                  <span className="font-bold">:</span>
                  <a
                    className="px-2 text-sky-400 underline mt-2"
                    href={imageURL}
                    title="Files"
                    target="_blank"
                  >
                    Preview file
                  </a>
                  <a
                    className="px-2 text-sky-400 underline mt-2"
                    href={imageURL}
                    title="Files"
                    download={true}
                  >
                    Download file
                  </a>
                </div>
              </div>
            )}
            <p className="w-full font-bold">Description </p>
            <p className="text-left w-full">{asset?.description}</p>
            <p className="text-left w-full mt-5 font-bold">Witnesses</p>
            {asset?.witnesses &&
              asset.witnesses.map((witness, index) => {
                if (index === 0)
                  return (
                    <>
                      <div className="grid grid-cols-5 gap2 mb-2 justify-start w-full">
                        <p className="col-span-2">Name</p>
                        <p className="col-span-3">Contact</p>
                        {/* <p className="cols-span-1">Confirmation</p> */}
                      </div>
                      <div className="grid grid-cols-5 gap2 mb-2 justify-start w-full">
                        <p className="col-span-2">
                          {`${index + 1}. `}
                          {witness.name}
                        </p>
                        <p className="col-span-3">{witness.contact}</p>
                      </div>
                    </>
                  );
                return (
                  <div className="grid grid-cols-5 gap2 mb-2 justify-start w-full">
                    <p className="col-span-2">
                      {`${index + 1}. `}
                      {witness.name}
                    </p>
                    <p className="col-span-3">{witness.contact}</p>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </>
  );
};

export default Asset;
