import { useParams } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { useState, useEffect } from "react";
import { Spinner } from "../../components";
import { toast, ToastContainer } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { NothingShown } from "../../components";
import { sendSMS } from "../../helpers/sendsms";
import { add_separator } from "../../helpers/thousand_separator";

export default function DepositVerify() {
  const { id } = useParams();
  const [user, profile, , roles] = useOutletContext();
  const [deposit, setDeposit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const role = roles
    ? roles.find((role) => role !== "member" && role !== "admin")
    : null;

  if (role) console.log("Role: ", role);
  console.log("Deposit: ", deposit);

  useEffect(() => {
    getApplication()
      .then(async (data) => {
        if (data) {
          console.log("here: ", data);
          setDeposit(data);
          // Downloading the image.
          if (!imageURL) {
            const { data: file, error } = await supabase.storage
              .from("deposits")
              .download(
                await data.application_meta.files[0].file_url.substring(9)
              );

            if (error) throw error;
            const url = URL.createObjectURL(file);
            setImageURL(url);
          }
        }
      })

      .catch((error) => console.log(error));
  }, []);

  const [imageURL, setImageURL] = useState("");

  const getApplication = async () => {
    const { error, data } = await supabase.rpc("fetch_deposit_applications");
    if (error) throw error;
    console.log(data);
    if (data) {
      const [deposit_application] = data.filter(
        (deposit_application) => deposit_application.application_id === id
      );
      return deposit_application;
    }
  };

  const approveDepositTransaction = async () => {
    setIsSubmitting(true);
    const toastID = toast.loading("Processing transaction approval");
    try {
      const { data, error } = await supabase.rpc(
        "approve_deposit_application",
        {
          application: id,
        }
      );

      if (error) {
        throw error;
      } else if (data) {
        console.log("Result: ", data);
        setIsSubmitting(false);
        // handle the alerts and navigation

        sendSMS(
          deposit.application_meta.member.id,
          `Hello ${
            deposit.application_meta.member.member_name
          },\nYour deposit application of UGX ${add_separator(
            deposit.application_meta.amount
          )} on your ${deposit.application_meta.account_type} account ${
            deposit?.application_meta?.designated_for === "other"
              ? `which was requested by the sacco's treasurer`
              : ""
          } has been approved by the chairperson. For more details visit https://tube.ablestate.co/deposits\nRegards, Bweyogerere Tuberebumu`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error, "Error"));

        toast.update(toastID, {
          render: "Deposit transaction approved",
          isLoading: false,
          autoClose: 5000,
          type: "success",
        });
        setDeposit((deposit) => ({ ...deposit, ...data }));
      }
    } catch (error) {
      console.log("Error: ", error)
      setIsSubmitting(false);
      toast.update(toastID, {
        render: `${error?.message}`,
        isLoading: false,
        autoClose: 5000,
        type: "error",
      });
    }
  };

  const rejectDepositTransaction = async () => {
    setIsSubmitting(true);
    const toastID = toast.loading("Processing transaction rejection");
    try {
      const { data, error } = await supabase.rpc("reject_deposit_application", {
        details: JSON.stringify({
          ...deposit.application_meta,
          admin_name: profile.fullname,
          admin_id: profile.id,
          admin_position: profile.position_in_sacco,
          application: deposit.application_id,
          created_at: new Date()
            .toISOString()
            .toLocaleString("en-GB", { timeZone: "UTC" }),
          updated_at: new Date()
            .toISOString()
            .toLocaleString("en-GB", { timeZone: "UTC" }),
          _type: deposit._type,
        }),
      });
      if (error) {
        throw error;
      } else {
        setIsSubmitting(false);

        sendSMS(
          deposit.application_meta.member.id,
          `Hey ${
            deposit.application_meta.member.member_name
          }, we regret to inform you that your deposit application of amount UGX ${add_separator(
            deposit.application_meta.amount
          )} on your ${deposit.application_meta.account_type} account ${
            deposit?.application_meta?.designated_for === "other"
              ? `which was requested by the sacco's treasurer`
              : ""
          } has been rejected by the sacco's chairperson.\n For more details visit https://tube.ablestate.co/deposit Regards, Bweyogerere Tuberebumu Sacco.`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error, "Error"));
        toast.update(toastID, {
          render: `Transaction has been rejected`,
          type: "error",
          autoClose: 5000,
          isLoading: false,
        });
        // handle the alerts and navigation
        setDeposit((deposit) => ({ ...deposit, ...data }));
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <ToastContainer position="top-center" />
      <div className="flex flex-col justify-between pb-3 h-[60px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Verify Deposit
        </h1>
      </div>
      <div className="bg-white p-3 md:overflow-y-auto  relative  md:h-[calc(100%-80px)] dark:bg-dark-bg-700 dark:text-secondary-text">
        {deposit ? (
          <div className="flex flex-grow flex-col min-h-full">
            <div className="mb-3">
              <h1 className="font-semibold mb-3">
                {deposit.application_meta &&
                  deposit.application_meta.applicants_name}
                's Deposit Details
                <span
                  className={` py-1 px-2 rounded-lg text-white text-xs ml-1 inline-block capitalize ${
                    deposit?.transaction_meta
                      ? "bg-green-400"
                      : deposit?.application_meta?.review_status === "rejected"
                      ? "bg-red-400"
                      : deposit?.application_meta?.review_status === "approved"
                      ? "bg-green-400"
                      : deposit?.application_meta?.review_status === "forwarded"
                      ? "bg-blue-400"
                      : "bg-yellow-400"
                  }`}
                >
                  {deposit?.application_meta?.review_status === "pending"
                    ? "pending approval"
                    : deposit?.transaction_meta
                    ? "approved "
                    : deposit?.application_meta?.review_status}
                </span>
              </h1>
              <div className="outline outline-1 outline-gray-100 dark:outline-secondary-text p-3">
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Application ID</p>
                  <p className="font-bold col-span-3">: {deposit.app_id}</p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Created At</p>
                  <p className="font-bold col-span-3">
                    : {moment(deposit.created_at).format("DD-MM-YYYY hh:mm a")}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Amount</p>
                  <p className="font-bold col-span-3">
                    : UGX{" "}
                    {currencyFormatter(
                      deposit.application_meta &&
                        deposit.application_meta.amount
                    )}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Designated For</p>
                  <p className="font-bold col-span-3 capitalize">
                    :{" "}
                    {deposit?.application_meta?.designated_for === "own"
                      ? "self"
                      : "other member"}
                  </p>
                </div>
                {deposit.application_meta.designated_for === "other" && (
                  <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                    <p className="col-span-2">Members Name</p>
                    <p className="font-bold col-span-3">
                      : {deposit?.application_meta?.member?.member_name}
                    </p>
                  </div>
                )}
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Account</p>
                  <p className="font-bold col-span-3">
                    :{" "}
                    {deposit.application_meta &&
                      deposit.application_meta.account_type}
                  </p>
                </div>
                {deposit?.application_meta?.mwana && (
                  <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                    <p className="col-span-2">Mwana</p>
                    <p className="font-bold col-span-3">
                      :{" "}
                      {deposit.application_meta &&
                        deposit?.application_meta.mwana.mwana_name}
                    </p>
                  </div>
                )}
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Comments</p>
                  <p className="font-bold col-span-3">
                    :{" "}
                    {(deposit.application_meta &&
                      deposit?.application_meta.comments) ||
                      "Not Specified"}
                  </p>
                </div>

                {imageURL && (
                  <div className="grid grid-cols-5 gap-2 justify-start w-full items-center">
                    <p className="col-span-2">Proof of payment</p>
                    <div className="flex min-w-fit col-span-3 items-center">
                      <span className="font-bold">:</span>
                      <a
                        className="px-2 text-sky-400 underline mt-2"
                        href={imageURL}
                        title="Proof of deposit"
                        target="_blank"
                      >
                        Preview file
                      </a>
                      <a
                        className="px-2 text-sky-400 underline mt-2"
                        href={imageURL}
                        title="Files"
                        download={`Proof of deposit Tube Sacco`}
                      >
                        Download file
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {deposit?.application_meta.applicants_id !== profile.id &&
              ((role === "secretary" || role === "asst_secretary") &&
              deposit?.application_meta.review_status === "pending" ? (
                <div className="flex gap-10 justify-end items-center mt-3">
                  <button
                    type="submit"
                    className="bg-blue-500 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                    onClick={async () => {
                      const toastID = toast.loading("Forwarding deposit");

                      try {
                        const { data, error } = await supabase
                          .from("applications")
                          .update({
                            application_meta: {
                              ...deposit.application_meta,
                              ...{
                                admin_1: {
                                  admin_id: `${profile.id}`,
                                  admin_name: `${profile.fullname}`,
                                  position_in_sacco: `${profile.position_in_sacco}`,
                                  review_status: "forwarded",
                                  reviewed: true,
                                  reviewed_at: `${new Date().toISOString()}`,
                                  seen: true,
                                },
                              },
                              review_status: "forwarded",
                            },
                          })
                          .eq("application_id", deposit.application_id)
                          .single();

                        if (error) throw error;
                        console.log("Data: ", data);

                        if (data) {
                          sendSMS(
                            deposit.application_meta.member.id,
                            `Hey ${
                              deposit.application_meta.member.member_name
                            }, your application of amount UGX ${add_separator(
                              deposit.application_meta.amount
                            )} on your ${
                              deposit.application_meta.account_type
                            } account ${
                              deposit?.application_meta?.designated_for ===
                              "other"
                                ? `which was requested by the sacco's treasurer`
                                : ""
                            } has been forwarded to the chairperson for review.\n For more details visit https://tube.ablestate.co/deposit\nRegards,\nTube Sacco.`
                          )
                            .then((response) => response.json())
                            .then((data) => {
                              console.log(data);
                            })
                            .catch((error) => console.log(error, "Error"));

                          toast.update(toastID, {
                            render: "Deposit successfully forwarded",
                            type: "success",
                            isLoading: false,
                            autoClose: 5000,
                          });

                          setDeposit(() => data);
                        }
                      } catch (error) {
                        console.log("Error: ", error);

                        toast.update(toastID, {
                          render: "Failure. Please try again later.",
                          type: "error",
                          isLoading: false,
                          autoClose: 5000,
                        });
                      }
                    }}
                    disabled={isSubmitting}
                  >
                    Forward
                  </button>
                </div>
              ) : (
                (role === "chairperson" || role === "vice_chairperson") &&
                deposit?.application_meta.review_status === "forwarded" && (
                  <div className="flex gap-10 justify-end items-center mt-3">
                    <button
                      type="submit"
                      className="bg-accent-red inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                      onClick={rejectDepositTransaction}
                      disabled={isSubmitting}
                    >
                      Reject
                    </button>
                    <button
                      type="submit"
                      className="bg-green-600 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                      onClick={approveDepositTransaction}
                      disabled={isSubmitting}
                    >
                      Approve
                    </button>
                  </div>
                )
              ))}
          </div>
        ) : loading ? (
          <Spinner />
        ) : (
          <NothingShown />
        )}
      </div>
    </div>
  );
}
