import { Pagination } from "../../components";
import React from "react";
import { useEffect, useState } from "react";
import { supabase } from "../../helpers/supabase";
import { LoanModal } from "../../components";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { Spinner, NothingShown } from "../../components";
import { MdDownload } from "react-icons/md";
import { TbFilterOff } from "react-icons/tb";
import DateRangeFilter from "../../components/DateRangeFilter";
import Frown from "../../components/Frown";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "../../hooks";
import { useOutletContext } from "react-router-dom";

export default function Loan() {
  const [ ,profile ] = useOutletContext()

  useEffect(() => {
    document.title = "Loans - Bweyogere tuberebumu";
    fetch_loans().catch((error) => console.log(error));

    const mySubscription = supabase
      .from("loans")
      .on("*", async (payload) => {
        await fetch_loans().catch((error) => console.log(error));
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, []);

  const [loans, setLoans] = useState([]);
  const [loanModal, setLoanModal] = useState(false);
  const [status, setStatus] = useState("");
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allLoans, setAllLoans] = useState([]);
  const [nothingShown, setNothingShown] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [loansPerPage, setLoansPerPage] = useState(10);
  const indexOfLastPage = currentPage * loansPerPage;
  const indexOfFirstPage = indexOfLastPage - loansPerPage;
  let shownloans = loans && loans?.length > 0 ? loans.slice(indexOfFirstPage, indexOfLastPage) : [];

  useEffect(() => {
    let data = allLoans;

    data =
      data && data?.length > 0
        ? data.filter(
            (loan) =>
              !status ||
              loan?.loan?.loan_status === status ||
              loan?.application_meta?.review_status === status
          )
        : null;

    data =
      data && data?.length > 0
        ? data.filter(
            (loan) =>
              !date ||
              loan?.created_at?.substring(0, 10) === date ||
              loan?.loan?.created_at?.substring(0, 10) === date
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              if (loan?.application_meta) {
                return moment(startDate).isSameOrBefore(
                  moment(loan?.created_at?.substring(0, 10))
                );
              } else {
                return moment(startDate).isSameOrBefore(
                  moment(loan?.loan?.created_at?.substring(0, 10))
                );
              }
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              if (loan?.application_meta) {
                return moment(endDate).isSameOrAfter(
                  moment(loan?.created_at?.substring(0, 10))
                );
              } else {
                return moment(endDate).isSameOrAfter(
                  moment(loan?.loan?.created_at?.substring(0, 10)).format("")
                );
              }
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              if (loan?.application_meta) {
                return moment(loan?.created_at?.substring(0, 10)).isBetween(
                  moment(startDate),
                  moment(endDate),
                  undefined,
                  "[]"
                );
              }

              return moment(loan?.loan?.created_at?.substring(0, 10)).isBetween(
                moment(startDate),
                moment(endDate),
                undefined,
                "[]"
              );
            })
          : null;
    }
    setLoans(data ?? []);
  }, [date, status, startDate, endDate]);

  const [activeIndex, setActiveIndex] = useState(null);
  const [show, setShow] = useState(false);
  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const fetch_loans = async () => {
    const { data, error } = await supabase.rpc("fetch_loans");

    const { current_loans, pending_loans } = data;
    if (error) {
      setLoading(false);
      throw error;
    } else {
      let data = [];
      if (current_loans) data.push(...current_loans);
      if (pending_loans) data.push(...pending_loans);

      const sorted_data = data.sort(
        (a, b) =>
          new Date(b?.application_meta ? b?.created_at : b?.loan?.created_at) -
          new Date(a?.application_meta ? a?.created_at : a?.loan?.created_at)
      );

      if (data?.length === 0 || !data) setNothingShown(true);

      setLoans(sorted_data);
      setAllLoans(sorted_data);
      setLoading(false);
    }
  };

  const generate_loan_report = async () => {
    // processLoans
    const formattedLoans = allLoans.map((loan) => {
      return {
        "Member Name": loan?.loan?.loan_meta?.applicants_name
          ? loan.loan.loan_meta.applicants_name
          : loan?.application_meta?.applicants_name,
        "Loan Status":
          loan?.loan?.loan_status === "defaulted"
            ? "arrears"
            : (loan?.loan?.loan_status === "pending"
                ? "Approved"
                : loan?.loan?.loan_status) ||
              loan?.application_meta?.review_status,
        "Loan ID": loan?.loan?.loan_id ? loan?.loan?.loan_id : loan?.app_id,
        Principal: loan?.application_meta
          ? loan?.application_meta?.amount
          : loan?.loan?.amount_issued,
        "Amount Paid": loan?.application_meta ? 0 : (loan?.loan?.principal_paid ?? 0) + (loan?.loan?.interest_paid ?? 0),
        "Interest Rate": loan?.loan
          ? loan?.loan?.interest_rate
          : loan?.application_meta?.interest_rate,
        "Approved At": loan?.loan
          ? moment(loan?.loan.loan_meta?.approved_at).format(
              "DD-MM-YYYY"
            )
          : "",
        "Start Date": loan?.loan
          ? moment(loan?.loan?.start_date).format("DD-MM-YYYY")
          : "",
        "End Date": loan?.loan
          ? moment(loan?.loan?.end_date).format("DD-MM-YYYY")
          : "",
        "Approved by": loan?.loan ? loan?.loan?.loan_meta?.approved_by : "",
        Imported: loan?.loan?.loan_meta?.imported ? "yes" : "no",
      };
    });

    // Process the csv
    const fields = Object.keys(formattedLoans[0]);
    const replacer = (key, value) => (value === null ? "" : value);
    let csv = formattedLoans.map(function (row) {
      return fields
        .map(function (fieldName) {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(",");
    });
    csv.unshift(fields.join(",")); // add header column
    csv = csv.join("\r\n");

    // Downloading rg csv
    const filename =
      `${profile.fullname} Bweyogerere Tuberebumu loan report ` +
      new Date().toISOString().slice(0, 10) +
      ".csv";

    const link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  return (
    <div className="flex-grow mx-3 md:mx-5 my-2 h-[calc(100vh-140px)]">
      <div className="flex flex-col pb-3 md:h-[150px]">
        <h1 className="mb-5 font-bold uppercase dark:text-white">My Loans</h1>

        <div className="flex flex-wrap my-1 justify-between gap-2">
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-2 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
            >
              <option value="">Status</option>
              <option value="on going">On going</option>
              <option value="pending">Pending</option>
              <option value="defaulted">Due</option>
              <option value="cleared">Cleared</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => setStatus("")}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              id="loan-date-filter"
              type="date"
              name="inputDate"
              onChange={(event) => {
                setDate(event.target.value);
                console.log(event.target.value);
              }}
              className="py-2 px-4 bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded-l"
              defaultValue={status}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-1 pl-0 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                setDate("");
                document.getElementById("loan-date-filter").value = "";
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <DateRangeFilter
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />

          <button
            onClick={(status) => {
              if (status) setStatus("");
              if (date) setDate("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              if (startDate) setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              if (endDate) setEndDate("");
              document.getElementById("loan-date-filter").value = "";
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {largeDesktop && !smallDesktop && "Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
          <button
            className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
            onClick={() => {
              generate_loan_report();
            }}
            disabled={!allLoans || allLoans?.length === 0}
          >
            {largeDesktop && !smallDesktop && "Export"}
            <MdDownload className="ml-1" />
          </button>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-120px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : shownloans && shownloans.length > 0 ? (
          <>
            <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
              <table
                className="w-full h-6 text-sm text-left text-gray-500 dark:text-gray-400 mb-5"
                id="loans"
              >
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="pr-6 py-4">Date</th>
                    <th className="px-4 py-4">Amount to Pay (UGX)</th>
                    <th className="px-4 py-4">Amount Paid (UGX)</th>
                    <th className="px-4 py-4">Principal (UGX)</th>
                    <th className="px-4 py-4">Interest (%)</th>
                    <th className="px-4 py-4">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {shownloans.map((loan, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr
                          onClick={() => {
                            setLoanModal(true);
                            setActiveIndex(index);
                          }}
                          className={`${
                            index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                          } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                          key={index}
                        >
                          <td>
                            <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                          </td>
                          <td className="pr-6 py-3">
                            {loan?.loan
                              ? moment(loan?.loan?.created_at).format(
                                  "DD-MM-YYYY"
                                )
                              : moment(loan?.created_at).format("DD-MM-YYYY")}
                          </td>
                          <td className="px-6 py-3">
                            {currencyFormatter(
                              loan?.application_meta
                                ? loan?.application_meta?.total
                                : loan?.loan?.reducing_balance +
                                    loan?.loan?.interest_to_pay
                            )}
                          </td>
                          <td className="px-6 py-3">
                            {currencyFormatter(
                              loan?.application_meta
                                ? 0
                                : loan?.loan.interest_paid +
                                    loan?.loan.principal_paid
                            )}
                          </td>
                          <td className="px-6 py-3">
                            {currencyFormatter(
                              loan?.application_meta
                                ? loan?.application_meta?.total
                                : loan?.loan.amount_issued
                            )}
                          </td>
                          <td className="px-6 py-3">
                            {currencyFormatter(
                              loan?.application_meta
                                ? loan.application_meta.interest_rate
                                : loan?.loan?.interest_rate
                            )}
                          </td>
                          <td className={`px-6 py-3`}>
                            <span
                              className={` py-1 px-2 rounded-xl text-white ${
                                loan?.application_meta?.review_status ===
                                "pending"
                                  ? "bg-yellow-400"
                                  : loan?.loan?.loan_status === "pending"
                                  ? "bg-green-400"
                                  : loan?.application_meta?.review_status ===
                                      "rejected" ||
                                    loan?.loan?.loan_status === "defaulted"
                                  ? "bg-red-400"
                                  : loan?.loan?.loan_status === "cleared"
                                  ? "bg-green-400"
                                  : loan?.loan?.loan_status === "on going" &&
                                    "bg-blue-400"
                              }`}
                            >
                              {loan?.loan?.loan_status === "defaulted"
                                ? "arrears"
                                : loan?.loan?.loan_status === "pending"
                                ? "Approved"
                                : loan?.application_meta?.review_status ===
                                  "pending"
                                ? "pending approval"
                                : loan?.loan?.loan_status}
                            </span>
                          </td>
                        </tr>
                        {loanModal && activeIndex === index && (
                          <LoanModal
                            setLoanModal={setLoanModal}
                            loan={loan}
                            loanModal={loanModal}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(loans.length / loansPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={loans}
                DepositsPerPage={loansPerPage}
                setDepositsPerPage={setLoansPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          !loans || (loans?.length === 0 && <Frown />)
        )}
      </div>
    </div>
  );
}
