import { FieldArray, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { supabase } from "../helpers/supabase";

export default function Nominee({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  initialValues,
  setInitialValues,
}) {
  const [, setProfiles] = useState([]);

  useEffect(() => {
    getProfiles()
      .then((data) => setProfiles(data))
      .catch((error) => console.log(error));
  }, []);

  const getProfiles = async () => {
    const { data, error } = await supabase.rpc("get_member_profiles");

    if (error) throw error;
    return data;
  };

  let { nominees } = values;
  return (
    <div className="mb-3">
      <h1 className="font-semibold">Nominee Information</h1>
      <FieldArray
        name="nominees"
        render={(arrayHelpers) => (
          <>
            {values.nominees &&
              values.nominees.length > 0 &&
              values.nominees.map(
                ({ percentage, fullname, dob, contact }, index) => (
                  <div
                    key={index}
                    className="flex flex-wrap gap-5 mb-3 outline outline-1 rounded p-2"
                  >
                    <div className="flex flex-col w-56 mb-3">
                      <label
                        htmlFor={`nominees${index}fullname`}
                        className=" text-sm"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name={`nominees[${index}].fullname`}
                        id={`nominees${index}fullname`}
                        placeholder="Enter name"
                        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.nominees &&
                          errors.nominees.length > 0 &&
                          errors.nominees[index]?.fullname &&
                          touched?.nominees &&
                          touched?.nominees.length > 0 &&
                          touched.nominees[index]?.fullname
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={(event) => {
                          setFieldValue(event.target.name, event.target.value);
                          const temp = initialValues;
                          temp.nominees[index].fullname = event.target.value;
                          setInitialValues((initialValues) => ({
                            ...initialValues,
                            ...temp,
                          }));
                        }}
                        onBlur={handleBlur}
                        defaultValue={fullname}
                      />
                      <ErrorMessage name={`nominees[${index}].fullname`}>
                        {(msg) => (
                          <div className="error text-red-600 text-xs">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="flex flex-col w-56 mb-3">
                      <label
                        htmlFor={`nominees${index}dob`}
                        className=" text-sm"
                      >
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        name={`nominees[${index}].dob`}
                        id={`nominees${index}dob`}
                        placeholder="Enter birth date"
                        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.nominees &&
                          errors.nominees.length > 0 &&
                          errors.nominees[index]?.dob &&
                          touched?.nominees &&
                          touched?.nominees.length > 0 &&
                          touched.nominees[index]?.dob
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={(event) => {
                          setFieldValue(event.target.name, event.target.value);
                          const temp = initialValues;
                          temp.nominees[index].dob = event.target.value;
                          setInitialValues((initialValues) => ({
                            ...initialValues,
                            ...temp,
                          }));
                        }}
                        onBlur={handleBlur}
                        defaultValue={dob}
                      />
                      <ErrorMessage name={`nominees[${index}].dob`}>
                        {(msg) => (
                          <div className="error text-red-600 text-xs">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="flex flex-col w-56 mb-3">
                      <label
                        htmlFor={`nominees${index}contact`}
                        className=" text-sm"
                      >
                        Contact
                      </label>
                      <input
                        type="text"
                        name={`nominees[${index}].contact`}
                        id={`nominees${index}contact`}
                        placeholder="Enter Contact"
                        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.nominees &&
                          errors.nominees.length > 0 &&
                          errors.nominees[index]?.contact &&
                          touched?.nominees &&
                          touched?.nominees.length > 0 &&
                          touched.nominees[index]?.contact
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={(event) => {
                          setFieldValue(event.target.name, event.target.value);
                          const temp = initialValues;
                          temp.nominees[index].contact = event.target.value;
                          setInitialValues((initialValues) => ({
                            ...initialValues,
                            ...temp,
                          }));
                        }}
                        onBlur={handleBlur}
                        defaultValue={contact}
                      />
                      <ErrorMessage name={`nominees[${index}].contact`}>
                        {(msg) => (
                          <div className="error text-red-600 text-xs">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="flex flex-col w-56 mb-3">
                      <label
                        htmlFor={`nominees${index}percentage`}
                        className=" text-sm"
                      >
                        Percentage
                      </label>
                      <input
                        type="text"
                        name={`nominees[${index}].percentage`}
                        id={`nominees${index}percentage`}
                        placeholder="Enter Percentage"
                        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.nominees &&
                          errors.nominees.length > 0 &&
                          errors.nominees[index]?.percentage &&
                          touched?.nominees &&
                          touched?.nominees.length > 0 &&
                          touched.nominees[index]?.percentage
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={(event) => {
                          setFieldValue(event.target.name, event.target.value);
                          const temp = initialValues;
                          temp.nominees[index].percentage = event.target.value;
                          setInitialValues((initialValues) => ({
                            ...initialValues,
                            ...temp,
                          }));
                        }}
                        onBlur={handleBlur}
                        defaultValue={percentage}
                      />
                      <ErrorMessage name={`nominees[${index}].percentage`}>
                        {(msg) => (
                          <div className="error text-red-600 text-xs">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                )
              )}
            <button
              className="bg-primary text-white px-3 py-2 rounded m-2"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                arrayHelpers.push({
                  percentage: "",
                  fullname: "",
                  contact: "",
                  dob: "",
                });
              }}
            >
              +
            </button>
            <button
              className="bg-accent-red text-white px-3 py-2 rounded m-2"
              onClick={(event) => {
                event.preventDefault();
                nominees.length > 2 && arrayHelpers.pop();
              }}
            >
              -
            </button>
          </>
        )}
      />
    </div>
  );
}
