import { IconContext } from "react-icons";
import { BsEmojiFrown } from "react-icons/bs";

function Frown() {
  return (
    <div className="h-full w-full flex flex-col items-center justify-center text-gray-700 dark:text-secondary-text font-bold text-lg">
      <IconContext.Provider value={{ className: `mb-2` }}>
        <BsEmojiFrown size={70} />
      </IconContext.Provider>
      <p>No records found</p>
    </div>
  );
}

export default Frown;
