import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { supabase } from "../../helpers/supabase";
import { toast, ToastContainer } from "react-toastify";
import { Spinner } from "../../components";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { useOutletContext } from "react-router-dom";
import { sendSMS } from "../../helpers/sendsms";
import { add_separator } from "../../helpers/thousand_separator";

export default function TransactionVerify() {
  const { id } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [user, profile, setProfile, roles] = useOutletContext();
  const role = roles
    ? roles.find((role) => role !== "member" && role !== "admin")
    : null;

  const {
    id: admin_id,
    fullname: admin_name,
    position_in_sacco: admin_position,
  } = profile;

  useEffect(() => {
    getApplication().catch((error) => console.log(error));
  }, []);

  const getApplication = async () => {
    const { error, data } = await supabase
      .from("applications")
      .select()
      .eq("application_id", id)
      .single();

    if (error) throw error;
    setLoading(false);
    console.log("Transaction: ", data);
    setTransaction(data);
  };

  const approveTransaction = async () => {
    setSubmitting(true);

    try {
      const { error, data } = await supabase.rpc(
        "handle_reserve_account_transaction",
        {
          details: JSON.stringify({
            ...transaction.application_meta,
            admin_name: admin_name,
            admin_id: admin_id,
            admin_position,
            application: transaction.application_id,
            created_at: new Date()
              .toISOString()
              .toLocaleString("en-GB", { timeZone: "UTC" }),
            updated_at: new Date()
              .toISOString()
              .toLocaleString("en-GB", { timeZone: "UTC" }),
            _type: transaction._type,
          }),
        }
      );

      if (error) {
        setLoading(false);
        toast.error(`${error?.message}`, { position: "top-center" });
        setSubmitting(false);
        console.log(error);
      } else {
        setTransaction((transaction) => ({
          ...transaction,
          application_meta: data?.application_meta,
        }));
        setLoading(false);

        sendSMS(
          transaction.application_meta.applicants_id,
          `Hey ${transaction.application_meta.applicants_name}, your organisation transaction has been approved. For more details, visit https://tube.ablestate.co/assets Regards, Bweyogerere Tuberebumu Sacco.`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error, "Error"));

        toast.success(`transaction has been approved.`, {
          position: "top-center",
        });
        setSubmitting(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Try again later`, { position: "top-center" });
    }
  };

  const rejectTransaction = async () => {
    try {
      const { error, data } = await supabase.rpc(
        "reject_organisation_transaction",
        {
          details: JSON.stringify({
            ...transaction.application_meta,
            admin_name: admin_name,
            admin_id: admin_id,
            admin_position,
            application: transaction.application_id,
            created_at: new Date()
              .toISOString()
              .toLocaleString("en-GB", { timeZone: "UTC" }),
            updated_at: new Date()
              .toISOString()
              .toLocaleString("en-GB", { timeZone: "UTC" }),
            _type: transaction._type,
          }),
        }
      );

      if (error) {
        toast.error(`${error?.message}`, { position: "top-center" });
      } else if (data) {
        setTransaction((transaction) => ({
          ...transaction,
          application_meta: data?.application_meta,
        }));
        sendSMS(
          transaction.application_meta.applicants_id,
          `Hey ${transaction.application_meta.applicants_name}, your organisation transaction has been rejected. For more details, visit https://tube.ablestate.co/assets Regards, Bweyogerere Tuberebumu Sacco.`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error, "Error"));
        // setLoading(false);
        toast.success(`Transaction has been rejected.`, {
          position: "top-center",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Try again later`, { position: "top-center" });
    }
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <div className="flex flex-col justify-between pb-3 h-[60px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Transaction Verify
        </h1>
      </div>
      <ToastContainer />
      <div
        className={`bg-white ${
          !loading && "p-3"
        }  overflow-y-auto  relative  h-[calc(100%-80px)] dark:bg-dark-bg-700`}
      >
        {loading && (
          <div className="absolute z-10 bg-white dark:bg-dark-bg-700 dark:bg-opacity-90 bg-opacity-90 w-full h-full rounded-lg">
            <Spinner />
          </div>
        )}
        {transaction ? (
          <div className="flex flex-grow flex-col min-h-full">
            <div className="mb-3">
              <h1 className="font-semibold mb-2">
                {transaction.application_meta &&
                  transaction.application_meta.applicants_name}
                's transaction Request Details
                <span
                  className={` py-1 px-2 rounded-lg text-white text-xs ml-1 inline-block capitalize ${
                    transaction?.transaction_meta ||
                    transaction?.application_meta?.review_status === "approved"
                      ? "bg-green-400"
                      : transaction?.application_meta?.review_status ===
                        "rejected"
                      ? "bg-red-400"
                      : transaction?.application_meta?.review_status ===
                        "rejected"
                      ? "bg-green-400"
                      : transaction?.application_meta?.review_status ===
                        "forwarded"
                      ? "bg-blue-400"
                      : transaction?.application_meta?.review_status ===
                        "pending"
                      ? " bg-yellow-400"
                      : ""
                  }`}
                >
                  {transaction?.application_meta?.review_status}
                </span>
              </h1>

              <div className="outline outline-1 outline-gray-100 p-3">
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Application ID:</p>
                  <p className="font-bold col-span-3">{transaction.app_id}</p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Applicant's name:</p>
                  <p className="font-bold col-span-3">
                    {transaction.application_meta?.applicants_name}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2 capitalize">Type:</p>
                  <p className="font-bold col-span-3">
                    {transaction.application_meta?._type}
                  </p>
                </div>

                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Amount:</p>
                  <p className="font-bold col-span-3">
                    UGX{" "}
                    {currencyFormatter(transaction?.application_meta?.amount)}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2 capitalize">Account:</p>
                  <p className="font-bold col-span-3">
                    {transaction.application_meta?.account_type}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2 capitalize">Transaction Type:</p>
                  <p className="font-bold col-span-3">
                    {transaction.application_meta?.transaction_type ||
                      transaction?.transaction_meta?.transaction_type}
                  </p>
                </div>
                {transaction._type === "withdraw" && (
                  <>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Reason:</p>
                      <p className="font-bold col-span-3">
                        {transaction.application_meta?.reason ||
                          transaction.application_meta?.transaction_type}
                      </p>
                    </div>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Cashout method:</p>
                      <p className="font-bold col-span-3">
                        {transaction?.application_meta?.cashout_method}
                      </p>
                    </div>
                  </>
                )}

                {(transaction?.application_meta?.transaction_type ===
                  "external sevice" ||
                  transaction?.application_meta === "external service") && (
                  <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                    <p className="col-span-2 capitalize">Service Provider:</p>
                    <p className="font-bold col-span-3">
                      {transaction?.application_meta?.transaction_type ||
                        transaction?.transaction_meta?.transaction_type}
                    </p>
                  </div>
                )}

                {transaction && transaction.reviewed && (
                  <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                    <p className="col-span-2">Reviewed on:</p>
                    <p className="font-bold col-span-3">
                      {moment(transaction.application_meta?.reviewed_at).format(
                        "MMMM Do YYYY, h a"
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {transaction?.application_meta.applicants_id !== profile.id &&
              ((role === "secretary" || role === "asst_secretary") &&
              transaction?.application_meta.review_status === "pending" ? (
                <div className="flex gap-10 justify-end items-center mt-3">
                  <button
                    type="submit"
                    className="bg-blue-500 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                    onClick={async () => {
                      setSubmitting(true);
                      const toastID = toast.loading("Forwarding transaction");

                      try {
                        const { data, error } = await supabase
                          .from("applications")
                          .update({
                            application_meta: {
                              ...transaction.application_meta,
                              ...{
                                admin_1: {
                                  admin_id: `${profile.id}`,
                                  admin_name: `${profile.fullname}`,
                                  position_in_sacco: `${profile.position_in_sacco}`,
                                  review_status: "forwarded",
                                  reviewed: true,
                                  reviewed_at: `${new Date().toISOString()}`,
                                  seen: true,
                                },
                              },
                              review_status: "forwarded",
                            },
                          })
                          .eq("application_id", transaction.application_id)
                          .single();

                        if (error) throw error;
                        console.log("Data: ", data);

                        if (data) {
                          sendSMS(
                            transaction.application_meta.applicants_id,
                            `Hey ${
                              transaction.application_meta.applicants_name
                            }, your application of amount UGX ${add_separator(
                              transaction.application_meta.amount
                            )} on your ${
                              transaction.application_meta.account_type
                            } account has been forwarded to the chairperson for review. For more details visit https://tube.ablestate.co/organisation/transaction \nRegards, Bweyogerere Tuberebumu Sacco.`
                          )
                            .then((response) => response.json())
                            .then((data) => {
                              console.log(data);
                            })
                            .catch((error) => console.log(error, "Error"));

                          toast.update(toastID, {
                            render: "transaction successfully forwarded",
                            type: "success",
                            isLoading: false,
                            autoClose: 5000,
                          });

                          setTransaction(() => data);
                          setSubmitting(false);
                        }
                      } catch (error) {
                        console.log("Error: ", error);

                        toast.update(toastID, {
                          render: "Failure. Please try again later.",
                          type: "error",
                          isLoading: false,
                          autoClose: 5000,
                        });
                        setSubmitting(false);
                      }
                    }}
                    disabled={isSubmitting}
                  >
                    Forward
                  </button>
                </div>
              ) : (
                (role === "chairperson" || role === "vice_chairperson") &&
                transaction?.application_meta.review_status === "forwarded" && (
                  <div className="flex gap-10 justify-end items-center mt-3">
                    <button
                      type="submit"
                      className="bg-accent-red inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                      onClick={rejectTransaction}
                      disabled={isSubmitting}
                    >
                      Reject
                    </button>
                    <button
                      type="submit"
                      className="bg-green-600 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                      onClick={approveTransaction}
                      disabled={isSubmitting}
                    >
                      Approve
                    </button>
                  </div>
                )
              ))}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
