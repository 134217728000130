import { Formik, Form, ErrorMessage } from "formik";
import { supabase } from "../helpers/supabase";
import { toBase64 } from "../helpers/toBase64";
import { ConfirmModal } from "../components";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import Spinner from "./Loaders/Spinner";
import * as Yup from "yup";
import { InputField } from "./Form/CustomInputField";
import { editProfileValidationSchema } from "../helpers/validator";

function EditModal({ setEditPop }) {
  const [user, profile, setProfile] = useOutletContext();
  console.log(profile.fathers_name);

  const initialValues = {
    password: "",
    fullname: profile?.fullname ?? "",
    dob: profile?.dob ?? "",
    phone_number: profile?.phone_number ?? "",
    present_address: profile?.present_address ?? "",
    gender: profile?.gender ?? "",
    fathers_name: profile?.fathers_name ?? "",
    fathers_address: profile?.fathers_address ?? "",
    id_passport_number: profile?.id_passport_number ?? "",
    email_address: profile?.email_address ?? "",
    marital_status: profile?.marital_status ?? "",
  };
  const { id } = supabase.auth.user();
  const [loading, setLoading] = useState(false);
  console.log("profile: ", profile);

  return (
    <ConfirmModal setPopUp={setEditPop}>
      {loading && (
        <div className="absolute left-0 top-0 bottom-0 bg-white dark:bg-dark-bg-700 dark:bg-opacity-90 bg-opacity-90 w-full h-full rounded-lg outline z-40">
          <Spinner />
        </div>
      )}
      <h1 className="font-bold dark:text-white">Edit Details</h1>
      <span className="text-sm text-red-500 mb-3">
        All Fields with a * are mandatory
      </span>
      <Formik
        initialValues={initialValues}
        validationSchema={editProfileValidationSchema}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const {
            password,
            fullname,
            dob,
            gender,
            email_address,
            phone_number,
            id_passport_number,
            present_address,
            marital_status,
            fathers_address,
            fathers_name,
            avatar,
          } = values;

          setLoading(true);
          await supabase.auth.update({ phone: `256${phone_number.slice(1)}` })
          await supabase
            .rpc("check_password", { current_password: password, _user_id: id })
            .then(async ({ data, error: password_error }) => {
              if (password_error) {
                setLoading(false);
                toast.error(`Wrong password.`, { position: "top-center" });
              } else {
                const { error, data } = await supabase
                  .from("profiles")
                  .update({
                    fullname: fullname, //
                    dob: dob, // worked
                    gender: gender,
                    email_address: email_address,
                    phone_number: phone_number,
                    id_passport_number: id_passport_number,
                    present_address: present_address, // didn't
                    marital_status: marital_status,
                    fathers_address: fathers_address, // didn't
                    fathers_name: fathers_name, // didn't
                    avatar: avatar,
                  })
                  .eq("id", id)
                  .single();

                if (error) {
                  setLoading(false);
                  throw error;
                } else {
                  setLoading(false);
                  setEditPop(false);
                  setProfile({ ...profile, ...data });
                  toast.success("Profile updated successfully.", {
                    position: "top-center",
                  });
                }
              }
              setLoading(false);
              resetForm({ values: initialValues });
              setSubmitting(false);
            })
            .catch((error) => {
              setLoading(false);
              setSubmitting(false);
              toast.error(`${error?.message}`, { position: "top-center" });
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form className="">
              <div className="mb-3 flex flex-wrap gap-3">
                <InputField
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  reference="fullname"
                  defaultValue={initialValues.fullname}
                  label="Full Name"
                  placeholder="Enter Fullname"
                  setFieldValue={setFieldValue}
                  mandatory={true}
                />
                <InputField
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  reference="dob"
                  defaultValue={initialValues.dob}
                  label="Date of Birth"
                  setFieldValue={setFieldValue}
                  type="date"
                />
                <div>
                  <label htmlFor="">Gender</label>
                  <div className="flex gap-3">
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        value="Male"
                        name="gender"
                        id=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultChecked={initialValues?.gender === "Male"}
                      />
                      <label htmlFor="">Male</label>
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        value="Female"
                        name="gender"
                        id=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultChecked={initialValues?.gender === "Female"}
                      />
                      <label htmlFor="">Female</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <InputField
                  label="Email Address"
                  id="email_address"
                  reference="email_address"
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  defaultValue={initialValues?.email_address}
                  setFieldValue={setFieldValue}
                  mandatory={true}
                />
              </div>

              <div className="mb-3 flex flex-wrap gap-3">
                <InputField
                  label="Phone Number"
                  id="phone_number"
                  reference="phone_number"
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  defaultValue={initialValues.phone_number}
                  setFieldValue={setFieldValue}
                  mandatory={true}
                />

                <InputField
                  label="ID/ Passport Number"
                  id="id_passport_number"
                  reference="id_passport_number"
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  defaultValue={initialValues.id_passport_number}
                  setFieldValue={setFieldValue}
                  mandatory={true}
                />
              </div>

              <div className="mb-3 flex flex-wrap gap-3">
                <InputField
                  label="Present Address"
                  id="present_address"
                  reference="present_address"
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  defaultValue={initialValues.present_address}
                  setFieldValue={setFieldValue}
                  mandatory={true}
                />

                <div className="flex flex-col w-56 ">
                  <label className=" text-sm">
                    Marital Status
                    <span className={`text-sm text-red-500 ml-2 font-bold`}>
                      *
                    </span>
                  </label>
                  <select
                    name="marital_status"
                    id="marital_status"
                    defaultValue={initialValues.marital_status}
                    onChange={(event) =>
                      setFieldValue(event.target.name, event.target.value)
                    }
                    className={`ring-1 rounded px-2 py-2 bg-white dark:bg-dark-bg-600 focus:outline-none focus:ring-2 focus:ring-primary ${
                      errors?.marital_status && touched?.marital_status
                        ? "ring-red-500"
                        : "ring-black"
                    }`}
                    onBlur={handleBlur}
                  >
                    <option value="">--Marital Status--</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="widowed">Widowed</option>
                    <option value="divorced">Divorced</option>
                  </select>
                  <ErrorMessage name="marital_status">
                    {(msg) => (
                      <div className="error text-xs text-red-500">{msg}</div>
                    )}
                  </ErrorMessage>
                </div>
              </div>

              <div className="mb-3 flex flex-wrap gap-3">
                <InputField
                  label="Father's Name"
                  id="fathers_name"
                  reference="fathers_name"
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  defaultValue={initialValues?.fathers_name}
                  setFieldValue={setFieldValue}
                />
                <InputField
                  label="Father's Address"
                  id="fathers_address"
                  reference="fathers_address"
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  defaultValue={initialValues?.fathers_address}
                  setFieldValue={setFieldValue}
                />
              </div>

              <div className="mb-3 flex flex-wrap gap-3">
                <div className="flex flex-col">
                  <p>Upload Photo</p>
                  <div className="flex-grow flex">
                    <input
                      type="file"
                      name="avatar"
                      id="avatar"
                      placeholder="Avatar"
                      className="ring-1 ring-black rounded px-2 py-1"
                      onChange={async (event) => {
                        const file = event.target.files[0];
                        const fileString = await toBase64(file);
                        values.avatar = fileString;
                      }}
                    />
                  </div>
                </div>
              </div>
              <InputField
                label="Enter password to save changes"
                id="password"
                reference="password"
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                type="password"
                setFieldValue={setFieldValue}
                mandatory={true}
              />

              <div className="flex justify-end gap-3 mt-3">
                {/* Debugging */}
                {/* <button 
                  onClick={(event) => {
                    event.preventDefault()

                    console.log(errors)
                    console.log(values)

                  }}
                >
                  try-me
                </button> */}
                <input
                  className="bg-primary px-3 py-1 outline outline-1 outline-primary rounded-md text-white cursor-pointer"
                  type="submit"
                  value="Save"
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </ConfirmModal>
  );
}

export default EditModal;
