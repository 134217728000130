import ReactDOM from "react-dom";
import { IoCloseSharp } from "react-icons/io5";
import { useAuth } from "../../auth/AuthContext";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { supabase } from "../../helpers/supabase";
import { ToastContainer, toast, toastContainer } from "react-toastify";

export default function TransferModal({ passed, setTransferModal, transfer }) {
  console.log("Transfer: ", transfer);
  const { darkMode } = useAuth();
  const [, profile, , roles] = useOutletContext();
  const [cancelTransfer, setCancelTransfer] = useState(false);
  const navigate = useNavigate();
  const role = roles.find((role) => role !== "member" && role !== "admin");
  console.log("Role: ", role);
  console.log("Application ID: ", transfer.application_id);
  console.log("Transfer: ", transfer);

  if (transfer?.application_meta) {
    let permissionsCheck = false;

    permissionsCheck =
      ((role === "secretary" || role === "ass_secretary") &&
        transfer?.application_meta?.review_status === "pending" &&
        transfer?.application_meta?.applicants_id !== profile.id) ||
      ((role === "chairperson" || role === "vice_chairperson") &&
        transfer?.application_meta?.review_status === "forwarded" &&
        transfer?.application_meta?.applicants_id !== profile.id);

    console.log("Permissions Check : ", permissionsCheck);

    console.log(
      "Let's check something here",
      profile.id === transfer?.application_meta?.applicants_id &&
        transfer?.application_meta?.review_status === "pending"
    );
  }
  return ReactDOM.createPortal(
    <div
      className={`bg-black bg-opacity-20 z-40 w-screen h-screen fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center ${
        darkMode ? "dark" : ""
      }`}
    >
      <div
        className="bg-white mx-2 dark:bg-dark-bg dark:text-secondary-text p-10 rounded-md shadow-md flex flex-col items-center  relative"
        ref={passed}
      >
        <ToastContainer />

        {/* {children} */}
        {cancelTransfer && (
          <div className="p-5 rounded bg-black/20 top-0 absolute dark:bg-dark-bg w-full h-full flex justify-center items-center">
            <div className="p-5 h-fit w-fit bg-white rounded">
              <h1 className="font-bold">Are you sure?</h1>
              <p>You want to cancel this transfer.</p>
              <div className="flex justify-end gap-3 mt-3">
                <button
                  className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                  onClick={() => {
                    setCancelTransfer(false);
                  }}
                >
                  No
                </button>
                <button
                  onClick={async () => {
                    const toastID = toast.loading("Cancelling the transfer");
                    try {
                      const { data, error } = await supabase
                        .from("applications")
                        .update({
                          application_meta: {
                            ...transfer.application_meta,
                            review_status: "cancelled",
                          },
                        })
                        .eq("application_id", transfer.application_id)
                        .single();

                      if (error) throw error;

                      if (data) {
                        toast.update(toastID, {
                          render: "Transfer sucessfully cancelled",
                          type: "success",
                          isLoading: false,
                          autoClose: 5000,
                        });
                        setCancelTransfer(false);
                      }
                    } catch (error) {
                      toast.update(toastID, {
                        render: "Failure. Please try again later",
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                      });
                      setCancelTransfer(false);
                    }
                  }}
                  className="bg-primary px-3 py-1 outline outline-1 outline-primary rounded-md text-white"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-between items-center w-full mb-5">
          <div>
            <span className="font-bold text-lg capitalize">
              transfer details
            </span>
            {console.log(
              "Transfer check: ",
              (transfer?.application_meta &&
                roles.includes("asst_secretary")) ||
                (roles.includes("secretary") &&
                  transfer?.review_status === "pending")
            )}

            {(["secretary", "asst_secretary"].find(
              (user_role) => user_role === role
            ) &&
              transfer?.application_meta?.review_status === "pending") ||
              (["chairperson", "vice_chairperson"].find(
                (user_role) => user_role === role
              ) &&
                transfer?.application_meta?.review_status === "forwarded" &&
                ((transfer?.application_meta?.designated_for === "own" &&
                  transfer?.application_meta?.applicants_id !== profile.id) ||
                  (transfer?.application_meta?.designated_for === "other" &&
                    transfer?.application_meta?.member?.id !== profile.id)) && (
                  <button
                    className="bg-green-500 text-white outline-offset-2 px-1 rounded-sm w-22 capitalize font-normal text-base py-0.5 border ml-10"
                    onClick={() => {
                      console.log(
                        `Path: http://localhost:3000/transfers/members/${transfer.application_id}`
                      );
                      navigate(`/transfers/members/${transfer.application_id}`);
                    }}
                  >
                    Verify
                  </button>
                ))}
          </div>
          <div
            className="dark:hover:bg-dark-bg-600 hover:bg-accent p-2 rounded-full cursor-pointer"
            onClick={() => setTransferModal(false)}
          >
            <IoCloseSharp />
          </div>
        </div>
        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">Date</p>
          <p className="font-bold col-span-3 pl-8">
            : {moment(transfer?.created_at).format("DD-MM-YYYY, hh:mm a")}
          </p>
        </div>

        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">Amount</p>
          <p className="font-bold col-span-3 pl-8">
            : UGX{" "}
            {currencyFormatter(
              transfer?.amount || transfer?.application_meta?.amount
            )}
          </p>
        </div>
        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">Designated For</p>
          <p className="font-bold col-span-3 pl-8 capitalize">
            :{" "}
            {transfer?.transaction_meta?.designated_for === "own" ||
            transfer?.application_meta?.designated_for === "own"
              ? "Self"
              : "Other Member"}
          </p>
        </div>
        <div className="col-span-5 font-semibold w-full">Source</div>
        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">Member Name</p>
          <p className="font-bold col-span-3 pl-8 capitalize">
            :{" "}
            {transfer?.transaction_meta?.from?.member_name ||
              transfer?.application_meta?.senders_member_name ||
              transfer?.transaction_meta?.transferred_from}
          </p>
        </div>
        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">Account type</p>
          <p className="font-bold col-span-3 pl-8 capitalize">
            :{" "}
            {transfer?.transaction_meta?.from?.account_type ||
              transfer?.application_meta?.senders_account_type ||
              "shares"}
          </p>
        </div>
        <div className="col-span-5 font-semibold w-full">Destination</div>
        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">Member Name</p>
          <p className="font-bold col-span-3 pl-8 capitalize">
            :{" "}
            {transfer?.transaction_meta?.to?.member_name ||
              transfer?.application_meta?.receivers_member_name ||
              transfer?.transaction_meta?.member_name}
          </p>
        </div>
        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">Account type</p>
          <p className="font-bold col-span-3 pl-8 capitalize">
            :{" "}
            {transfer?.transaction_meta?.to?.account_type ||
              transfer?.application_meta?.receivers_account_type ||
              "shares"}
          </p>
        </div>
        {transfer?.transaction_meta && (
          <>
            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-1">Approved at</p>
              <p className="font-bold col-span-3 pl-8">
                :{" "}
                {moment(transfer?.transaction_meta?.approved_at).format(
                  "DD-MM-YYYY, hh:mm a"
                )}
              </p>
            </div>
            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-1">Status</p>
              <p className="font-bold col-span-3 pl-8">
                :{" "}
                {
                  <span className="bg-green-400 py-1 px-2 rounded-xl text-white">
                    approved
                  </span>
                }
              </p>
            </div>
          </>
        )}
        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
          <p className="col-span-1">
            {transfer?.transaction_meta ? "Approved by" : "Status"}
          </p>
          <p className="font-bold col-span-3 pl-8">
            :{" "}
            {transfer?.transaction_meta ? (
              transfer?.transaction_meta?.approved_by
            ) : (
              <span
                className={` py-1 px-2 rounded-xl text-white inline-block ${
                  transfer?.application_meta?.review_status === "pending"
                    ? "bg-yellow-400"
                    : transfer?.application_meta?.review_status === "rejected"
                    ? "bg-red-400"
                    : transfer?.application_meta?.review_status === "forwarded"
                    ? "bg-blue-400"
                    : "bg-green-400"
                }`}
              >
                {transfer?.application_meta?.review_status === "pending"
                  ? "pending approval"
                  : transfer?.transaction_meta
                  ? "approved "
                  : transfer?.application_meta?.review_status}
              </span>
            )}
          </p>
        </div>
        <div className="flex w-full justify-end">
          {profile?.id === transfer?.application_meta?.applicants_id &&
            transfer?.application_meta?.review_status === "pending" && (
              <button
                className="rounded py-1 px-2 bg-red-500 border-0 text-white text-base w-fit"
                onClick={() => {
                  console.log(
                    "Change the status of the application to cancelled"
                  );
                  setCancelTransfer(true);
                }}
              >
                {" "}
                Cancel Transfer
              </button>
            )}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
}
