export const steps = [
    {
      number: 1,
      title: "Renumerate"
    },
    {
      number: 2,
      title: "Transfer Shares"
    },
    {
      number: 3,
      title: "Terminate"
    }
  ]