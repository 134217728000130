import { useState } from "react";
import ApplicationPg1 from "./ApplicationPg1";
import ApplicationPg2 from "./ApplicationPg2";
import { supabase } from "../../helpers/supabase";
import { useAuth } from "../../auth/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { useOutletContext, useNavigate, useLocation } from "react-router-dom";
import { getOTP } from "../../helpers/getotp";
import ApplicationVerify from "./ApplicationVerify";
import { Spinner } from "../../components";

function MemberApplication() {
  const [pageNumber, setPageNumber] = useState(1);
  const [user, profile, setProfile] = useOutletContext();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    fullname: "",
    dob: "",
    gender: "",
    present_address: "",
    email_address: "",
    phone_number: "",
    id_passport_number: "",
    marital_status: "",
    fathers_name: "",
    fathers_address: "",
    income_sources: {
      status: "",
      employed: {
        employers_name: "",
        employers_address: "",
        position: "",
        work_station: "",
        gross_monthly_income: "",
        appointment_date: "",
        payroll_number: "",
        source_of_income: "",
      },
      business: {
        business_name: "",
        business_address: "",
        business_location: "",
        other_income_sources: "",
      },
    },
    nominees: [
      {
        percentage: "",
        fullname: "",
        dob: "",
        contact:""
      },
      {
        percentage: "",
        fullname: "",
        dob: "",
        contact: ""
      },
    ],
    proposed_mode_of_remittances: {
      standing_order: false,
      direct_debit: false,
      date_effective: "",
      others: "",
    },
    proposed_monthly_contributions: "",
    amount_in_words: "",
    membership_fee: "",
    evidence: ""
  });

  return (
    <>
      <ToastContainer />
      <div className="flex-grow sm:mx-2 md:mx-5 my-2 h-[calc(100vh-70px)] dark:text-secondary-text">
        <div className="flex flex-col justify-between pb-3 md:h-[60px]">
          <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
            Membership Application
          </h1>
        </div>

        <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-80px)] dark:bg-dark-bg-700 p-6">
          {loading && (
            <div className="absolute z-10 bg-white dark:bg-dark-bg-700 dark:bg-opacity-90 bg-opacity-90 w-full h-full rounded-lg">
              <Spinner />
            </div>
          )}
          <div className="w-full overflow-x-auto h-full  relative overflow-y-auto p-2">
            <div className="flex flex-grow flex-col min-h-full">
              {pageNumber === 1 && (
                <ApplicationPg1
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              )}
              {pageNumber === 2 && (
                <ApplicationPg2
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  password={password}
                  setPassword={setPassword}
                  setLoading={setLoading}
                />
              )}
              {pageNumber === 3 && (
                <ApplicationVerify
                  values={initialValues}
                  password={password}
                  setPassword={setPassword}
                  setInitialValues={setInitialValues}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MemberApplication;
