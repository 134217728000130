import { supabase } from "../../helpers/supabase";
import React from "react";
import { useEffect, useState } from "react";
import { NothingShown, Spinner } from "../../components";
import { TbFilterOff } from "react-icons/tb";
import { Pagination } from "../../components";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import LoanAppModal from "../../components/Modals/LoanAppModal";
import DateRangeFilter from "../../components/DateRangeFilter";
import { Helmet } from "react-helmet";
import Frown from "../../components/Frown";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "../../hooks";

export default function LoanAdmin() {
  useEffect(() => {
    fetch_loan_applications().catch((error) => console.log(error));

    const mySubscription = supabase
      .from("applications")
      .on("*", async () => {
        await fetch_loan_applications().catch((error) => console.log(error));
      })
      .subscribe();
    return () => supabase.removeSubscription(mySubscription);
  }, []);

  const [loans, setLoans] = useState([]);
  const [allLoans, setAllLoans] = useState([]);
  const [loanModal, setLoanModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [nothingShown, setNothingShown] = useState(false);

  const fetch_loan_applications = async () => {
    const { data, error } = await supabase.rpc("fetch_loan_applications");
    if (error) {
      setLoading(false);
      throw error;
    }

    if (!data || data.length === 0) setNothingShown(true);

    setLoans(data ?? []);
    setAllLoans(data ?? []);
    setLoading(false);
  };

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawPerPage, setWithdrawPerPage] = useState(10);
  const indexOfLastPage = currentPage * withdrawPerPage;
  const indexOfFirstPage = indexOfLastPage - withdrawPerPage;

  useEffect(() => {
    let data = allLoans;

    data =
      data && data?.length > 0
        ? data.filter(
            (loan) =>
              !status || loan?.application_meta?.review_status === status
          )
        : null;

    data =
      data && data?.length > 0
        ? data.filter(
            (loan) => !date || loan?.created_at?.substring(0, 10) === date
          )
        : null;

    data =
      data && data?.length > 0
        ? data.filter(
            (loan) =>
              !searchText ||
              loan?.application_meta?.applicants_name
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(startDate).isSameOrBefore(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(endDate).isSameOrAfter(
                moment(loan?.created_at?.substring(0, 10)).format("")
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(loan?.created_at?.substring(0, 10)).isBetween(
                moment(startDate),
                moment(endDate),
                undefined,
                "[]"
              );
            })
          : null;
    }

    setLoans(data);
  }, [searchText, status, date, startDate, endDate]);

  let shownLoans = !loans || loans.slice(indexOfFirstPage, indexOfLastPage);

  const approvedMembers = allLoans.filter(
    (loan) => loan?.application_meta?.review_status === "approved"
  );
  const pendingMembers = allLoans.filter((member) => !member.reviewed);
  const rejectedMembers = allLoans.filter(
    (member) =>
      member.reviewed && member.application_meta.review_status !== "approved"
  );

  const approved =
    approvedMembers && approvedMembers?.length > 0
      ? (approvedMembers.length / allLoans?.length ?? 0) * 100
      : 0;

  const pending =
    pendingMembers && pendingMembers?.length > 0
      ? (pendingMembers.length / allLoans?.length ?? 0) * 100
      : 0;

  const rejected =
    rejectedMembers && rejectedMembers?.length > 0
      ? (rejectedMembers.length / allLoans?.length ?? 0) * 100
      : 0;

  // context
  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);
  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  return (
    <div className="flex-grow mx-3 md:mx-5 my-2 h-[calc(100vh-140px)]">
      <Helmet>
        <title>Loan Applications - Bweyogere tuberebumu</title>
      </Helmet>
      <div className="flex flex-col justify-between pb-3 md:h-[160px] mb-8">
        <h1 className="mb-2 font-bold uppercase dark:text-white">
          Loan Applications
        </h1>
        <div className=" dark:text-secondary-text rounded">
          <div className="w-full h-7 rounded flex overflow-hidden">
            {allLoans.length === 0 && (
              <>
                <div
                  className="animate-pulse h-7 inline-block bg-accent"
                  style={{ width: `100%` }}
                ></div>
              </>
            )}
            <div
              className="h-7 inline-block bg-green-400"
              style={{
                width: `${
                  status === "approved" ? 100 : status === "" ? approved : 0
                }%`,
              }}
            ></div>
            <div
              className="h-7 inline-block bg-yellow-400"
              style={{
                width: `${
                  status === "pending" ? 100 : status === "" ? pending : 0
                }%`,
              }}
            ></div>
            <div
              className="h-7 inline-block bg-red-400"
              style={{
                width: `${
                  status === "rejected" ? 100 : status === "" ? rejected : 0
                }%`,
              }}
            ></div>
          </div>
          <div className="flex flex-wrap justify-between px-2 items-center py-2">
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-green-400 inline-block rounded-full"></div>{" "}
              Approved: {approvedMembers.length} ({Math.round(approved)}%)
            </div>
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-yellow-400 inline-block rounded-full"></div>{" "}
              Pending: {pendingMembers.length} ({Math.round(pending)}%)
            </div>
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-red-400 inline-block rounded-full"></div>{" "}
              Rejected: {rejectedMembers.length} ({Math.round(rejected)}%)
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 justify-between">
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="text"
              name="name"
              onChange={(event) => setSearchText(event.target.value)}
              id="name"
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={searchText}
              placeholder="Search by name..."
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (searchText) setSearchText("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-2 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
            >
              <option value="">Status</option>
              <option value="on going">On going</option>
              <option value="cleared">Cleared</option>
              <option value="defaulted">Due</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id=""
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={date}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <DateRangeFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          <button
            onClick={() => {
              setSearchText("");
              setStatus("");
              setDate("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              setEndDate("");
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {largeDesktop && !smallDesktop && "Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
        </div>
      </div>
      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-160px)] dark:bg-dark-bg-700 mt-5">
        {loading ? (
          <Spinner />
        ) : loans !== null && shownLoans.length > 0 ? (
          <>
            {startDate && console.log("startDate")}
            <div className="w-full overflow-x-auto h-full relative overflow-y-auto ">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-5">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Date</th>
                    <th className="px-6 py-4">Transaction ID</th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">Amount UGX</th>
                    <th className="px-6 py-4">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {shownLoans.map((deposit, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`cursor-pointer ${
                          index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                        } hover:bg-gray-100 dark:hover:bg-dark-bg-600`}
                        key={index}
                        onClick={() => {
                          setLoanModal(true);
                          setActiveIndex(index);
                        }}
                      >
                        <td>
                          <span className="ml-2 pl-4 px-4 py-3 text-sm">
                            &gt;
                          </span>
                        </td>
                        <td className="px-6 py-3">
                          {moment(deposit.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td className="px-6 py-3">{deposit.app_id}</td>
                        <td className="px-6 py-3">
                          {deposit?.application_meta.applicants_name}
                        </td>
                        <td className="px-6 py-3">
                          {currencyFormatter(deposit?.application_meta.amount)}
                        </td>

                        <td className={`px-6 py-3`}>
                          <span
                            className={` py-1 px-2 rounded-xl text-white ${
                              deposit.reviewed
                                ? deposit.application_meta.review_status ===
                                  "approved"
                                  ? "bg-green-400"
                                  : "bg-red-400"
                                : "bg-yellow-400"
                            }`}
                          >
                            {deposit.reviewed
                              ? deposit.application_meta.review_status ===
                                "approved"
                                ? "Approved"
                                : "Rejected"
                              : "Pending"}
                          </span>
                        </td>
                      </tr>
                      {loanModal && activeIndex === index && (
                        <LoanAppModal
                          setLoanModal={setLoanModal}
                          loan={deposit}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(loans.length / withdrawPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={loans}
                DepositsPerPage={withdrawPerPage}
                setDepositsPerPage={setWithdrawPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allLoans &&
          allLoans.length > 0 &&
          (!loans || loans?.length === 0) && <Frown />
        )}
      </div>
    </div>
  );
}
