import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { ToastContainer, toast } from "react-toastify";
import { sendSMS } from "../../helpers/sendsms";

function Verification() {
  const { id } = useParams();
  const [asset, setAsset] = useState({});
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState("");
  const [, { id: userID }] = useOutletContext();
  const [witnessStatus, setWitnessStatus] = useState();

  useEffect(() => {
    fetchAsset().catch((error) => console.log("Error: ", error));
  }, []);

  const fetchAsset = async () => {
    const { data, error } = await supabase
      .from("assets")
      .select()
      .eq("id", id)
      .single();
    if (error) throw error;
    if (data) {
      setAsset(data);
      setLoading(false);

      setWitnessStatus(
        (witnessStatus) =>
          data.witnesses.find((witness) => witness.id === userID)[
            "confirmation_status"
          ]
      );

      if (!imageURL) {
        const { data: file, error: evidenceError } = await supabase.storage
          .from("assets")
          .download(await data.evidence.substring(7));

        if (evidenceError) throw error;
        const url = URL.createObjectURL(file);
        setImageURL(url);
      }
    }
  };

  const affirmAssetWitness = async () => {
    const toastID = toast.loading("Processing confirmation");
    const { data, error } = await supabase.rpc("affirm_asset_witnessed", {
      witness: id,
      current: userID,
    });

    if (error) {
      toast.update(toastID, {
        render: error.message,
        isLoading: false,
        type: "error",
        autoClose: 5000,
      });
    } else {
      setAsset((asset) => ({ ...asset, ...data }));
      setWitnessStatus(() => "approved")

      console.log("Returned witnesses: ", data);
      toast.update(toastID, {
        render: "Successfully confirmed asset",
        isLoading: false,
        type: "success",
        autoClose: 5000,
      });

      const { data: executive } = await supabase
        .from("_member_profiles")
        .select(
          `
                id, 
                fullname
            `
        )
        .in("position_in_sacco", [
          "secretary",
          "assistant secretary",
          "chairperson",
          "vice chairperson",
          "chairperson credits",
          "vice chairperson credits",
          "treasurer",
          "assistant treasurer",
        ])
        .eq("is_under_termination", false);

      if (executive && executive?.length > 0) {
        executive.forEach(async (member) => {
          await sendSMS(
            member.id,
            `Hello ${member.fullname}, one of the witnesses for your SACCO asset acquisition has approved their statement. Please confirm at: https://tube.ablestate.co/organisation/assets`
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
            })
            .catch((error) => console.log(error, "Error"));
        });
      }
    }
  };

  const rejectAssetWitness = async () => {
    const toastID = toast.loading("Processing confirmation rejection");
    const { data, error } = await supabase.rpc("reject_asset_witnessed", {
      witness: id,
      current: userID,
    });

    if (error) {
      toast.update(toastID, {
        render: error.message,
        isLoading: false,
        type: "error",
        autoClose: 5000,
      });
    } else {
      setAsset((asset) => ({ ...asset, ...data }));

      console.log("Returned witnesses: ", data);
      toast.update(toastID, {
        render: "Successfully rejected assets",
        isLoading: false,
        type: "success",
        autoClose: 5000,
      });

      const { data: executive } = await supabase
        .from("_member_profiles")
        .select(
          `
                  id, 
                  fullname
              `
        )
        .in("position_in_sacco", [
          "secretary",
          "assistant secretary",
          "chairperson",
          "vice chairperson",
          "chairperson credits",
          "vice chairperson credits",
          "treasurer",
          "assistant treasurer",
        ])
        .eq("is_under_termination", false);

      if (executive && executive?.length > 0) {
        executive.forEach(async (member) => {
          await sendSMS(
            member.id,
            `Hello ${member.fullname}, one of the witnesses for your SACCO asset acquisition has rejected their statement. Please confirm at: https://tube.ablestate.co/organisation/assets`
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
            })
            .catch((error) => console.log(error, "Error"));
        });
      }
    }
  };

  return (
    <div>
      <ToastContainer position="top-center" />
      <h1 className={`text-left font-bold uppercase my-5 mx-5`}>ASSET</h1>
      <div
        className={`bg-white dark:bg-dark-bg dark:text-secondary-text p-10 shadow-md flex flex-col items-center overflow-y-scroll mx-5 h-[calc(100vh-100px)]`}
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full ">
              <p className="col-span-2">Name</p>
              <p className={`font-bold col-span-3 capitalize `}>
                : {asset?.name}
              </p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Type</p>
              <p className="font-bold col-span-3 capitalize">: {asset?.type}</p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Valuation</p>
              <p className="font-bold col-span-3 capitalize">
                : UGX {currencyFormatter(asset?.valuation)}
              </p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Date Acquired</p>
              <p className="font-bold col-span-3">
                : {moment(asset?.date_acquired).format("DD-MM-YYYY HH:mm:ss")}
              </p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 w-full">
              <p className="col-span-2">Asset Status</p>
              <p className="font-bold col-span-3">
                {": "}
                <span
                  className={` py-1 px-2 rounded-xl text-white ${
                    asset?.status === "approved"
                      ? "bg-emerald-400"
                      : asset?.status === "pending"
                      ? "bg-yellow-400"
                      : "bg-rose-400"
                  } `}
                >
                  {asset?.status === "pending"
                    ? "pending approval"
                    : asset.status}
                </span>
              </p>
            </div>

            <div className="grid grid-cols-5 gap-2 mb-2 w-full">
              <p className="col-span-2">Witness Status</p>
              <p className="font-bold col-span-3">
                {": "}
                <span
                  className={` py-1 px-2 rounded-xl text-white ${
                    witnessStatus === "approved"
                      ? "bg-emerald-400"
                      : witnessStatus === "pending"
                      ? "bg-yellow-400"
                      : witnessStatus === "rejected"
                      ? "bg-rose-400"
                      : ""
                  } `}
                >
                  {witnessStatus === "pending"
                    ? "pending approval"
                    : witnessStatus}
                </span>
              </p>
            </div>

            {imageURL && (
              <div className="grid grid-cols-5 gap-2 justify-start w-full items-center">
                <p className="col-span-2">Supporting Documents </p>
                <div className="flex min-w-fit col-span-3 items-center">
                  <span className="font-bold">:</span>
                  <a
                    className="px-2 text-sky-400 underline mt-2"
                    href={imageURL}
                    title="Files"
                    target="_blank"
                  >
                    Preview file
                  </a>
                  {/* <a
                    className="px-2 text-sky-400 underline mt-2"
                    href={imageURL}
                    title="Files"
                    download={true}
                  >
                    Download file
                  </a> */}
                </div>
              </div>
            )}

            {/* This is the description Here */}
            <p className="w-full text font-bold">Description </p>
            <p className="text-left w-full">{asset?.description}</p>
            {asset?.witnesses &&
              asset?.witnesses?.length > 0 &&
              asset.witnesses.findIndex(
                ({ id, confirmation_status }) =>
                  id === userID && confirmation_status === "pending"
              ) >= 0 && (
                <Fragment>
                  <h1 className="w-full text-left font-bold confirm">
                    Witness Confirmation
                  </h1>
                  <p className="w-full text-left mb-2 font-medium">
                    Do you affirm that witnessed the aquisition of this asset?
                  </p>
                  <div className="w-full flex justify-left gap-2 text-white">
                    <button
                      onClick={affirmAssetWitness}
                      className="bg-green-500 text-center p-2 border-none w-10 rounded-sm"
                    >
                      Yes
                    </button>
                    <button
                      onClick={rejectAssetWitness}
                      className="bg-red-600 px-2 text-center p-2 border-none w-10 rounded-sm"
                    >
                      No
                    </button>
                  </div>
                </Fragment>
              )}
          </>
        )}
      </div>
      Let's do get Done with this
    </div>
  );
}

export default Verification;
