import { Nominee } from "../../components";
import { InputField } from "../../components/Form/CustomInputField";
import { Formik, Form, ErrorMessage } from "formik";
import { member2ValidationSchema } from "../../helpers/validator";
import { useLocation, useOutletContext, useNavigate } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { useAuth } from "../../auth/AuthContext";
import { toast } from "react-toastify";
import PasswordGenerator from "../../components/Form/PasswordGenerator";
import { addMember } from "../../helpers/addMember";
import {
  add_separator,
  remove_separator,
} from "../../helpers/thousand_separator";

function ApplicationPg2({
  initialValues,
  setInitialValues,
  pageNumber,
  setPageNumber,
  password,
  setPassword,
}) {
  const defaultInitialValues = {
    fullname: "",
    dob: "",
    gender: "",
    present_address: "",
    email_address: "",
    phone_number: "",
    id_passport_number: "",
    marital_status: "",
    fathers_name: "",
    fathers_address: "",
    income_sources: {
      status: "",
      employed: {
        employers_name: "",
        employers_address: "",
        position: "",
        work_station: "",
        gross_monthly_income: "",
        appointment_date: "",
        payroll_number: "",
        source_of_income: "",
      },
      business: {
        business_name: "",
        business_address: "",
        business_location: "",
        other_income_sources: "",
      },
    },
    nominees: [
      {
        nominee_id: "",
        percentage: "",
      },
    ],
    proposed_mode_of_remittances: {
      standing_order: false,
      direct_debit: false,
      date_effective: "",
      others: "",
    },
    proposed_monthly_contributions: "",
    amount_in_words: "",
  };

  const { proposed_monthly_contributions, amount_in_words } = initialValues;

  const location = useLocation();
  const {
    user: { id: applicants_id },
  } = useAuth();
  const [user, profile, setProfile] = useOutletContext();
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    setInitialValues({ ...initialValues, ...values });
    const {
      fullname: applicants_name,
      phone_number,
      evidence,
      ...rest
    } = values;
    const toastID = toast.loading("Processing application form");

    if (evidence) {
      const [, fileExtension] = evidence.name.split(".");
      const timestamp = new Date().getTime().toString();

      const { error } = await supabase.storage
        .from("membership")
        .upload(`payment_receipt_${timestamp}.${fileExtension}`, evidence, {
          cacheControl: 3600,
          upsert: true,
        });

      if (error) {
        console.log("Upload Error: ", error);
      } else {
        const { publicURL, error } = supabase.storage
          .from("membership")
          .getPublicUrl(`payment_receipt_${timestamp}.${fileExtension}`);

        if (error) {
          console.log("Download Error: ", error);
        } else {
          values.evidence_url = publicURL;
          console.log("Public Url: ", publicURL);
        }
      }
    }

    try {
      if (location.state?.from === "/members") {
        const { position_in_sacco: administrator } = profile;

        addMember(
          `256${phone_number.slice(1)}`,
          password,
          values,
          administrator
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("Data: ", data);
            if (data?.Status === "Success") {
              toast.update(toastID, {
                render: `Member has successfully been created.`,
                type: "success",
                isLoading: false,
                autoClose: 5000,
              });
            }

            setPassword("");
            setInitialValues(defaultInitialValues);
          })
          .catch((error) =>
            toast.update(toastID, {
              render: `${error.message}`,
              type: "success",
              isLoading: false,
              autoClose: 5000,
            })
          );
      } else {
        const { error, data } = await supabase
          .from("applications")
          .insert([
            {
              _type: "membership",
              created_at: new Date()
                .toISOString()
                .toLocaleString("en-GB", { timeZone: "UTC" }),
              updated_at: new Date()
                .toISOString()
                .toLocaleString("en-GB", { timeZone: "UTC" }),
              reviewed: false,
              application_meta: {
                applicants_id,
                applicants_name,
                review_status: "pending",
                ...rest,
                ...values,
              },
            },
          ])
          .single();

        if (error) {
          throw error;
        } else {
          setInitialValues({ values: initialValues });
          toast.update(toastID, {
            render: `Membership application submitted for review`,
            autoClose: 5000,
            isLoading: false,
            type: "success",
          });

          const { data, error } = await supabase
            .from("_member_profiles")
            .select()
            .eq("id", applicants_id)
            .single();

          if (error) {
            throw error;
          } else {
            setProfile(data);
            navigate("/dashboard");
          }
        }
      }

      // resetForm();
    } catch (error) {
      // handle the errors depending on error status codes & give appropriate messages to the users
      toastID.update(toastID, {
        render: `${error?.message}`,
        type: "error",
        isLoading: "false",
        autoClose: 5000,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={member2ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form>
          <div className="mb-3">
            <span className="text-sm text-red-500 mb-3">
              All Fields with a * are mandatory
            </span>
            <div className="flex flex-wrap gap-5">
              <div className="flex flex-col w-58 mb-3">
                <label htmlFor="amount" className=" text-sm">
                  Proposed Monthly Contributions
                  <span className="text-sm text-red-500 ml-2 font-bold">*</span>
                </label>
                <input
                  type="text"
                  name="proposed_monthly_contributions"
                  id="proposed_monthly_contributions"
                  placeholder="Monthly Contributions"
                  className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                    errors?.proposed_monthly_contributions &&
                    touched?.proposed_monthly_contributions
                      ? "ring-red-500"
                      : "ring-black"
                  }`}
                  onChange={(event) => {
                    let formatted_string = add_separator(
                      remove_separator(event.target.value)
                    );
                    event.target.value = formatted_string;
                    values.proposed_monthly_contributions = parseFloat(
                      remove_separator(event.target.value)
                    );
                    setInitialValues((initialValues) => ({
                      ...initialValues,
                      ...values,
                    }));
                  }}
                  onBlur={handleBlur}
                  defaultValue={add_separator(proposed_monthly_contributions)}
                />
                <div className="flex relative mt-1">
                  <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                    {" "}
                  </div>
                  <div className="text-xs text-gray-400 pl-3">
                    Use figures without separators
                  </div>
                </div>
                <ErrorMessage name="proposed_monthly_contributions">
                  {(msg) => <span className="text-xs text-red-500">{msg}</span>}
                </ErrorMessage>
              </div>
              <InputField
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="amount_in_words"
                label="Amount in words"
                placeholder="Enter amount in words."
                defaultValue={amount_in_words}
                type="text"
                initialValues={initialValues}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
                instructions={["Use letters only"]}
              />
            </div>
          </div>
          <div className="mb-3">
            <h1 className="font-semibold">
              Proposed Mode of Remittances-check off
            </h1>
            <div className="flex flex-wrap gap-5">
              <div className="flex justify-center items-center gap-2">
                <input
                  type="checkbox"
                  name="proposed_mode_of_remittances[standing_order]"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultChecked={
                    initialValues.proposed_mode_of_remittances
                      ?.standing_order || false
                  }
                />
                <label htmlFor="">Standing Order</label>
              </div>
              <div className="flex justify-center items-center gap-2">
                <input
                  type="checkbox"
                  name="proposed_mode_of_remittances[direct_debit]"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultChecked={
                    initialValues.proposed_mode_of_remittances?.direct_debit ||
                    false
                  }
                />
                <label htmlFor="">Direct Debit</label>
              </div>
              <InputField
                errors={errors}
                touched={touched}
                id="proposed_mode_of_remittances.others"
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="proposed_mode_of_remittances[others]"
                label="Specify Others"
                placeholder="Enter other modes"
                defaultValue={
                  initialValues.proposed_mode_of_remittances?.others || ""
                }
                type="text"
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                setFieldValue={setFieldValue}
              />
              <InputField
                errors={errors}
                touched={touched}
                id="proposed_mode_of_remittances.date_effective"
                handleChange={handleChange}
                handleBlur={handleBlur}
                reference="proposed_mode_of_remittances[date_effective]"
                label="Effective Date (dd/mm/yyyy)"
                placeholder="dd/mm/yyyy"
                defaultValue={
                  initialValues.proposed_mode_of_remittances?.date_effective
                }
                type="date"
                initialValues={initialValues}
                setFieldValue={setFieldValue}
                setInitialValues={setInitialValues}
              />
            </div>
          </div>
          <Nominee
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            setFieldValue={setFieldValue}
          />
          <div>
            <h1 className="font-semibold">Membership Fee</h1>
            <div className="flex flex-wrap gap-5">
              <div className="flex flex-col w-56 mb-3">
                <label htmlFor="membership_fee" className=" text-sm">
                  Membership fee in figures
                  <span className="text-sm text-red-500 ml-2 font-bold">*</span>
                </label>
                <input
                  type="text"
                  name="membership_fee"
                  id="membership_fee"
                  placeholder="Enter membership fee"
                  className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                    errors?.membership_fee && touched?.membership_fee
                      ? "ring-red-500"
                      : "ring-black"
                  }`}
                  onChange={(event) => {
                    let formatted_string = add_separator(
                      remove_separator(event.target.value)
                    );
                    event.target.value = formatted_string;
                    setFieldValue(
                      event.target.name,
                      parseFloat(remove_separator(event.target.value))
                    );

                    setInitialValues((initialValues) => ({
                      ...initialValues,
                      membership_fee: parseFloat(
                        remove_separator(event.target.value)
                      ),
                    }));
                  }}
                  onBlur={handleBlur}
                  defaultValue={
                    initialValues?.membership_fee
                      ? add_separator(initialValues.membership_fee)
                      : ""
                  }
                />

                <div className="flex relative mt-1">
                  <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                    {" "}
                  </div>
                  <div className="text-xs text-gray-400 pl-3">
                    Use figures without separators.
                  </div>
                </div>

                {touched?.membership_fee && errors?.membership_fee && (
                  <div className="error text-red-600 text-xs">
                    {errors?.membership_fee}
                  </div>
                )}
              </div>
              <div className="flex flex-col w-56 ">
                <label className=" text-sm">
                  Evidence
                  <span className="text-sm text-red-500 ml-2 font-bold">*</span>
                </label>
                <input
                  type="file"
                  name="evidence"
                  className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                    errors?.evidence && touched?.evidence
                      ? "ring-red-500"
                      : "ring-black"
                  }`}
                  onChange={(event) => {
                    setFieldValue(event.target.name, event.target.files[0]);
                    setInitialValues((initialValues) => ({
                      ...initialValues,
                      evidence: event.target.files[0],
                    }));
                  }}
                  onBlur={handleBlur}
                  accept="image/png, image/jpeg, application/pdf, .doc, .docx"
                />
                {/* <span className="text-sm font-bold text-gray-400 uppercase">
                  Instructions
                </span> */}
                <div className="flex relative mt-1">
                  <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                    {" "}
                  </div>
                  <div className="text-xs capitalize text-gray-400 pl-3">
                    Click choose file
                  </div>
                </div>
                <div className="flex relative">
                  <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                    {" "}
                  </div>
                  <div className="text-xs capitalize text-gray-400 pl-3">
                    Select the file
                  </div>
                </div>
                <div className="flex relative">
                  <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                    {" "}
                  </div>
                  <div className="text-xs capitalize text-gray-400 pl-3">
                    Click upload
                  </div>
                </div>
                {errors?.evidence && touched?.evidence && (
                  <div className="text-red-500 text-xs">{errors.evidence}</div>
                )}
              </div>
            </div>
          </div>
          {location.state?.from === "/members" && (
            <div>
              <PasswordGenerator
                password={password}
                setPassword={setPassword}
              />
            </div>
          )}
          <div className="flex justify-between w-full">
            <input
              type="submit"
              value="Previous"
              className="outline outline-gray-500 outline-2 text-gray-500 px-4 py-1 rounded-lg cursor-pointer"
              onClick={(event) => {
                event.preventDefault();
                setInitialValues({ ...initialValues, ...values });
                setPageNumber(pageNumber - 1);
              }}
            />
            <button
              type="button"
              onClick={(event) => {
                event.preventDefault();
                console.log("Errors: ", errors);
                console.log("Form Values: ", values);
              }}
            >
              Debug
            </button>
            <button
              type="submit"
              className="outline outline-primary outline-2 text-white bg-primary px-4 py-1 rounded-lg cursor-pointer"
              disabled={
                location.state?.from === "/member" && !password ? true : false
              }
            >
              submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ApplicationPg2;
