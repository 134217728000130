/**
 * @function 
 * @name sendSMS
 * @param {string} applicants_id the id of the member whom you'd want to send to 
 * @param {string} message the message that you want to send to a member
 * @returns {Object} An object containing the msg if the API has successfully been reached. An error object containing the key error if the SMS service API has not been reached.
 */

export const sendSMS = async ( applicants_id, message) => {
    const response = await fetch("/api/sendmessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ applicants_id: applicants_id, message: message })
    })

    return response    
}