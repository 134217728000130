import { Pagination, Spinner, NothingShown } from "../../components";
import { useState, useEffect, Fragment } from "react";
import { supabase } from "../../helpers/supabase";
import { TbFilterOff } from "react-icons/tb";
import { Helmet } from "react-helmet";
import ServiceProviderModal from "../../components/Modals/ServiceProviderModal";
import moment from "moment";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import DateRangeFilter from "../../components/DateRangeFilter";
import Frown from "../../components/Frown";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "../../hooks";
import { useOutletContext } from "react-router-dom";
import { RiPencilFill } from "react-icons/ri";
import { AiFillDelete } from "react-icons/ai";
import { ConfirmModal } from "../../components";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { InputField } from "../../components/Form/CustomInputField";

export default function ServiceProviders() {
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allProviders, setAllProviders] = useState([]);
  const [nothingShown, setNothingShown] = useState(false);
  const [, profile] = useOutletContext();
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [providerModal, setProviderModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    name: "",
    service: "",
    description: "",
    created_at: "",
    updated_at: "",
  });

  useEffect(() => {
    supabase.from("service_providers").on("*", async (payload) => {
      console.log("Payload: ", payload);
      await getDeposits();
    }).subscribe();
    getDeposits();
    // return () => {};
  }, []);

  const [date, setDate] = useState(null);

  const getDeposits = async () => {
    const { data, error } = await supabase.rpc("get_service_providers");
    if (error) {
      setLoading(false);
      throw error;
    } else {
      if (data?.length === 0 || !data) setNothingShown(true);

      setLoading(false);
      setDeposits(data);
      setAllProviders(data);
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [depositsPerPage, setDepositsPerPage] = useState(10);
  const indexOfLastPage = currentPage * depositsPerPage;
  const indexOfFirstPage = indexOfLastPage - depositsPerPage;

  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  let filteredDeposits =
    deposits && deposits?.length > 0
      ? deposits.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  useEffect(() => {
    let data = allProviders;
    data =
      data && data?.length > 0
        ? data.filter(
            (deposit) => !date || deposit.created_at.substring(0, 10) === date
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(startDate).isSameOrBefore(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(endDate).isSameOrAfter(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(loan?.created_at?.substring(0, 10)).isBetween(
                moment(startDate),
                moment(endDate),
                undefined,
                "[]"
              );
            })
          : null;
    }

    setDeposits(data ?? []);
  }, [date, startDate, endDate, allProviders]);

  const generate_deposits_report = () => {
    const formattedDeposits = allProviders.map((deposit) => {
      return {
        "Transaction ID": deposit?.application_meta
          ? deposit?.app_id
          : deposit?.trans_id,
        Date: moment(deposit?.created_at).format("DD-MM-YYYY"),
        Amount: deposit?.transaction_meta
          ? deposit?.amount
          : deposit?.application_meta?.amount,
        Account: deposit?.transaction_meta
          ? deposit?.transaction_meta?.account_type
          : deposit?.application_meta?.account_type,
        "Approved At": deposit?.transaction_meta
          ? moment(deposit.transaction_meta?.approved_at).format("DD-MM-YYYY")
          : "",
        Status: deposit?.transaction_meta ? "approved" : "pending",
        "Approved By": deposit?.transaction_meta
          ? deposit?.transaction_meta?.approved_by
          : "",
      };
    });

    generateReportFromJson(
      formattedDeposits,
      `${profile.fullname} Deposits ${new Date()
        .toISOString()
        .substring(0, 10)} `
    );
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-140px)]">
      <Helmet>
        <title>Service Providers - Bweyogere tuberebumu</title>
      </Helmet>
      <ToastContainer />
      <div className="flex flex-col pb-3 md:h-[150px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Service providers
        </h1>
        <div className="flex flex-wrap gap-2 justify-between">
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id="date"
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={date}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <DateRangeFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          {/* <div className="flex justify-between text-sm"> */}
          <button
            onClick={() => {
              if (date) setDate("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              if (startDate) setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              if (endDate) setEndDate("");
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {largeDesktop && !smallDesktop && "Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
          <button
            className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
            onClick={() => {
              generate_deposits_report();
            }}
            disabled={!allProviders || allProviders?.length === 0}
          >
            {largeDesktop && !smallDesktop && "Export"}
            <MdDownload className="ml-1" />
          </button>
          <button
            className="bg-primary align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
            onClick={() => {
              console.log("Let's do something here");
              navigate("/organisation/service-providers/add-providers");
            }}
          >
            Add Provider
          </button>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-120px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : filteredDeposits && filteredDeposits.length > 0 ? (
          <>
            <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Date</th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">Service</th>
                    <th className="px-6 py-4">Added by</th>
                    <th className="px-6 py-4">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDeposits.map((provider, index) => (
                    <Fragment key={index}>
                      <tr
                        className={`${
                          index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                        } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                        key={index}
                      >
                        <td
                          onClick={() => {
                            setActiveIndex(index);
                            setProviderModal(true);
                          }}
                        >
                          <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                        </td>
                        <td
                          className="pr-6 py-3"
                          onClick={() => {
                            setActiveIndex(index);
                            setProviderModal(true);
                          }}
                        >
                          {moment(provider.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td className="px-6 py-3">{provider?.name}</td>
                        <td
                          className="px-6 py-3 capitalize"
                          onClick={() => {
                            setActiveIndex(index);
                            setProviderModal(true);
                          }}
                        >
                          {provider?.service}
                        </td>
                        <td
                          className="px-6 py-3"
                          onClick={() => {
                            setActiveIndex(index);
                            setProviderModal(true);
                          }}
                        >
                          {provider.added_by}
                        </td>
                        <td className="gap-1 align-middle px-6">
                          <button
                            className="p-1 border rounded-lg my-auto mr-1 hover:text_white hover:border-primary group"
                            onClick={(event) => {
                              event.preventDefault();
                              setActiveIndex(() => index);
                              console.log(
                                "Let's edit the service provider now"
                              );
                              setEditModal(true);
                            }}
                          >
                            <RiPencilFill
                              size={15}
                              className="group-hover:text-primary"
                            />
                          </button>
                          <button
                            className="p-1 border rounded-lg my-auto mr-1 hover:text_white hover:border-accent-red group"
                            onClick={(event) => {
                              event.preventDefault();
                              setActiveIndex(() => index);
                              setDeletePopUp(true);
                            }}
                          >
                            <AiFillDelete
                              size={15}
                              className="group-hover:text-accent-red"
                            />
                          </button>
                        </td>
                      </tr>
                      {editModal && activeIndex === index && (
                        <ConfirmModal setPopUp={setEditModal}>
                          <Formik
                            initialValues={initialValues}
                            onSubmit={async (values, { resetForm }) => {
                              console.log("Form Values", values);

                              const toastId = toast.loading("Processing");

                              try {
                                const { data, error } = await supabase
                                  .from("service_providers")
                                  .update({
                                    ...values,
                                    created_at: new Date()
                                      .toISOString()
                                      .toLocaleString("en-GB", {
                                        timeZone: "UTC",
                                      }),
                                    updated_at: new Date()
                                      .toISOString()
                                      .toLocaleString("en-GB", {
                                        timeZone: "UTC",
                                      }),
                                    provider_meta: {
                                      added_by: profile.id,
                                    },
                                  })
                                  .eq("id", provider.id);

                                if (error) {
                                  throw error;
                                } else if (data) {
                                  console.log("Data: ", data);
                                  toast.update(toastId, {
                                    render: "Provider successfully updated",
                                    type: "success",
                                    isLoading: false,
                                    autoClose: 5000,
                                  });
                                }
                              } catch (error) {
                                console.log("Error: ", error);

                                toast.update(toastId, {
                                  render: `Failure. Please try again later`,
                                  type: "error",
                                  isLoading: false,
                                  autoClose: 5000,
                                });
                              }
                            }}
                          >
                            {({
                              touched,
                              errors,
                              setFieldValue,
                              handleChange,
                              handleBlur,
                              setSubmitting,
                            }) => {
                              return (
                                <Form className="grid grid-cols-2 gap-5">
                                  <InputField
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    reference="name"
                                    defaultValue={provider?.name}
                                    label="Name"
                                    placeholder="Enter Name"
                                    setFieldValue={setFieldValue}
                                    mandatory={true}
                                  />
                                  <InputField
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    reference="service"
                                    defaultValue={provider?.service}
                                    label="Service"
                                    placeholder="Enter Service"
                                    setFieldValue={setFieldValue}
                                    mandatory={true}
                                  />
                                  <div className="flex justify-end mt-5 col-span-2">
                                    <button
                                      className=" px-4 bg-primary py-1 text-white flex justify-center items-center"
                                      type="submit"
                                      onClick={() => setSubmitting(true)}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </ConfirmModal>
                      )}
                      {deletePopUp && activeIndex === index && (
                        <ConfirmModal setPopUp={setDeletePopUp}>
                          <h1 className="font-bold">
                            Are you sure you want to delete {provider?.name}?
                          </h1>
                          <p>
                            {provider?.name} will be deleted permanently from
                            your service providers
                          </p>
                          <div className="flex justify-end gap-3 mt-3">
                            <button
                              className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                              onClick={() => setDeletePopUp(false)}
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-accent-red px-3 py-1 outline outline-1 outline-accent-red rounded-md text-white"
                              onClick={async (event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setDeletePopUp(false);

                                const toastNotification = toast.loading(
                                  "Deleting service provider."
                                );

                                try {
                                  const { data, error } = await supabase
                                    .from("service_providers")
                                    .delete()
                                    .match({ id: provider.id })
                                    .single();

                                  if (error) {
                                    console.log("Deletion error: ", error);
                                  } else {
                                    console.log("Data: ", data);
                                    toast.update(toastNotification, {
                                      render: ` Successfully deleted service provider`,
                                      type: "success",
                                      isLoading: false,
                                      autoClose: 5000,
                                    });
                                  }
                                } catch (error) {
                                  console.log("Error: ", error);
                                  toast.update(toastNotification, {
                                    render: `Failure. Please try again later`,
                                    type: "error",
                                    isLoading: false,
                                    autoClose: 5000,
                                  });
                                }
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </ConfirmModal>
                      )}
                      {providerModal && index === activeIndex && (
                        <ServiceProviderModal
                          deposit={provider}
                          setDepositModal={setProviderModal}
                        />
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(deposits.length / depositsPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={deposits}
                depositsPerPage={depositsPerPage}
                setDepositsPerPage={setDepositsPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allProviders.length > 0 &&
          (filteredDeposits?.length === 0 || !filteredDeposits) && <Frown />
        )}
      </div>
    </div>
  );
}
