import { Pagination, Spinner, NothingShown } from "../../components";
import { useState, useEffect, Fragment } from "react";
import { supabase } from "../../helpers/supabase";
import { TbFilterOff } from "react-icons/tb";
import { Helmet } from "react-helmet";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { useOutletContext } from "react-router-dom";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import DateRangeFilter from "../../components/DateRangeFilter";
import Frown from "../../components/Frown";
import { toast } from "react-toastify";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "../../hooks";

export default function Shares() {
  const [transactions, setTransactions] = useState([]);
  const [account, setAccount] = useState({});
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [nothingShown, setNothingShown] = useState(false);
  const [allTransactions, setAllTransactions] = useState([]);

  const [user, profile] = useOutletContext();
  const [date, setDate] = useState(null);

  const getAccount = async () => {
    const { data, error } = await supabase
      .from("shares_accounts")
      .select()
      .eq("member_id", profile.id)
      .single();
    if (error) throw error;
    setAccount(data ? data : null);
  };

  const getSharesTransactions = async () => {
    const {
      data: { monthly_charges, shares_transactions, applications },
      error,
    } = await supabase.rpc("fetch_shares_transactions");

    console.log(applications);

    if (error) throw error;
    let data = [];
    if (monthly_charges) data.push(...monthly_charges);
    if (shares_transactions) data.push(...shares_transactions);
    if (applications) data.push(...applications);
    data.sort(
      (trans1, trans2) =>
        new Date(trans2.created_at) - new Date(trans1.created_at)
    );
    if (data?.length === 0 || !data) setNothingShown(true);
    setTransactions(data ?? []);
    setAllTransactions(data ?? []);
    setLoading(false);
  };

  const create_account = async () => {
    try {
      const { error } = await supabase.rpc("create_shares_account", {});
      if (error) throw error;
      console.log("success");
      toast.success(`Account successfully opened`, { position: "top-center" });
    } catch (error) {
      console.log("failed ", error);
      toast.error(
        `${
          error?.code === "23505"
            ? "You already have an account."
            : "Please try again later."
        }`,
        { position: "top-center" }
      );
    }
  };

  useEffect(() => {
    getAccount().catch((error) => {
      if (error?.code === "PGRST116") {
        setAccount(null);
      }
    });
    getSharesTransactions();
  }, []);

  useEffect(() => {
    let data = allTransactions;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((transaction) => {
              return moment(startDate).isSameOrBefore(
                moment(transaction?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((transaction) => {
              return moment(endDate).isSameOrAfter(
                moment(transaction?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((transaction) => {
              return moment(
                transaction?.created_at?.substring(0, 10)
              ).isBetween(moment(startDate), moment(endDate), undefined, "[]");
            })
          : null;
    } else if (date) {
      if (data) {
        data =
          data && data?.length > 0
            ? data.filter(
                (transaction) =>
                  transaction.created_at.substring(0, 10) === date
              )
            : null;
      } else {
        data = null;
      }
    }

    data =
      !data ||
      data
        .filter((transaction) =>
          !status || status === ""
            ? transaction
            : status === "approved"
            ? transaction?.transaction_meta
            : transaction?.application_meta?.review_status === status
        )
        .filter(
          (transaction) =>
            !type ||
            (transaction?.transaction_meta
              ? transaction?._type === type
              : transaction?._type === type)
        );

    setTransactions(data ?? []);
  }, [date, startDate, endDate, type, status]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastPage = currentPage * itemsPerPage;
  const indexOfFirstPage = indexOfLastPage - itemsPerPage;

  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);

  const generate_transactions_report = () => {
    const formattedTransactions = allTransactions.map((transaction) => {
      return {
        "Transaction ID": transaction?.application_meta
          ? transaction?.app_id
          : transaction?.trans_id,
        Date: transaction?.created_at,
        Amount: transaction?.transaction_meta
          ? transaction?.amount
          : transaction?.application_meta?.amount,
        Type: transaction?._type,
        status: transaction?.application_meta
          ? transaction.application_meta?.review_status === "pending"
            ? "pending approval"
            : transaction.application_meta?.review_status
          : "approved",
      };
    });

    generateReportFromJson(
      formattedTransactions,
      "Personal shares transactions report"
    );
  };

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  let filteredTransactions = transactions;
  filteredTransactions =
    filteredTransactions && filteredTransactions?.length > 0
      ? filteredTransactions.slice(indexOfFirstPage, indexOfLastPage)
      : null;

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  return (
    <div className="mx-5 my-2 h-[calc(100vh-140px)]">
      <Helmet>
        <title>Shares Account - Bweyogere tuberebumu</title>
      </Helmet>
      <div className="flex flex-col justify-between mb-5 md:mb-8 pb-3 md:h-[150px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Shares Account
        </h1>

        <div className="flex flex-wrap gap-2 justify-between">
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-4 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
            >
              <option value="">Status</option>
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <select
              name="type"
              id=""
              className="py-2 px-2 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text w-40"
              onChange={(event) => setType(event.target.value)}
              value={type}
            >
              <option value="">type</option>
              <option value="deposit">Deposit</option>
              <option value="withdraw">Withdraw</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (type) setType("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>

          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id="inputDate"
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={date}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
                document.getElementById("inputDate").value = "";
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <DateRangeFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          <button
            onClick={(status) => {
              if (status) setStatus("");
              if (date) setDate("");
              if (type) setType("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              if (startDate) setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              if (endDate) setEndDate("");
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {largeDesktop && !smallDesktop && "Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
          <button
            className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
            onClick={() => {
              generate_transactions_report();
            }}
            disabled={!allTransactions || allTransactions?.length === 0}
          >
            {largeDesktop && !smallDesktop && "Export"}
            <MdDownload className="ml-1" />
          </button>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-110px)] dark:bg-dark-bg-700 dark:text-secondary-text">
        {account && Object.keys(account).length > 0 ? (
          <div className="flex h-full flex-col p-3 w-full">
            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Account ID:</p>
              <p className="font-bold col-span-3">
                {account && account?.account_no}
              </p>
            </div>
            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Owned by:</p>
              <p className="font-bold col-span-3">
                {account && account?.account_meta.owners_name || profile.fullname}
              </p>
            </div>
            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Account Balance:</p>
              <p className="font-bold col-span-3">
                UGX {account && currencyFormatter(account?.balance)}
              </p>
            </div>
            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Created At:</p>
              <p className="font-bold col-span-3">
                {account && moment(account?.created_at).format("DD-MM-YYYY")}
              </p>
            </div>
            <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
              <p className="col-span-2">Account Status:</p>
              <p className="font-bold col-span-3">
                {account && account?.account_status}
              </p>
            </div>
            <h2 className="capitalize font-semibold mb-2">TRANSACTIONS</h2>
            <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-300px)] dark:bg-dark-bg-700">
              {transactions !== null &&
              filteredTransactions !== null &&
              filteredTransactions?.length > 0 ? (
                <>
                  <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                        <tr>
                          <th></th>
                          <th className="pr-6 py-4">Date</th>
                          <th className="px-6 py-4">Transaction ID</th>
                          <th className="px-6 py-4">Type</th>
                          <th className="px-6 py-4">Amount(UGX)</th>
                          <th className="px-6 py-4">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredTransactions.map((transaction, index) => (
                          <Fragment key={index}>
                            <tr
                              className={`${
                                index % 2 === 0
                                  ? "bg-gray-50 dark:bg-dark-bg"
                                  : ""
                              } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                              key={index}
                              onClick={() => {
                                // setDepositModal(true);
                                setActiveIndex(index);
                              }}
                            >
                              <td>
                                <span className="ml-2 px-4 py-3 text-sm">
                                  &gt;
                                </span>
                              </td>
                              <td className="pr-6 py-3">
                                {moment(transaction.created_at).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td className="px-6 py-3">
                                {transaction?.trans_id || transaction?.app_id}
                              </td>
                              <td className="px-6 py-3 capitalize">
                                {transaction?._type ||
                                  transaction?.application_meta?.type}
                              </td>
                              <td className="px-6 py-3">
                                {currencyFormatter(
                                  transaction?.amount ||
                                    transaction?.application_meta?.amount
                                )}
                              </td>
                              <td className="px-6 py-3">
                                <span
                                  className={` py-1 px-2 rounded-xl text-white min-w-fit ${
                                    transaction?.application_meta
                                      ?.review_status === "pending"
                                      ? "bg-yellow-400"
                                      : transaction?.application_meta
                                          ?.review_status === "rejected"
                                      ? "bg-red-400"
                                      : "bg-green-400"
                                  }`}
                                >
                                  {transaction?.application_meta
                                    ?.review_status === "pending"
                                    ? "pending approval"
                                    : transaction?.transaction_meta
                                    ? "approved "
                                    : transaction?.application_meta
                                        ?.review_status}
                                </span>
                              </td>
                            </tr>
                            {/* {depositModal && index === activeIndex && (
                              <DepositModal
                                deposit={deposit}
                                setDepositModal={setDepositModal}
                              />
                            )} */}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
                    <Pagination
                      pages={Math.ceil(transactions.length / itemsPerPage)}
                      setCurrentPage={setCurrentPage}
                      indexOfFirstPage={indexOfFirstPage}
                      indexOfLastPage={indexOfLastPage}
                      data={transactions}
                      depositsPerPagdDatae={itemsPerPage}
                      setDepositsPerPage={setItemsPerPage}
                    />
                  </div>
                </>
              ) : nothingShown ? (
                <NothingShown />
              ) : (
                !transactions ||
                (transactions?.length === 0 && !loading && <Frown />)
              )}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
