import { ErrorMessage, useFormikContext } from "formik";
import { useEffect } from "react";

export const InputField = ({
  errors,
  defaultValue = "",
  touched,
  reference,
  id,
  label,
  handleChange,
  handleBlur,
  mandatory,
  instructions,
  setFieldValue,
  setInitialValues,
  initialValues,
  ...props
}) => {
  const { values } = useFormikContext()
  useEffect(() => {
    if ( initialValues ) {
      setInitialValues(initialValues => ({ ...initialValues, ...values }))
    }

  }, [ values ])
  
  return (
    <div className="flex flex-col w-56">
      <label htmlFor={id} className="text-sm">
        {label}
        {mandatory && <span className="text-sm text-red-500 ml-2 font-bold">*</span>}
      </label>
      <input
        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 mb-1 ${errors[reference] && touched[reference] ? "ring-red-500"  : "ring-black"  }`}
        onChange={(event) => {
          setFieldValue(reference, event.target.value)
        }}
        defaultValue={defaultValue}
        onBlur={handleBlur(reference)}
        {...props}
      />
      {
        instructions?.length > 0 && <>
          {/* <span className="text-sm font-bold text-gray-400 uppercase">Instructions</span> */}
          {
            instructions.map((instruction, index) => {
              return <div key={index} className="relative">
                <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">{" "}</div>
                <div className="text-xs text-gray-400 pl-3">{instruction}</div>
              </div>
            })
          }
        </>
      }
      <ErrorMessage name={reference}>
        {(msg) => <div className="error text-xs text-red-500">{msg}</div>}
      </ErrorMessage>
    </div>
  );
} 
