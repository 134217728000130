import { Formik, Form } from "formik";
import { uploadFile } from "../../helpers/uploadFile";
import { toast, ToastContainer } from "react-toastify";
import { supabase } from "../../helpers/supabase";
import { useOutletContext, useParams } from "react-router-dom";
import { loanPaymentRequestValidationSchema } from "../../helpers/validator";
import { useEffect, useState } from "react";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import {
  add_separator,
  remove_separator,
} from "../../helpers/thousand_separator";
import { Spinner } from "../../components";
import { Helmet } from "react-helmet";
import { sendSMS } from "../../helpers/sendsms";

function LoanPayment() {
  // Will be used later
  const { id } = useParams();

  useEffect(() => {
    getApplications();
  }, []);

  const [loan, setLoan] = useState({});
  const [, { fullname: applicants_name, id: current_user }] =
    useOutletContext();
  const initialValues = {
    amount: "",
    evidence: "",
    comments: "",
  };
  const [loading, setLoading] = useState(false);

  const getApplications = async () => {
    const { error, data } = await supabase
      .from("loans")
      .select()
      .eq("id", id)
      .single();

    console.log("Loan Application: ", data);
    if (error) throw error;
    setLoan(data);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loanPaymentRequestValidationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const toastID = toast.loading("Processing payment application");
        setLoading(true);
        const { amount, comments, evidence } = values;

        try {
          let file_url = "";
          if (evidence) {
            const { Key: url } = await uploadFile(evidence, "loans");
            file_url = url;
          }

          const { error, data } = await supabase.from("applications").insert({
            _type: "payment",
            created_at: new Date()
              .toISOString()
              .toLocaleString("en-GB", { timeZone: "UTC" }),
            updated_at: new Date()
              .toISOString()
              .toLocaleString("en-GB", { timeZone: "UTC" }),
            reviewed: false,
            application_meta: {
              applicants_id: current_user,
              applicants_name,
              loan_id: id,
              loan_ref: loan.loan_id,
              amount,
              files: [
                {
                  file_url,
                },
              ],
              comments,
              account_type: "sacco",
              review_status: "pending",
              member_id: loan.member_id,
            },
          });

          if (error) throw error;
          if (data) {
            const { data: credits_committee } = await supabase
              .from("_member_profiles")
              .select(
                `
                  id, fullname, position_in_sacco
                `
              )
              .in("position_in_sacco", [
                "chairperson credits",
                "vice chairperson credits",
                "chairperson",
                "vice chairperson",
                "secretary",
                "assistant secretary",
              ])
              .eq("is_under_termination", false);

            if (credits_committee && credits_committee?.length > 0) {
              credits_committee.forEach(async (member) => {
                await sendSMS(
                  member.id,
                  `Hello ${
                    member.fullname.split(" ")[0]
                  }, a sacco member has made a payment on their loan. Please coordinate with all stakeholders to ensure to make an appropriate decision about the loans payment. For more details visit http://localhost:3000/loans/applications Regards, Bweyogerere Tuberebumu Sacco.`
                )
                  .then((response) => response.json())
                  .then((data) => {
                    console.log(data);
                  })
                  .catch((error) => console.log(error, "Error"));
              });
            }
            resetForm({ values: initialValues });
            setSubmitting(false);
            toast.update(toastID, {
              type: "success",
              render: `Request submitted for review.`,
              isLoading: false,
              autoClose: 5000,
            });
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          setSubmitting(false);
          toast.update(toastID, {
            render: `${error.message}`,
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <Form className="mx-5 my-2 h-[calc(100vh-70px)]">
            <Helmet>
              <title>Loan Payment - Bweyogere tuberebumu</title>
            </Helmet>
            <ToastContainer />
            <h1 className="mb-3 mt-2 font-bold uppercase dark:text-white">
              Loan Payment
            </h1>
            <div className="flex flex-col bg-white dark:bg-dark-bg-700 dark:text-secondary-text p-6 min-h-full relative">
              {loading ? (
                <Spinner className="h-full w-full absolute" />
              ) : (
                <>
                  <div className="m-2">
                    <label>
                      Total Amount To Pay:{" "}
                      <span className="font-bold">
                        {"UGX "}{" "}
                        {currencyFormatter(
                          (loan?.reducing_balance ?? 0) +
                            (loan?.interest_to_pay ?? 0)
                        )}
                      </span>
                    </label>
                  </div>
                  {errors?.amount &&
                    touched?.amount &&
                    console.log(errors?.amount && touched?.amount)}

                  <div className="m-2 flex flex-wrap gap-5 h-16">
                    <div className="flex flex-col w-56 ">
                      <label htmlFor="amount" className=" text-sm">
                        Enter Amount
                      </label>
                      <input
                        type="text"
                        name="amount"
                        id="amount"
                        placeholder="Enter Amount"
                        className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                          errors?.amount && touched?.amount
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={(event) => {
                          let formatted_string = add_separator(
                            remove_separator(event.target.value)
                          );
                          event.target.value = formatted_string;
                          setFieldValue(
                            event.target.name,
                            parseFloat(remove_separator(event.target.value))
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      {touched?.amount && errors?.amount && (
                        <div className="error text-red-600 text-xs">
                          {errors?.amount}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col w-56 ">
                      <label htmlFor="evidence" className=" text-sm">
                        Upload Receipt
                      </label>
                      <input
                        type="file"
                        name="evidence"
                        id="evidence"
                        placeholder="Enter postal address"
                        className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600`}
                        onChange={(event) => {
                          values.evidence = event.currentTarget.files[0];
                        }}
                        onBlur={handleBlur}
                        accept="image/png, image/jpeg, application/pdf, .doc, .docx"
                      />
                      {touched?.evidence && errors?.evidence && (
                        <div className="error text-red-600 text-xs">
                          {errors?.evidence}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-2">
                    <h1 className="font-semibold">
                      Comments{" "}
                      <span className="text-sm font-normal">
                        ( Specify the transaction ID )
                      </span>
                    </h1>
                    <textarea
                      name="comments"
                      id="comments"
                      cols="20"
                      rows="10"
                      className="outline outline-1 p-2 rounded-md w-full dark:bg-dark-bg-700"
                      onChange={handleChange("comments")}
                      defaultValue={values?.comments}
                      placeholder="Hint: Type transaction ID for payments made via mobile money or bank app and specify the service provider."
                    ></textarea>
                  </div>
                  <div className="w-56">
                    <input
                      type="submit"
                      value="submit"
                      className="bg-primary inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2 w-full mt-1 cursor-pointer"
                      disabled={isSubmitting}
                    />
                  </div>
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default LoanPayment;
