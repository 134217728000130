import { FaRegEdit } from "react-icons/fa";
import { ConfirmModal } from "../components";
import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import { supabase } from "../helpers/supabase";
import { toast, ToastContainer } from "react-toastify";
import EditModal from "../components/EditModal";
import { Spinner } from "../components";
import { changeUserPasswordValidationSchema } from "../helpers/validator";
import { useNavigate } from "react-router-dom";
import { sendSMS } from "../helpers/sendsms";

function Profile() {
  useEffect(() => {
    document.title = "Profile - Bweyogere tuberebumu";
  }, []);

  const [popUp, setPopUp] = useState(false);
  const [editPop, setEditPop] = useState(false);
  const [, profile, setProfile] = useOutletContext();
  const { id } = supabase.auth.user();
  const navigate = useNavigate();
  // const { setUser } = useAuth();
  const [, setLoading] = useState(false);
  const [revealPasswords, setRevealPasswords] = useState({
    current_password: false,
    new_password: false,
    confirm_password: false,
    self_termination: false,
  });

  const handleTermination = (event, values) => {
    event.preventDefault();
    supabase
      .rpc("check_password", {
        current_password: values.password,
        _user_id: id,
      })
      .then(async ({ data }) => {
        if (data) {
          setPopUp(true);
        } else {
          toast.error(`Wrong password.`, { position: "top-center" });
        }
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      });
    document.terminationForm.reset();
  };

  const handleChangePassword = async (event, values) => {
    event.preventDefault();
    event.stopPropagation();

    await supabase
      .rpc("check_password", {
        current_password: values.current_password,
        _user_id: id,
      })
      .then(async ({ data, error: check_error }) => {
        if (data) {
          const { user, error } = await supabase.auth.update({
            password: values.new_password,
          });
          if (user) {
            toast.success(`Successfully updated password.`, {
              position: "top-center",
            });
          } else if (error) {
            toast.error(`Error ${error}.`, { position: "top-center" });
          }
        } else {
          toast.error(`${check_error.message}`, { position: "top-center" });
        }
        return true;
      })
      .catch((error) => {
        console.log(`Error ${error}`);
        return false;
      });

    document.getElementById("changePasswordForm").reset();
  };

  return (
    <div className="mx-3 md:mx-5 mt-2 h-[calc(100vh-70px)] dark:text-[#b2b2b2]">
      <div className="flex flex-col justify-between pb-3 h-[60px]">
        <h1 className="mb-5 mt-2 font-bold uppercase text dark:text-white">
          Profile
        </h1>
      </div>
      <ToastContainer position="top-center" />
      <div className="bg-white p-6 overflow-y-auto  md:h-[calc(100%-65px)] dark:bg-dark-bg-700">
        {profile?.fullname ? (
          <>
            <h1 className="font-semibold mb-3">Profile Details</h1>
            <div className="flex justify-between items-start mb-5">
              {profile?.avatar ? (
                <div
                  className="w-16 h-16 bg-accent rounded-full mx-2 overflow-hidden bg-cover"
                  style={{ backgroundImage: `url(${profile?.avatar})` }}
                ></div>
              ) : (
                <span className="h-16 w-16 bg-accent dark:bg-dark-bg-600 rounded-full flex justify-center font-bold items-center overflow-hidden">
                  {profile?.fullname !== undefined &&
                    profile.fullname !== null &&
                    ` ${profile?.fullname.split("")[0]}`}
                </span>
              )}
              <i
                className="text-white p-2 bg-primary rounded text-lg cursor-pointer"
                onClick={() => setEditPop(true)}
              >
                <FaRegEdit />
              </i>
            </div>
            <section className="mb-5">
              <div className="grid grid-cols-5 gap-2 mb-2">
                <p className=" col-span-2">Name</p>
                <p className="font-bold  col-span-3">{profile?.fullname}</p>
              </div>
              <div className="grid grid-cols-5 gap-2 mb-2">
                <p className=" col-span-2">Telephone Number</p>
                <p className="font-bold  col-span-3">{profile?.phone_number}</p>
              </div>
              <div className="grid grid-cols-5 gap-2 mb-2">
                <p className=" col-span-2">Email</p>
                <p className="font-bold  col-span-3">
                  {profile?.email_address}
                </p>
              </div>
              <div className="grid grid-cols-5 gap-2 mb-2">
                <p className=" col-span-2">{`${
                  profile &&
                  profile?.roles &&
                  profile.roles.includes("super_admin")
                    ? "Super Admin"
                    : "Member"
                } Status`}</p>
                <div className=" col-span-3 flex justify-start">
                  <p
                    className={`${
                      profile?.member_status === "active"
                        ? "bg-green-600"
                        : "bg-accent-red"
                    } font-bold text-white rounded-sm flex px-3`}
                  >
                    {profile?.member_status ?? "pending"}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-5 gap-2 mb-2">
                <p className=" col-span-2">Marital Status</p>
                <p className="font-bold  col-span-3">
                  {profile?.marital_status}
                </p>
              </div>
              <div className="grid grid-cols-5 gap-2 mb-2">
                <p className=" col-span-2">Role</p>
                <p className="font-bold col-span-3">
                  {profile?.position_in_sacco}
                </p>
              </div>
            </section>
            {/* handleChangePassword */}
            <Formik
              initialValues={{
                current_password: "",
                new_password: "",
                confirm_password: "",
              }}
              validationSchema={changeUserPasswordValidationSchema}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => {
                return (
                  <Form
                    className="mb-3"
                    name="changePasswordForm"
                    id="changePasswordForm"
                    onSubmit={(event) => {
                      setLoading(true);
                      handleChangePassword(event, values);
                    }}
                  >
                    <h1 className="font-semibold mb-3">Password Reset</h1>
                    <div className="flex flex-col h-20">
                      <label className="text-sm">Old Password</label>
                      <div className="relative flex w-56 items-center">
                        <input
                          type={
                            revealPasswords.current_password
                              ? "text"
                              : "password"
                          }
                          name="current_password"
                          id="current_password"
                          onChange={handleChange("current_password")}
                          onBlur={handleBlur}
                          placeholder="Old Password"
                          className="ring-1 ring-black dark:ring-dark-bg-600 dark:bg-dark-bg-700 rounded focus:outline-none focus:ring-2 focus:ring-primary px-2 py-1 mb-1 w-56"
                        />
                        <div
                          className={`absolute right-2 text-xs cursor-pointer font-semibold ${
                            values.current_password?.length < 1 && "hidden"
                          }`}
                          onClick={(event) => {
                            event.preventDefault();
                            setRevealPasswords((revealPasswords) => ({
                              ...revealPasswords,
                              current_password:
                                !revealPasswords.current_password,
                            }));
                          }}
                        >
                          {revealPasswords.current_password ? "HIDE" : "SHOW"}
                        </div>
                      </div>
                      <ErrorMessage name="current_password">
                        {(msg) => (
                          <div className="error text-xs text-red-500">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="flex flex-col h-20">
                      <label className="text-sm">New Password</label>
                      <div className="relative flex w-56 items-center">
                        <input
                          type={
                            revealPasswords.new_password ? "text" : "password"
                          }
                          name="new_password"
                          id="new_password"
                          onChange={handleChange("new_password")}
                          onBlur={handleBlur}
                          placeholder="New Password"
                          className="ring-1 ring-black dark:ring-dark-bg-600 rounded focus:outline-none focus:ring-2 focus:ring-primary px-2 py-1 dark:bg-dark-bg-700 mb-1 w-56"
                        />
                        <div
                          className={`absolute right-2 text-xs cursor-pointer font-semibold ${
                            values.new_password?.length < 1 && "hidden"
                          }`}
                          onClick={(event) => {
                            event.preventDefault();
                            setRevealPasswords((revealPasswords) => ({
                              ...revealPasswords,
                              new_password: !revealPasswords.new_password,
                            }));
                          }}
                        >
                          {revealPasswords.new_password ? "HIDE" : "SHOW"}
                        </div>
                      </div>
                      <ErrorMessage name="new_password">
                        {(msg) => (
                          <div className="error text-xs text-red-500">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="flex flex-col mb-3 h-20">
                      <label className="text-sm">Confirm Password</label>
                      <div className="relative flex w-56 items-center">
                        <input
                          type={
                            revealPasswords.confirm_password
                              ? "text"
                              : "password"
                          }
                          name="confirm_password"
                          id="confirm_password"
                          onChange={handleChange("confirm_password")}
                          onBlur={handleBlur}
                          placeholder="Confirm Password"
                          className="ring-1 ring-black dark:ring-dark-bg-600 rounded focus:outline-none focus:ring-2 focus:ring-primary px-2 py-1 dark:bg-dark-bg-700 mb-1 w-56"
                        />
                        <div
                          className={`absolute right-2 text-xs cursor-pointer font-semibold ${
                            values.confirm_password?.length < 1 && "hidden"
                          }`}
                          onClick={(event) => {
                            event.preventDefault();
                            setRevealPasswords((revealPasswords) => ({
                              ...revealPasswords,
                              confirm_password:
                                !revealPasswords.confirm_password,
                            }));
                          }}
                        >
                          {revealPasswords.confirm_password ? "HIDE" : "SHOW"}
                        </div>
                      </div>
                      <ErrorMessage name="confirm_password">
                        {(msg) => (
                          <div className="error text-xs text-red-500">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="flex justify-start gap-3 mt-3">
                      <input
                        type="submit"
                        value="Save"
                        className="bg-primary px-3 py-1 outline outline-1 outline-primary rounded-md text-white cursor-pointer"
                      />
                      {/* Debug */}
                      {/* <button
                        onClick={event => {
                          event.preventDefault()
                          console.log("Values: ", values)
                          console.log("Errors: ", errors)
                        }}
                      >
                        Debug
                      </button> */}
                    </div>
                  </Form>
                );
              }}
            </Formik>
            {/* handleTermination */}
            <Formik initialValues={{ password: "" }}>
              {({ values, errors, touched, handleChange, handleBlur }) => {
                return (
                  <Form
                    className="mb-3"
                    name="terminationForm"
                    onSubmit={(event) => handleTermination(event, values)}
                  >
                    <h1 className="font-semibold">Danger Zone</h1>
                    <div className="my-2 outline outline-5 p-2 rounded-md bg-red-100 outline-red-700">
                      <h1 className="font-semibold">Termination</h1>
                      <p>
                        Termination implies that you no longer subscribe to and
                        therefore sieze being a member of Bweyogerere Tuberebumu
                        sacco. If you're sure that you want to terminate your
                        membership, click terminate to proceed.
                      </p>
                      <br />
                      <div className="flex mt-1">
                        <div className="flex flex-col w-56">
                          <label className="text-sm">
                            Enter Password to confirm
                          </label>
                          <div className="w-full relative flex flex-col justify-center">
                            <input
                              type={
                                revealPasswords.self_termination
                                  ? "text"
                                  : "password"
                              }
                              name="password"
                              id=""
                              placeholder="Password"
                              onChange={handleChange("password")}
                              className="ring-1 ring-red-200 dark:ring-dark-bg-600 dark:bg-dark-bg-700 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-primary bg-red-50 border"
                              required
                            />
                            <div
                              className={`absolute right-2 text-xs cursor-pointer font-semibold ${
                                values.password?.length < 1 && "hidden"
                              }`}
                              onClick={(event) => {
                                event.preventDefault();
                                setRevealPasswords((revealPasswords) => ({
                                  ...revealPasswords,
                                  self_termination:
                                    !revealPasswords.self_termination,
                                }));
                              }}
                            >
                              {revealPasswords.self_termination
                                ? "HIDE"
                                : "SHOW"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex justify-end">
                        <input
                          type="submit"
                          className="text-white bg-accent-red px-4 py-1 my-2 rounded-md uppercase cursor-pointer"
                          value="Terminate"
                        />
                        {popUp && (
                          <ConfirmModal setPopUp={setPopUp}>
                            <h1 className="font-bold">
                              Are you sure you want to terminate your account?
                            </h1>
                            <p>
                              If you terminate this account, you can’t recover
                              it.
                            </p>
                            <div className="flex justify-end gap-3 mt-3">
                              <button
                                className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                                onClick={() => setPopUp(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-accent-red px-3 py-1 outline outline-1 outline-accent-red rounded-md text-white"
                                onClick={async (event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  const toastNotification = toast.loading(
                                    "Processing Termination request"
                                  );

                                  try {
                                    const { data, error } = await supabase.rpc(
                                      "self_terminate"
                                    );
                                    if (error) throw error;

                                    if (data) {
                                      const { data: executive } = await supabase
                                        .from("_member_profiles")
                                        .select(
                                          `
                                            id, fullname
                                            `
                                        )
                                        .in("position_in_sacco", [
                                          "secretary",
                                          "assistant secretary",
                                          "chairperson",
                                          "vice chairperson",
                                          "chairperson credits",
                                          "vice chairperson credits",
                                          "treasurer",
                                          "assistant treasurer",
                                        ])
                                        .eq("is_under_termination", false);

                                      if (executive && executive?.length > 0) {
                                        executive.forEach(async (member) => {
                                          await sendSMS(
                                            member.id,
                                            `Hello ${member.fullname}, a sacco member has terminate their membership. To approve termination, visit https://tube.ablestate.co/termination Regards, Bweyogerere Tuberebumu Sacco.`
                                          )
                                            .then((response) => response.json())
                                            .then((data) => {
                                              console.log(data);
                                            })
                                            .catch((error) =>
                                              console.log(error, "Error")
                                            );
                                        });
                                      }
                                      setProfile(() => data.updated_user);
                                      navigate("dashboard/", { replace: true });
                                      toast.update(toastNotification, {
                                        render: `Termination submitted for account reconciliation`,
                                        type: "success",
                                        isLoading: false,
                                        autoClose: 5000,
                                      });
                                    }
                                  } catch (error) {
                                    console.log("Error: ", error);
                                    toast.update(toastNotification, {
                                      render: `Termination failed. Please try again later`,
                                      type: "error",
                                      isLoading: false,
                                      autoClose: 5000,
                                    });
                                  }
                                }}
                              >
                                Terminate
                              </button>
                            </div>
                          </ConfirmModal>
                        )}
                        {editPop && <EditModal setEditPop={setEditPop} />}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        ) : (
          <div className="display h-[calc(100vh-100px)] py-5 my-2 flex justify-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
