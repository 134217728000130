import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import { add_separator } from "../../helpers/thousand_separator";
import { remove_separator } from "../../helpers/thousand_separator";
import { Submit } from "../../components";
import { useEffect, useState } from "react";
import { supabase } from "../../helpers/supabase";
import { useOutletContext } from "react-router-dom";
import { assetsValidationSchema } from "../../helpers/validator";
import { uploadFile } from "../../helpers/uploadFile";
import { ToastContainer, toast } from "react-toastify";
import { sendSMS } from "../../helpers/sendsms";
import { Spinner } from "../../components";

function AddAsset() {
  const [profiles, setProfiles] = useState([]);
  const [, { fullname, id, position_in_sacco }] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const initialValues = {
    admin_id: id,
    admin_name: fullname,
    admin_position: position_in_sacco,
    name: "",
    type: "",
    valuation: "",
    description: "",
    date_acquired: "",
    evidence: "",
    witnesses: [
      {
        name: "",
        contact: "",
        id: "",
        confirmation_status: "pending",
      },
      {
        name: "",
        contact: "",
        id: "",
        confirmation_status: "pending",
      },
    ],
  };

  useEffect(() => {
    document.title = "Add Assets - Bweyogere tuberebumu";
    getProfiles()
      .then((data) => {
        setProfiles(data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const getProfiles = async () => {
    const { data, error } = await supabase.rpc("get_member_profiles");

    if (error) throw error;
    return data;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        const toastID = toast.loading("Processing Asset");
        const { evidence, witnesses } = values;

        try {
          const { Key: url } = await uploadFile(evidence, "assets");
          const details = {
            ...values,
            witnesses,
            evidence: url,
            status: "pending",
            created_at: new Date()
              .toISOString()
              .toLocaleString("en-GB", { timeZone: "UTC" }),
            updated_at: new Date()
              .toISOString()
              .toLocaleString("en-GB", { timeZone: "UTC" }),
          };

          document.getElementById("valuation").value = "";
          document.getElementById("evidence").value = "";
          document.getElementById("person0").value = "";
          document.getElementById("person1").value = "";
          document.getElementById("asset-form").reset();

          resetForm({ values: initialValues });

          const { error } = await supabase.rpc("handle_asset", {
            details: JSON.stringify(details, null, 4),
          });

          if (error) throw error;
          if (!error) {
            toast.update(toastID, {
              render: "Asset was added successfully.",
              type: "success",
              autoClose: 5000,
              isLoading: false,
            });

            for (let witness of witnesses) {
              await sendSMS(
                witness.id,
                `Hey, ${witness.name}! hope this finds you well. We would like you to acknowledge that you witnessed the process of aquiring an asset. Please visit https://tube.ablestate.co/ to confirm with us. Thank you. Regards, Bweyogerere Tuberebumu Sacco`
              )
                .then((response) => response.json())
                .then((data) => {
                  console.log(data);
                })
                .catch((error) => console.log(error, "Error"));
            }
          }
        } catch (error) {
          toast.error("Failed to add asset.", {
            position: "top-center",
          });
        }
      }}
      validationSchema={assetsValidationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        dirty,
        isValid,
        setFieldValue,
      }) => {
        return (
          <div className="flex-grow mx-3 md:mx-5 my-2 h-[calc(100vh-140px)]">
            <ToastContainer />
            <h1 className="mb-3 md:mb-0 mt-2 font-bold uppercase dark:text-white md:h-[60px]">
              Add Asset
            </h1>
            <div className="flex bg-white overflow-y-scroll dark:bg-dark-bg-700 dark:text-secondary-text min-h-full md:h-[calc(100%-80px)] w-full mb-2">
              {loading ? (
                <Spinner />
              ) : (
                <Form
                  className="flex flex-col h-full overflow-y-scroll overflow-x-hidden p-6"
                  id="asset-form"
                >
                  <span className="text-sm text-red-500 font-light">
                    All field marked with an * are mandatory
                  </span>
                  <div className="mb-3">
                    <div className="flex flex-wrap gap-5">
                      <div className="flex flex-col w-56 mb-3">
                        <label htmlFor="name" className=" text-sm">
                          Name{" "}
                          <span className="text-sm text-red-500 ml-2 font-bold">
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={values.name}
                          placeholder="Enter Name"
                          className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                            errors?.name && touched?.name
                              ? "ring-red-500"
                              : "ring-black"
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched?.name && errors?.name && (
                          <div className="error text-red-600 text-xs">
                            {errors?.name}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col w-56">
                        <label className="text-sm" htmlFor="type">
                          Select type{" "}
                          <span className="text-sm text-red-500 ml-2 font-bold">
                            *
                          </span>
                        </label>
                        <select
                          name="type"
                          id="type"
                          className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 ${
                            touched?.type && errors?.type
                              ? "ring-red-500"
                              : "ring-black"
                          }`}
                          value={values?.type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">--Select type--</option>
                          <option value="tangible">Tangible</option>
                          <option value="intangilble">Intangible</option>
                          <option value="operating">Operating</option>
                          <option value="non_operating">Non operating</option>
                          <option value="growth">Growth</option>
                          <option value="defensive">Defensive</option>
                        </select>
                        {touched?.type && errors?.type && (
                          <div className="error text-red-600 text-xs">
                            {errors?.type}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col w-56 mb-3">
                        <label htmlFor="valuation" className=" text-sm">
                          Enter Valuation{" "}
                          <span className="text-sm text-red-500 ml-2 font-bold">
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="valuation"
                          id="valuation"
                          placeholder="Asset Value (UGX)"
                          className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                            errors?.valuation && touched?.valuation
                              ? "ring-red-500"
                              : "ring-black"
                          }`}
                          onChange={(event) => {
                            let formatted_string = add_separator(
                              remove_separator(event.target.value)
                            );
                            event.target.value = formatted_string;
                            setFieldValue(
                              event.target.name,
                              parseFloat(remove_separator(event.target.value))
                            );
                          }}
                          onBlur={handleBlur}
                        />
                        {touched?.valuation && errors?.valuation && (
                          <div className="error text-red-600 text-xs">
                            {errors?.valuation}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col w-56 mb-3">
                        <label htmlFor="date_acquired" className=" text-sm">
                          Date Acquired{" "}
                          <span className="text-sm text-red-500 ml-2 font-bold">
                            *
                          </span>
                        </label>
                        <input
                          type="date"
                          name="date_acquired"
                          id="date_acquired"
                          value={values.date_acquired}
                          className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                            errors?.date_acquired && touched?.date_acquired
                              ? "ring-red-500"
                              : "ring-black"
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched?.date_acquired && errors?.date_acquired && (
                          <div className="error text-red-600 text-xs">
                            {errors?.date_acquired}
                          </div>
                        )}
                      </div>
                      <div className="mb-3 flex flex-col md:flex-row gap-5">
                        <div className="flex flex-col w-56 ">
                          <label className=" text-sm">
                            Supporting files{" "}
                            <span className="text-sm text-red-500 ml-2 font-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="file"
                            name="evidence"
                            id="evidence"
                            accept=".pdf,.docx,.png,.jpeg,.jpg"
                            placeholder="Upload Evidence"
                            className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                              touched?.evidence && errors?.evidence
                                ? "ring-red-500"
                                : "ring-black"
                            }`}
                            onChange={(event) => {
                              values.evidence = event.currentTarget.files[0];
                            }}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="evidence">
                            {(msg) => (
                              <div className="text-red-600 text-xs">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-3">
                    <h1 className="font-semibold">
                      Asset Description{" "}
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </h1>
                    <textarea
                      name="description"
                      id="description"
                      cols="30"
                      rows="10"
                      className={`outline outline-1 p-2 rounded-md w-full dark:bg-dark-bg-600 ${
                        errors?.description && touched?.description
                          ? "outline-red-500"
                          : "outline-black"
                      }`}
                      value={values?.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                    {touched?.description && errors?.description && (
                      <div className="error text-red-600 text-xs">
                        {errors?.description}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col flex-start gap-5 outline outline-gray-50 dark:outline-gray-800 py-5 rounded mb-2">
                    <h1 className="font-semibold ml-2">
                      Witnesses
                    </h1>
                    <FieldArray
                      name="witnesses"
                      render={(fieldArrayProp) => (
                        <>
                          {values.witnesses.map((witness, index) => (
                            <div className="flex flex-col md:flex-row gap-5 mb-2 outline p-2 outline-1 rounded-md outline-gray-300 dark:outline-gray-700 mx-3">
                              {/* <InputField errors={errors} touched={touched} handleChange={handleChange}  handleBlur={handleBlur} reference={`witnesses[${index}].name`} label="Name" placeholder="Enter name" /> */}
                              <div className="flex flex-col w-56">
                                <label className="text-sm">
                                  Select Member{" "}
                                  <span className="text-sm text-red-500 ml-2 font-bold">
                                    *
                                  </span>
                                </label>
                                <select
                                  name={`witnesses[${index}][name]`}
                                  id={`person${index}`}
                                  className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                                    errors?.witnesses &&
                                    errors?.witnesses?.length > 0 &&
                                    errors?.witnesses[index]?.name &&
                                    touched?.witnesses &&
                                    touched?.witnesses?.length > 0 &&
                                    touched?.witnesses[index]?.name
                                      ? "ring-red-500"
                                      : "ring-black"
                                  }`}
                                  onChange={(event) => {
                                    values.witnesses[index]["name"] =
                                      profiles[event.target.value]["fullname"];
                                    values.witnesses[index]["id"] =
                                      profiles[event.target.value]["id"];
                                  }}
                                  onBlur={handleBlur(
                                    `witnesses[${index}]["name"]`
                                  )}
                                >
                                  <option value="">--Select Member--</option>
                                  {profiles &&
                                    profiles?.length > 0 &&
                                    profiles.map(({ fullname }, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={index}
                                          className="capitalize"
                                        >
                                          {fullname}
                                        </option>
                                      );
                                    })}
                                </select>
                                <ErrorMessage
                                  name={`witnesses.[${index}].name`}
                                >
                                  {(msg) => (
                                    <div className="error text-xs text-red-500">
                                      {msg}
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>
                              <div className="flex flex-col w-56 mb-3">
                                <label
                                  htmlFor="date_acquired"
                                  className="text-sm"
                                >
                                  Phone Number{" "}
                                  <span className="text-sm text-red-500 ml-2 font-bold">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  name={`witnesses[${index}].contact`}
                                  id={`witnesses[${index}].contact`}
                                  className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                    errors?.witnesses &&
                                    errors?.witnesses?.length > 0 &&
                                    errors?.witnesses[index]?.contact &&
                                    touched?.witnesses &&
                                    touched?.witnesses?.length > 0 &&
                                    touched?.witnesses[index]?.contact
                                      ? "ring-red-500"
                                      : "ring-black"
                                  }`}
                                  placeholder="Enter number"
                                  onChange={handleChange}
                                  value={values.witnesses[index]["contact"]}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  name={`witnesses.[${index}].contact`}
                                >
                                  {(msg) => (
                                    <div className="error text-xs text-red-500">
                                      {msg}
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                          ))}
                          {errors.witnesses &&
                            typeof errors.witnesses === "string" && (
                              <div className="error text-xs text-red-500">
                                {errors.witnesses}
                              </div>
                            )}
                          {/* Debuggging */}
                          {/* <button
                                onClick={(event ) => {
                                    event.preventDefault()
                                    console.log("Errors: ", errors)
                                    console.log("Values: ", values)
                                }}
                            >
                                tryme
                            </button> */}
                        </>
                      )}
                    ></FieldArray>
                  </div>
                  <div className="w-56 mb-6">
                    <Submit value="Submit" disabled={!(dirty && isValid)} />
                  </div>
                </Form>
              )}
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default AddAsset;
