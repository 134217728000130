import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { Spinner } from "../../components";
import { NothingShown } from "../../components";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { sendSMS } from "../../helpers/sendsms";

function BaanaApproval() {
  const { application_id } = useParams();
  const [baanaApplication, setBaanaApplication] = useState({});
  const [, profile] = useOutletContext();
  const [isSubmitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState("");

  console.log("Application ID", application_id);
  useEffect(() => {
    if (application_id) {
      getBaanaApplication().catch((error) => console.log(error));
    }

    return () => {};
  }, [application_id]);

  const getBaanaApplication = async () => {
    const { data, error } = await supabase
      .from("applications")
      .select()
      .eq("application_id", application_id)
      .single();

      if ( data ) console.log("Data: ", data)

    if (error) throw error;
    if ( !imageURL && data?.application_meta?.evidence ) {
      const { data: file, error } = await supabase.storage
        .from("deposits")
        .download(await data.application_meta.evidence.substring(9));

      if (error) throw error;
      const url = URL.createObjectURL(file);
      setImageURL(url);
    }

    setBaanaApplication(() => data);
    setLoading(false);
    console.log("Application Data: ", data);
  };

  const approveBaanaApplication = async () => {
    const toastId = toast.loading("Processing");

    try {
      if (baanaApplication.application_meta.type === "add") {
        const { data, error } = await supabase.rpc("approve_baana", {
          application_id: baanaApplication.application_id,
        });
        if (error) throw error;

        toast.update(toastId, {
          render: `Successfully added baana.`,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });

        setBaanaApplication((baanaApplication) => ({
          ...baanaApplication,
          application_meta: data.application_update,
        }));

        sendSMS(
          baanaApplication.application_meta.applicants_id,
          `Hey ${baanaApplication.application_meta.applicants_name}, your baana application has been approved. Visit https://tube.ablestate.co/baana to confirm transaction.`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log("Error", error));

        // console.log("Approved Baana Data: ", data);
      } else if (baanaApplication.application_meta.type === "delete") {
        // console.log("Baana delete here: ")

        const { data, error } = await supabase.rpc("delete_mwana", {
          application_id: baanaApplication.application_id,
        });
        if (error) throw error;
        console.log("Approved Baana Data: ", data);

        sendSMS(
          baanaApplication.application_meta.applicants_id,
          `Hey ${baanaApplication.applicants_name}, your baana application has been approved. Visit https://tube.ablestate.co/baana to confirm transaction.`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error, "Error"));

        toast.update(toastId, {
          render: `Successfully deleted mwana.`,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });

        setBaanaApplication((baanaApplication) => ({
          ...baanaApplication,
          application_meta: data.application_update,
        }));
      }
    } catch (error) {
      console.log("Error: ", error);

      toast.update(toastId, {
        render: `Failure. Please try again later`,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <div className="flex flex-col justify-between pb-3 h-[60px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Verify Baana
        </h1>
      </div>
      <ToastContainer />
      <div className="bg-white p-3 md:overflow-y-auto  relative  md:h-[calc(100%-80px)] dark:bg-dark-bg-700 dark:text-secondary-text">
        {loading ? (
          <Spinner />
        ) : baanaApplication ? (
          <div className="flex flex-grow flex-col min-h-full">
            <div className="mb-3">
              <h1 className="font-semibold mb-3">
                {baanaApplication?.application_meta &&
                  baanaApplication?.application_meta?.applicants_name}
                's Baana
                <span
                  className={` py-1 px-2 rounded-lg text-white text-xs ml-1 inline-block capitalize ${
                    baanaApplication?.baana_meta
                      ? "bg-green-400"
                      : baanaApplication?.application_meta?.review_status ===
                        "rejected"
                      ? "bg-red-400"
                      : baanaApplication?.application_meta?.review_status ===
                        "approved"
                      ? "bg-green-400"
                      : "bg-yellow-400"
                  }`}
                >
                  {baanaApplication?.application_meta?.review_status ===
                  "pending"
                    ? "pending approval"
                    : baanaApplication?.transaction_meta
                    ? "approved "
                    : baanaApplication?.application_meta?.review_status}
                </span>
              </h1>
              <div className="outline outline-1 outline-gray-100 dark:outline-secondary-text p-3">
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Application ID</p>
                  <p className="font-bold col-span-3">
                    : {baanaApplication?.app_id}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Created At</p>
                  <p className="font-bold col-span-3">
                    :{" "}
                    {moment(baanaApplication?.created_at).format(
                      "DD-MM-YYYY hh:mm a"
                    )}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Type</p>
                  <p className="font-bold col-span-3 capitalize">
                    : {baanaApplication?.application_meta?.type} Request
                  </p>
                </div>
                <div>
                  {baanaApplication &&
                    baanaApplication?.application_meta?.children?.length > 0 &&
                    baanaApplication.application_meta.children.map(
                      (mwana, index) => {
                        return (
                          <div className="mb-2">
                            <h3 className="font-semibold">
                              Mwana 0{index + 1}
                            </h3>
                            <div className="grid grid-cols-3 pl-2">
                              <div className="mb-2">
                                <div className="font-light">Name</div>
                                <div className="font-semibold capitalize">
                                  {mwana.name || mwana.mwana_meta.name}
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="font-extralight">Gender</div>
                                <div className="font-semibold capitalize">
                                  {mwana.gender || mwana.mwana_meta.gender}
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="font-extralight">
                                  Birth Date
                                </div>
                                <div className="font-semibold capitalize">
                                  {mwana.dob || mwana.mwana_meta.dob}
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="font-extralight">
                                  Education Level
                                </div>
                                <div className="font-semibold capitalize">
                                  {mwana.education ||
                                    mwana.mwana_meta.education}
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="font-extralight">
                                  Social Security Number
                                </div>
                                <div className="font-semibold capitalize">
                                  {mwana.social_security_number
                                    ? mwana.social_security_number ||
                                      mwana.mwana_meta.social_security_number
                                    : "None"}
                                </div>
                              </div>
                              <div className="mb-2">
                                <div className="font-extralight">
                                  Health Issues
                                </div>
                                <div className="font-semibold capitalize">
                                  {mwana?.health_issues
                                    ? mwana.health_issues ||
                                      mwana.mwana_meta.health_issues
                                    : "None"}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
                {imageURL && (
                  <div className="grid grid-cols-5 gap-2 justify-start w-full items-center">
                    <p className="col-span-2">Sacco Charge</p>
                    <div className="flex min-w-fit col-span-3 items-center">
                      <span className="font-bold">:</span>
                      <a
                        className="px-2 text-sky-400 underline mt-2"
                        href={imageURL}
                        title="Files"
                        target="_blank"
                      >
                        Preview file
                      </a>
                      <a
                        className="px-2 text-sky-400 underline mt-2"
                        href={imageURL}
                        title="Files"
                        download={true}
                      >
                        Download file
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {baanaApplication?.application_meta?.applicants_id !== profile.id &&
              baanaApplication?.application_meta?.review_status ===
                "pending" && (
                <div className="flex gap-10 justify-end items-center mt-3">
                  <button
                    type="submit"
                    className="bg-accent-red inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                    disabled={isSubmitting}
                    onClick={async () => {
                      const toastID = toast.loading("Processing transaction");
                      const { data, error } = await supabase
                        .from("application")
                        .update({
                          application_meta: {
                            ...baanaApplication.application_meta,
                            review_status: "rejected",
                          },
                        });

                      if (error) {
                        toast.update(toastID, {
                          render: "Failure please try again later",
                          type: "error",
                          isLoading: false,
                          autoClose: 5000,
                        });
                      } else if (data) {
                        sendSMS(
                          baanaApplication.application_meta.applicants_id,
                          `Hey ${baanaApplication.applicants_name}, your baana application has been rejected. Visit https://tube.ablestate.co/baana to confirm.`
                        )
                          .then((response) => response.json())
                          .then((data) => {
                            console.log(data);
                          })
                          .catch((error) => console.log(error, "Error"));

                        toast.update(toastID, {
                          render: "Application has successfully rejected",
                          type: "error",
                          isLoading: false,
                          autoClose: 5000,
                        });
                      }
                    }}
                  >
                    Reject
                  </button>
                  <button
                    type="submit"
                    className="bg-green-600 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                    onClick={approveBaanaApplication}
                    disabled={isSubmitting}
                  >
                    Approve
                  </button>
                </div>
              )}
          </div>
        ) : loading ? (
          <Spinner />
        ) : (
          <NothingShown />
        )}
      </div>
    </div>
  );
}

export default BaanaApproval;
