import * as React from "react";

function TubeIcon(props) {
  return (
    <svg
      width={50}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M71 4c0-4-4-4-4-4s-4 0-4 4 4 4 4 4 4 0 4-4z" fill="#B93131" />
      <path
        d="M94 8c0-4-4-4-4-4s-4 0-4 4 4 4 4 4 4 0 4-4zM85.5 14.5C83.5 14 76 10 76 10s1.5 5 2 7.5S79.5 29 79.5 29s7.5-6 9.5-7.5 11-6.5 11-6.5-12.5 0-14.5-.5z"
        fill="#27427A"
      />
      <path
        d="M69 11c3.5-1.5 10-6 10-6s-2 6-2.5 8-1 15.5-1 15.5-8-9-10.5-11-8-4.5-8-4.5 8.5-.5 12-2z"
        fill="#B93131"
      />
      <path
        d="M69 31v37l11 5V24.5L69 31zM87 73v2l9 5V35l-6 2.667V73h-3z"
        fill="#27427A"
      />
      <path
        d="M90 73V37.667L87 39v34h3zM103 81.75V83l10 4V46l-7 2.1v34.4l-3-.75z"
        fill="#27427A"
      />
      <path d="M106 82.5V48.1l-3 .9v32.75l3 .75z" fill="#27427A" />
      <path
        d="M113 46v41l9 1V49l-9-3zM96 35v45l7 1.75V49l3-.9V40l-10-5zM80 24.5V73h7V39l3-1.333V29.5l-10-5z"
        fill="#3576BB"
      />
      <path
        d="M37 71C6.5 71.5 0 99 0 99s8.5-19.5 35.5-15S87 112.5 115 110s34.5-28 34.5-28-4 15.5-29 15-53-26.5-83.5-26z"
        fill="#B93131"
      />
      <path
        d="M52 62.5c-11.5-2-27 2.5-27 2.5s18-2 37 5.5S104.5 92 122 93s24-14.5 24-14.5-3.5 7.5-20.5 8.5c-9.837.58-23.558-8.66-41.5-16-13.063-5.343-27.155-7.657-32-8.5z"
        fill="#27427A"
      />
    </svg>
  );
}

export default TubeIcon;
