import { useParams } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { useState, useEffect } from "react";
import { Spinner } from "../../components";
import { downloadFile } from "../../helpers/utilites";
import { toast, ToastContainer } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import moment from "moment";
import { sendSMS } from "../../helpers/sendsms";
import { add_separator } from "../../helpers/thousand_separator";

export default function VerifyPayment() {
  const { id } = useParams();
  const [, profile, , roles] = useOutletContext();
  const [deposit, setDeposit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const role = roles ? roles.find( role => role !== "member" || role !=="admin") : null

  useEffect(() => {
    getApplication().catch((error) => console.log(error));
  }, []);
  const [imageURL, setImageURL] = useState("");

  const getApplication = async () => {
    const { error, data } = await supabase
      .from("applications")
      .select()
      .eq("_type", "payment")
      .eq("application_id", id)
      .single();

    if (error) {
      setLoading(false);
      throw error;
    }
    console.log("Data: ", data);
    setLoading(false);
    setDeposit(data);
  };

  if (
    deposit?.application_meta?.files &&
    deposit.application_meta.files?.length > 0 &&
    deposit.application_meta.files[0]?.file_url &&
    !imageURL
  ) {
    downloadFile(
      deposit.application_meta.files[0].file_url.substring(6),
      "loans"
    )
      .then((data) => setImageURL(data.avatar_url))
      .catch((error) => error);
  }

  const approveDepositTransaction = async () => {

    setIsSubmitting(true)
    try {
      const { data, error } = await supabase.rpc("approve_loan_payment", {
        loan: deposit?.application_meta?.loan_id,
        application: id,
      });
      if (error) {
        throw error;
      } else {
        // handle the alerts and navigation
        setDeposit((deposit) => ({
          ...deposit,
          application_meta: data.application_meta,
        }));
        console.log("Result: ", data);
        toast.success(`Transaction has been approved.`, {
          position: "top-center",
        });
        setIsSubmitting(false)
      }
    } catch (error) {
      toast.error(`${error?.message}`, { position: "top-center" });
      console.log(error);
      setIsSubmitting(false)
    }
  };

  const rejectDepositTransaction = async () => {
    setIsSubmitting(true)
    try {
      const { data, error } = await supabase.rpc("reject_loan_payment", {
        details: JSON.stringify({
          ...deposit.application_meta,
          admin_name: profile.fullname,
          admin_id: profile.id,
          admin_position: profile.position_in_sacco,
          application: deposit.application_id,
          created_at: new Date()
            .toISOString()
            .toLocaleString("en-GB", { timeZone: "UTC" }),
          updated_at: new Date()
            .toISOString()
            .toLocaleString("en-GB", { timeZone: "UTC" }),
          _type: deposit._type,
        }),
      });
      if (error) {
        throw error;
      } else {
        setDeposit((deposit) => ({
          ...deposit,
          application_meta: data.application_meta,
        }));
        console.log("Result: ", data);
        toast.success(`Transaction has been rejected.`, {
          position: "top-center",
        });
        // handle the alerts and navigation
        setIsSubmitting(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <ToastContainer />
      <div className="flex flex-col justify-between pb-3 h-[60px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Verify Loan Payment
        </h1>
      </div>
      <div className="bg-white p-3 overflow-hidden  relative  h-[calc(100%-80px)] dark:bg-dark-bg-700 dark:text-secondary-text">
        {deposit ? (
          <div className="flex flex-grow flex-col min-h-full">
            <div className="mb-3">
              {/* <h1 className='font-semibold'>{profile?.fullname}'s withdraw Request Details</h1> */}
              <div className="outline outline-1 outline-gray-100 dark:outline-secondary-text p-3">
                <div className="my-6">
                  Application ID:{" "}
                  <span className="font-semibold">
                    {deposit.app_id || deposit.application_id}
                  </span>
                </div>
                <div className="my-6">
                  Loan ID:{" "}
                  <span className="font-semibold">
                    {deposit?.application_meta?.loan_ref}
                  </span>
                </div>
                <div className="my-6">
                  Applicants Name:{" "}
                  <span className="font-semibold">
                    {deposit?.application_meta?.applicants_name}
                  </span>
                </div>
                <div className="my-6">
                  Account:{" "}
                  <span className="font-semibold">
                    {deposit?.application_meta?.account_type}
                  </span>
                </div>
                <div className="my-6">
                  Created At:{" "}
                  <span className="font-semibold">
                    {moment(deposit?.created_at).format("DD-MM-YYYY HH:MM")}
                  </span>
                </div>
                <div className="my-6">
                  Amount Paid:{" "}
                  <span className="font-semibold">
                    {"UGX "}
                    {currencyFormatter(deposit?.application_meta?.amount)}
                  </span>
                </div>
                {deposit?.application_meta?.comments && (
                  <div className="my-6">
                    Comments:{" "}
                    <span className="font-semibold">
                      {deposit?.application_meta?.comments}
                    </span>
                  </div>
                )}
                {deposit?.application_meta?.files &&
                  deposit.application_meta.files?.length > 0 &&
                  deposit.application_meta.files[0]?.file_url && (
                    <div className="border-t-[0.5px] pt-4 mt-2">
                      <p className="mb-2 font-semibold">Files and statements</p>
                      <div className="m-1 flex items-center">
                        <p className="mr-2"> Evidence</p>
                        <a
                          className="p-2 text-sky-400 underline mt-2"
                          href={imageURL}
                          title="Files"
                          target="_blank"
                        >
                          Preview file
                        </a>
                        <a
                          className="p-2 text-sky-400 underline mt-2"
                          href={imageURL}
                          title="Files"
                          download={true}
                        >
                          Download file
                        </a>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            {deposit?.application_meta.applicants_id !== profile.id &&
              ((role === "secretary" || role === "asst_secretary") &&
              deposit?.application_meta.review_status === "pending" ? (
                <div className="flex gap-10 justify-end items-center mt-3">
                  <button
                    type="submit"
                    className="bg-blue-500 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                    onClick={async () => {
                      const toastID = toast.loading("Forwarding deposit");

                      try {
                        const { data, error } = await supabase
                          .from("applications")
                          .update({
                            application_meta: {
                              ...deposit.application_meta,
                              ...{
                                admin_1: {
                                  admin_id: `${profile.id}`,
                                  admin_name: `${profile.fullname}`,
                                  position_in_sacco: `${profile.position_in_sacco}`,
                                  review_status: "forwarded",
                                  reviewed: true,
                                  reviewed_at: `${new Date().toISOString()}`,
                                  seen: true,
                                },
                              },
                              review_status: "forwarded",
                            },
                          })
                          .eq("application_id", deposit.application_id)
                          .single();

                        if (error) throw error;
                        console.log("Data: ", data);

                        if (data) {
                          sendSMS(
                            deposit.application_meta.member.id,
                            `Hey ${
                              deposit.application_meta.member.member_name
                            }, your loan payment has been forwarded to the credits commitee for review.\n For more details visit https://tube.ablestate.co/deposit \nRegards, Bweyogerere Tuberebumu Sacco.`
                          )
                            .then((response) => response.json())
                            .then((data) => {
                              console.log(data);
                            })
                            .catch((error) => console.log(error, "Error"));

                          toast.update(toastID, {
                            render: "Deposit successfully forwarded",
                            type: "success",
                            isLoading: false,
                            autoClose: 5000,
                          });

                          setDeposit(() => data);
                        }
                      } catch (error) {
                        console.log("Error: ", error);

                        toast.update(toastID, {
                          render: "Failure. Please try again later.",
                          type: "error",
                          isLoading: false,
                          autoClose: 5000,
                        });
                      }
                    }}
                    disabled={isSubmitting}
                  >
                    Forward
                  </button>
                </div>
              ) : (
                (role === "chairperson_credits" || role === "vice_chairperson") &&
                deposit?.application_meta.review_status === "forwarded" && (
                  <div className="flex gap-10 justify-end items-center mt-3">
                    <button
                      type="submit"
                      className="bg-accent-red inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                      onClick={rejectDepositTransaction}
                      disabled={isSubmitting}
                    >
                      Reject
                    </button>
                    <button
                      type="submit"
                      className="bg-green-600 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                      onClick={approveDepositTransaction}
                      disabled={isSubmitting}
                    >
                      Approve
                    </button>
                  </div>
                )
              ))}
          </div>
        ) : (
          loading && <Spinner />
        )}
      </div>
    </div>
  );
}
