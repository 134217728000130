import { supabase } from "../../helpers/supabase";
import { useEffect, useState, Fragment } from "react";
import { NothingShown, Spinner } from "../../components";
import { Pagination } from "../../components";
import DepositModal from "../../components/Modals/DepositModal";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import DateRangeFilter from "../../components/DateRangeFilter";
import { useMediaQuery } from "../../hooks";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TbFilterOff } from "react-icons/tb";
import { MdDownload } from "react-icons/md";
import Frown from "../../components/Frown";
import { Helmet } from "react-helmet";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";

export default function DepositAdmin() {
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getApplications().catch((error) => console.log(error));
    const mySubscription = supabase
      .from("applications")
      .on("*", async (payload) => {
        await getApplications().catch((error) => console.log(error));
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, []);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawPerPage, setWithdrawPerPage] = useState(10);
  const [depositModal, setDepositModal] = useState(false);
  const indexOfLastPage = currentPage * withdrawPerPage;
  const indexOfFirstPage = indexOfLastPage - withdrawPerPage;

  const getApplications = async () => {
    const {
      data: { applications, transactions },
      error,
    } = await supabase.rpc("fetch_member_deposits");

    if (error) {
      setLoading(false);
      throw error;
    } else {
      let data = [];
      if (applications) data.push(...applications);
      if (transactions) data.push(...transactions);

      const sorted_data =
        data && data?.length > 0
          ? data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          : null;

      setNothingShown(() => !data || data?.length === 0);

      setAllDeposits(sorted_data);
      setDeposits(sorted_data);
      setLoading(false);
    }
  };

  const [status, setStatus] = useState("");
  const [account, setAccount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allDeposits, setAllDeposits] = useState([]);
  const [nothingShown, setNothingShown] = useState(false);

  useEffect(() => {
    let data = allDeposits;
    data =
      data && data?.length > 0
        ? data.filter(
            (deposit) => !date || deposit?.created_at?.substring(0, 10) === date
          )
        : null;

    data =
      data && data?.length > 0
        ? data.filter(
            (deposit) =>
              !searchText ||
              deposit?.application_meta?.applicants_name
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1 ||
              deposit?.transaction_meta?.member_name
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
          )
        : null;

    if (startDate && !endDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(startDate).isSameOrBefore(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && !startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(endDate).isSameOrAfter(
                moment(loan?.created_at?.substring(0, 10))
              );
            })
          : null;
    } else if (endDate && startDate) {
      data =
        data && data?.length > 0
          ? data.filter((loan) => {
              return moment(loan?.created_at?.substring(0, 10)).isBetween(
                moment(startDate),
                moment(endDate),
                undefined,
                "[]"
              );
            })
          : null;
    }

    data =
      !data ||
      data
        .filter((deposit) =>
          !status || status === ""
            ? deposit
            : status === "approved"
            ? deposit?.transaction_meta
            : deposit?.application_meta?.review_status === status
        )
        .filter(
          (deposit) =>
            !account ||
            (deposit?.transaction_meta
              ? deposit?.transaction_meta?.account_type === account
              : deposit?.application_meta?.account_type === account)
        );

    console.log("Filtered Data: ", data);

    setDeposits(data ?? []);
  }, [date, startDate, endDate, account, status, searchText, allDeposits]);

  const generate_member_deposits_report = () => {
    const formattedDeposits = allDeposits.map((deposit) => {
      return {
        "Member Name": deposit?.transaction_meta
          ? deposit?.transaction_meta?.member_name
          : deposit?.application_meta?.applicants_name,
        "Transaction ID": deposit?.application_meta
          ? deposit.app_id
          : deposit?.trans_id,
        Date: moment(deposit?.created_at).format('DD-MM-YYYY'),
        Amount: deposit?.transaction_meta
          ? deposit?.amount
          : deposit?.application_meta?.amount,
        Account: deposit?.transaction_meta
          ? deposit?.transaction_meta?.account_type
          : deposit?.application_meta?.account_type,
        "Approved At": deposit?.transaction_meta
          ? moment(deposit?.transaction_meta?.approved_at).format('DD-MM-YYYY')
          : "",
        Status: deposit?.transaction_meta
          ? "approved"
          : deposit?.application_meta?.review_status,
        "Approved By": deposit?.transaction_meta
          ? deposit?.transaction_meta?.approved_by
          : "",
      };
    });

    generateReportFromJson(
      formattedDeposits,
      "Bweyogerere Tuberebumu Member Deposits " +
        new Date().toISOString().substring(0, 10) 
    );
  };

  const approvedDeposits =
    allDeposits && allDeposits?.length > 0
      ? allDeposits.filter((deposit) => deposit?.transaction_meta)
      : [];
  const pendingDeposits =
    allDeposits && allDeposits?.length > 0
      ? allDeposits.filter(
          (deposit) => deposit?.application_meta?.review_status === "pending"
        )
      : [];
  const rejectedDeposits =
    allDeposits && allDeposits?.length > 0
      ? allDeposits.filter(
          (deposit) => deposit?.application_meta?.review_status === "rejected"
        )
      : [];

  const forwardedDeposits =
    allDeposits && allDeposits?.length > 0
      ? allDeposits.filter(
          (deposit) => deposit?.application_meta?.review_status === "forwarded"
        )
      : [];

  const approved =
    approvedDeposits && approvedDeposits?.length > 0
      ? (approvedDeposits.length / allDeposits.length) * 100
      : 0;

  const pending =
    pendingDeposits && pendingDeposits?.length > 0
      ? (pendingDeposits.length / allDeposits.length) * 100
      : 0;

  const rejected =
    rejectedDeposits && rejectedDeposits?.length > 0
      ? (rejectedDeposits.length / allDeposits.length) * 100
      : 0;

  const forwarded =
    forwardedDeposits && forwardedDeposits?.length > 0
      ? (forwardedDeposits.length / allDeposits.length) * 100
      : 0;

  let shownDeposits =
    deposits && Array.isArray(deposits) && deposits?.length > 0
      ? deposits.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);
  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const smallDesktop = useMediaQuery("(max-width: 800px)");
  const largeDesktop = useMediaQuery("(min-width: 900px)");

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <div className="flex flex-col justify-between pb-3 mb-2 overflow-hidden">
        <Helmet>
          <title>Deposit Applications - Bweyogere tuberebumu</title>
        </Helmet>
        <h1 className="mb-3 mt-2 font-bold uppercase dark:text-white">
          Member Deposits
        </h1>

        <div className=" dark:text-secondary-text rounded">
          <div className="w-full h-7 rounded flex overflow-hidden">
            {deposits.length === 0 && (
              <>
                <div
                  className="animate-pulse h-7 inline-block bg-accent"
                  style={{ width: `100%` }}
                ></div>
              </>
            )}
            <div
              className="h-7 inline-block bg-green-400"
              style={{ width: `${approved}%` }}
            ></div>
            <div
              className="h-7 inline-block bg-yellow-400"
              style={{ width: `${pending}%` }}
            ></div>
            <div
              className="h-7 inline-block bg-red-400"
              style={{ width: `${rejected}%` }}
            ></div>
            <div
              className="h-7 inline-block bg-blue-400"
              style={{ width: `${forwarded}%` }}
            ></div>
          </div>
          <div className="flex justify-between px-2 items-center py-2">
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-green-400 inline-block rounded-full"></div>{" "}
              Approved: {approvedDeposits.length} ({Math.round(approved)}%)
            </div>
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-yellow-400 inline-block rounded-full"></div>{" "}
              Pending: {pendingDeposits.length} ({Math.round(pending)}%)
            </div>
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-red-400 inline-block rounded-full"></div>{" "}
              Reject: {rejectedDeposits.length} ({Math.round(rejected)}%)
            </div>
            <div className="flex items-center gap-1 text-sm">
              <div className="w-2 h-2 bg-blue-400 inline-block rounded-full"></div>{" "}
              Forwarded: {forwardedDeposits.length} ({Math.round(forwarded)}%)
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 justify-between w-full">
          <div className="flex bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="text"
              name="name"
              onChange={(event) => setSearchText(event.target.value)}
              id="name"
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={searchText}
              placeholder="Search by name..."
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (searchText) setSearchText("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-2 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
            >
              <option value="">Status</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
              <option value="forwarded">Forwarded</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="account"
              id="account"
              className="py-2 px-2 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setAccount(event.target.value);
              }}
              setAccount={account}
            >
              <option value="">Account</option>
              <option value="shares">Shares</option>
              <option value="savings">Savings</option>
              <option value="fixed">Fixed</option>
              <option value="mwana">Mwana</option>
              <option value="development">Development</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
            <input
              type="date"
              name=""
              onChange={(event) => setDate(event.target.value)}
              id=""
              className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
              value={date}
            />
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
              onClick={() => {
                if (date) setDate("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <DateRangeFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          <button
            onClick={() => {
              setSearchText("");
              setStatus("");
              setDate("");
              document.getElementById("startDate").value = "";
              document.getElementById("startDate").setAttribute("max", "");
              setStartDate("");
              document.getElementById("endDate").value = "";
              document.getElementById("endDate").setAttribute("min", "");
              setEndDate("");
            }}
            className="bg-blue-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
          >
            {largeDesktop && !smallDesktop && "Reset Filters"}
            <TbFilterOff className="ml-1" size={20} />
          </button>
          {/* Button to export member deposits */}
          <button
            className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
            onClick={() => {
              generate_member_deposits_report();
            }}
            disabled={!allDeposits || allDeposits?.length === 0}
          >
            {largeDesktop && !smallDesktop && "Export"}
            <MdDownload className="ml-1" />
          </button>
        </div>
      </div>
      <div className="bg-white pb-6 overflow-hidden  relative  md:h-[calc(100%-255px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : deposits !== null && shownDeposits.length > 0 ? (
          <>
            <div className="w-full overflow-x-auto h-full  relative overflow-y-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Date</th>
                    <th className="px-6 py-4">Transaction ID</th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">Account</th>
                    <th className="px-6 py-4">Amount</th>
                    <th className="px-6 py-4">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {shownDeposits.map((deposit, index) => (
                    <Fragment key={index}>
                      <tr
                        className={`${
                          index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                        } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                        key={index}
                        onClick={() => {
                          setActiveIndex(index);
                          setDepositModal(true);
                        }}
                      >
                        <td>
                          <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                        </td>
                        <td className="px-6 py-3">
                          {deposit?.created_at
                            ? moment(deposit?.created_at).format("DD-MM-YYYY")
                            : "Unspecified"}
                        </td>
                        <td className="px-6 py-3">
                          {deposit?.app_id || deposit?.trans_id}
                        </td>
                        <td className="px-6 py-3">
                          {deposit?.application_meta?.applicants_name ||
                            deposit?.transaction_meta?.member_name}
                        </td>
                        <td className="px-6 py-3">
                          {deposit?.application_meta?.account_type ||
                            deposit?.transaction_meta.account_type}
                        </td>
                        <td className="px-6 py-3">
                          {currencyFormatter(
                            deposit?.application_meta?.amount || deposit?.amount
                          )}
                        </td>

                        <td className={`px-6 py-3`}>
                          <span
                            className={` py-1 px-2 rounded-xl text-white inline-block ${
                              deposit?.application_meta?.review_status ===
                              "pending"
                                ? "bg-yellow-400"
                                : deposit?.application_meta?.review_status ===
                                  "rejected"
                                ? "bg-red-400"
                                : deposit?.application_meta?.review_status ===
                                  "forwarded"
                                ? "bg-blue-400"
                                : "bg-green-400"
                            }`}
                          >
                            {deposit?.application_meta?.review_status ===
                            "pending"
                              ? "pending approval"
                              : deposit?.transaction_meta
                              ? "approved "
                              : deposit?.application_meta?.review_status}
                          </span>
                        </td>
                      </tr>
                      {depositModal && index === activeIndex && (
                        <DepositModal
                          deposit={deposit}
                          setDepositModal={setDepositModal}
                        />
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(deposits.length / withdrawPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={deposits}
                depositsPerPage={withdrawPerPage}
                setDepositsPerPage={setWithdrawPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allDeposits.length > 0 &&
          (shownDeposits?.length === 0 || !shownDeposits) && <Frown />
        )}
      </div>
    </div>
  );
}
