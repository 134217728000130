export const steps = [
  {
    number: 1,
    title: "Personal Information"
  },
  {
    number: 2,
    title: "Employment"
  },
  {
    number: 3,
    title: "Other Loans"
  },
  {
    number: 4,
    title: "Securities and Guarantors"
  },
  {
    number: 5,
    title: "Acceptance"
  },
  {
    number: 6,
    title: "Verify"
  }
]