import { useState, useEffect, Fragment } from "react";
import { BaanaModal } from "../../components";
import { Pagination } from "../../components";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { Spinner, NothingShown } from "../../components";
import { useOutletContext } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import { Helmet } from "react-helmet";
import Frown from "../../components/Frown";
import moment from "moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { RiPencilFill } from "react-icons/ri";
import { AiFillDelete } from "react-icons/ai";
import { ConfirmModal } from "../../components";
import { Formik, Form, ErrorMessage } from "formik";
import { InputField } from "../../components/Form/CustomInputField";

export default function Members() {
  useEffect(() => {
    // Fetch members on component render

    fetchBaana().catch((error) => console.log(error));

    // Realtime
    const mySubscription = supabase
      .from("baana")
      .on("*", async () => {
        await fetchBaana().catch((error) => console.log(error));
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, []);

  const [, profile] = useOutletContext();

  const fetchBaana = async () => {
    const {
      data: { applications, baana },
      error,
    } = await supabase.rpc("fetch_members_baana");
    if (error) throw error;

    let data = [];
    if (applications) {
      applications.forEach((application) => {
        const review_status = application.application_meta.review_status;
        const created_at = application.created_at;
        const children = application.application_meta.children.map((child) => ({
          ...child,
          review_status,
          created_at,
        }));
        data.push(...children);
      });
    }
    if (baana) data.push(...baana);

    console.log("Data: ", data);

    if (!data || data?.length === 0) setNothingShown(true);
    const sorted_data =
        data && data?.length > 0
          ? data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          : null;
    setMembers(sorted_data);
    setAllMembers(sorted_data);
    setLoading(false);
  };

  const fetch_members = async () => {
    const { data, error } = await supabase.rpc("fetch_members_only");
    if (error) throw error;
    if (!data || data?.length === 0) setNothingShown(true);
    setMembers(data);
    setAllMembers(data);
    setLoading(false);
  };

  const [nothingShown, setNothingShown] = useState(false);
  const [members, setMembers] = useState([]);
  const [date, setDate] = useState(null);
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [show, setShow] = useState(false);

  const [mwanaModal, setMwanaModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [allMembers, setAllMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawPerPage, setWithdrawPerPage] = useState(10);
  const indexOfLastPage = currentPage * withdrawPerPage;
  const indexOfFirstPage = indexOfLastPage - withdrawPerPage;
  const [initialValues, setInitialValues] = useState({
    name: "",
    dob: "",
    gender: "",
    education: "",
  });

  // Editing and Deletion
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    let data = allMembers;

    data =
      data && data?.length > 0
        ? data.filter((member) =>
            member?.mwana_meta
              ? member?.mwana_meta?.name
                  ?.toLowerCase()
                  .indexOf(searchText.toLowerCase()) > -1
              : member?.name
              ? member.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
              : false
          )
        : null;
    data =
      data && data?.length > 0
        ? data.filter((member) =>
            status === "approved"
              ? member?.mwana_meta
              : !status
              ? member
              : member?.review_status === status
          )
        : null;
    data =
      data && data?.length > 0
        ? data.filter(
            (member) =>
              !date ||
              (member?.created_at &&
                member.created_at.substring(0, 10) === date)
          )
        : null;

    setMembers(data);
  }, [searchText, date, status]);

  const shownMembers =
    members && members?.length > 0
      ? members.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const export_baana = () => {
    const formattedBaana = members.map((mwana) => {
      return {
        Name: (mwana.name || mwana.mwana_meta.name) ?? "Not specified",
        "Date of Birth": (mwana.dob || mwana.mwana_meta.dob) ?? "Not specified",
        Gender: (mwana.gender || mwana.mwana_meta.gender) ?? "Not specified",
        "Account Balance( UGX )":
          mwana?.balance && mwana?.balance > 0
            ? currencyFormatter(mwana.balance)
            : 0,
        "Mwana status": mwana.mwana_meta ? "approved" : mwana.review_status,
        "Educaton Level": mwana?.mwana_meta
          ? mwana.mwana_meta.education
          : mwana.education,
        "Health Issues": mwana.mwana_meta
          ? mwana.mwana_meta.health_issues
          : mwana.health_issues,
      };
    });

    generateReportFromJson(
      formattedBaana,
      "Baana " + new Date().toISOString().substring(0, 10)
    );
  };

  return (
    <div className="mx-5 my-2 md:h-[calc(100vh-70px)]">
      <ToastContainer />
      <Helmet>
        <title>Baana - Bweyogere tuberebumu</title>
      </Helmet>
      <div className="flex flex-col justify-between pb-3 md:h-[150px]">
        <h1 className="mb-3 mt-2 font-bold uppercase dark:text-white">BAANA</h1>
        <div className=" flex justify-between flex-wrap">
          <input
            type="text"
            className="w-8/12 rounded-md px-2 py-2 sm:py-1 dark:bg-dark-bg-600"
            placeholder="Search"
            onChange={(event) => setSearchText(event.target.value)}
          />
          {profile?.roles && !profile?.roles?.includes("super_admin") && (
            <button
              className=" px-4 bg-primary py-2 text-white rounded-md flex justify-center items-center"
              onClick={() => {
                navigate("/baana/add-baana");
              }}
            >
              Add Mwana
            </button>
          )}
        </div>
        <div className="flex justify-between flex-wrap">
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-4 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
            >
              <option value="">Status</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option valaue="rejected">Rejected</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex gap-2 flex-wrap">
            <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
              <input
                type="date"
                name=""
                onChange={(event) => setDate(event.target.value)}
                id="inputDate"
                className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
                value={date}
              />
              <button
                className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
                onClick={() => {
                  if (date) setDate("");
                  document.getElementById("inputDate").value = "";
                }}
              >
                <AiOutlineCloseCircle size={20} />
              </button>
            </div>
            <button
              className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
              onClick={() => {
                export_baana();
              }}
              disabled={!allMembers || allMembers?.length === 0}
            >
              Export
              <MdDownload className="ml-1" />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-170px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : members !== null && shownMembers.length > 0 ? (
          <>
            <div className="w-full pb-3 overflow-x-auto h-full  relative overflow-y-auto ">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Date</th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">ACC Balance (UGX)</th>
                    <th className="px-6 py-4">Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {shownMembers.map((mwana, index) => {
                    return (
                      <Fragment key={index}>
                        <tr
                          className={`${
                            index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                          } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                          key={index}
                        >
                          <td
                            onClick={() => {
                              setActiveIndex(index);
                              setMwanaModal(true);
                            }}
                          >
                            <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                          </td>
                          <td
                            className="px-6 py-3"
                            onClick={() => {
                              setActiveIndex(index);
                              setMwanaModal(true);
                            }}
                          >
                            {mwana?.created_at
                              ? moment(mwana?.created_at).format("DD-MM-YYYY")
                              : ""}
                          </td>
                          <td
                            className="px-6 py-3"
                            onClick={() => {
                              setActiveIndex(index);
                              setMwanaModal(true);
                            }}
                          >
                            {mwana.name || mwana.mwana_meta.name}
                          </td>
                          <td
                            className="px-6 py-3"
                            onClick={() => {
                              setActiveIndex(index);
                              setMwanaModal(true);
                            }}
                          >
                            {mwana?.balance
                              ? currencyFormatter(mwana.balance)
                              : 0}
                          </td>
                          <td
                            className={`px-4 py-3`}
                            onClick={() => {
                              setActiveIndex(index);
                              setMwanaModal(true);
                            }}
                          >
                            <span
                              className={` py-1 px-2 rounded-xl text-white ${
                                mwana.review_status === "pending"
                                  ? "bg-yellow-400"
                                  : mwana?.mwana_meta
                                  ? "bg-green-400"
                                  : "bg-rose-600"
                              }`}
                            >
                              {mwana.review_status === "pending"
                                ? "pending approval"
                                : mwana?.mwana_meta
                                ? "approved"
                                : mwana.review_status}
                            </span>
                          </td>
                          <td className="gap-1 align-middle">
                            <button
                              className="p-1 border rounded-lg my-auto mr-1 hover:text_white hover:border-primary group"
                              onClick={(event) => {
                                event.preventDefault();
                                setActiveIndex(() => index);
                                setInitialValues((initialValues) => {

                                  if ( mwana?.mwana_meta) {
                                    const {
                                      name,
                                      dob,
                                      gender,
                                      education,
                                      social_security_number,
                                      health_issues,
                                    } = mwana?.mwana_meta ;
  
                                    return {
                                      ...initialValues,
                                      name,
                                      dob,
                                      gender,
                                      education,
                                      social_security_number,
                                      health_issues,
                                    };
                                  } else {
                                    return initialValues
                                  }
                                });

                                mwana.review_status === "pending"
                                  ? toast.info("Mwana still under review", {
                                      position: "top-center",
                                    })
                                  : setEditModal(true);
                              }}
                            >
                              <RiPencilFill
                                size={15}
                                className="group-hover:text-primary"
                              />
                            </button>
                            <button
                              className="p-1 border rounded-lg my-auto mr-1 hover:text_white hover:border-accent-red group"
                              onClick={(event) => {
                                event.preventDefault();
                                setActiveIndex(() => index);
                                mwana.review_status === "pending"
                                  ? toast.info("Mwana still under review", {
                                      position: "top-center",
                                    })
                                  : setDeletePopUp(true);
                              }}
                            >
                              <AiFillDelete
                                size={15}
                                className="group-hover:text-accent-red"
                              />
                            </button>
                          </td>
                        </tr>
                        {deletePopUp && activeIndex === index && (
                          <ConfirmModal setPopUp={setDeletePopUp}>
                            <h1 className="font-bold">
                              Are you sure you want to delete{" "}
                              {mwana?.name || mwana?.mwana_meta?.name}?
                            </h1>
                            <p>
                              If you delete{" "}
                              {mwana.name || mwana.mwana_meta.name}'s mwana
                              account? <br /> You won't be able to recover it.
                            </p>
                            <div className="flex justify-end gap-3 mt-3">
                              <button
                                className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                                onClick={() => setDeletePopUp(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-accent-red px-3 py-1 outline outline-1 outline-accent-red rounded-md text-white"
                                onClick={async (event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setDeletePopUp(false);

                                  const toastNotification = toast.loading(
                                    "Processing request for mwana deletion."
                                  );

                                  try {
                                    const { data, error } = await supabase
                                      .from("applications")
                                      .insert({
                                        _type: "baana",
                                        created_at: new Date()
                                          .toISOString()
                                          .toLocaleString("en-GB", {
                                            timeZone: "UTC",
                                          }),
                                        updated_at: new Date()
                                          .toISOString()
                                          .toLocaleString("en-GB", {
                                            timeZone: "UTC",
                                          }),
                                        reviewed: false,
                                        application_meta: {
                                          applicants_id: profile.id,
                                          applicants_name: profile.fullname,
                                          review_status: "pending",
                                          type: "delete",
                                          children: [mwana.mwana_meta],
                                          account_id: mwana.id,
                                        },
                                      });

                                    if (error) throw error;

                                    if (data) {
                                      toast.update(toastNotification, {
                                        render: ` Submitted request for mwana deletion`,
                                        type: "success",
                                        isLoading: false,
                                        autoClose: 5000,
                                      });
                                    }
                                  } catch (error) {
                                    console.log("Error: ", error);
                                    toast.update(toastNotification, {
                                      render: `Failure. Please try again later`,
                                      type: "error",
                                      isLoading: false,
                                      autoClose: 5000,
                                    });
                                  }
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </ConfirmModal>
                        )}
                        {editModal && activeIndex === index && (
                          <ConfirmModal setPopUp={setEditModal}>
                            <Formik
                              initialValues={initialValues}
                              onSubmit={async (values, { resetForm }) => {
                                console.log("Form Values", values);

                                const toastId = toast.loading("Processing");

                                try {
                                  const { data, error } = await supabase
                                    .from("baana")
                                    .update({
                                      mwana_meta: {
                                        ...mwana.mwana_meta,
                                        ...values,
                                      },
                                    })
                                    .eq("id", mwana.id);

                                  if (error) {
                                    throw error;
                                  } else if ( data ) {
                                    console.log("Data: ", data)
                                    toast.update(toastId, {
                                      render: "Mwana successfully edited",
                                      type: "success",
                                      isLoading: false,
                                      autoClose: 5000,
                                    });
                                  }
                                } catch (error) {
                                  console.log("Error: ", error);

                                  toast.update(toastId, {
                                    render: `Failure. Please try again later`,
                                    type: "error",
                                    isLoading: false,
                                    autoClose: 5000,
                                  });
                                }
                              }}
                            >
                              {({
                                values,
                                touched,
                                errors,
                                setFieldValue,
                                handleChange,
                                handleBlur,
                                setSubmitting,
                              }) => {
                                return (
                                  <Form className="grid grid-cols-2 gap-5">
                                    <InputField
                                      errors={errors}
                                      touched={touched}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      reference="name"
                                      defaultValue={
                                        mwana?.name || mwana?.mwana_meta?.name
                                      }
                                      label="Full Name"
                                      placeholder="Enter Fullname"
                                      setFieldValue={setFieldValue}
                                      mandatory={true}
                                    />
                                    <InputField
                                      type="date"
                                      errors={errors}
                                      touched={touched}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      reference="dob"
                                      defaultValue={
                                        mwana?.dob || mwana?.mwana_meta?.dob
                                      }
                                      label="Birth Date"
                                      placeholder="Enter Fullname"
                                      setFieldValue={setFieldValue}
                                      mandatory={true}
                                    />
                                    <InputField
                                      errors={errors}
                                      touched={touched}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      reference="gender"
                                      defaultValue={
                                        mwana?.gender || mwana?.mwana_meta?.gender
                                      }
                                      label="Gender"
                                      placeholder="Enter Gender"
                                      setFieldValue={setFieldValue}
                                      mandatory={true}
                                    />
                                    <div className="flex flex-col w-56 ">
                                      <label className=" text-sm">
                                        Education level
                                        <span
                                          className={`text-sm text-red-500 ml-2 font-bold`}
                                        >
                                          *
                                        </span>
                                      </label>
                                      <select
                                        name="education"
                                        id=""
                                        onChange={(event) =>
                                          (values.education =
                                            event.target.value)
                                        }
                                        className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                          errors?.education &&
                                          touched?.education
                                            ? "ring-red-500"
                                            : "ring-black"
                                        }`}
                                      >
                                        <option value="">
                                          --Select Level--
                                        </option>
                                        <option
                                          value="No formal education"
                                          selected={
                                            mwana?.education ===
                                              "No formal education" ||
                                            mwana?.mwana_meta?.education ===
                                              "No formal education"
                                          }
                                        >
                                          No formal education
                                        </option>
                                        <option
                                          value="Primary education"
                                          selected={
                                            mwana?.education ===
                                              "Primary education" ||
                                            mwana?.mwana_meta?.education ===
                                              "Primary education"
                                          }
                                        >
                                          Primary
                                        </option>
                                        <option
                                          value="Secondary education"
                                          selected={
                                            mwana?.education ===
                                              "Secondary education" ||
                                            mwana?.mwana_meta?.education ===
                                              "Secondary education"
                                          }
                                        >
                                          Secondary
                                        </option>
                                        <option
                                          value="Vocational education"
                                          selected={
                                            mwana?.education ===
                                              "Vocational education" ||
                                            mwana?.mwana_meta?.education ===
                                              "Vocational education"
                                          }
                                        >
                                          Vocational{" "}
                                        </option>
                                        <option
                                          value="Associate's degree"
                                          selected={
                                            mwana?.education ===
                                              "Associate's degree" ||
                                            mwana?.mwana_meta?.education ===
                                              "Associate's degree"
                                          }
                                        >
                                          Associates
                                        </option>
                                        <option
                                          value="Bachelor's degree"
                                          selected={
                                            mwana?.education ===
                                              "Bachelor's degree" ||
                                            mwana?.mwana_meta?.education ===
                                              "Bachelor's degree"
                                          }
                                        >
                                          Bachelors
                                        </option>
                                        <option
                                          value="Master's degree"
                                          selected={
                                            mwana?.education ===
                                              "Master's degree" ||
                                            mwana?.mwana_meta?.education ===
                                              "Master's degree"
                                          }
                                        >
                                          Masters
                                        </option>
                                        <option
                                          value="Doctorate degree"
                                          selected={
                                            mwana?.education ===
                                              "Doctorate degree" ||
                                            mwana?.mwana_meta?.education ===
                                              "Doctorate degree"
                                          }
                                        >
                                          Doctorate
                                        </option>
                                      </select>
                                      <ErrorMessage name="education">
                                        {(msg) => (
                                          <div className="error text-xs text-red-500">
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </div>
                                    <InputField
                                      errors={errors}
                                      touched={touched}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      reference="social_security_number"
                                      defaultValue={
                                        mwana?.social_security_number ||
                                        mwana?.mwana_meta?.social_security_number
                                      }
                                      label="NIN"
                                      placeholder="Enter NIN"
                                      setFieldValue={setFieldValue}
                                      mandatory={false}
                                    />
                                    <textarea
                                      name="health_issues"
                                      id="health_issues"
                                      cols="40"
                                      rows="1"
                                      onChange={(event) => {
                                        setFieldValue(
                                          event.target.name,
                                          event.target.value,
                                          true
                                        );
                                        setInitialValues((initialValues) => ({
                                          ...initialValues,
                                          health_issues: event.target.value,
                                        }));
                                      }}
                                      onBlur={handleBlur}
                                      placeholder="Enter health issues"
                                      className={`ring-1 ring-black rounded px-2 py-2 bg-white dark:bg-dark-bg-600 focus:outline-none focus:ring-2 focus:ring-primary col-span-2 ${
                                        errors?.health_issues &&
                                        touched?.health_issues
                                          ? "ring-red-500"
                                          : "ring-black"
                                      }`}
                                      defaultValue={
                                        mwana?.health_issues ||
                                        mwana?.mwana_meta?.health_issues
                                      }
                                    ></textarea>
                                    <div className="flex justify-end mt-5 col-span-2">
                                      <button
                                        className=" px-4 bg-primary py-1 text-white flex justify-center items-center"
                                        type="submit"
                                        onClick={() => setSubmitting(true)}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </Form>
                                );
                              }}
                            </Formik>
                          </ConfirmModal>
                        )}
                        {mwanaModal && activeIndex === index && (
                          <BaanaModal
                            mwana={activeIndex === index && mwana}
                            setMwanaModal={setMwanaModal}
                            mwanaModal={mwanaModal}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(members.length / withdrawPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={members}
                depositsPerPage={withdrawPerPage}
                setDepositsPerPage={setWithdrawPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allMembers.length > 0 &&
          (shownMembers?.length === 0 || !shownMembers) && <Frown />
        )}
      </div>
    </div>
  );
}
