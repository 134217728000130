import { useParams } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { useState, useEffect } from "react";
import { Spinner } from "../../components";
import { toast, ToastContainer } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { NothingShown } from "../../components";
import { getOTP } from "../../helpers/getotp";
import { verifyOTP } from "../../helpers/verifyotp";
import { OTPBox } from "../../components";
import { sendSMS } from "../../helpers/sendsms";
import { add_separator } from "../../helpers/thousand_separator";

export default function TransferVerify() {
  const { id } = useParams();

  const [, profile, , roles] = useOutletContext();
  const [transfer, setTransfer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verify, setVerify] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const role = roles
    ? roles.find((role) => role !== "member" && role !== "admin")
    : null;

  useEffect(() => {
    getApplication()
      .then(async (data) => {
        if (data) {
          console.log("here: ", data);
          setTransfer(data);

          console.log(transfer.application_meta.file_url);
          // Downloading the image.
          if (!imageURL) {
            console.log(
              "File URL: ",
              data.application_meta.file_url.substring(9)
            );

            const { data: file, error } = await supabase.storage
              .from("deposits")
              .download(await data.application_meta.file_url.substring(9));

            console.log("Error: ", error);

            if (error) throw error;
            const url = URL.createObjectURL(file);
            console.log("Url: ", url);
            setImageURL(url);
          }
        }
      })

      .catch((error) => console.log(error));
  }, []);

  const [imageURL, setImageURL] = useState("");

  const getApplication = async () => {
    const { error, data } = await supabase
      .from("applications")
      .select()
      .eq("application_id", id)
      .single();
    if (error) throw error;
    console.log("Transfer: ", data);
    return data;
  };

  const approvetransferTransaction = async () => {
    console.log("Approving here");
    setIsSubmitting(true);
    try {
      if (transfer?.application_meta?.designated_for === "own") {
        const { data, error } = await supabase.rpc(
          "approve_transfer_application",
          {
            application: id,
          }
        );

        if (error) {
          throw error;
        } else if (data) {
          console.log("Result: ", data);
          setIsSubmitting(false);
          // handle the alerts and navigation
          // Send SMS notification.
          sendSMS(
            transfer.application_meta.member.id,
            `Hey ${
              transfer.application_meta.member.member_name
            }, your transfer application of amount UGX ${add_separator(
              transfer.application_meta.amount
            )} from your ${transfer.application_meta.account_type} account ${
              transfer?.application_meta?.designated_for === "other"
                ? `which was requested by the sacco's treasurer`
                : ""
            } has been approved by the sacco's chairperson. For more details visit https://tube.ablestate.co/transfers Regards, Bweyogerere Tuberebumu`
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
            })
            .catch((error) => console.log(error, "Error"));

          toast.success(`Transaction has been approved.`, {
            position: "top-center",
          });
          setTransfer((transfer) => ({ ...transfer, ...data }));
        }
      } else if (transfer?.application_meta?.designated_for === "other") {
        const toastID = toast.loading("Processing application");

        setVerify(true);
        getOTP(
          transfer?.application_meta?.senders_phone_number,
          "TRANSFER VERIFICATION"
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("Transfer Data: ", data);
            localStorage.setItem(
              "transfer_application_verification_key",
              data?.Details
            );

            if (data) {
              toast.update(toastID, {
                render:
                  data?.Status === "Failure"
                    ? data.Details
                    : "OTP sent. Enter OTP to verify your account",
                type: data?.Status === "Failure" ? "error" : "success",
                isLoading: false,
                autoClose: 5000,
              });
            }

            return;
          })
          .catch((error) => console.log("OTP Error: ", error));
      }
    } catch (error) {
      setIsSubmitting(false);
      toast.error(`${error?.message}`, { position: "top-center" });
    }
  };

  const rejecttransferTransaction = async () => {
    console.log("Rejecting here");
    setIsSubmitting(true);
    try {
      const { data, error } = await supabase
        .update({
          application_meta: {
            ...transfer.application_meta,
            admin_name: profile.fullname,
            admin_id: profile.id,
            admin_position: profile.position_in_sacco,
            review_status: "rejected",
          },
        })
        .eq("application_id", id)
        .single();

      if (error) {
        throw error;
      } else {
        setIsSubmitting(false);
        toast.success(`Transaction has been rejected.`, {
          position: "top-center",
        });
        // handle the alerts and navigation
        setTransfer((transfer) => ({ ...transfer, ...data }));
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <ToastContainer position="top-center" />
      <div className="flex flex-col justify-between pb-3 h-[60px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Verify transfer
        </h1>
      </div>
      <div className="bg-white p-3 md:overflow-y-auto  relative  md:h-[calc(100%-80px)] dark:bg-dark-bg-700 dark:text-secondary-text">
        {verify ? (
          <div className="w-full h-full flex justify-center items-center flex-col">
            <h1 className="font-bold">
              Verify your identity to confirm your submission
            </h1>
            <p className="text-sm">
              An OTP has been sent to your phone number. Please enter a valid
              OTP to confirm transfer.
            </p>
            <div className="flex flex-col justify-center items-center mt-5 p-5">
              <OTPBox otp={otp} setOtp={setOtp} />
              <button
                className=" bg-primary rounded-sm border-0 px-4 py-1 mt-2 text-white w-full"
                type="button"
                disabled={otp?.length < 6 || verifying}
                onClick={async (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setVerifying(true);

                  const toastID = toast.loading("Verifying OTP");
                  if (otp) {
                    console.log("The otp", otp.join(""));
                    const one_time_password = otp.join("");
                    const verification_key = localStorage.getItem(
                      "transfer_application_verification_key"
                    );

                    verifyOTP(
                      transfer.application_meta.senders_phone_number,
                      one_time_password,
                      verification_key
                    )
                      .then((response) => response.json())
                      .then(async (data) => {
                        if (data) {
                          toast.update(toastID, {
                            render:
                              data?.Status == "Failure"
                                ? data.Details
                                : "OTP verified",
                            type:
                              data?.Status === "Failure" ? "error" : "success",
                            isLoading: false,
                            autoClose: 5000,
                          });

                          try {
                            const { data, error } = await supabase.rpc(
                              "approve_transfer_other_application",
                              {
                                application: id,
                              }
                            );

                            if (error) {
                              throw error;
                            } else if (data) {
                              setIsSubmitting(false);
                              toast.success(`Transaction has been approved.`, {
                                position: "top-center",
                              });
                              setTransfer((transfer) => ({
                                ...transfer,
                                ...data,
                              }));
                              setVerify(false);
                            }
                          } catch (error) {
                            console.log("Application Error: ", error);
                            toast.update(toastID, {
                              render: `${error.message}`,
                              type: "error",
                              isLoading: false,
                              autoClose: 5000,
                            });
                            setVerifying(false);
                            setVerify(false);
                          }
                        }
                      });
                  }
                }}
              >
                Verify
              </button>
              <button
                className="w-full outline outline-1 outline-gray-400 rounded-sm px-3 py-1 mt-2 text-gray-400"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  getOTP(
                    transfer?.application_meta?.senders_phone_number,
                    "IDENTITY VERIFICATION"
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      console.log(data);
                      localStorage.setItem(
                        "transfer_application_verification_key",
                        data?.Details
                      );
                      return;
                    })
                    .catch((error) => console.log(error));
                }}
              >
                Resend OTP
              </button>
            </div>
          </div>
        ) : (
          <>
            {transfer ? (
              <div className="flex flex-grow flex-col min-h-full">
                <div className="mb-3">
                  <h1 className="font-semibold mb-3">
                    {transfer.application_meta &&
                      transfer.application_meta.applicants_name}
                    's transfer Details
                    <span
                      className={` py-1 px-2 rounded-lg text-white text-xs ml-1 inline-block capitalize ${
                        transfer?.transaction_meta
                          ? "bg-green-400"
                          : transfer?.application_meta?.review_status ===
                            "rejected"
                          ? "bg-red-400"
                          : transfer?.application_meta?.review_status ===
                            "approved"
                          ? "bg-green-400"
                          : transfer?.application_meta?.review_status ===
                            "forwarded"
                          ? "bg-blue-400"
                          : "bg-yellow-400"
                      }`}
                    >
                      {transfer?.application_meta?.review_status === "pending"
                        ? "pending approval"
                        : transfer?.transaction_meta
                        ? "approved "
                        : transfer?.application_meta?.review_status}
                    </span>
                  </h1>
                  <div className="outline outline-1 outline-gray-100 dark:outline-secondary-text p-3">
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Application ID</p>
                      <p className="font-bold col-span-3">
                        : {transfer.app_id}
                      </p>
                    </div>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Created At</p>
                      <p className="font-bold col-span-3">
                        :{" "}
                        {moment(transfer.created_at).format(
                          "DD-MM-YYYY hh:mm a"
                        )}
                      </p>
                    </div>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Amount</p>
                      <p className="font-bold col-span-3">
                        : UGX{" "}
                        {currencyFormatter(
                          transfer.application_meta &&
                            transfer.application_meta.amount
                        )}
                      </p>
                    </div>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Designated For</p>
                      <p className="font-bold col-span-3 capitalize">
                        :{" "}
                        {transfer?.application_meta?.designated_for === "own"
                          ? "self"
                          : "other member"}
                      </p>
                    </div>
                    <hr />
                    <div className="grid col-span-5 font-semibold w-full">
                      Source
                    </div>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Member Name</p>
                      <p className="font-bold col-span-3 capitalize">
                        :{" "}
                        {transfer?.transaction_meta?.from?.member_name ||
                          transfer?.application_meta?.senders_member_name}
                      </p>
                    </div>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Account type</p>
                      <p className="font-bold col-span-3  capitalize">
                        :{" "}
                        {transfer?.transaction_meta?.from?.account_type ||
                          transfer?.application_meta?.senders_account_type}
                      </p>
                    </div>
                    <hr />
                    <div className="col-span-5 font-semibold w-full">
                      Destination
                    </div>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Member Name</p>
                      <p className="font-bold col-span-3 capitalize">
                        :{" "}
                        {transfer?.transaction_meta?.to?.member_name ||
                          transfer?.application_meta?.receivers_member_name}
                      </p>
                    </div>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Account type</p>
                      <p className="font-bold col-span-3 capitalize">
                        :{" "}
                        {transfer?.transaction_meta?.to?.account_type ||
                          transfer?.application_meta?.receivers_account_type}
                      </p>
                    </div>
                    <hr />
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Comments</p>
                      <p className="font-bold col-span-3">
                        :{" "}
                        {(transfer.application_meta &&
                          transfer?.application_meta.comments) ||
                          "Not Specified"}
                      </p>
                    </div>
                    {imageURL && (
                      <div className="grid grid-cols-5 gap-2 justify-start w-full items-center">
                        <p className="col-span-2">Proof of payment</p>
                        <div className="flex min-w-fit col-span-3 items-center">
                          <span className="font-bold">:</span>
                          <a
                            className="px-2 text-sky-400 underline mt-2"
                            href={imageURL}
                            title="Files"
                            target="_blank"
                          >
                            Preview file
                          </a>
                          <a
                            className="px-2 text-sky-400 underline mt-2"
                            href={imageURL}
                            title="Files"
                            download={true}
                          >
                            Download file
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {transfer?.application_meta.applicants_id !== profile.id &&
                  ((role === "secretary" || role === "asst_secretary") &&
                  transfer?.application_meta.review_status === "pending" &&
                  transfer.application_meta?.designated_for === "own" ? (
                    <div className="flex gap-10 justify-end items-center mt-3">
                      <button
                        type="submit"
                        className="bg-blue-500 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                        onClick={async () => {
                          const toastID = toast.loading("Forwarding transfer");

                          try {
                            const { data, error } = await supabase
                              .from("applications")
                              .update({
                                application_meta: {
                                  ...transfer.application_meta,
                                  ...{
                                    admin_1: {
                                      admin_id: `${profile.id}`,
                                      admin_name: `${profile.fullname}`,
                                      position_in_sacco: `${profile.position_in_sacco}`,
                                      review_status: "forwarded",
                                      reviewed: true,
                                      reviewed_at: `${new Date().toISOString()}`,
                                      seen: true,
                                    },
                                  },
                                  review_status: "forwarded",
                                },
                              })
                              .eq("application_id", transfer.application_id)
                              .single();

                            if (error) throw error;
                            console.log("Data: ", data);

                            if (data) {
                              sendSMS(
                                transfer.application_meta.member.id,
                                `Hey ${
                                  transfer.application_meta.member.member_name
                                }, your transfer application of amount UGX has been forwarded for review to the chairperson. For more details visit https://tube.ablestate.co/transfers Regards, Bweyogerere Tuberebumu`
                              )
                                .then((response) => response.json())
                                .then((data) => {
                                  console.log(data);
                                })
                                .catch((error) => console.log(error, "Error"));
                              toast.update(toastID, {
                                render: "transfer successfully forwarded",
                                type: "success",
                                isLoading: false,
                                autoClose: 5000,
                              });

                              setTransfer(() => data);
                            }
                          } catch (error) {
                            console.log("Error: ", error);

                            toast.update(toastID, {
                              render: "Failure. Please try again later.",
                              type: "error",
                              isLoading: false,
                              autoClose: 5000,
                            });
                          }
                        }}
                        disabled={isSubmitting}
                      >
                        Forward
                      </button>
                    </div>
                  ) : (
                    ( 
                      ["chairperson", "vice_chairperson"].find( user_role => user_role === role ) &&
                      transfer?.application_meta.review_status === "forwarded" &&
                      ( 
                        ( transfer?.application_meta?.designated_for === "own" && transfer?.application_meta?.applicants_id !== profile.id ) ||
                        ( transfer?.application_meta?.designated_for === "other" && transfer?.application_meta?.member?.id !== profile.id )) &&
                        ![ "pending", "rejected", "approved" ].includes( transfer?.application_meta?.review_status ) && 
                       (
                        <div className="flex gap-10 justify-end items-center mt-3">
                          <button
                            type="submit"
                            className="bg-accent-red inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                            onClick={rejecttransferTransaction}
                            disabled={isSubmitting}
                          >
                            Reject
                          </button>
                          <button
                            type="submit"
                            className="bg-green-600 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                            onClick={approvetransferTransaction}
                            disabled={isSubmitting}
                          >
                            Approve
                          </button>
                        </div>
                      ))
                  ))}
              </div>
            ) : loading ? (
              <Spinner />
            ) : (
              <NothingShown />
            )}
          </>
        )}
      </div>
    </div>
  );
}
