import { Submit, Spinner } from "../../components";
import { Formik, Form, ErrorMessage } from "formik";
import { uploadFile } from "../../helpers/uploadFile";
import { supabase } from "../../helpers/supabase";
import { useAuth } from "../../auth/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { transferValidationSchema } from "../../helpers/validator";
import { useOutletContext } from "react-router-dom";
import { useState } from "react";
import {
  add_separator,
  remove_separator,
} from "../../helpers/thousand_separator";
import { useEffect } from "react";
import { OTPBox } from "../../components";
import { getOTP } from "../../helpers/getotp";
import { verifyOTP } from "../../helpers/verifyotp";
import { sendSMS } from "../../helpers/sendsms";

function RequestSingleTransfer() {
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState({});
  const [allBaana, setAllBaana] = useState([]);
  const [verify, setVerify] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isVerifying, setVerifying] = useState(false);
  //   const navigate = useNavigate();

  const {
    user: { id: applicants_id },
  } = useAuth();
  const [
    ,
    {
      fullname: applicants_name,
      phone_number,
      position_in_sacco: admin_position,
    },
    ,
    roles,
  ] = useOutletContext();
  useEffect(() => {
    fetchMemberAccounts();
  }, []);

  console.log("Phone number: ", phone_number);

  const fetchMemberAccounts = async () => {
    const {
      data: { baana: baanaData, ...data },
      error,
    } = await supabase.rpc("fetch_transfer_information");

    console.log(error);

    if (error) console.log("Transfer Error: ", error);
    console.log("Data: ", data);
    if (data) {
      console.log("Members accounts: ", data);
      setProfiles(() => data);
      setAllBaana(() => baanaData ?? []);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const initialValues = {
    account_type: "",
    amount: "",
    comments: "",
    evidence: "",
    designated_for: "own",
    receivers_member_id: "",
    receivers_member_name: "",
    senders_member_id: applicants_id,
    senders_member_name: applicants_name,
    senders_baana: [],
    receivers_baana: [],
    senders_account_type: "",
    receivers_account_type: "",
    senders_mwana: "",
    receivers_mwana: "",
    member_name: "",
    member_id: "",
    senders_phone_number: "",
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
        Transfers
      </h1>
      <div className="flex bg-white overflow-hidden relative dark:bg-dark-bg-700 dark:text-secondary-text p-6 w-full justify-start h-[calc(100vh-120px)] overflow-y-scroll">
        {loading && (
          <div className="absolute z-10 bg-white dark:bg-dark-bg-700 dark:bg-opacity-90 bg-opacity-90 w-full h-full rounded-lg">
            <Spinner />
          </div>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            const toastID = toast.loading("Processing application");
            console.log("Form Values", values);
            setSubmitting(true);

            let file_url = "";

            try {
              if (values.designated_for === "own") {
                // Get the otp and change the state so that it renders the verification page.
                setVerify(true);
                getOTP(phone_number, "TRANSFER VERIFICATION")
                  .then((response) => response.json())
                  .then((data) => {
                    console.log("Transfer Data: ", data);
                    localStorage.setItem(
                      "transfer_application_verification_key",
                      data?.Details
                    );

                    if (data) {
                      toast.update(toastID, {
                        render:
                          data?.Status === "Failure"
                            ? data.Details
                            : "OTP sent. Enter OTP to verify your account",
                        type: data?.Status === "Failure" ? "error" : "success",
                        isLoading: false,
                        autoClose: 5000,
                      });
                    }

                    return;
                  })
                  .catch((error) => console.log("OTP Error: ", error));
              } else if (values.designated_for === "other") {
                if (values?.evidence) {
                  const { Key: url } = await uploadFile(
                    values.evidence,
                    "deposits"
                  );
                  file_url = url;
                }

                const application_meta = {
                  applicants_name,
                  applicants_id,
                  ...values,
                  file_url,
                  created_at: new Date()
                    .toISOString()
                    .toLocaleString("en-GB", { timeZone: "UTC" }),
                  updated_at: new Date()
                    .toISOString()
                    .toLocaleString("en-GB", { timeZone: "UTC" }),
                  member: {
                    id: values.senders_member_id,
                    member_name: values.senders_member_name,
                  },
                  review_status: "pending",
                };

                const { data, error } = await supabase
                  .from("applications")
                  .insert({
                    _type: "transfer",
                    created_at: new Date()
                      .toISOString()
                      .toLocaleString("en-GB", { timeZone: "UTC" }),
                    updated_at: new Date()
                      .toISOString()
                      .toLocaleString("en-GB", { timeZone: "UTC" }),
                    reviewed: false,
                    application_meta,
                  });

                if (error) {
                  throw error;
                } else if (data) {
                  const { data: secretariate } = await supabase
                    .from("_member_profiles")
                    .select(
                      `
                  id, fullname
                `
                    )
                    .in("position_in_sacco", [
                      "secretary",
                      "assistant secretary",
                    ])
                    .eq("is_under_termination", false);

                  if (secretariate && secretariate?.length > 0) {
                    secretariate.forEach(async (member) => {
                      await sendSMS(
                        member.id,
                        `Hello ${
                          member.fullname
                        }, a sacco member has requested for a transfer from their ${
                          values.senders_account_type
                        } ${
                          values.senders_member_id ===
                          values.receivers_member_id
                            ? `to their ${values.receivers_account_type} account`
                            : `to ${values.receivers_member_name}'s ${values.receivers_account_type} account.`
                        }. To forward the request to the chairperson, visit https://tube.ablestate.co/transfers Regards, Bweyogerere Tuberebumu Sacco.`
                      )
                        .then((response) => response.json())
                        .then((data) => {
                          console.log(data);
                        })
                        .catch((error) => console.log(error, "Error"));
                    });
                  }

                  if (values?.designated_for === "other") {
                    sendSMS(
                      values.member_id,
                      `Hello ${values.member_name}, a transfer has been requested by the ${admin_position} of amount UGX ${values.amount} from your ${values.senders_account_type} account to ${values.receivers_member_name}'s ${values.receivers_account_type} account. For more details visit For more details visit https://tube.ablestate.co/transfers Regards, Bweyogerere Tuberebumu Sacco.`
                    )
                      .then((response) => response.json())
                      .then((data) => {
                        console.log(data);
                      })
                      .catch((error) => console.log(error, "Error"));
                  }

                  console.log("Transfer request", data);
                  toast.update(toastID, {
                    render: "Transfer request submitted for review",
                    type: "success",
                    isLoading: false,
                    autoClose: 5000,
                  });

                  setSubmitting(false);
                  resetForm({ values: initialValues });
                }
              }
            } catch (error) {
              console.log("Error: ", error);
              toast.update(toastID, {
                render: `${error.message}`,
                type: "error",
                isLoading: false,
                autoClose: 5000,
              });

              setSubmitting(false);
              resetForm({ values: initialValues });
            }
          }}
          validationSchema={transferValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            setFieldValue,
            isSubmitting,
            resetForm,
          }) => {
            return (
              <Form className="w-full">
                <ToastContainer position="top-center" />

                {verify ? (
                  <div className="w-full h-full flex justify-center items-center flex-col">
                    <h1 className="font-bold">
                      Verify your identity to confirm your submission
                    </h1>
                    <p className="text-sm">
                      An OTP has been sent to your phone number. Please enter a
                      valid OTP to confirm transfer.
                    </p>
                    <div className="flex flex-col justify-center items-center mt-5 p-5">
                      <OTPBox otp={otp} setOtp={setOtp} />
                      <button
                        className=" bg-primary rounded-sm border-0 px-4 py-1 mt-2 text-white w-full"
                        type="button"
                        disabled={otp?.length < 6 || isVerifying}
                        onClick={async (event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setVerifying(true);

                          const toastID = toast.loading("Verifying OTP");
                          if (otp) {
                            console.log("The otp", otp.join(""));
                            const one_time_password = otp.join("");
                            const verification_key = localStorage.getItem(
                              "transfer_application_verification_key"
                            );

                            console.log("Verification Key: ", verification_key);

                            verifyOTP(
                              phone_number,
                              one_time_password,
                              verification_key
                            )
                              .then((response) => response.json())
                              .then(async (data) => {
                                if (data) {
                                  toast.update(toastID, {
                                    render:
                                      data?.Status == "Failure"
                                        ? data.Details
                                        : "OTP verified",
                                    type:
                                      data?.Status === "Failure"
                                        ? "error"
                                        : "success",
                                    isLoading: false,
                                    autoClose: 5000,
                                  });

                                  try {
                                    let file_url = "";

                                    if (values?.evidence) {
                                      const { Key: url } = await uploadFile(
                                        values.evidence,
                                        "deposits"
                                      );
                                      file_url = url;
                                    }

                                    const application_meta = {
                                      applicants_name,
                                      applicants_id,
                                      ...values,
                                      file_url,
                                      created_at: new Date()
                                        .toISOString()
                                        .toLocaleString("en-GB", {
                                          timeZone: "UTC",
                                        }),
                                      updated_at: new Date()
                                        .toISOString()
                                        .toLocaleString("en-GB", {
                                          timeZone: "UTC",
                                        }),
                                      member: {
                                        id: values.senders_member_id,
                                        member_name: values.senders_member_name,
                                      },
                                      review_status: "pending",
                                    };

                                    const { data, error } = await supabase
                                      .from("applications")
                                      .insert({
                                        _type: "transfer",
                                        created_at: new Date()
                                          .toISOString()
                                          .toLocaleString("en-GB", {
                                            timeZone: "UTC",
                                          }),
                                        updated_at: new Date()
                                          .toISOString()
                                          .toLocaleString("en-GB", {
                                            timeZone: "UTC",
                                          }),
                                        reviewed: false,
                                        application_meta,
                                      });

                                    if (error) {
                                      throw error;
                                    } else if (data) {
                                      console.log("Application Data: ", data);

                                      const { data: secretariate } =
                                        await supabase
                                          .from("_member_profiles")
                                          .select(
                                            `
                                            id, fullname
                                          `
                                          )
                                          .in("position_in_sacco", [
                                            "secretary",
                                            "assistant secretary",
                                          ])
                                          .eq("is_under_termination", false);

                                      if (
                                        secretariate &&
                                        secretariate?.length > 0
                                      ) {
                                        secretariate.forEach(async (member) => {
                                          await sendSMS(
                                            member.id,
                                            `Hello ${
                                              member.fullname
                                            }, a sacco member has requested for a transfer from their ${
                                              values.senders_account_type
                                            } ${
                                              values.senders_member_id ===
                                              values.receivers_member_id
                                                ? `to their ${values.receivers_account_type} account`
                                                : `to ${values.receivers_member_name}'s ${values.receivers_account_type} account.`
                                            }. To forward the request to the chairperson, visit https://tube.ablestate.co/transfers Regards, Bweyogerere Tuberebumu Sacco.`
                                          )
                                            .then((response) => response.json())
                                            .then((data) => {
                                              console.log(data);
                                            })
                                            .catch((error) =>
                                              console.log(error, "Error")
                                            );
                                        });
                                      }

                                      toast.update(toastID, {
                                        render:
                                          "Transfer request submitted for review",
                                        type: "success",
                                        isLoading: false,
                                        autoClose: 5000,
                                      });
                                      setVerifying("false");
                                      resetForm({ values: initialValues });
                                      setVerify(false);
                                    }
                                  } catch (error) {
                                    console.log("Application Error: ", error);
                                    toast.update(toastID, {
                                      render: `${error.message}`,
                                      type: "error",
                                      isLoading: false,
                                      autoClose: 5000,
                                    });
                                    setVerifying(false);
                                    resetForm({ values: initialValues });
                                    setVerify(false);
                                  }
                                }
                              });
                          }
                        }}
                      >
                        Verify
                      </button>
                      <button
                        className="w-full outline outline-1 outline-gray-400 rounded-sm px-3 py-1 mt-2 text-gray-400"
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          getOTP(phone_number, "IDENTITY VERIFICATION")
                            .then((response) => response.json())
                            .then((data) => {
                              console.log(data);
                              localStorage.setItem(
                                "transfer_application_verification_key",
                                data?.Details
                              );
                              return;
                            })
                            .catch((error) => console.log(error));
                        }}
                      >
                        Resend OTP
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-grow flex-col min-h-full w-full">
                    <div className="mb-3 flex gap-5 flex-wrap flex-col">
                      {roles && roles.includes("treasurer") && (
                        <div className="flex flex-wrap gap-5">
                          <div className="flex flex-col w-56 pb-3">
                            <label className="text-sm">
                              Designated for{" "}
                              <span className="text-sm text-red-500 ml-2 font-bold">
                                *
                              </span>
                            </label>
                            <div className="flex justify-between">
                              <div className="flex gap-1">
                                <input
                                  type="radio"
                                  id="own"
                                  name="designated_for"
                                  value="own"
                                  className="w-4 h-4"
                                  onChange={() => {
                                    setFieldValue("designated_for", "own");
                                    setFieldValue(
                                      "senders_member_id",
                                      applicants_id
                                    );
                                    setFieldValue(
                                      "senders_member_name",
                                      applicants_name
                                    );
                                    setFieldValue(
                                      "senders_phone_number",
                                      phone_number
                                    );
                                  }}
                                />
                                <label htmlFor="own" className="text-sm">
                                  My Own
                                </label>
                              </div>
                              <div className="flex gap-1">
                                <input
                                  type="radio"
                                  id="other"
                                  name="designated_for"
                                  value="other"
                                  className="w-4 h-4"
                                  onChange={() => {
                                    setFieldValue("designated_for", "other");
                                  }}
                                />
                                <label htmlFor="other" className="text-sm">
                                  Other Member
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col">
                        <h3 className="font-semibold">Source</h3>
                        <div className="flex gap-5 flex-wrap">
                          <div className="flex flex-col w-56">
                            <label className="text-sm">
                              Account Type{" "}
                              <span className="text-sm text-red-500 ml-2 font-bold">
                                *
                              </span>
                            </label>
                            <select
                              name="senders_account_type"
                              id="senders_account_type"
                              className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                                errors?.senders_account_type &&
                                touched?.senders_account_type
                                  ? "ring-red-500"
                                  : "ring-black"
                              }`}
                              onChange={(event) => {
                                setFieldValue(
                                  event.target.name,
                                  event.target.value
                                );
                              }}
                              onBlur={handleBlur}
                              value={values.senders_account_type}
                            >
                              <option value="">--Select Account--</option>
                              <option value="savings">Savings</option>
                              <option value="shares">Shares</option>
                              <option value="fixed">Fixed</option>
                              {/* <option value="mwana">Mwana</option> */}
                              {/* <option value="development">Development</option> */}
                            </select>
                            <ErrorMessage name="senders_account_type">
                              {(msg) => (
                                <div className="error text-red-500 text-xs">
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          {values.designated_for === "other" && (
                            <div className="flex flex-col w-56">
                              <label className="text-sm">
                                From Member{" "}
                                <span className="text-sm text-red-500 ml-2 font-bold">
                                  *
                                </span>
                              </label>
                              <select
                                name="senders_member_id"
                                id="senders_member_id"
                                className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                                  errors?.senders_member_id &&
                                  touched?.senders_member_id
                                    ? "ring-red-500"
                                    : "ring-black"
                                }`}
                                onChange={async (event) => {
                                  const [memberID, memberName, phoneNumber] =
                                    event.target.value.split("|");

                                  console.log("Members ID: ", memberID);
                                  console.log("Member Name: ", memberName);

                                  setFieldValue(event.target.name, memberID);
                                  setFieldValue(
                                    "senders_phone_number",
                                    phone_number
                                  );
                                  values.senders_member_name = memberName;
                                  const filteredBaana = allBaana.filter(
                                    (mwana) => mwana.member_id === memberID
                                  );
                                  setFieldValue("senders_baana", filteredBaana);
                                }}
                                onBlur={handleBlur}
                              >
                                <option value="">--Select Member--</option>
                                {values.senders_account_type === "savings"
                                  ? profiles &&
                                    profiles?.savings_accounts &&
                                    profiles.savings_accounts.map(
                                      (
                                        { fullname, id, phone_number },
                                        index
                                      ) => {
                                        return (
                                          <option
                                            key={index}
                                            value={`${id}|${fullname}|${phone_number}`}
                                            className="capitalize"
                                          >
                                            {fullname}
                                          </option>
                                        );
                                      }
                                    )
                                  : values.senders_account_type ===
                                    "development"
                                  ? profiles &&
                                    profiles?.development_accounts &&
                                    profiles.development_accounts.map(
                                      (
                                        { fullname, id, phone_number },
                                        index
                                      ) => {
                                        return (
                                          <option
                                            key={index}
                                            value={`${id}|${fullname}|${phone_number}`}
                                            className="capitalize"
                                          >
                                            {fullname}
                                          </option>
                                        );
                                      }
                                    )
                                  : values?.senders_account_type === "shares"
                                  ? profiles &&
                                    profiles?.shares_accounts &&
                                    profiles.shares_accounts.map(
                                      (
                                        { fullname, id, phone_number },
                                        index
                                      ) => {
                                        return (
                                          <option
                                            key={index}
                                            value={`${id}|${fullname}|${phone_number}`}
                                            className="capitalize"
                                          >
                                            {fullname}
                                          </option>
                                        );
                                      }
                                    )
                                  : values?.senders_account_type === "mwana"
                                  ? profiles &&
                                    profiles?.mwana_accounts &&
                                    profiles.mwana_accounts.map(
                                      (
                                        { fullname, id, phone_number },
                                        index
                                      ) => {
                                        return (
                                          <option
                                            key={index}
                                            value={`${id}|${fullname}|${phone_number}`}
                                            className="capitalize"
                                          >
                                            {fullname}
                                          </option>
                                        );
                                      }
                                    )
                                  : values?.senders_account_type === "fixed" &&
                                    profiles &&
                                    profiles?.fixed_deposit_accounts &&
                                    profiles.fixed_deposit_accounts.map(
                                      (
                                        { fullname, id, phone_number },
                                        index
                                      ) => {
                                        return (
                                          <option
                                            key={index}
                                            value={`${id}|${fullname}|${phone_number}`}
                                            className="capitalize"
                                          >
                                            {fullname}
                                          </option>
                                        );
                                      }
                                    )}
                              </select>
                              <ErrorMessage name="senders_member_id">
                                {(msg) => (
                                  <div className="error text-red-600 text-xs">
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                              {values?.account_type === "mwana" &&
                                values?.designated_for === "other" &&
                                values?.senders_member_id &&
                                values.senders_baana.length === 0 &&
                                touched.senders_member_id && (
                                  <div className="error text-red-600 text-xs">
                                    The member has not yet added baana
                                  </div>
                                )}
                            </div>
                          )}
                          {values.senders_account_type === "mwana" && (
                            <div className="flex flex-col w-56">
                              <label className="text-sm">
                                Select Mwana{" "}
                                <span className="text-sm text-red-500 ml-2 font-bold">
                                  *
                                </span>
                              </label>
                              <select
                                name="senders_mwana"
                                id="senders_mwana"
                                className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                                  errors?.senders_mwana &&
                                  touched?.senders_mwana
                                    ? "ring-red-500"
                                    : "ring-black"
                                }`}
                                onChange={(event) => {
                                  setFieldValue(
                                    "senders_mwana",
                                    event.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                                value={values.senders_mwana}
                              >
                                <option value="">--Select Mwana--</option>
                                {values.senders_baana.map((mwana, index) => {
                                  return (
                                    <option value={mwana?.id} key={index}>
                                      {mwana?.mwana_meta?.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <ErrorMessage name="senders_mwana">
                                {(msg) => (
                                  <div className="error text-red-500 text-xs">
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <h3 className="font-semibold">Destination</h3>
                        <div className="flex gap-5 flex-wrap">
                          <div className="flex flex-col w-56">
                            <label className="text-sm">
                              To Account{" "}
                              <span className="text-sm text-red-500 ml-2 font-bold">
                                *
                              </span>
                            </label>
                            <select
                              name="receivers_account_type"
                              id="receivers_account_type"
                              className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                                errors?.receivers_account_type &&
                                touched?.receivers_account_type
                                  ? "ring-red-500"
                                  : "ring-black"
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.receivers_account_type}
                            >
                              <option value="">--Select Account--</option>
                              <option value="savings">Savings</option>
                              <option value="shares">Shares</option>
                              <option value="fixed">Fixed</option>
                              {/* <option value="mwana">Mwana</option> */}
                              {/* <option value="development">Development</option> */}
                            </select>
                            <ErrorMessage name="receivers_account_type">
                              {(msg) => (
                                <div className="error text-red-500 text-xs">
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="flex flex-col w-56">
                            <label className="text-sm">
                              To Member{" "}
                              <span className="text-sm text-red-500 ml-2 font-bold">
                                *
                              </span>
                            </label>
                            <select
                              name="receivers_member_id"
                              id="receivers_member_id"
                              className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                                errors?.receivers_member_id &&
                                touched?.receivers_member_id
                                  ? "ring-red-500"
                                  : "ring-black"
                              }`}
                              onChange={async (event) => {
                                const [memberID, memberName] =
                                  event.target.value.split("|");

                                setFieldValue(event.target.name, memberID);
                                values.receivers_member_name = memberName;
                                const filteredBaana = allBaana.filter(
                                  (mwana) => mwana.member_id === memberID
                                );
                                setFieldValue("receivers_baana", filteredBaana);
                              }}
                              onBlur={handleBlur}
                            >
                              <option value="">--Select Member--</option>
                              {values.receivers_account_type === "savings"
                                ? profiles &&
                                  profiles?.savings_accounts &&
                                  profiles.savings_accounts.map(
                                    ({ fullname, id }, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={`${id}|${fullname}`}
                                          className="capitalize"
                                        >
                                          {fullname}
                                        </option>
                                      );
                                    }
                                  )
                                : values.receivers_account_type ===
                                  "development"
                                ? profiles &&
                                  profiles?.development_accounts &&
                                  profiles.development_accounts.map(
                                    ({ fullname, id }, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={`${id}|${fullname}`}
                                          className="capitalize"
                                        >
                                          {fullname}
                                        </option>
                                      );
                                    }
                                  )
                                : values?.receivers_account_type === "shares"
                                ? profiles &&
                                  profiles?.shares_accounts &&
                                  profiles.shares_accounts.map(
                                    ({ fullname, id }, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={`${id}|${fullname}`}
                                          className="capitalize"
                                        >
                                          {fullname}
                                        </option>
                                      );
                                    }
                                  )
                                : values?.receivers_account_type === "mwana"
                                ? profiles &&
                                  profiles?.mwana_accounts &&
                                  profiles.mwana_accounts.map(
                                    ({ fullname, id }, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={`${id}|${fullname}`}
                                          className="capitalize"
                                        >
                                          {fullname}
                                        </option>
                                      );
                                    }
                                  )
                                : values?.receivers_account_type === "fixed" &&
                                  profiles &&
                                  profiles?.fixed_deposit_accounts &&
                                  profiles.fixed_deposit_accounts.map(
                                    ({ fullname, id }, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={`${id}|${fullname}`}
                                          className="capitalize"
                                        >
                                          {fullname}
                                        </option>
                                      );
                                    }
                                  )}
                            </select>
                            <ErrorMessage name="receivers_member_id">
                              {(msg) => (
                                <div className="error text-red-600 text-xs">
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                            {values?.account_type === "mwana" &&
                              values?.designated_for === "other" &&
                              values?.receivers_member_id &&
                              values.receivers_baana.length === 0 &&
                              touched.receivers_member_id && (
                                <div className="error text-red-600 text-xs">
                                  The member has not yet added baana
                                </div>
                              )}
                          </div>
                          {values.receivers_account_type === "mwana" && (
                            <div className="flex flex-col w-56">
                              <label className="text-sm">
                                Select Mwana{" "}
                                <span className="text-sm text-red-500 ml-2 font-bold">
                                  *
                                </span>
                              </label>
                              <select
                                name="receivers_mwana"
                                id="receivers_mwana"
                                className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                                  errors?.receivers_mwana &&
                                  touched?.receivers_mwana
                                    ? "ring-red-500"
                                    : "ring-black"
                                }`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.receivers_mwana}
                              >
                                <option value="">--Select Mwana--</option>
                                {values.receivers_baana.map((mwana, index) => {
                                  return (
                                    <option value={mwana?.id} key={index}>
                                      {mwana?.mwana_meta?.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <ErrorMessage name="receivers_mwana">
                                {(msg) => (
                                  <div className="error text-red-500 text-xs">
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mb-3 flex flex-wrap gap-5">
                        <div className="flex flex-col w-56 ">
                          <label className=" text-sm">
                            Enter Amount{" "}
                            <span className="text-sm text-red-500 ml-2 font-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            name="amount"
                            id="amount"
                            placeholder="Enter amount"
                            className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                              errors?.amount && touched?.amount
                                ? "ring-red-500"
                                : "ring-black"
                            }`}
                            onChange={(event) => {
                              let formatted_string = add_separator(
                                remove_separator(event.target.value)
                              );
                              event.target.value = formatted_string;
                              setFieldValue(
                                event.target.name,
                                parseFloat(remove_separator(formatted_string))
                              );
                            }}
                            onBlur={handleBlur}
                          />
                          <div className="relative mt-1">
                            <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                              {" "}
                            </div>
                            <div className="text-xs text-gray-400 pl-3">
                              Use figures
                            </div>
                          </div>
                          <ErrorMessage name="amount">
                            {(msg) => (
                              <div className="error text-red-500 text-xs">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                        {/* <div className="flex flex-col w-56 ">
                          <label className=" text-sm">Upload Receipt</label>
                          <input
                            type="file"
                            name="evidence"
                            id="evidence"
                            placeholder="Enter postal address"
                            className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                              errors?.evidence && touched?.evidence
                                ? "ring-red-500"
                                : "ring-black"
                            }`}
                            onChange={(event) => {
                              values.evidence = event.currentTarget.files[0];
                            }}
                            onBlur={handleBlur}
                            accept="image/png, image/jpeg, application/pdf, .doc, .docx"
                          />
                          {touched?.evidence && errors?.evidence && (
                            <div className="error text-red-600 text-xs">
                              {errors?.evidence}
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div className="mb-3">
                      <h1 className="font-semibold">
                        {values.designated_for === "organisation"
                          ? "Reason"
                          : "Comments"}
                        {"    "}
                        {/* <span className="text-sm font-normal">
                          ( Specify the transaction ID )
                        </span> */}
                      </h1>
                      <textarea
                        name="comments"
                        id="comments"
                        cols="30"
                        rows="10"
                        className="outline outline-1 rounded-md w-full p-2 dark:bg-dark-bg-600"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.comments}
                        placeholder=""
                      ></textarea>
                      {touched?.comments && errors?.comments && (
                        <div className="error text-red-600 text-xs">
                          {errors?.comments}
                        </div>
                      )}
                    </div>
                    <div className="flex justify-start w-full">
                      <div className="w-56 pb-3">
                        <Submit
                          value="Submit"
                          disabled={!(isValid && dirty) || isSubmitting}
                        />
                      </div>
                      {/*Debug */}
                      {/* <button
                        className="px-2 bg-primary text-white capitalize ml-2"
                        type="button"
                        onClick={(event) => {
  
                          event.preventDefault()
                          console.log("Values: ", values)
                          console.log("Errors: ", errors)
                        }}
                      >
                        debug
                      </button> */}
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default RequestSingleTransfer;
