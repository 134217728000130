import { useEffect, useState, useCallback, useRef } from "react";
import { supabase } from "../../helpers/supabase";
import { getFormattedDateAgo } from "../../helpers/formatDate";
import { BiCheckDouble } from "react-icons/bi";
import { useAuth } from "../../auth/AuthContext";
import { uniqBy } from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function NotificationContext({
  show,
  loading,
  setLoading,
  setUnseenNotifications,
}) {
  const [notifications, setNotifications] = useState([]);
  const { id } = supabase.auth.user();
  const [done, setDone] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [range, setRange] = useState({
    from: 0,
    to: 9,
  });
  const navigate = useNavigate();

  const { user } = useAuth();

  let options = {
    root: document.querySelector("#scrollArea"),
    rootMargin: "0px",
    threshold: 1.0,
  };

  const intObserver = useRef();
  const lastElementRef = useCallback((lastNotificationNode) => {
    if (intObserver.current) intObserver.current.disconnect();

    intObserver.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting && !done) {
        // Fetch more notifications
        setRange((range) => ({
          from: range.from + 10,
          to: range.to + 10,
        }));

        setLoadingMore(true);
      }
    }, options);

    if (lastNotificationNode) intObserver.current.observe(lastNotificationNode);
  });

  useEffect(() => {
    getNotifications().catch((error) => console.log(error));

    const mySubscription = supabase
      .from("notifications")
      .on("*", (payload) => {
        getNotifications();
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, [range]);

  const getNotifications = async () => {
    const { data: unseen } = await supabase
      .from("notifications")
      .select()
      .order("created_at", { ascending: false })
      .match({ receiver: id, seen: false });

    setUnseenNotifications(() => unseen.length ?? 0);

    const { data, error } = await supabase
      .from("notifications")
      .select()
      .order("created_at", { ascending: false })
      .match({ receiver: id })
      .range(range.from, range.to);

    if (error) {
      throw error;
    } else {
      if (data?.length > 0) {
        setNotifications((notifications) =>
          [...uniqBy([...notifications, ...data], (obj) => obj.id)].sort(
            (a, b) => new Date(b.created_at) - new Date(a?.created_at)
          )
        );
        setLoading(false);
        setLoadingMore(false);
        // console.log(`Range (${range.from} - ${range.to})`, data);
      } else {
        setDone(true);
        setLoading(false);
      }
    }
  };

  return (
    <div
      className={`absolute -right-20 w-52 md:w-[25vw] py-2 px-5 mt-2 z-60 bg-white shadow-lg ease-in-out duration-300 dark:bg-dark-bg-700 dark:text-secondary-text pb-6 overflow-y-hidden ${
        show ? "" : "hidden"
      } max-h-[calc(100vh-50vh)] rounded justify-between`}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div className="flex justify-between items-center min-h-fit">
        <h1 className="py-3 font-bold">Notifications</h1>
        <div
          className="flex gap-2 cursor-pointer"
          onClick={async (event) => {
            event.stopPropagation();
            const { data, error } = await supabase
              .from("notifications")
              .update({ seen: true })
              .match({ receiver: user.id });

            if (error) throw error;
            setNotifications((notifications) =>
              [...uniqBy([...data, ...notifications], (obj) => obj.id)].sort(
                (a, b) => new Date(b.created_at) - new Date(a?.created_at)
              )
            );
            setUnseenNotifications(0);
          }}
        >
          <span className="text-xs font-thin">Mark all as read</span>
          <BiCheckDouble size={15} />
        </div>
      </div>
      <hr />
      <ul id="scrollArea" className="overflow-y-scroll h-80 scroll">
        {(notifications && notifications?.length) > 0
          ? notifications.map((notification, index) => {
              if (notifications.length === index + 1) {
                return (
                  <li
                    key={index}
                    className="py-5 px-2 flex items-start hover:bg-accent dark:hover:bg-dark-bg-600 h-16 hover:h-fit overflow-hidden rounded justify-center outline-gray-100 relative my-2 cursor-pointer group"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    ref={lastElementRef}
                    id="lastNotification"
                  >
                    <div className="w-5/6 h-full text-sm">
                      <p className="text-xs h-[70%] overflow-hidden w-[95%] text-ellipsis line-clamp-1 hover:line-clamp-none">
                        {notification?.message}
                      </p>
                      <div className=" h-[30%] flex">
                        <span className="text-xs font-bold">
                          {moment(notification.created_at).fromNow()}
                        </span>
                      </div>
                    </div>
                    {!notification?.seen && (
                      <>
                        <div className="bg-green-500 w-2 h-2 rounded-full dark:bg-green-500"></div>
                        <div
                          className="flex absolute bottom-3 right-4 group-hover:visible invisible"
                          onClick={async (event) => {
                            event.stopPropagation();

                            // Mark this notification as read.
                            const { data, error } = await supabase
                              .from("notifications")
                              .update({ seen: true })
                              .match({ id: notification.id })
                              .single();

                            if (error) throw error;

                            setNotifications((notifications) =>
                              notifications.map((notification, currentIndex) =>
                                index === currentIndex ? data : notification
                              )
                            );
                            setUnseenNotifications(
                              (unseenNotifications) => unseenNotifications - 1
                            );
                          }}
                        >
                          <span className="text-xs mr-2">Mark as read</span>
                          <BiCheckDouble
                            color={notification?.seen ? "green" : "gray"}
                          />
                        </div>
                      </>
                    )}
                  </li>
                );
              }
              return (
                <li
                  key={index}
                  className="py-5 px-2 flex items-start hover:bg-accent dark:hover:bg-dark-bg-600 h-16 hover:h-fit overflow-hidden rounded justify-center outline-gray-100 relative my-2 cursor-pointer notification group"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <div className="w-5/6 h-full text-sm">
                    <p className="text-xs h-[70%] overflow-hidden w-[95%] text-ellipsis line-clamp-1 hover:line-clamp-none">
                      {notification?.message}
                    </p>
                    <div className=" h-[30%] flex">
                      <span className="text-xs font-bold">
                        {getFormattedDateAgo(new Date(notification.created_at))}
                      </span>
                    </div>
                  </div>
                  {!notification?.seen && (
                    <>
                      <div className="bg-green-500 w-2 h-2 rounded-full dark:bg-green-500"></div>
                      <div
                        className="flex absolute bottom-3 right-4 group-hover:visible invisible"
                        onClick={async (event) => {
                          event.stopPropagation();

                          // Mark this notification as read.
                          const { data, error } = await supabase
                            .from("notifications")
                            .update({ seen: true })
                            .match({ id: notification.id })
                            .single();

                          if (error) throw error;
                          setNotifications((notifications) =>
                            notifications.map((notification, currentIndex) =>
                              index === currentIndex ? data : notification
                            )
                          );
                          setUnseenNotifications(
                            (unseenNotifications) => unseenNotifications - 1
                          );
                        }}
                      >
                        <span className="text-xs mr-2">Mark as read</span>
                        <BiCheckDouble
                          color={notification?.seen ? "green" : "gray"}
                        />
                      </div>
                    </>
                  )}
                </li>
              );
            })
          : !loading && <div className="text-xs mt-2">No Notifications</div>}
        {loadingMore && !done && (
          <li className="py-5 px-2 flex items-start hover:bg-accent dark:hover:bg-dark-bg-600 h-16 justify-center my-2 cursor-pointer notification">
            <img
              className="h-7 w-7"
              src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
              alt=""
            />
          </li>
        )}
      </ul>
      <div className="text-end flex justify-end mt-2">
        <button
          className="bg-primary capitalize align-text-middle px-2 py-1 text-white rounded-sm flex items-center text-sm font-light"
          onClick={(event) => {
            event.preventDefault();
            console.log("Let's view notification here");
            navigate("/notifications/")

          }}
          type="button"
        >
          View all
        </button>
      </div>
    </div>
  );
}

export default NotificationContext;
