import { Submit, Spinner } from "../../components";
import { Formik, Form, ErrorMessage } from "formik";
import { supabase } from "../../helpers/supabase";
import { useAuth } from "../../auth/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { nonEvidencedRequestValidationSchema as withdrawRequestValidationSchema } from "../../helpers/validator";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useState } from "react";
import {
  add_separator,
  remove_separator,
} from "../../helpers/thousand_separator";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { sendSMS } from "../../helpers/sendsms";

function WithdrawRequest() {
  const {
    user: { id: applicants_id },
  } = useAuth();


  console.log("Applicants id: ", applicants_id)
  const [, { fullname: applicants_name, position_in_sacco }, , roles] =
    useOutletContext();
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState(false);
  const [allBaana, setAllBaana] = useState([]);
  const [baana, setBaana] = useState([]);
  const navigate = useNavigate();

  const initialValues = {
    account_type: "",
    amount: "",
    comments: "",
    cashout_method: "",
    phone_number: "",
    designated_for: "own",
    member_id: applicants_id,
    baana: baana,
    member_name: "",
    mwana: "",
    mwana_name: "",
  };

  useEffect(() => {
    fetchMemberAccounts();
  }, []);

  const fetchMemberAccounts = async () => {
    const {
      data: { baana: baanaData, ...data },
      error,
    } = await supabase.rpc("fetch_transfer_information");

    if (error) console.log("Error: ", error);
    console.log("Data: ", data);
    if (data) {
      console.log("Members accounts: ", data);
      setLoading(false);
      setProfiles(() => data);
      setAllBaana(() => baanaData ?? []);
      setBaana(() =>
        baanaData && baanaData?.length > 0
          ? baanaData.filter((mwana) => mwana.member_id === applicants_id)
          : []
      );

      console.log("Done here");
    } else {
      setLoading(false);
    }
    console.log("All Baana: ", baanaData);
  };

  console.log("Members baana: ", allBaana);

  return (
    <div className="flex-grow mx-5 my-2 h-[calc(100vh-70px)]">
      <ToastContainer />
      <Helmet>
        <title>Withdraw Request - Bweyogere tuberebumu</title>
      </Helmet>
      <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
        Withdraw Request
      </h1>
      <div
        className={`flex bg-white relative dark:bg-dark-bg-700 dark:text-secondary-text min-h-full`}
      >
        {loading && (
          <div className="absolute z-10 bg-white dark:bg-dark-bg-700 dark:bg-opacity-90 bg-opacity-90 w-full h-full rounded-lg">
            <Spinner />
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={withdrawRequestValidationSchema}
          enableReinitialize={true}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            try {
              const {
                account_type,
                amount,
                comments,
                cashout_method,
                phone_number,
                member_id,
                designated_for,
                mwana_name,
                member_name,
                mwana,
              } = values;

              const application_meta = {
                _type: "withdraw",
                created_at: new Date()
                  .toISOString()
                  .toLocaleString("en-GB", { timeZone: "UTC" }),
                updated_at: new Date()
                  .toISOString()
                  .toLocaleString("en-GB", { timeZone: "UTC" }),
                reviewed: false,
                applicants_id,
                applicants_name,
                account_type,
                amount,
                comments,
                cashout_method,
                phone_number,
                review_status: "pending",
                designated_for,
                member: {
                  fullname: member_name,
                  id: member_id,
                },
                mwana: {
                  fullname: mwana_name,
                  id: mwana,
                },
              };


              console.log("Application meta: ", application_meta)

              const { data, error } = await supabase
                .from("applications")
                .insert({
                  _type: "withdraw",
                  created_at: new Date()
                    .toISOString()
                    .toLocaleString("en-GB", { timeZone: "UTC" }),
                  updated_at: new Date()
                    .toISOString()
                    .toLocaleString("en-GB", { timeZone: "UTC" }),
                  reviewed: false,
                  application_meta,
                })
                .single();

              if (error) {
                setSubmitting(false);
                resetForm({ values: initialValues });
                throw error;
              }

              if (data) {
                const { data: secretariate } = await supabase
                  .from("_member_profiles")
                  .select(
                    `
                    id, fullname
                    `
                  )
                  .in("position_in_sacco", ["secretary", "assistant secretary"])
                  .eq("is_under_termination", false);

                if (secretariate && secretariate?.length > 0) {
                  secretariate.forEach(async (member) => {
                    await sendSMS(
                      member.id,
                      `Hello ${member.fullname}, a sacco member has requested for a withdraw. To forward the request to the chairperson, visit https://tube.ablestate.co/deposit/members Regards, Bweyogerere Tuberebumu Sacco.`
                    )
                      .then((response) => response.json())
                      .then((data) => {
                        console.log(data);
                      })
                      .catch((error) => console.log(error, "Error"));
                  });
                }

                if (values.designated_for === "other") {
                  sendSMS(
                    values.member_id,
                    `Hello ${values.member_name}, a withdraw has been made by the ${position_in_sacco} on your ${values.account_type} account. For more details visit For more details visit https://tube.ablestate.co/deposits Regards, Bweyogerere Tuberebumu Sacco.`
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      console.log(data);
                    })
                    .catch((error) => console.log(error, "Error"));
                }

                console.log("Member", data?.application_meta?.member?.id)

                // Notifying the member
                sendSMS(
                  data?.application_meta?.member?.id,
                  `Hello ${
                    data?.application_meta?.member?.fullname ? data.application_meta.member.fullname.split(" ")[0] : data?.application_meta?.applicants_name
                  }, your withdraw request has been submitted for review.\n 
                  Regards,\n 
                  Bweyogerere Tuberebumu.`
                )
                  .then((response) => response.json())
                  .then((data) => console.log(data))
                  .catch((error) => console.log(error));

                toast.success(`Your withdraw is being processed.`, {
                  position: "top-center",
                });
                document.getElementById("amount").value = "";
                setSubmitting(false);
                resetForm({ values: initialValues });
              }
            } catch (error) {

              console.log("Error: ", error)
              setLoading(false);
              toast.error(`${error?.message}`, { position: "top-center" });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            dirty,
            isValid,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <Form className="flex flex-grow flex-col min-h-full p-6">
                <div className="mb-3 flex flex-wrap gap-5">
                  {roles && roles.includes("treasurer") && (
                    <div className="flex flex-col w-56 pb-3">
                      <label className="text-sm">Designated for</label>
                      <div className="flex justify-between">
                        <div className="flex gap-1">
                          <input
                            type="radio"
                            id="own"
                            name="designated_for"
                            value="own"
                            className="w-4 h-4"
                            onChange={() => {
                              setFieldValue("designated_for", "own");
                              const filteredBaana = allBaana.filter(
                                (mwana) => mwana.member_id === applicants_id
                              );
                              setFieldValue("baana", filteredBaana);
                              setBaana(() => filteredBaana);
                            }}
                          />
                          <label htmlFor="own" className="text-sm">
                            My Own
                          </label>
                        </div>
                        <div className="flex gap-1">
                          <input
                            type="radio"
                            id="other"
                            name="designated_for"
                            value="other"
                            className="w-4 h-4"
                            onChange={handleChange("designated_for")}
                          />
                          <label htmlFor="other" className="text-sm">
                            Other Member
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex flex-col w-56">
                    <label className="text-sm" htmlFor="account_type">
                      Please select an account{" "}
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <select
                      name="account_type"
                      id="account_type"
                      className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 ${
                        errors?.account_type && touched?.account_type
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      value={values?.account_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">--Select Account--</option>
                      <option value="savings">Savings</option>
                      <option value="fixed">Fixed</option>
                      <option value="mwana">Mwana</option>
                      {/* <option value="development">Development</option> */}
                    </select>
                    {touched?.account_type && errors?.account_type && (
                      <div className="error text-red-600 text-xs">
                        {errors?.account_type}
                      </div>
                    )}
                  </div>

                  {values?.designated_for === "other" && (
                    <div className="flex flex-col w-56">
                      <label className="text-sm">
                        Select Member{" "}
                        <span className="text-sm text-red-500 ml-2 font-bold">
                          *
                        </span>
                      </label>
                      <select
                        name="member_id"
                        id="member_id"
                        className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                          errors?.member_id && touched?.member_id
                            ? "ring-red-500"
                            : "ring-black"
                        }`}
                        onChange={async (event) => {
                          const [memberID, memberName] =
                            event.target.value.split("|");

                          setFieldValue(event.target.name, memberID);
                          values.member_name = memberName;
                          const filteredBaana = allBaana.filter(
                            (mwana) => mwana.member_id === memberID
                          );

                          console.log(
                            "Filtered Baana Withdraw: ",
                            filteredBaana
                          );
                          setFieldValue("baana", filteredBaana);
                        }}
                        onBlur={handleBlur}
                      >
                        <option value="">--Select Member--</option>
                        {values.account_type === "savings"
                          ? profiles &&
                            profiles?.savings_accounts &&
                            profiles.savings_accounts.map(
                              ({ fullname, id }, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={`${id}|${fullname}`}
                                    className="capitalize"
                                  >
                                    {fullname}
                                  </option>
                                );
                              }
                            )
                          : values.account_type === "development"
                          ? profiles &&
                            profiles?.development_accounts &&
                            profiles.development_accounts.map(
                              ({ fullname, id }, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={`${id}|${fullname}`}
                                    className="capitalize"
                                  >
                                    {fullname}
                                  </option>
                                );
                              }
                            )
                          : values?.account_type === "shares"
                          ? profiles &&
                            profiles?.shares_accounts &&
                            profiles.shares_accounts.map(
                              ({ fullname, id }, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={`${id}|${fullname}`}
                                    className="capitalize"
                                  >
                                    {fullname}
                                  </option>
                                );
                              }
                            )
                          : values?.account_type === "mwana"
                          ? profiles &&
                            profiles?.mwana_accounts &&
                            profiles.mwana_accounts.map(
                              ({ fullname, id }, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={`${id}|${fullname}`}
                                    className="capitalize"
                                  >
                                    {fullname}
                                  </option>
                                );
                              }
                            )
                          : values?.account_type === "fixed" &&
                            profiles &&
                            profiles?.fixed_deposit_accounts &&
                            profiles.fixed_deposit_accounts.map(
                              ({ fullname, id }, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={`${id}|${fullname}`}
                                    className="capitalize"
                                  >
                                    {fullname}
                                  </option>
                                );
                              }
                            )}
                      </select>
                      <ErrorMessage name="member_id">
                        {(msg) => (
                          <div className="error text-red-600 text-xs">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                      {values?.account_type === "mwana" &&
                        values?.designated_for === "other" &&
                        values?.member_id &&
                        baana.length === 0 &&
                        touched.member_id && (
                          <div className="error text-red-600 text-xs">
                            The member has not yet added baana
                          </div>
                        )}
                    </div>
                  )}
                  {values.baana && console.log("My Baana: ", baana)}

                  {values.account_type === "mwana" &&
                    (values.baana && values.baana?.length > 0 ? (
                      <div className="flex flex-col w-56">
                        <label className="text-sm">
                          Select Mwana{" "}
                          <span className="text-sm text-red-500 ml-2 font-bold">
                            *
                          </span>
                        </label>
                        <select
                          name="mwana"
                          id="mwana"
                          className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                            errors?.mwana && touched?.mwana
                              ? "ring-red-500"
                              : "ring-black"
                          }`}
                          onChange={(event) => {
                            const [mwanaID, mwanaName] =
                              event.target.value.split("|");
                            setFieldValue("mwana", mwanaID);
                            setFieldValue("mwana_name", mwanaName);
                          }}
                          onBlur={handleBlur}
                          value={values.mwana + "|" + values.mwana_name}
                        >
                          <option value="">--Select Mwana--</option>
                          {values.baana.map((mwana, index) => {
                            return (
                              <option
                                value={`${mwana.id}|${mwana.mwana_meta.name}`}
                                key={index}
                              >
                                {mwana.mwana_meta.name}
                              </option>
                            );
                          })}
                        </select>
                        <ErrorMessage name="account_type">
                          {(msg) => (
                            <div className="error text-red-500 text-xs">
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    ) : (
                      values.designated_for === "own" && (
                        <button
                          className="bg-primary inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2 cursor-pointer self-center mt-2"
                          type="button"
                          onClick={(event) => {
                            event.preventDefault();
                            navigate("/baana/add-baana", { replace: true });
                          }}
                        >
                          {" "}
                          Add mwana{" "}
                        </button>
                      )
                    ))}

                  {/* Cashout method */}
                </div>
                <div className="mb-3 flex flex-wrap gap-5">
                  <div className="flex flex-col w-56">
                    <label className="text-sm">
                      Cashout Method{" "}
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <select
                      name="cashout_method"
                      id="cashout_method"
                      className={`ring-1 rounded px-2 py-1 bg-white dark:bg-dark-bg-600 dark:text-secondary-text ${
                        touched?.cashout_method && errors?.cashout_method
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cashout_method}
                    >
                      <option value="">--Select Method--</option>
                      <option value="Cash">Cash</option>
                      <option value="Mobile Money">Mobile Money</option>
                      <option value="Airtel Money">Airtel Money</option>
                      <option value="Cheque">Cheque</option>
                      <option value="EFT">EFT</option>
                      <option value="RTGS">RTGS</option>
                    </select>
                    {touched?.cashout_method && errors?.cashout_method && (
                      <div className="error text-red-600 text-xs">
                        {errors?.cashout_method}
                      </div>
                    )}
                  </div>
                  {/* Phone number */}
                  <div className="flex flex-col w-56">
                    <label className="text-sm">
                      Enter Phone Number{" "}
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      placeholder="Enter phone number"
                      className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                        errors?.phone_number && touched?.phone_number
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone_number}
                    />
                    <div className="relative mt-1">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs text-gray-400 pl-3">
                        Use local format
                      </div>
                    </div>
                    {touched?.phone_number && errors?.phone_number && (
                      <div className="error text-red-600 text-xs">
                        {errors?.phone_number}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col w-56 mb-3">
                    <label htmlFor="amount" className=" text-sm">
                      Enter Amount{" "}
                      <span className="text-sm text-red-500 ml-2 font-bold">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      placeholder="Enter amount to withdraw"
                      className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                        errors?.amount && touched?.amount
                          ? "ring-red-500"
                          : "ring-black"
                      }`}
                      onChange={(event) => {
                        let formatted_string = add_separator(
                          remove_separator(event.target.value)
                        );
                        event.target.value = formatted_string;
                        setFieldValue(
                          event.target.name,
                          parseFloat(remove_separator(event.target.value))
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    <div className="relative mt-1">
                      <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                        {" "}
                      </div>
                      <div className="text-xs text-gray-400 pl-3">
                        Use figures
                      </div>
                    </div>
                    {touched?.amount && errors?.amount && (
                      <div className="error text-red-600 text-xs">
                        {errors?.amount}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <h1 className="font-semibold">comments</h1>
                  <textarea
                    name="comments"
                    id="comments"
                    cols="30"
                    rows="10"
                    className="outline outline-1 p-2 rounded-md w-full dark:bg-dark-bg-600"
                    value={values?.comments}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder=""
                  ></textarea>
                  {touched?.comments && errors?.comments && (
                    <div className="error text-red-600 text-xs">
                      {errors?.comments}
                    </div>
                  )}
                </div>
                <div className="w-56">
                  <Submit
                    value="Submit"
                    disabled={!(dirty && isValid) || isSubmitting}
                  />
                </div>

                {/* <button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();

                    console.log("values", values);
                    console.log("errors", errors);
                  }}
                >
                  Debug
                </button> */}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default WithdrawRequest;
