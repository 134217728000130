import React, { Fragment } from "react";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import { useOutletContext } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { addBaanaValidationSchema } from "../../helpers/validator";
import { toast, ToastContainer } from "react-toastify";
import { uploadFile } from "../../helpers/uploadFile";
import {
  add_separator,
  remove_separator,
} from "../../helpers/thousand_separator";

function AddBaana() {
  const [, profile] = useOutletContext();
  console.log("User: ", profile);

  return (
    <div>
      <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
        Add Mwana
      </h1>
      <div className="flex bg-white overflow-hidden relative dark:bg-dark-bg-700 dark:text-secondary-text p-6 min-h-full w-full justify-start ml-2">
        <Formik
          initialValues={{
            sacco_charge: "",
            children: [
              {
                name: "",
                dob: "",
                gender: "",
                education: "",
                social_security_number: "",
                health_issues: "",
              },
            ],
          }}
          onSubmit={async (
            { children, sacco_charge, evidence },
            { resetForm }
          ) => {
            const toastId = toast.loading("Processing ");
            let file_url = null;

            if (evidence) {
              const { Key: url } = await uploadFile(evidence, "deposits");
              file_url = url;
            }

            try {
              const { data, error } = await supabase
                .from("applications")
                .insert({
                  _type: "baana",
                  created_at: new Date()
                    .toISOString()
                    .toLocaleString("en-GB", { timeZone: "UTC" }),
                  updated_at: new Date()
                    .toISOString()
                    .toLocaleString("en-GB", { timeZone: "UTC" }),
                  reviewed: false,
                  application_meta: {
                    applicants_id: profile.id,
                    applicants_name: profile.fullname,
                    review_status: "pending",
                    children,
                    type: "add",
                    sacco_charge,
                    evidence: file_url,
                  },
                });

              if (error) throw error;
              if (data) {
                toast.update(toastId, {
                  render: `Submitted request for review`,
                  type: "success",
                  isLoading: false,
                  autoClose: 5000,
                });
              }
            } catch (error) {
              console.log("Error: ", error);
              toast.update(toastId, {
                render: `${
                  error?.message ?? "Submission failed. Try again later."
                }`,
                type: "error",
                isLoading: false,
                autoClose: 5000,
              });
            }
          }}
          validationSchema={addBaanaValidationSchema}
        >
          {({ handleBlur, values, errors, touched, setFieldValue }) => {
            return (
              <div className=" md:h-[calc(100vh-20vh)]">
                <ToastContainer position="top-center" />
                <div className="bg-white relative  h-full dark:bg-dark-bg-700 dark:text-secondary-text p-6">
                  <Form className="overflow-y-scroll h-full">
                    <FieldArray
                      name="children"
                      render={(fieldArrayProp) => {
                        return (
                          <div>
                            {values?.children.map(
                              (
                                {
                                  name,
                                  dob,
                                  gender,
                                  education,
                                  health_issues,
                                  social_security_number,
                                },
                                index
                              ) => (
                                <Fragment key={index}>
                                  <div
                                    className="m-2 flex flex-col"
                                    key={index}
                                  >
                                    <div className="flex justify-start items-center">
                                      <h1 className="font-bold">
                                        Mwana's Details
                                        {/* {index + 1} */}
                                      </h1>
                                    </div>
                                    <div className="flex gap-5 flex-wrap">
                                      <div className="flex flex-wrap gap-5">
                                        <div className="flex flex-col w-56 mb-3">
                                          <label
                                            htmlFor={`child${index}`}
                                            className=" text-sm"
                                          >
                                            Name
                                            <span className="text-sm text-red-500 ml-2 font-bold">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            name={`children[${index}].name`}
                                            id={`children-${index}-name`}
                                            placeholder="Enter Name"
                                            className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                              errors?.children &&
                                              errors.children.length > 0 &&
                                              errors.children[index]?.name &&
                                              touched?.children &&
                                              touched?.children.length > 0 &&
                                              touched.children[index]?.name
                                                ? "ring-red-500"
                                                : "ring-black"
                                            }`}
                                            onChange={async (event) => {
                                              setFieldValue(
                                                event.target.name,
                                                event.target.value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            defaultValue={name}
                                          />

                                          <ErrorMessage
                                            name={`children[${index}].name`}
                                          >
                                            {(msg) => (
                                              <div className="error text-red-600 text-xs">
                                                {msg}
                                              </div>
                                            )}
                                          </ErrorMessage>
                                        </div>
                                      </div>
                                      <div className="flex flex-wrap gap-5">
                                        <div className="flex flex-col w-56 mb-3">
                                          <label
                                            htmlFor={`child${index}`}
                                            className=" text-sm"
                                          >
                                            Birth Date
                                            <span className="text-sm text-red-500 ml-2 font-bold">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="date"
                                            name={`children[${index}].dob`}
                                            id={`children-${index}-dob`}
                                            className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                              errors?.children &&
                                              errors.children.length > 0 &&
                                              errors.children[index]?.dob &&
                                              touched?.children &&
                                              touched?.children.length > 0 &&
                                              touched.children[index]?.dob
                                                ? "ring-red-500"
                                                : "ring-black"
                                            }`}
                                            onChange={async (event) => {
                                              setFieldValue(
                                                event.target.name,
                                                event.target.value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            defaultValue={dob}
                                          />

                                          <ErrorMessage
                                            name={`children[${index}].dob`}
                                          >
                                            {(msg) => (
                                              <div className="error text-red-600 text-xs">
                                                {msg}
                                              </div>
                                            )}
                                          </ErrorMessage>
                                        </div>
                                      </div>
                                      <div className="flex flex-wrap gap-5">
                                        <div className="flex flex-col w-56 mb-3">
                                          <label
                                            htmlFor={`child${index}`}
                                            className=" text-sm"
                                          >
                                            Gender
                                            <span className="text-sm text-red-500 ml-2 font-bold">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            type="text"
                                            name={`children[${index}].gender`}
                                            id={`children-${index}-gender`}
                                            className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                              errors?.children &&
                                              errors.children.length > 0 &&
                                              errors.children[index]?.gender &&
                                              touched?.children &&
                                              touched?.children.length > 0 &&
                                              touched.children[index]?.gender
                                                ? "ring-red-500"
                                                : "ring-black"
                                            }`}
                                            onChange={async (event) => {
                                              setFieldValue(
                                                event.target.name,
                                                event.target.value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            defaultValue={gender}
                                          >
                                            <option value="">
                                              --Select Gender--
                                            </option>
                                            <option value="male">Male</option>
                                            <option value="female">
                                              Female
                                            </option>
                                          </select>

                                          <ErrorMessage
                                            name={`children[${index}].gender`}
                                          >
                                            {(msg) => (
                                              <div className="error text-red-600 text-xs">
                                                {msg}
                                              </div>
                                            )}
                                          </ErrorMessage>
                                        </div>
                                      </div>
                                      <div className="flex flex-wrap gap-5">
                                        <div className="flex flex-col w-56 mb-3">
                                          <label
                                            htmlFor={`child${index}`}
                                            className=" text-sm"
                                          >
                                            Education Level
                                            <span className="text-sm text-red-500 ml-2 font-bold">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            type="text"
                                            name={`children[${index}].education`}
                                            id={`children-${index}-education`}
                                            className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                              errors?.children &&
                                              errors.children.length > 0 &&
                                              errors.children[index]
                                                ?.education &&
                                              touched?.children &&
                                              touched?.children.length > 0 &&
                                              touched.children[index]?.education
                                                ? "ring-red-500"
                                                : "ring-black"
                                            }`}
                                            onChange={async (event) => {
                                              setFieldValue(
                                                event.target.name,
                                                event.target.value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            defaultValue={education}
                                          >
                                            <option value="">
                                              --Select Level--
                                            </option>
                                            <option value="No formal education">
                                              No formal education
                                            </option>
                                            <option value="Primary education">
                                              Primary
                                            </option>
                                            <option value="Secondary education">
                                              Secondary
                                            </option>
                                            <option value="Vocational education">
                                              Vocational{" "}
                                            </option>
                                            <option value="Associate's degree">
                                              Associates
                                            </option>
                                            <option value="Bachelor's degree">
                                              Bachelors
                                            </option>
                                            <option value="Master's degree">
                                              Masters
                                            </option>
                                            <option value="Doctorate degree">
                                              Doctorate
                                            </option>
                                          </select>
                                          <ErrorMessage
                                            name={`children[${index}].education`}
                                          >
                                            {(msg) => (
                                              <div className="error text-red-600 text-xs">
                                                {msg}
                                              </div>
                                            )}
                                          </ErrorMessage>
                                        </div>
                                        <div className="flex flex-wrap gap-5">
                                          <div className="flex flex-col w-56 mb-3">
                                            <label
                                              htmlFor={`child${index}`}
                                              className=" text-sm"
                                            >
                                              NIN
                                              {/* <span className="text-sm text-red-500 ml-2 font-bold">
                                            *
                                          </span> */}
                                            </label>
                                            <input
                                              type="text"
                                              name={`children[${index}].social_security_number`}
                                              id={`children-${index}-social_security_number`}
                                              placeholder="Enter Social Security No."
                                              className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                                errors?.children &&
                                                errors.children.length > 0 &&
                                                errors.children[index]
                                                  ?.social_security_number &&
                                                touched?.children &&
                                                touched?.children.length > 0 &&
                                                touched.children[index]
                                                  ?.social_security_number
                                                  ? "ring-red-500"
                                                  : "ring-black"
                                              }`}
                                              onChange={async (event) => {
                                                setFieldValue(
                                                  event.target
                                                    .social_security_number,
                                                  event.target.value
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              defaultValue={
                                                social_security_number
                                              }
                                            />

                                            <ErrorMessage
                                              name={`children[${index}].social_security_number`}
                                            >
                                              {(msg) => (
                                                <div className="error text-red-600 text-xs">
                                                  {msg}
                                                </div>
                                              )}
                                            </ErrorMessage>
                                          </div>
                                          <div className="flex flex-wrap gap-5">
                                            <div className="flex flex-col w-56 mb-3">
                                              <label
                                                htmlFor={`child${index}`}
                                                className=" text-sm"
                                              >
                                                Health issues
                                                {/* <span className="text-sm text-red-500 ml-2 font-bold">
                                            *
                                          </span> */}
                                              </label>
                                              <input
                                                type="text"
                                                name={`children[${index}].health_issues`}
                                                id={`children-${index}-health_issues`}
                                                placeholder="Enter Health Issues"
                                                className={`ring-1 ring-black rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                                  errors?.children &&
                                                  errors.children.length > 0 &&
                                                  errors.children[index]
                                                    ?.health_issues &&
                                                  touched?.children &&
                                                  touched?.children.length >
                                                    0 &&
                                                  touched.children[index]
                                                    ?.health_issues
                                                    ? "ring-red-500"
                                                    : "ring-black"
                                                }`}
                                                onChange={async (event) => {
                                                  setFieldValue(
                                                    event.target.health_issues,
                                                    event.target.value
                                                  );
                                                }}
                                                onBlur={handleBlur}
                                                defaultValue={health_issues}
                                              />

                                              <ErrorMessage
                                                name={`children[${index}].heatlh_issues`}
                                              >
                                                {(msg) => (
                                                  <div className="error text-red-600 text-xs">
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex flex-col w-56 ">
                                          <label className=" text-sm">
                                            Sacco Charge{" "}
                                            <span className="text-sm text-red-500 ml-2 font-bold">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            name="sacco_charge"
                                            id="sacco_charge"
                                            placeholder="Enter Sacco Charge"
                                            className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                              errors?.sacco_charge &&
                                              touched?.sacco_charge
                                                ? "ring-red-500"
                                                : "ring-black"
                                            }`}
                                            onChange={(event) => {
                                              let formatted_string =
                                                add_separator(
                                                  remove_separator(
                                                    event.target.value
                                                  )
                                                );
                                              event.target.value =
                                                formatted_string;
                                              setFieldValue(
                                                event.target.name,
                                                parseFloat(
                                                  remove_separator(
                                                    formatted_string
                                                  )
                                                )
                                              );
                                            }}
                                            onBlur={handleBlur}
                                          />
                                          <div className="relative mt-1">
                                            <div className="bg-gray-400 rounded-full w-1 h-1 mx-1 absolute top-1">
                                              {" "}
                                            </div>
                                            <div className="text-xs text-gray-400 pl-3">
                                              Use figures
                                            </div>
                                          </div>
                                          <ErrorMessage name="sacco_charge">
                                            {(msg) => (
                                              <div className="error text-red-500 text-xs">
                                                {msg}
                                              </div>
                                            )}
                                          </ErrorMessage>
                                        </div>
                                        <div className="mb-3 flex flex-col md:flex-row gap-5">
                                          <div className="flex flex-col w-56 ">
                                            <label className=" text-sm">
                                              Upload Receipt {" "}
                                              <span className="text-sm text-red-500 ml-2 font-bold">
                                              *
                                            </span>
                                            </label>
                                            <input
                                              type="file"
                                              name="evidence"
                                              id="evidence"
                                              placeholder="Enter postal address"
                                              className={`ring-1 rounded px-2 py-1 dark:bg-dark-bg-600 ${
                                                errors?.evidence &&
                                                touched?.evidence
                                                  ? "ring-red-500"
                                                  : "ring-black"
                                              }`}
                                              onChange={(event) => {
                                                values.evidence =
                                                  event.currentTarget.files[0];
                                              }}
                                              onBlur={handleBlur}
                                              accept="image/png, image/jpeg, application/pdf, .doc, .docx"
                                            />
                                            {touched?.evidence &&
                                              errors?.evidence && (
                                                <div className="error text-red-600 text-xs">
                                                  {errors?.evidence}
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <hr />
                                </Fragment>
                              )
                            )}

                            {/* <button
                              type="button"
                              className="bg-primary text-white px-3 py-2 rounded m-2"
                              onClick={() => {
                                if (values?.children?.length > 0)
                                  fieldArrayProp.push({
                                    name: "",
                                    dob: "",
                                    gender: "",
                                    nationality: "",
                                    social_security_number: "",
                                    health_issues: "",
                                  });

                                console.log("Values: ", values);
                                console.log("Errors: ", errors);
                              }}
                            >
                              +
                            </button>
                            <button
                              type="button"
                              className="bg-accent-red text-white px-3 py-2 rounded m-2"
                              onClick={async () => {
                                if (values?.children?.length > 1) {
                                  await fieldArrayProp.pop();
                                }
                              }}
                            >
                              -
                            </button> */}
                          </div>
                        );
                      }}
                    ></FieldArray>
                    <div className="flex justify-end mt-2">
                      <input
                        type="submit"
                        value="Submit"
                        className="outline bg-primary text-white px-4 py-2 cursor-pointer mb-5"
                      />
                    </div>
                  </Form>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddBaana;
