import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { supabase } from "../../helpers/supabase";
import { toast, ToastContainer } from "react-toastify";
import { Spinner } from "../../components";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { useOutletContext } from "react-router-dom";
import { sendSMS } from "../../helpers/sendsms";

export default function WithdrawVerify() {
  const { id } = useParams();
  const [withdraw, setWithdraw] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, profile, , roles] = useOutletContext();
  const [showActions, setShowActions] = useState(true);
  const [role, setRole] = useState("");

  useEffect(() => {
    getApplication().catch((error) => console.log(error));
  }, []);

  const getApplication = async () => {
    const { error, data } = await supabase
      .from("applications")
      .select()
      .eq("_type", "withdraw")
      .eq("application_id", id)
      .single();
    if (error) throw error;
    const [my_role] =
      roles && roles.filter((role) => role !== "member" && role !== "admin");

    setRole(my_role);

    if (
      ["approved", "rejected"].includes(data?.application_meta?.review_status)
    )
      setShowActions(() => false);
    setWithdraw(data);
  };

  const approveWithdrawTransaction = async () => {
    setLoading(true);

    try {
      const { error, data } = await supabase.rpc(
        "approve_withdraw_transaction",
        {
          application: id,
          admin: profile.id,
        }
      );

      setLoading(false);

      console.log("Error: ", error);
      console.log("Data: ", data);

      if (error) {
        setLoading(false);
        toast.error(`${error?.message}`, { position: "top-center" });
      } else if (data) {
        setWithdraw((withdraw) => ({
          ...withdraw,
          application_meta: data?.application_meta,
        }));

        sendSMS(
          data.application_meta.applicants_id,
          `Hello ${data.application_meta.member.member_name}, a deposit has been approved on your ${data.application_meta.account_type} account. For more details visit For more details visit https://tube.ablestate.co/withdraws Regards, Bweyogerere Tuberebumu Sacco.`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error, "Error"));


        setLoading(false);
        toast.success(`Withdraw has been approved.`, {
          position: "top-center",
        });

        setShowActions(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Try again later`, { position: "top-center" });
    }
  };

  const rejectWithdrawTransaction = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.rpc(
        "reject_withdraw_transaction",
        {
          application: id,
          admin: profile.id,
        }
      );
      if (error) {
        setLoading(false);
        toast.error(`${error?.message}`, { position: "top-center" });
      } else {
        setLoading(false);
        setWithdraw((withdraw) => ({
          ...withdraw,
          application_meta: data?.application_meta,
        }));

        sendSMS(
          data.application_meta.applicants_id,
          `Hello ${data.application_meta.member.member_name}, a deposit has been rejected on your ${data.application_meta.account_type} account. For more details visit For more details visit https://tube.ablestate.co/withdraws Regards, Bweyogerere Tuberebumu Sacco.`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error, "Error"));

        setShowActions(false);
        toast.success(`Withdraw has been rejected.`, {
          position: "top-center",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Try again later`, { position: "top-center" });
    }
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <div className="flex flex-col justify-between pb-3 h-[60px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Verify Withdraw
        </h1>
      </div>
      <ToastContainer />
      <div
        className={`bg-white ${
          !loading && "p-3"
        }  overflow-y-auto  relative  h-[calc(100%-80px)] dark:bg-dark-bg-700`}
      >
        {loading && (
          <div className="absolute z-10 bg-white dark:bg-dark-bg-700 dark:bg-opacity-90 bg-opacity-90 w-full h-full rounded-lg">
            <Spinner />
          </div>
        )}
        {withdraw ? (
          <div className="flex flex-grow flex-col min-h-full">
            <div className="mb-3">
              <h1 className="font-semibold mb-2">
                {withdraw.application_meta &&
                  withdraw.application_meta.applicants_name}
                's withdraw Request Details
                <span
                  className={` py-1 px-2 rounded-lg text-white text-xs ml-1 inline-block capitalize ${
                    withdraw?.transaction_meta
                      ? "bg-green-400"
                      : withdraw?.application_meta?.review_status === "rejected"
                      ? "bg-red-400"
                      : withdraw?.application_meta?.review_status === "approved"
                      ? "bg-green-400"
                      : withdraw?.application_meta?.review_status ===
                        "forwarded"
                      ? "bg-blue-400"
                      : "bg-yellow-400"
                  }`}
                >
                  {withdraw?.application_meta?.review_status}
                </span>
              </h1>

              <div className="outline outline-1 outline-gray-100 p-3">
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Application ID:</p>
                  <p className="font-bold col-span-3">{withdraw.app_id}</p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Applicant's name:</p>
                  <p className="font-bold col-span-3">
                    {withdraw.application_meta.applicants_name}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Designated for :</p>
                  <p className="font-bold col-span-3">
                    {withdraw?.application_meta?.designated_for === "other"
                      ? "Other member"
                      : "Oneself"}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Account:</p>
                  <p className="font-bold col-span-3">
                    {withdraw.application_meta.account_type}
                  </p>
                </div>
                {withdraw?.application_meta?.account_type === "mwana" && (
                  <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                    <p className="col-span-2">Mwana:</p>
                    <p className="font-bold col-span-3">
                      {withdraw.application_meta.mwana.fullname}
                    </p>
                  </div>
                )}
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Amount:</p>
                  <p className="font-bold col-span-3">
                    UGX {currencyFormatter(withdraw.application_meta.amount)}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Cashout method:</p>
                  <p className="font-bold col-span-3">
                    {withdraw?.application_meta?.cashout_method}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Comments:</p>
                  <p className="font-bold col-span-3">
                    {withdraw.application_meta.comments || "Unspecified"}
                  </p>
                </div>
                {/* <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Forward status:</p>
                  <p className="font-bold col-span-3 capitalize">
                    {withdraw?.application_meta?.admin_1?.review_status ??
                      "Awaiting"}
                  </p>
                </div> */}

                {withdraw && withdraw.reviewed && (
                  <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                    <p className="col-span-2">Reviewed on:</p>
                    <p className="font-bold col-span-3">
                      {moment(withdraw.application_meta.reviewed_at).format(
                        "MMMM Do YYYY, h a"
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* {withdraw?.application_meta?.designated_for === "other" && (
              <>
                <div className="outline outline-1 outline-gray-100 p-3 mb-3">
                  <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                    <p className="col-span-2">Member's name:</p>
                    <p className="font-bold col-span-3">
                      {withdraw?.application_meta?.member?.fullname}
                    </p>
                  </div>
                  <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                    <p className="col-span-2">Phone number:</p>
                    <p className="font-bold col-span-3">
                      {withdraw?.application_meta?.member?.phone_number}
                    </p>
                  </div>
                </div>
                {(withdraw?.application_meta?.admin_1?.admin_name ||
                  withdraw?.application_meta?.admin_2?.admin_name) && (
                  <div className="outline outline-1 outline-gray-100 p-3">
                    <h1 className="mb-5 mt-2 uppercase dark:text-white font-bold">
                      Review details
                    </h1>
                    {
                      // withdraw?.application_meta?.admin_1?.admin_name &&
                      <>
                        <h1 className="mb-2 mt-2 font-bold capitalize dark:text-white">
                          Secretariate
                        </h1>
                        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                          <p className="col-span-2">Reviewed by:</p>
                          <p className="font-bold col-span-3">
                            {withdraw?.application_meta?.admin_1?.admin_name}
                          </p>
                        </div>
                        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                          <p className="col-span-2">Role:</p>
                          <p className="font-bold col-span-3 capitalize">
                            {
                              withdraw?.application_meta?.admin_1
                                ?.position_in_sacco
                            }
                          </p>
                        </div>
                        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                          <p className="col-span-2">Status:</p>
                          <p className="font-bold col-span-3 capitalize">
                            {withdraw?.application_meta?.admin_1?.review_status}
                          </p>
                        </div>
                        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full border-b pb-3">
                          <p className="col-span-2">Reviewed at:</p>
                          <p className="font-bold col-span-3">
                            {withdraw?.application_meta?.admin_1?.reviewed_at &&
                              moment(
                                withdraw.application_meta?.admin_1?.reviewed_at
                              ).format("DD-MM-YYYY  hh:mm a")}
                          </p>
                        </div>
                      </>
                    }
                    {
                      // withdraw?.application_meta?.admin_2?.admin_name &&
                      <>
                        <h1 className="mb-2 mt-2 font-bold capitalize dark:text-white">
                          Chair
                        </h1>
                        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                          <p className="col-span-2">Reviewed by:</p>
                          <p className="font-bold col-span-3">
                            {withdraw?.application_meta?.admin_2?.admin_name}
                          </p>
                        </div>
                        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                          <p className="col-span-2">Role:</p>
                          <p className="font-bold col-span-3">
                            {
                              withdraw?.application_meta?.admin_2
                                ?.position_in_sacco
                            }
                          </p>
                        </div>
                        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                          <p className="col-span-2">Status:</p>
                          <p className="font-bold col-span-3">
                            {withdraw?.application_meta?.admin_2?.review_status}
                          </p>
                        </div>
                        <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                          <p className="col-span-2">Reviewed at:</p>
                          <p className="font-bold col-span-3">
                            {withdraw?.application_meta?.admin_2?.reviewed_at &&
                              moment(
                                withdraw?.application_meta?.admin_2?.reviewed_at
                              ).format("DD-MM-YYYY  hh:mm a")}
                          </p>
                        </div>
                      </>
                    }
                  </div>
                )}
              </>
            )} */}
            <div className="flex gap-10 justify-end items-center mt-3">
              {showActions && (
                <div className={`flex gap-10 justify-end items-center mt-3`}>
                  {["secretary", "ass_secretary"].includes(role) &&
                    withdraw?.application_meta?.admin_1?.review_status !==
                      "forwarded" && (
                      <>
                        <button
                          className="bg-blue-500 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                          onClick={async () => {
                            const newApplicationMeta = {
                              ...withdraw.application_meta,
                              ...{
                                admin_1: {
                                  admin_id: `${profile.id}`,
                                  admin_name: `${profile.fullname}`,
                                  position_in_sacco: `${profile.position_in_sacco}`,
                                  review_status: "forwarded",
                                  reviewed: true,
                                  reviewed_at: `${new Date().toISOString()}`,
                                  seen: true,
                                },
                              },
                              review_status: "forwarded",
                            };

                            const { data, error } = await supabase
                              .from("applications")
                              .update({ application_meta: newApplicationMeta })
                              .eq("application_id", id)
                              .single();

                            if (error) {
                              console.log("Error: ", error);
                            } else {
                              setWithdraw((withdraw) => ({
                                ...withdraw,
                                ...data,
                              }));
                              const { data: notification_data, error } =
                                await supabase.from("notifications").insert({
                                  created_at: new Date()
                                    .toISOString()
                                    .toLocaleString("en-GB", {
                                      timeZone: "UTC",
                                    }),
                                  updated_at: new Date()
                                    .toISOString()
                                    .toLocaleString("en-GB", {
                                      timeZone: "UTC",
                                    }),
                                  message: `Hey ${withdraw.application_meta?.applicants_name}, your withdraw application has been forwarded to the applicants chairperson for approval.`,
                                  receiver:
                                    withdraw.application_meta.applicants_id,
                                  seen: false,
                                  meta: {
                                    type: "withdraw",
                                  },
                                });
                              if (error) {
                                console.log("Error: ", error);
                              } else {
                                console.log(
                                  "Notification: ",
                                  notification_data
                                );
                                toast.success(
                                  `Withdraw request forwarded successfully`,
                                  { position: "top-center" }
                                );
                              }
                            }
                          }}
                        >
                          Forward
                        </button>
                      </>
                    )}

                  {["chairperson", "vice_chairperson"].includes(role) && (
                    <>
                      <button
                        className="bg-accent-red inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                        onClick={() => rejectWithdrawTransaction()}
                      >
                        Reject
                      </button>
                      <button
                        className="bg-green-600 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                        onClick={() => approveWithdrawTransaction()}
                      >
                        Approve
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
