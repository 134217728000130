import React from "react";
import moment from "moment/moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "../hooks";

function DateRangeFilter({ setStartDate, setEndDate }) {
  const matches = useMediaQuery("(min-width: 800px)");

  console.log(matches);
  return (
    <div className="flex text-sm dark:bg-dark-bg-600">
      {/* {matches && (
        <span className="rounded-l flex items-center px-2 py-2 border bg-slate-400 text-white font-bold justify-center text-sm">
          From
        </span>
      )} */}
      <input
        type="date"
        name="startDate"
        onChange={(event) => {
          setStartDate(event.target.value);
          console.log("Start Date: ", event.target.value);
          console.log(
            "Start Date: ",
            moment(event.target.value).add(1, "days").format("YYYY-MM-DD")
          );

          document
            .getElementById("endDate")
            .setAttribute(
              "min",
              moment(event.target.value).add(1, "days").format("YYYY-MM-DD")
            );
        }}
        className="rounded-l pl-3 pr-1 dark:bg-dark-bg-600 text-sm dark:text-secondary-text focus:ring-0 focus:border-none focus:outline-none"
        id="startDate"
      />
      <span className="flex items-center border-white bg-white dark:bg-dark-bg-600 text-black dark:text-gray-400 font-bold justify-center text-sm">
        -
      </span>
      <input
        type="date"
        name="endDate"
        onChange={(event) => {
          setEndDate(event.target.value);
          console.log("End Date: ", event.target.value);
          document
            .getElementById("startDate")
            .setAttribute("max", event.target.value);
        }}
        className="inputDate dark:bg-dark-bg-600 text-sm dark:text-secondary-text focus:ring-0 focus:border-none focus:outline-none"
        id="endDate"
      />
      <button
        className="bg-white dark:bg-dark-bg-600 dark:text-gray-400 align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r text-sm"
        onClick={() => {
          document.getElementById("startDate").value = "";
          document.getElementById("startDate").setAttribute("max", "");
          setStartDate("");
          document.getElementById("endDate").value = "";
          document.getElementById("endDate").setAttribute("min", "");
          setEndDate("");
        }}
      >
        <AiOutlineCloseCircle size={20} />
      </button>
    </div>
  );
}

export default DateRangeFilter;
