import { RiDeleteBin6Line } from "react-icons/ri";
import { MdNoAccounts, MdOutlineAdminPanelSettings } from "react-icons/md";
import { HiLockOpen } from "react-icons/hi";

export default function ContextMenu({
  index,
  activeIndex,
  show,
  setMemberModal,
  setDeleteModal,
  setSuspendModal,
  // profile,
  setPromoteModal,
  setUnsuspendModal,
  memberDetails,
  setDemoteModal,
}) {
  return (
    <>
      <ul
        className={`absolute right-0 w-48 py-2 mt-2 z-50 bg-white shadow-lg ease-in-out duration-300 dark:bg-dark-bg-700 ${
          index === activeIndex && show ? "" : "hidden"
        }`}
      >
        {
          // profile.roles.includes("super_admin") &&
          (memberDetails?.roles && memberDetails?.roles.length > 0 && !memberDetails?.roles?.includes("admin") ? (
            <li
              className="flex gap-1 justify-start items-center px-4 py-2 cursor-pointer mb-2 hover:bg-accent dark:hover:bg-dark-bg-600"
              onClick={() => {
                setPromoteModal(true);
                setMemberModal(false);
              }}
            >
              <MdOutlineAdminPanelSettings /> Promote
            </li>
          ) : (
            <li
              className="flex gap-1 justify-start items-center px-4 py-2 cursor-pointer mb-2 hover:bg-accent dark:hover:bg-dark-bg-600"
              onClick={() => setDemoteModal(true)}
            >
              <RiDeleteBin6Line /> Demote
            </li>
          ))}
        {memberDetails?.suspended ? (
          <li
            className="flex gap-1 justify-start items-center px-4 py-2 cursor-pointer mb-2 hover:bg-accent dark:hover:bg-dark-bg-600"
            onClick={() => setUnsuspendModal(true)}
          >
            <HiLockOpen /> Unsuspend
          </li>
        ) : (
          <li
            className="flex gap-1 justify-start items-center px-4 py-2 cursor-pointer mb-2 hover:bg-accent dark:hover:bg-dark-bg-600"
            onClick={() => setSuspendModal(true)}
          >
            <MdNoAccounts /> Suspend
          </li>
        )}
      </ul>
    </>
  );
}
