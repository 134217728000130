import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { useState, useEffect, Fragment } from "react";
import { Spinner } from "../../components";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { currencyFormatter } from "../../helpers/currencyFormatter";
import { Formik, Form } from "formik";
import { sendSMS } from "../../helpers/sendsms";

export default function LoanVerify() {
  const { id } = useParams();
  const [loan, setLoan] = useState(null);
  const [bankStatementURL, setBankStatementURL] = useState("");
  const [yearsCashflowURL, setCashflowURL] = useState("");
  const [additionalFilesURL, setAdditionalFilesURL] = useState("");
  const [supportingFilesURL, setSupportingFilesURL] = useState("");
  const [accountsInformation, setAccountsInformation] = useState({
    shares: { balance: 0 },
    savings: { balance: 0 },
  });
  const [recurrentDeductions, setRecurrentDeductions] = useState(0);
  const [loanCount, setLoanCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getApplication()
      .then(async (data) => {
        setLoan(data);
        if (data?.application_meta?.bank_settlement_url && !bankStatementURL) {
          const { data: file, error } = await supabase.storage
            .from("loans")
            .download(
              await data.application_meta.files[0].file_url.substring(5)
            );

          if (error) throw error;
          const url = URL.createObjectURL(file);
          setBankStatementURL(url);
        }
        if (data?.application_meta?.a_years_cashflow_url && !yearsCashflowURL) {
          const { data: file, error } = await supabase.storage
            .from("loans")
            .download(
              await data.application_meta.files[0].file_url.substring(5)
            );

          if (error) throw error;
          const url = URL.createObjectURL(file);
          setCashflowURL(url);
        }
        if (
          data?.application_meta?.additional_files_url &&
          !additionalFilesURL
        ) {
          const { data: file, error } = await supabase.storage
            .from("loans")
            .download(
              await data.application_meta.files[0].file_url.substring(5)
            );

          console.log(file);

          if (error) throw error;
          const url = URL.createObjectURL(file);
          setAdditionalFilesURL(url);
        }
        if (
          data?.application_meta?.supporting_files_url &&
          !supportingFilesURL
        ) {
          const { data: file, error } = await supabase.storage
            .from("loans")
            .download(
              await data.application_meta.supporting_files_url.substring(5)
            );

          if (error) throw error;
          const url = URL.createObjectURL(file);
          setSupportingFilesURL(url);
        }

        getAccountInformation(data.application_meta.applicants_id).catch(
          (error) => console.log(error)
        );
        fetchRecurrendDeductions(data.application_meta.applicants_id).catch(
          (error) => console.log(error)
        );
        getLoanCount(data.application_meta.applicants_id)
          .then((data) => setLoanCount(data))
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }, []);

  const getLoanCount = async (member) => {
    const { data, error } = await supabase
      .from("loans")
      .select("*")
      .match({ member_id: member });

    if (error) throw error;
    return data.length ?? 0;
  };

  const getAccountInformation = async (account) => {
    const { data, error } = await supabase.rpc(
      "get_member_accounts_information",
      { account }
    );
    if (error) throw error;
    console.log("Accounts information: ", data);
    setAccountsInformation(data);
  };

  const fetchRecurrendDeductions = async (member) => {
    const { data, error } = await supabase.rpc(
      "fetch_members_recurrent_deductions",
      { member }
    );
    if (error) throw error;
    console.log("Recurrent deductions", data);
    setRecurrentDeductions(data.total_recurrent_deductions);
  };

  const getApplication = async () => {
    const { error, data } = await supabase
      .from("applications")
      .select()
      .eq("_type", "loan")
      .eq("application_id", id)
      .single();

    if (error) throw error;
    console.log(data);
    return data;
  };

  const approveLoanPaymentTransaction = async (values) => {
    let updated_loan_data = {};
    if (
      loan.application_meta.guarantors.some(
        (guarantor) => guarantor.guarantee_status === "pending"
      )
    ) {
      toast.info(`Loan hasn't been full guaranteed`, {
        position: "top-center",
      });
    } else if (
      loan.application_meta.guarantors.some(
        (guarantor) => guarantor.guarantee_status === "rejected"
      )
    ) {
      toast.error(`The loan has been rejected by some guarantor(s)`, {
        position: "top-center",
      });
    } else {
      const {
        data: { application_update },
        error,
      } = await supabase.rpc("approve_loan", {
        members_id: loan.application_meta.applicant_id,
        application: loan.application_id,
      });

      if (error) console.log("Existing loan approval error: ", error);
      if (error) throw error;

      sendSMS(
        loan.application_meta.applicant_id,
        `Hello ${loan.application_meta.applicant_name}, your loan has been approved by the credits commitee. Loan repayment is expected to start in a months time. For more details visit https://tube.ablestate.co/loans Regards, Bweyogerere Tuberebumu Sacco.`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.log(error, "Error"));

      if (application_update) {
        setLoan((loan) => ({ ...loan, ...application_update }));
        updated_loan_data = { ...loan, ...application_update };
      }
      if (values?.comments) {
        const { data, error } = await supabase
          .from("applications")
          .update({
            application_meta: {
              ...application_update.application_meta,
              comments: values.comments ?? "",
            },
          })
          .match({ application_id: loan.application_id });

        if (error) throw error;
        updated_loan_data = { ...updated_loan_data, ...data };
        setLoan((loan) => updated_loan_data);
      }
      toast.success(`Loan has been approved`, {
        position: "top-center",
      });
    }
  };

  const rejectLoanApplication = async (values) => {
    const { data: application_update, error } = await supabase.rpc(
      "reject_application",
      { application: loan.application_id }
    );
    if (error) throw error;
    setLoan((loan) => ({
      ...loan,
      application_meta: application_update.application_meta,
      reviewed: application_update.reviewed,
    }));
    console.log("Results: ", {
      ...loan,
      application_meta: application_update.application_meta,
    });

    if (values?.comments) {
      const { data, error } = await supabase
        .from("applications")
        .update({
          application_meta: {
            ...application_update.application_meta,
            comments: values.comments ?? "",
          },
        })
        .match({ application_id: loan.application_id })
        .single();

      if (error) throw error;
      if (data) {
        sendSMS(
          loan?.application_meta?.applicant_id,
          `Hello ${
            loan?.application_meta?.applicant_name ?? "member"
          }, we regret to inform you that your loan application has been rejected by the credits commitee. \n
          For more details visit https://tube.ablestate.co/loans \n
          Regards, \n
          Bweyogerere Tuberebumu Sacco.`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error, "Error"));

        setLoan((loan) => data);
        toast.success(`Loan has been rejected`, {
          position: "top-center",
        });
      }
    }
  };

  return (
    <div className="mx-5 my-2 h-[calc(100vh-70px)]">
      <ToastContainer />
      <div className="flex flex-col justify-between pb-3 h-[60px]">
        <h1 className="mb-5 mt-2 font-bold uppercase dark:text-white">
          Verify Loan request
        </h1>
      </div>
      <div className="bg-white p-3 overflow-y-scroll  relative  h-[calc(100%-80px)] dark:bg-dark-bg-700 dark:text-secondary-text">
        {loan ? (
          <div className="flex flex-grow flex-col min-h-full">
            <div className="mb-3">
              <h1 className="font-semibold mb-2">
                {loan.application_meta && loan.application_meta.applicants_name}
                's Loan Request
                <span
                  className={` py-1 px-2 rounded-lg text-white text-xs ml-1 ${
                    !loan?.reviewed
                      ? "bg-yellow-400"
                      : loan?.application_meta?.review_status === "approved"
                      ? "bg-green-400"
                      : "bg-red-400"
                  }`}
                >
                  {!loan?.reviewed
                    ? "Pending Approval"
                    : loan.application_meta.review_status === "approved"
                    ? "Approved"
                    : "Rejected"}
                </span>
              </h1>

              <div className="outline outline-1 outline-gray-100 p-3">
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Application ID:</p>
                  <p className="font-bold col-span-3">{loan.app_id}</p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Application Date:</p>
                  <p className="font-bold col-span-3">
                    {moment(loan.created_at).format("DD-MM-YYYY")}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Applicants Name:</p>
                  <p className="font-bold col-span-3">
                    {loan.application_meta.applicants_name}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Position in SACCO:</p>
                  <p className="font-bold col-span-3 capitalize">
                    {loan?.application_meta?.position_in_sacco}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Loan Type:</p>
                  <p className="font-bold col-span-3 capitalize">
                    {loan.application_meta?.loan_type}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Amount Requested:</p>
                  <p className="font-bold col-span-3">
                    UGX{" "}
                    {loan.application_meta &&
                      currencyFormatter(loan.application_meta.amount)}{" "}
                    (
                    {loan.application_meta &&
                      loan.application_meta.amount_in_words}
                    )
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Loan Purpose:</p>
                  <p className="font-bold col-span-3">
                    {loan.application_meta.loan_purpose}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Assets:</p>
                  <p className="font-bold col-span-3">
                    {loan.application_meta.asset1},{" "}
                    {loan.application_meta.asset2},{" "}
                    {loan.application_meta.asset3}
                  </p>
                </div>

                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Landline:</p>
                  <p className="font-bold col-span-3">
                    {loan.application_meta?.landline_number}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">District:</p>
                  <p className="font-bold col-span-3 capitalize">
                    {loan.application_meta?.district}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">County:</p>
                  <p className="font-bold col-span-3 capitalize">
                    {loan.application_meta?.county}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Sub County:</p>
                  <p className="font-bold col-span-3 capitalize">
                    {loan.application_meta?.sub_county}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Parish:</p>
                  <p className="font-bold col-span-3 capitalize">
                    {loan.application_meta?.parish}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">SubParish:</p>
                  <p className="font-bold col-span-3 capitalize">
                    {loan.application_meta?.sub_parish}
                  </p>
                </div>

                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Next of Kin:</p>
                  <p className="font-bold col-span-3">
                    {loan.application_meta?.kin_name}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Next of Kin Contact:</p>
                  <p className="font-bold col-span-3">
                    {loan.application_meta?.kin_contact}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Interest Rate:</p>
                  <p className="font-bold col-span-3">
                    {loan.application_meta?.interest_rate}%
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Securities:</p>
                  <p className="font-bold col-span-3">
                    {loan.application_meta?.securities.map(
                      (security, index) => {
                        return (
                          <span key={index} className="capitalize">
                            {security},{" "}
                          </span>
                        );
                      }
                    )}
                  </p>
                </div>

                <div className="border-t-[0.5px] pt-2 mt-2">
                  <h1>Guarantors: </h1>
                  {loan.application_meta?.guarantors &&
                    loan.application_meta.guarantors.map((guarantor, index) => {
                      return (
                        <Fragment key={index}>
                          <div className="grid grid-cols-8 gap-2 mb-2 justify-start w-full">
                            <p className="col-span-1 flex items-center justify-center font-bold">
                              {" "}
                              {index + 1}
                            </p>
                            <div className="col-span-2 flex flex-col mb-2 justify-start w-full">
                              <p className="col-span-2 font-bold">Name</p>
                              <p className="font-normal col-span-3">
                                {guarantor.name}
                              </p>
                            </div>
                            <div className="  flex flex-col mb-2 justify-start w-full">
                              <p className="col-span-2 font-bold">Contact</p>
                              <p className="font-normal col-span-3">
                                {guarantor.contact}
                              </p>
                            </div>
                            <div className="col-span-2 flex flex-col mb-2 justify-start w-full">
                              <p className="col-span-2 font-bold">
                                Guarantee Status
                              </p>
                              <p className={`font-normal col-span-3`}>
                                <span
                                  className={`py-1 px-2 rounded-full capitalize text-sm font-semibold text-white ${
                                    guarantor.guarantee_status === "pending"
                                      ? "bg-yellow-400"
                                      : guarantor.guarantee_status ===
                                        "rejected"
                                      ? "bg-red-400"
                                      : guarantor.guarantee_status ===
                                          "approved" && "bg-green-400"
                                  }`}
                                >
                                  {guarantor.guarantee_status === "pending"
                                    ? "pending approval"
                                    : guarantor.guarantee_status}
                                </span>
                              </p>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                </div>
                {loan.application_meta?.bank_loans &&
                  loan.application_meta.bank_loans[0]?.name && (
                    <div className="border-t-[0.5px] pt-2 mt-2">
                      <h1>Other Bank Loans: </h1>
                      {loan.application_meta.bank_loans
                        .filter((loan) => loan?.name?.length > 0)
                        .map((loan, index) => {
                          return (
                            <Fragment key={index}>
                              <div className="grid grid-cols-8 gap-2 mb-2 justify-start w-full">
                                <p className="col-span-1 flex items-center justify-center font-bold">
                                  {" "}
                                  {index + 1}
                                </p>
                                <div className="col-span-2 flex flex-col mb-2 justify-start w-full">
                                  <p className="col-span-1 font-bold">Name</p>
                                  <p className="font-normal col-span-3">
                                    {loan.name}
                                  </p>
                                </div>
                                <div className="  flex flex-col mb-2 justify-start w-full">
                                  <p className="col-span-2 font-bold">
                                    balance
                                  </p>
                                  <p className="font-normal col-span-3">
                                    {loan.balance}
                                  </p>
                                </div>
                                <div className="  flex flex-col mb-2 justify-start w-full">
                                  <p className="col-span-2 font-bold">
                                    Date granted
                                  </p>
                                  <p className="font-normal col-span-3">
                                    {loan.date_granted}
                                  </p>
                                </div>
                                <div className="  flex flex-col mb-2 justify-start w-full">
                                  <p className="col-span-2 font-bold">
                                    balance
                                  </p>
                                  <p className="font-normal col-span-3">
                                    {loan.amount_advanced}
                                  </p>
                                </div>
                                <div className="  flex flex-col mb-2 justify-start w-full">
                                  <p className="col-span-2 font-bold">
                                    balance
                                  </p>
                                  <p className="font-normal col-span-3">
                                    {loan.repayment_period} months
                                  </p>
                                </div>
                              </div>
                            </Fragment>
                          );
                        })}
                    </div>
                  )}

                {loan.application_meta?.spouse_name && (
                  <>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Spouse:</p>
                      <p className="font-bold col-span-3">
                        {loan.application_meta?.spouse_name}
                      </p>
                    </div>
                    <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                      <p className="col-span-2">Next of spouse Contact:</p>
                      <p className="font-bold col-span-3">
                        {loan.application_meta?.spouse_contact}
                      </p>
                    </div>
                  </>
                )}
                <div className="border-t-[0.5px] pt-4 mt-2">
                  <p className="mb-2">Files and statements</p>
                  {bankStatementURL && (
                    <div className="m-1">
                      <p> Bank statement</p>
                      <a
                        className="p-2 text-sky-400 underline mt-2"
                        href={bankStatementURL}
                        title="Bank statement"
                        download={true}
                        target="_blank"
                      >
                        Preview file
                      </a>
                    </div>
                  )}
                  {yearsCashflowURL && (
                    <div className="m-1">
                      <p> One year's cashflow </p>
                      <a
                        className="p-2 text-sky-400 underline mt-2"
                        href={yearsCashflowURL}
                        title="A year's Cash Flow"
                        download={true}
                        target="_blank"
                      >
                        Preview file
                      </a>
                    </div>
                  )}
                  {additionalFilesURL && (
                    <div className="m-1">
                      <p> Additional Files </p>
                      <a
                        className="p-2 text-sky-400 underline mt-2"
                        href={additionalFilesURL}
                        title="Additional Files"
                        download={true}
                        target="_blank"
                      >
                        Preview file
                      </a>
                    </div>
                  )}
                  {supportingFilesURL && (
                    <div className="m-1">
                      <span> Supporting Files: </span>
                      <a
                        className="p-2 text-sky-400 underline mt-2 font-light"
                        href={supportingFilesURL}
                        title="Supporting Files"
                        target="_blank"
                      >
                        Preview files
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {loan?.application_meta?.comments && (
              <div className="bg-stone-200 p-4 rounded-sm">
                <h1 className="font-semibold">Remarks</h1>
                <p className="p-2 mb-3">{loan?.application_meta?.comments}</p>
              </div>
            )}
            <div>
              <p className="font-bold mb-2">Loan history</p>
              <div className="flex flex-col">
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Total number of loans taken:</p>
                  <p className="font-bold col-span-3">{loanCount ?? 0}</p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Recurrent deductions:</p>
                  <p className="font-bold col-span-3">
                    {`UGX ${currencyFormatter(recurrentDeductions)}`}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Total shares:</p>
                  <p className="font-bold col-span-3">
                    {accountsInformation && accountsInformation?.shares?.balance
                      ? `UGX ${currencyFormatter(
                          accountsInformation?.shares?.balance
                        )}`
                      : "UGX 0"}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Total savings:</p>
                  <p className="font-bold col-span-3">
                    {accountsInformation &&
                    accountsInformation?.savings?.balance
                      ? `UGX ${currencyFormatter(
                          accountsInformation?.savings?.balance
                        )}`
                      : "UGX 0"}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Amount recommended:</p>
                  <p className="font-bold col-span-3">
                    {loan?.application_meta?.amount >
                    (accountsInformation?.shares?.balance ?? 0) * 2
                      ? `UGX ${currencyFormatter(
                          (accountsInformation?.shares?.balance ?? 0) * 2
                        )}`
                      : `UGX ${currencyFormatter(
                          loan?.application_meta?.amount
                        )}`}
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2 mb-2 justify-start w-full">
                  <p className="col-span-2">Maximum credit:</p>
                  <p className="font-bold col-span-3">
                    {`UGX ${currencyFormatter(
                      (accountsInformation?.shares?.balance ?? 0) * 2
                    )}`}
                  </p>
                </div>
              </div>
              <button
                className="bg-blue-400 text-white p-2 mt-2 mb-5"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  navigate(`/loans/members`);
                }}
              >
                View Loans
              </button>
            </div>

            {loan?.application_meta?.review_status === "pending" && (
              <Formik
                initialValues={{
                  comments: "",
                }}
              >
                {({ handleChange, handleBlur, values }) => {
                  return (
                    <Form>
                      <div className="mb-3">
                        <h1 className="font-semibold">Comments</h1>
                        <textarea
                          name="comments"
                          id="comments"
                          cols="30"
                          rows="4"
                          className="outline outline-1 rounded-md w-full p-2 dark:bg-dark-bg-600"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></textarea>
                      </div>
                      <div className="flex gap-10 justify-end items-center mt-3">
                        <button
                          className="bg-accent-red inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2"
                          type="button"
                          onClick={(event) => {
                            event.preventDefault(values);
                            try {
                              rejectLoanApplication(values);
                            } catch (error) {
                              toast.error(`${error.message}`, {
                                position: "top-center",
                              });
                            }
                          }}
                        >
                          Reject
                        </button>
                        <button
                          className={`bg-green-600 inline-flex items-center justify-center  text-white text-base font-medium px-4 py-2 ${
                            loan.application_meta.guarantors.some(
                              (guarantor) =>
                                guarantor.guarantee_status === "rejected"
                            )
                              ? "hidden"
                              : "visible"
                          }`}
                          onClick={async (event) => {
                            event.preventDefault();
                            try {
                              await approveLoanPaymentTransaction(values);
                            } catch (error) {
                              console.log(error);
                              toast.error(`${error.message}`, {
                                position: "top-center",
                              });
                            }
                          }}
                        >
                          Approve
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
