import { useState, useEffect, Fragment } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { ContextMenu } from "../../components";
import { MemberModal } from "../../components";
import { Pagination } from "../../components";
import { ConfirmModal, PromoteModal } from "../../components";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../helpers/supabase";
import { Spinner, NothingShown } from "../../components";
import { useLocation, useOutletContext } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MdDownload } from "react-icons/md";
import { generateReportFromJson } from "../../helpers/generateReportFromJson";
import { Helmet } from "react-helmet";
import Frown from "../../components/Frown";
import moment from "moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { sendSMS } from "../../helpers/sendsms";

export default function Members() {
  useEffect(() => {
    // Fetch members on component render
    fetch_members().catch((error) => console.log(error));

    // Realtime
    const mySubscription = supabase
      .from("users")
      .on("*", async (payload) => {
        console.log("Payload: ", payload);
       
      })
      .subscribe();

    return () => supabase.removeSubscription(mySubscription);
  }, []);

  const [, profile, , roles] = useOutletContext();

  const unsuspendMember = async (member) => {
    setUnsuspendModal(false);
    const { data, error } = await supabase
      .from("users")
      .update({ suspended: false })
      .eq("id", member.id)
      .single();

    if (error) {
      toast.error(`${error.message}`, { position: "top-center" });
    } else if (data) {
      sendSMS(
        member.id,
        `Good news, ${member.fullname}! Your sacco account has been unsuspended. Please visit https://tube.ablestate.co/ to resume your activities as a member. Thankyou.`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.log(error, "Error"));

      setAllMembers((allMembers) =>
        allMembers.map((member) => {
          if (member.id === data.id) {
            return { ...member, suspended: data.suspended };
          }
          return member;
        })
      );

      toast.success(`${member.fullname} has been unsuspended`, {
        position: "top-center",
      });
    }
  };

  const fetch_members = async () => {
    const { data, error } = await supabase.rpc("fetch_members_only");
    if (error) throw error;
    if (!data || data?.length === 0) setNothingShown(true);
    setMembers(data);
    setAllMembers(data);
    setLoading(false);
  };

  const [nothingShown, setNothingShown] = useState(false);
  const [members, setMembers] = useState([]);
  const [date, setDate] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [show, setShow] = useState(false);

  const [memberModal, setMemberModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [suspendModal, setSuspendModal] = useState(false);
  const [unsuspendModal, setUnsuspendModal] = useState(false);
  const [promoteModal, setPromoteModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [allMembers, setAllMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [demoteModal, setDemoteModal] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawPerPage, setWithdrawPerPage] = useState(10);
  const indexOfLastPage = currentPage * withdrawPerPage;
  const indexOfFirstPage = indexOfLastPage - withdrawPerPage;

  useEffect(() => {
    let data = allMembers;

    data =
      data && data?.length > 0
        ? data.filter(
            (member) =>
              !member?.fullname ||
              member?.fullname
                ?.toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
          )
        : null;
    data =
      data && data?.length > 0
        ? data.filter((member) => !status || member?.member_status === status)
        : null;
    data =
      data && data?.length > 0
        ? data.filter(
            (member) =>
              !date ||
              (member?.created_at &&
                member.created_at.substring(0, 10) === date)
          )
        : null;

    setMembers(data);
  }, [searchText, date, status, allMembers]);

  const demoteMember = async (admin) => {
    const toastID = toast.loading("Demoting member");

    const { error, data } = await supabase
      .from("new_members")
      .update({ roles: ["member"], position_in_sacco: "member" })
      .eq("id", admin.id)
      .single();

    if (error) {
      toast.error(toastID, {
        render: `Failed to demote ${admin.fullname}.`,
        isLoading: false,
        autoClose: 5000,
        type: "error",
      });
    } else if (data) {
      toast.update(toastID, {
        render: `Demoted ${admin.fullname}.`,
        isLoading: false,
        autoClose: 5000,
        type: "success",
      });

      sendSMS(
        admin.id,
        `Hello ${admin.fullname}, your status has been changed to a regular sacco member. As a result, you will no longer be performing executive activities. Thank you for your service. If you have any questions, please let us know. Regards, Bweyogerere Tuberebumu Sacco.`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.log(error, "Error"));

      setAllMembers((allMembers) =>
        allMembers.map((member) =>
          member.id === data.id
            ? {
                ...member,
                roles: data.roles,
                position_in_sacco: data.position_in_sacco,
              }
            : member
        )
      );

      const { error } = await supabase
        .from("notifications")
        .insert({
          created_at: new Date()
            .toISOString()
            .toLocaleString("en-GB", { timeZone: "UTC" }),
          updated_at: new Date()
            .toISOString()
            .toLocaleString("en-GB", { timeZone: "UTC" }),
          message: `Hey ${
            admin.fullname.split(" ")[0]
          }, you have been demoted to a normal sacco member. Thank you for your service on the sacco's executive. It was good working with you.`,
          receiver: admin.id,
          seen: false,
          meta: {
            type: "demotion",
          },
        })
        .single();

      if (error) throw error;
    }

    setDemoteModal(false);
  };

  const shownMembers =
    members && members?.length > 0
      ? members.slice(indexOfFirstPage, indexOfLastPage)
      : [];

  if (show === true) {
    window.onclick = function (event) {
      if (!event.target.matches(".dialog")) {
        setShow(false);
      }
    };
  }

  const export_members = () => {
    const formattedMembers = members.map((member) => {
      return {
        Name: member.fullname,
        "Email Address": member.email_address ?? "Not specified",
        "Date of Birth": member.dob ?? "Not specified",
        Gender: member.gender ?? "Not specified",
        "ID / Passport Number": member.id_passport_number ?? "Not specified",
        "Marital Status": member.marital_status ?? "Not specified",
        "Phone Number": member.phone_number ?? "Not specified",
        "Position in Sacco": member.position_in_sacco ?? "Not specified",
      };
    });

    generateReportFromJson(
      formattedMembers,
      "Members " + new Date().toISOString().substring(0, 10)
    );
  };

  return (
    <div className="mx-5 my-2 md:h-[calc(100vh-70px)]">
      <ToastContainer />
      <Helmet>
        <title>Members - Bweyogere tuberebumu</title>
      </Helmet>
      <div className="flex flex-col justify-between pb-3 md:h-[150px]">
        <h1 className="mb-3 mt-2 font-bold uppercase dark:text-white">
          Members
        </h1>
        <div className=" flex justify-between flex-wrap">
          <input
            type="text"
            className="w-8/12 rounded-md px-2 py-2 sm:py-1 dark:bg-dark-bg-600"
            placeholder="Search"
            onChange={(event) => setSearchText(event.target.value)}
          />
          {profile?.roles && !profile?.roles?.includes("super_admin") && (
            <button
              className=" px-4 bg-primary py-2 text-white rounded-md flex justify-center items-center"
              onClick={() => {
                navigate("/application", {
                  state: { from: location.pathname },
                });
              }}
            >
              Add Member
            </button>
          )}
        </div>
        <div className="flex justify-between flex-wrap">
          <div className="flex text-sm bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded">
            <select
              name="status"
              id=""
              className="py-2 px-4 rounded-l bg-white dark:bg-dark-bg-600 dark:text-secondary-text"
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              value={status}
            >
              <option value="">Status</option>
              <option value="active">Active</option>
              <option value="dormant">Dormant</option>
            </select>
            <button
              className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle px-3 py-2 text-gray-600 font-bold flex text-sm items-center rounded-r"
              onClick={() => {
                if (status) setStatus("");
              }}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
          <div className="flex gap-2 flex-wrap">
            <div className="flex min-w-fit bg-white dark:bg-dark-bg-600 dark:text-secondary-text rounded text-sm">
              <input
                type="date"
                name=""
                onChange={(event) => setDate(event.target.value)}
                id="inputDate"
                className="py-2 px-2 rounded-l dark:bg-dark-bg-600 dark:text-secondary-text"
                value={date}
              />
              <button
                className="bg-white dark:bg-dark-bg-600 dark:text-secondary-text align-text-middle pr-3 py-2 text-gray-600 font-bold flex items-center rounded-r"
                onClick={() => {
                  if (date) setDate("");
                  document.getElementById("inputDate").value = "";
                }}
              >
                <AiOutlineCloseCircle size={20} />
              </button>
            </div>
            <button
              className="bg-green-500 align-text-middle px-3 py-2 text-white font-bold rounded flex items-center"
              onClick={() => {
                export_members();
              }}
              disabled={!allMembers || allMembers?.length === 0}
            >
              Export
              <MdDownload className="ml-1" />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden  relative  md:h-[calc(100%-170px)] dark:bg-dark-bg-700">
        {loading ? (
          <Spinner />
        ) : members !== null && shownMembers.length > 0 ? (
          <>
            <div className="w-full pb-3 overflow-x-auto h-full  relative overflow-y-auto ">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase  bg-gray-700 dark:bg-gray-700">
                  <tr>
                    <th></th>
                    <th className="px-6 py-4">Date</th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">ID</th>
                    <th className="px-6 py-4 whitespace-nowrap">
                      Phone Number
                    </th>
                    <th className="px-6 py-4">Status</th>
                    {roles &&
                      roles?.length > 0 &&
                      roles.includes("super_admin") && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {shownMembers.map((member, index) => (
                    <Fragment key={index}>
                      <tr
                        className={`${
                          index % 2 === 0 ? "bg-gray-50 dark:bg-dark-bg" : ""
                        } hover:bg-gray-100 dark:hover:bg-dark-bg-600 cursor-pointer`}
                        key={index}
                      >
                        {deleteModal && activeIndex === index && (
                          <ConfirmModal setPopUp={setDeleteModal}>
                            <h1 className="font-bold">Are you sure?</h1>
                            <p>
                              If you terminate this account,{" "}
                              {member.fullname.toUpperCase()} can't recover it.
                            </p>
                            <div className="flex justify-end gap-3 mt-3">
                              <button
                                className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                                onClick={() => setDeleteModal(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-accent-red px-3 py-1 outline outline-1  rounded-md text-white"
                                onClick={() => setDeleteModal(false)}
                              >
                                Delete
                              </button>
                            </div>
                          </ConfirmModal>
                        )}
                        {suspendModal && activeIndex === index && (
                          <ConfirmModal setPopUp={setSuspendModal}>
                            <h1 className="font-bold">Are you sure?</h1>
                            <p>
                              {member.fullname.toUpperCase()} won't be able to
                              use it until you unsuspend.
                            </p>
                            <div className="flex justify-end gap-3 mt-3">
                              <button
                                className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                                onClick={() => {
                                  setSuspendModal(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-accent-red px-3 py-1 outline outline-1  rounded-md text-white"
                                onClick={async () => {
                                  const { error, data } = await supabase
                                    .from("users")
                                    .update({ suspended: true })
                                    .eq("id", member.id)
                                    .single();

                                  if (error) {
                                    toast.error(`${error.message}`, {
                                      position: "top-center",
                                    });
                                  } else if ( data ){

                                    setAllMembers((allMembers) =>
                                      allMembers.map((member) => {
                                        if (member.id === data.id) {
                                          return {
                                            ...member,
                                            suspended: data.suspended,
                                          };
                                        }
                                        return member;
                                      })
                                    );

                                    console.log("Data Id: ", data )
                                    console.log("Member Id: ", member.id)

                                    sendSMS(
                                      member.id,
                                      `Hey ${member.fullname}, we regret to inform you that your account has been temporarily suspended. As a result, you will any sacco activities through the system. If you have any questions, contact the Sacco's executive. Regards, Bweyogerere Tuberebumu Sacco.`
                                    )
                                      .then((response) => response.json())
                                      .then((data) => {
                                        console.log(data);
                                      })
                                      .catch((error) =>
                                        console.log(error, "Error")
                                      );

                                    toast.success(
                                      `${member.fullname} has been suspended`,
                                      { position: "top-center" }
                                    );
                                  }
                                  setSuspendModal(false);
                                }}
                              >
                                Suspend
                              </button>
                            </div>
                          </ConfirmModal>
                        )}
                        {demoteModal && activeIndex === index && (
                          <ConfirmModal setPopUp={setSuspendModal}>
                            <h1 className="font-bold">Are you sure?</h1>
                            <p>
                              You are demoting {member.fullname.toUpperCase()}
                            </p>
                            <div className="flex justify-end gap-3 mt-3">
                              <button
                                className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                                onClick={() => {
                                  setDemoteModal(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-accent-red px-3 py-1 outline outline-1  rounded-md text-white"
                                onClick={() => demoteMember(member)}
                              >
                                Demote
                              </button>
                            </div>
                          </ConfirmModal>
                        )}
                        {unsuspendModal && activeIndex === index && (
                          <ConfirmModal setPopUp={setUnsuspendModal}>
                            <h1 className="font-bold">Are you sure?</h1>
                            <p>
                              You are unsupending{" "}
                              {member.fullname.toUpperCase()}
                            </p>
                            <div className="flex justify-end gap-3 mt-3">
                              <button
                                className="px-3 py-1 outline outline-1 outline-gray-500 rounded-md text-gray-500"
                                onClick={() => {
                                  setUnsuspendModal(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-green-500 px-3 py-1 outline outline-1  rounded-md text-white"
                                onClick={() => unsuspendMember(member)}
                              >
                                Unsuspend
                              </button>
                            </div>
                          </ConfirmModal>
                        )}
                        {promoteModal && activeIndex === index && (
                          <PromoteModal
                            setPromoteModal={setPromoteModal}
                            member={member}
                            setAllMembers={setAllMembers}
                          />
                        )}
                        <td
                          onClick={() => {
                            setActiveIndex(index);
                            setMemberModal(true);
                          }}
                        >
                          <span className="ml-2 px-4 py-3 text-sm">&gt;</span>
                        </td>
                        <td>
                          {moment(member.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td
                          className="px-6 py-3"
                          onClick={() => {
                            setActiveIndex(index);
                            setMemberModal(true);
                          }}
                        >
                          {member.fullname}
                        </td>
                        <td
                          className="px-6 py-3"
                          onClick={() => {
                            setActiveIndex(index);
                            setMemberModal(true);
                          }}
                        >
                          {member.member_id}
                        </td>
                        <td
                          className="px-6 py-3"
                          onClick={() => {
                            setActiveIndex(index);
                            setMemberModal(true);
                          }}
                        >
                          {member.phone_number}
                        </td>

                        <td
                          className={`px-6 py-3 font-semibold`}
                          onClick={() => {
                            setActiveIndex(index);
                            setMemberModal(true);
                          }}
                        >
                          <span
                            className={` py-1 px-2 rounded-xl text-white ${
                              member.member_status === "active"
                                ? "bg-emerald-600"
                                : "bg-rose-600"
                            }`}
                          >
                            {member.member_status}
                          </span>
                        </td>
                        {roles &&
                          roles?.length > 0 &&
                          roles.includes("super_admin") && (
                            <td className="p-2">
                              <div className="relative">
                                <button
                                  className="block p-2 rounded-md dialog"
                                  onClick={(event) => {
                                    setActiveIndex(index);
                                    setShow(!show);
                                    event.stopPropagation();
                                  }}
                                >
                                  <FaEllipsisV />
                                </button>
                                <ContextMenu
                                  activeIndex={activeIndex}
                                  show={show}
                                  index={index}
                                  setShow={setShow}
                                  setMemberModal={setMemberModal}
                                  setDeleteModal={setDeleteModal}
                                  setSuspendModal={setSuspendModal}
                                  setUnsuspendModal={setUnsuspendModal}
                                  setPromoteModal={setPromoteModal}
                                  setDemoteModal={setDemoteModal}
                                  member={activeIndex === index ? member : null}
                                  profile={profile}
                                  memberDetails={member}
                                />
                              </div>
                            </td>
                          )}
                      </tr>
                      {memberModal && activeIndex === index && (
                        <MemberModal
                          member={activeIndex === index && member}
                          setMemberModal={setMemberModal}
                          memberModal={memberModal}
                        />
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex bg-white dark:bg-dark-bg-700 justify-between md:absolute left-0 right-0 bottom-0 px-5 py-1">
              <Pagination
                pages={Math.ceil(members.length / withdrawPerPage)}
                setCurrentPage={setCurrentPage}
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPage={indexOfLastPage}
                data={members}
                depositsPerPage={withdrawPerPage}
                setDepositsPerPage={setWithdrawPerPage}
              />
            </div>
          </>
        ) : nothingShown ? (
          <NothingShown />
        ) : (
          allMembers.length > 0 &&
          (shownMembers?.length === 0 || !shownMembers) && <Frown />
        )}
      </div>
    </div>
  );
}
