import { useLocation } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import ReportPDF from "./ReportPDF";

function Report() {
  const {
    state: {
      data: { report_data, report_name },
    },
  } = useLocation();

  console.log("Report: ", report_data);
  console.log("Report Name: ", report_name);

  return (
    <div className="flex-grow flex">
      <PDFViewer style={{ flex: 1, height: "95vh" }}>
        <ReportPDF reportName={report_name} reportData={report_data}/>
      </PDFViewer>
    </div>
  );
}

export default Report;